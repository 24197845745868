/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\login\index.tsx
 * @LastEditTime: 2024-07-27 11:21:51
 */

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAsync } from "react-use";
import { Tooltip, Modal, Form, Input, Menu, Carousel, Image, Button, message } from 'antd';
import { reqGetAuth,reqLogin } from 'api/api';
import _ from 'lodash';
import styles from './index.module.scss';
import Cookies from "js-cookie";
import { setProfile } from "utils/auth";

const Login: React.FC = () => {
  const query = new URLSearchParams(useLocation().search)
  const token = query.get("token") || "";
  const redirect = query.get("redirect");

  const init = useAsync(async () => {
    if (token) {
      // Cookies.set("token", token);  
      setProfile(token,{test: false});
      console.log(token);
      
      reqGetAuth().then(res => {
        const profile = {
          accountid: _.get(res, "dataInfo.accountId"),
          // isAdmin: _.get(data, "Data.profile.isAdmin"),
          nick: _.get(res, "dataInfo.username"),
          test: true,
          userId: _.get(res, "dataInfo.userId"),
          userMobilePhone: "",
          userTrueName: _.get(res, "dataInfo.username"),
        };
        setProfile(token,profile);
        // const str = JSON.stringify(profile);
        // Cookies.set("profile", str); 
        // sessionStorage.setItem("profile", str);
        window.location.href = redirect || "/manager"
      })
    }
  })

  const handleLogin= async (values:any)=>{
    reqLogin(values).then(res=>{
      if (res.Result===200){
        // Cookies.set("token", res.Data.Token);
        // sessionStorage.setItem("token", res.Data.Token);
        const profile = {
          accountid: _.get(res, "Data.profile.accountid"),
          isAdmin: _.get(res, "Data.profile.isAdmin"),
          nick: _.get(res, "Data.profile.Nick"),
          test: true,
          userId: _.get(res, "Data.profile.UserId"),
          userMobilePhone:  _.get(res, "Data.profile.UserMobilePhone"),
          userTrueName: _.get(res, "Data.profile.UserTrueName"),
        };
        
        // const str = JSON.stringify(profile);
        // Cookies.set("profile", str)
        setProfile(res.Data.Token,profile);
        // sessionStorage.setItem("profile", str)
      } else {
        message.error(res.Message)
      }
      window.location.href = redirect || "/manager"
    })
  }

  return init.loading || (token) ?
    // <Loading />
    <div></div>
    :
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 8 }} style={{ paddingTop: 20 }} name="login"
      onFinish={handleLogin}>
      <Form.Item label="手机号" name="phone" rules={[{ required: true, message: '请输入手机号' }]}>
        <Input />
      </Form.Item>

      <Form.Item label="密码" name="password" rules={[{ required: true, message: '请输入密码' }]}>
        <Input.Password />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 8 }}>
        <Button type="primary" htmlType="submit">
          登录
        </Button>
      </Form.Item>
    </Form>
};

export default Login;
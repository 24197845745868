/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\pagemenuedit\index.tsx
 * @LastEditTime: 2023-01-06 17:41:30
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Table, AutoComplete, Button, Switch, message } from 'antd';
import ColorInput from 'components/common/colorinput';
import { NewGuid } from 'utils/common';
import MenuItemList from './menuitemlist';
import _ from 'lodash';
import styles from './index.module.scss';
import { fontFamilys, fontSizes } from "utils/componentdefine";

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'pagemenu',
  key: NewGuid(),
  title: '导航',
  items: [],  //{key: '1',label: '',linkType: 0,linkUrl: 'main',children: []}
  backgroundColor: '',  //背景色
  fontSize: 'inherit',
  fontFamily: 'inherit',
  color: 'inherit', //字色
  fontWeight: false,
  intalic: false,
  underline: false,
})

const columns = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
}]

const PageMenuEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑导航'
      width={850}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="backgroundColor"
          label="背景色"
        >
          {/* <AutoComplete options={[{ value: 'transparent' }, { value: 'red' }, { value: 'green' }, { value: 'blue' }]} /> */}
          <ColorInput customColors={['transparent']} />
        </Form.Item>
        <Form.Item
          name="fontSize"
          label="字体大小"
          rules={[{ required: true }]}
        >
          <AutoComplete options={fontSizes} />
        </Form.Item>
        <Form.Item
          name="fontFamily"
          label="字体"
          rules={[{ required: true }]}
        >
          <AutoComplete options={fontFamilys.map((item: any) => {
            return {
              value: item,
              label: item
            }
          })} />
        </Form.Item>
        <Form.Item
          name="color"
          label="字色"
          rules={[{ required: true }]}
        >
          {/* <AutoComplete options={[{ value: 'inherit' }, { value: 'black' }, { value: 'white' }]} /> */}
          <ColorInput customColors={['inherit']} />
        </Form.Item>
        <Form.Item
          name="fontWeight"
          label="粗体"
          rules={[{ required: true }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="intalic"
          label="斜体"
          rules={[{ required: true }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="underline"
          label="下划线"
          rules={[{ required: true }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="items"
          label="菜单"
          rules={[{ required: true }]}
        >
          <MenuItemList />
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default PageMenuEdit;
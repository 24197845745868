/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\articlegroup\index.tsx
 * @LastEditTime: 2023-05-29 10:40:43
 */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Tooltip, Modal, Form, Input, Carousel, Image, Button, Space, Popconfirm, Table } from 'antd';
import { reqGetArticleGroups, reqAddArticleGroup, reqUpdateArticleGroup, reqRemoveArticleGroup } from 'api/api';
import _ from 'lodash';
import styles from './index.module.scss';
import moment from "moment";


const ArticleGroup = () => {

  const [articleGroups, setArticleGroups] = useState<any>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [articleGroup, setArticleGroup] = useState<any>({});
  const [openMode, setOpenMode] = useState(0); //0：浏览 1：新增 2：修改
  const [form] = Form.useForm();

  const organization = useSelector((state: any) => state.global.organization);

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index'
    },
    {
      title: '分类名称',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => { handleEdit(record) }}>修改</a>
          <Popconfirm
            title={`确认删除?`}
            onConfirm={() => { handleDel(record) }}>
            <a >删除</a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  useEffect(() => {
    handleRefresh();
  }, [organization]);

  const handleRefresh = () => {
    if (organization?.organizationGuid) {
      const param = {
        organizationId: organization?.organizationGuid,
        articletype: 0,
      }
      reqGetArticleGroups(param).then(res => {
        setArticleGroups(res.items
          .sort((a: any, b: any) => { return moment(a.createdAt).diff(moment(b.createdAt), 'second') })
          .map((item: any, index: number) => ({
            ...item,
            index: index + 1,
          })));
      })
    }
  }

  const handleNew = () => {
    form.setFieldsValue({
      articlegroup: '',
    });
    setOpenMode(1);
    setModalVisible(true);
  }

  const handleEdit = (record: any) => {
    setArticleGroup(record);
    form.setFieldsValue({
      articlegroup: record.articlegroup,
    });
    setOpenMode(2);
    setModalVisible(true);
  }

  const handleDel = (record: any) => {
    reqRemoveArticleGroup(record.id).then(res => {
      message.success(res.message);
      handleRefresh();
    })
  }

  const handleOK = () => {
    form.validateFields().then(async (formdata) => {
      let res: any = {};
      if (openMode === 1) {
        res = await reqAddArticleGroup({
          organizationId: organization.organizationGuid,
          articletype: 0,
          title: formdata.title,
        });
        message.success(res.message);
      }
      else if (openMode === 2) {
        res = await reqUpdateArticleGroup({
          id: articleGroup.id,
          articletype: 0,
          title: formdata.title,
        });
        message.success(res.message);
      }
      setModalVisible(false);
      setOpenMode(0);
      handleRefresh();
    });
  }

  const handleCancel = () => {
    setModalVisible(false);
    setOpenMode(0);
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        文章分类管理
      </div>
      <div className={styles.tools}>
        <Button type="primary" className={styles.btn} onClick={handleNew}>新增</Button>
      </div>
      <div className={styles.content}>
        <Table columns={columns}
          scroll={{ y: 380 }}
          dataSource={articleGroups} />
      </div>
      <Modal title='编辑'
        visible={modalVisible}
        onOk={handleOK}
        onCancel={handleCancel}>
        <Form
          form={form}
          name="control-ref"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          preserve={false}
        >
          {/* 显示标题 */}
          <Form.Item
            name="title"
            label="文章分类"
            rules={[{ required: true, max: 20 }]}
            style={{ margin: "0" }}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
};

export default ArticleGroup;
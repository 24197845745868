/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\common\taginput\index.jsx
 * @LastEditTime: 2023-02-10 11:41:37
 */
import React, { useState, useEffect } from "react";
import { Button, Input, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { showColors } from 'utils/componentdefine';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  value?: string[];
  onChange?: (value: any) => void;
}

//分类标签录入
const TagInput: React.FC<IProps> = (props: IProps) => {
  const { value, onChange } = props;
  const [tag, setTag] = useState('');

  //新增
  const handleClick = () => {
    if (tag === '') return;
    let tempvalue = _.cloneDeep(value || []);
    if (tempvalue.some(o => o === tag)) return;
    tempvalue.push(tag);
    onChange && onChange(tempvalue);
    setTag('');
  }

  const handleTagClose = e => {
    let tempvalue = _.cloneDeep(value);
    _.remove(tempvalue, o => o === e);
    
    onChange && onChange(tempvalue);
  }

  return (
    <div>
      <Input.Group compact>
        <Input
          // className={styles.input}
          style={{
            width: "calc(100% - 32px)"
          }}
          value={tag}
          onChange={e => { setTag(e.target.value) }} />
        <Button icon={<PlusOutlined />} onClick={handleClick} />
      </Input.Group>
      <div className={styles.tag}>
        {(value || []).map((item, index) => (<Tag
          closable
          key={`tag${index}`}
          color={showColors[index % showColors.length]}
          onClose={(e) => { handleTagClose(item) }}>
          {item}
        </Tag>))}
      </div>
    </div>

  );
};

export default TagInput;
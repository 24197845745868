/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\componentlist\index.tsx
 * @LastEditTime: 2023-03-02 15:02:45
 */

import React, { useEffect, useState, useRef } from "react";
import {
  Drawer, MenuProps, Modal, Form, Input, Card, message,
  Tooltip, Popconfirm, Tag, Dropdown, Button
} from 'antd';
import { CheckCircleOutlined, EyeOutlined, EditOutlined, DeleteOutlined, CopyOutlined, PlusOutlined, MoreOutlined, SettingOutlined } from '@ant-design/icons';
import { reqGetComponents, reqAddComponent, reqUpdateComponent, reqRemoveComponent, reqAddComponentProd } from 'api/api';
import TagInput from 'components/common/taginput';
import { showColors } from 'utils/componentdefine';
import { getViewFromConfig } from 'components/pageview';
import _ from 'lodash';
import styles from './index.module.scss';
import { setItemPosition } from "utils/common";

const { Meta } = Card;
const { TextArea } = Input;

interface IProps {
  pageWidth: number;
  pageHeight: number;
  pageStyle: string;
  pageContent?: any;
  visible: boolean;
  onSelectComponent: (component: any) => void;
}

//云组件库
const ComponentList: React.FC<IProps> = (props: IProps) => {
  const { pageStyle, pageWidth, pageHeight, pageContent,
    visible, onSelectComponent } = props;

  const [components, setComponents] = useState<any>([]);
  const [content, setContent] = useState<any>(null);  //保存的内容
  const [previewVisible, setPreviewVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [openMode, setOpenMode] = useState(0); //0：浏览 1：新增 2：修改
  const [selectComponent, setSelectComponent] = useState<any>(null);  //选中的组件
  const [inputname, setInputname] = useState('');
  const [inputtag, setInputtag] = useState('');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth - 70); //当前窗口的宽度
  const [screenHeight, setScreenHeight] = useState(window.innerHeight - 120); //当前窗口的高度
  const [form] = Form.useForm();

  const pastecomponentStr = localStorage.getItem(pageStyle === 'datav' ? "datavpastecomponent" : "pastecomponent") || '';
  const clipboardContent = pastecomponentStr === '' ? null : JSON.parse(pastecomponentStr);

  useEffect(() => {
    if (visible) {
      handleRefresh();
    }
  }, [visible])

  //加载数据
  const handleRefresh = () => {
    reqGetComponents({ pageStyle }).then(res => {
      setComponents(res.items);
    })
  }

  const addItems: MenuProps['items'] = [
    {
      label: `选取剪贴板内容(${clipboardContent?.title})`,
      key: '1',
      disabled: _.isNull(clipboardContent),
    }, {
      label: '选取当前页面所有内容',
      key: '2',
    },
  ];

  //新建
  const handleAddClick: MenuProps['onClick'] = ({ key }) => {
    form.setFieldsValue({
      name: '',
      tag: [],
      comment: '',
    });
    switch (key) {
      case '1':
        let tempItem = _.cloneDeep(clipboardContent);
        setItemPosition(tempItem, 0, 0, tempItem.width, tempItem.height);
        setContent([tempItem]);
        form.setFieldsValue({
          name: tempItem.title,
        });
        break;
      case '2':
        setContent(pageContent);
        break;
      default:
        return;
    }
    setOpenMode(1);
    setModalVisible(true);
  };

  const moreItems: MenuProps['items'] = [
    {
      label: `复制`,
      key: 'copy',
    }, {
      label: '发送正式环境',
      key: 'send',
      disabled: process.env.NODE_ENV === "production",
    },
  ];

  //More
  const handleMoreClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'copy':
        handleCopyComponent(selectComponent);
        break;
      case 'send':
        // console.log(process.env.NODE_ENV);
        reqAddComponentProd({
          name: selectComponent.name,
          pageStyle,
          tag: selectComponent.tag,
          comment: selectComponent.comment,
          content: selectComponent.content,
        }).then(res => {
          message.success(res.message);
        })
        break;
      default:
        return;
    }

  };

  const handleOK = () => {
    form.validateFields().then(async (formdata) => {
      let res: any = {};
      if (openMode === 1) {
        res = await reqAddComponent({
          ...formdata,
          content: content,
          pageStyle,
        });
        message.success(res.message);
      }
      else if (openMode === 2) {
        res = await reqUpdateComponent({
          ...formdata,
          id: selectComponent?.id,
          content: content,
          pageStyle,
        });
        message.success(res.message);
      }
      setModalVisible(false);
      setOpenMode(0);
      handleRefresh();
    });
  }

  const handleCancel = () => {
    setModalVisible(false);
    setOpenMode(0);
  }

  //删除
  const handleDelComponent = (item: any) => {
    reqRemoveComponent(item.id).then(res => {
      message.success(res.msg);
      handleRefresh();
    })
  }

  //修改
  const handleEditComponent = (item: any) => {
    form.setFieldsValue({
      ...item
    });
    setContent(item.content);
    setOpenMode(2);
    setModalVisible(true);
  }

  //复制
  const handleCopyComponent = (item: any) => {
    form.setFieldsValue({
      ...item
    });
    setContent(item.content);
    setOpenMode(1);
    setModalVisible(true);
  }

  //选中
  const handleSelectComponent = (item: any) => {
    onSelectComponent && onSelectComponent(item);
  }

  //设计
  const handleDesignComponent = (item: any) => {

  }

  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        <div className={styles.item}>
          <span className={styles.title}>标题:</span>
          <Input
            className={styles.control}
            value={inputname}
            onChange={e => { setInputname(e.target.value) }} />
        </div>
        <div className={styles.item}>
          <span className={styles.title}>分类:</span>
          <Input
            className={styles.control}
            value={inputtag}
            onChange={e => { setInputtag(e.target.value) }} />
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.add}>
          <Dropdown menu={{ items: addItems, onClick: handleAddClick }} trigger={['click']}>
            <PlusOutlined />
          </Dropdown>
        </div>
        {components.filter((o: any) => {
          return (inputname === '' || o.name.indexOf(inputname) !== -1) &&
            (inputtag === '' || o.tag.some((t: any) => t.indexOf(inputtag) !== -1))
        })
          .map((item: any, index: number) => {
            return (<Card
              key={`card${index}`}
              className={styles.card}
              style={item.id === selectComponent?.id ? { border: "solid 1px" } : {}}
              onClick={() => { setSelectComponent(item) }}
              actions={[
                <Tooltip title="预览">
                  <EyeOutlined
                    className={styles.icon}
                    key="preview"
                    onClick={() => { setPreviewVisible(true) }}
                  />
                </Tooltip>,
                <Tooltip title="添加到当前页面">
                  <CheckCircleOutlined
                    className={styles.icon}
                    key="disign"
                    onClick={() => { handleSelectComponent(item) }} />
                </Tooltip>,
                // <Tooltip title="设计">
                //   <SettingOutlined
                //     className={styles.icon}
                //     key="disign"
                //     onClick={() => { handleDesignComponent(item) }} />
                // </Tooltip>,
                <Tooltip title="编辑">
                  <EditOutlined
                    className={styles.icon}
                    key="edit"
                    onClick={() => { handleEditComponent(item) }}
                  />
                </Tooltip>,
                // <Tooltip title="复制">
                //   <CopyOutlined
                //     className={styles.icon}
                //     key="copy"
                //     onClick={() => { handleCopyComponent(item) }}
                //   />
                // </Tooltip>,
                <Tooltip title="删除">
                  <Popconfirm
                    title="是否确定要删除此模板?"
                    onConfirm={() => { handleDelComponent(item) }}
                  >
                    <DeleteOutlined className={styles.icon} key="del" />
                  </Popconfirm>
                </Tooltip>,
                <Dropdown menu={{ items: moreItems, onClick: handleMoreClick }} trigger={['click']}>
                  <MoreOutlined className={styles.icon} key="more" />
                </Dropdown>

              ]}>
              <Meta
                description={(<div className={styles.meta}>
                  <h2 >{item.name}</h2>
                  <div className={`${styles.content} ${styles.top}`}>
                    {(item.tag || []).map((tag: string, tagindex: number) => (
                      <Tag
                        key={`tag-${tagindex}`}
                        color={showColors[tagindex % showColors.length]}>
                        {tag}
                      </Tag>
                    ))}
                  </div>
                  <div className={styles.content}>{item.comment}</div>
                </div>)}
              />
            </Card>)
          })}
      </div>
      <div>
        <Modal title='编辑'
          zIndex={2000}
          visible={modalVisible}
          onOk={handleOK}
          onCancel={handleCancel}>
          <Form
            form={form}
            name="control-ref"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            layout="horizontal"
            preserve={false}
          >
            {/* 显示标题 */}
            <Form.Item
              name="name"
              label="标题"
              rules={[{ required: true, max: 50 }]}
              style={{ margin: "0" }}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="tag"
              label="分类"
              style={{ margin: "0" }}
            >
              <TagInput />
            </Form.Item>
            <Form.Item
              name="comment"
              label="备注"
              style={{ margin: "0" }}
            >
              <TextArea rows={3} />
            </Form.Item>
          </Form>
        </Modal>

        <Drawer
          title={selectComponent?.name}
          placement="top"
          height="100vh"
          onClose={() => { setPreviewVisible(false) }}
          open={previewVisible}
          zIndex={99999}
          bodyStyle={{
            overflow: 'hidden',
          }}
        >
          {pageStyle === 'datav' ? (
            <div className={styles.datavcontent}
              style={{
                width: pageWidth,
                height: pageHeight,                
                transform: `scale(${screenWidth / pageWidth},${screenHeight / pageHeight})`                
              }} >
              {getViewFromConfig(selectComponent?.content || [])}
            </div>
          ) : (
            <div style={{
              overflowY: "auto",
            }}>
              {getViewFromConfig(selectComponent?.content || [])}
            </div>
          )}

        </Drawer>
      </div>

    </div>
  )
};

export default ComponentList;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\banneredit\index.tsx
 * @LastEditTime: 2024-04-03 16:12:17
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, InputNumber, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import { ImageScales, ImageEffects, ScrollEffects } from 'components/banner';
import ImageList from './imagelist';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'banner',
  key: NewGuid(),
  title: '横幅',
  width: '100%',
  height: '400px',
  imageScale: 0,  //图片缩放：铺满
  imageEffect: 0,  //图片效果：默认
  autoPlay: true,  //自动切换：是
  scrollEffect: 0, //切换效果 滚动
  images: [],
  children: [],
})

const BannerEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const heightValue = Form.useWatch('height', form);
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title={`'编辑横幅（建议上传宽高为 1920*${heightValue} 像素的图片）'`}
      width={850}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={()=>{onChange && onChange(form.getFieldsValue())}}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Tabs tabPosition="left" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            {/* 显示标题 */}
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="imageScale"
              label="图片缩放"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {ImageScales.map(item => (<Radio key={item.id} value={item.id}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="imageEffect"
              label="图片效果"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {ImageEffects.map(item => (<Radio key={item.id} value={item.id}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="autoPlay"
              label="自动切换"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="scrollEffect"
              label="切换效果"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {ScrollEffects.map(item => (<Radio key={item.id} value={item.id}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
          </Tabs.TabPane>
          {/* <Tabs.TabPane className={styles.tabpane} tab="图片" key="item-2" >
            <Form.Item
              name="images"
            >
              <ImageList />
            </Form.Item>
          </Tabs.TabPane> */}
        </Tabs>;
      </Form>
    </Modal>
  )
};

export default BannerEdit;
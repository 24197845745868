/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\customapiedit\index.tsx
 * @LastEditTime: 2024-04-10 10:30:59
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Checkbox, Input, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import { NewGuid } from 'utils/common';
import { InfoCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import styles from './index.module.scss';
import { useSelector } from "react-redux";
import DataSourceInput from "components/common/datasourceinput";

interface IProps {
  visible: boolean;
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'customapi',
  key: NewGuid(),
  title: '接口',
  data: {
    dataSource: 'static', //数据源类型
    staticData: [], //静态数据 [{"value": "标题"}]
    requestUrl: '', //api地址
    requestType: 'GET', //请求方式
    headers: {}, //请求头
    body: {}, //请求参数
    filters: 'return res;', //数据过滤器
    requestSec: 0, //多少秒一次请求，为0表示只请求1次
  },
  children: [],
})

const CustomApiEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);
  
  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  return (
    <Modal
      title='编辑工作区'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true }]}
          tooltip={{
            placement: "right",
            title: <div>
              <div>{"子元素属性支持包含内容格式为：${xxx}"}</div>
              <div>{"xxx为数据源数组中的字段"}</div>
            </div>, icon: <InfoCircleOutlined />
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="data"
          label="数据源"
          // rules={[{ required: true }]}
        >
          <DataSourceInput valueType='array'/>
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default CustomApiEdit;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\customvideoedit\index.tsx
 * @LastEditTime: 2023-01-18 11:26:46
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, InputNumber, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import { NewGuid } from 'utils/common';
import { reqGetSignature } from 'api/api';
import _ from 'lodash';
import styles from './index.module.scss';
import AudioInput from "components/common/audioinput";

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'customaudio',
  key: NewGuid(),
  title: '音频',
  src: '',
})

const CustomAudioEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);
  const [videoOSS, setVideoOSS] = React.useState<any>({}) //音频oss地址

  useEffect(() => {
    //获取videoOSS地址
    reqGetSignature({ bucketType: 1, system: 'muzhiyun' }).then(res => {
      setVideoOSS(res.Data[0]);
    })

  }, [])

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  return (
    <Modal
      title='编辑音频'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="src"
          label="音频地址"
          rules={[{ required: true }]}
        >
          <AudioInput dataOSS={videoOSS} />
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default CustomAudioEdit;
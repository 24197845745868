/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\pagemenuedit\menuitemlist\menuitem\submenuitem\index.tsx
 * @LastEditTime: 2023-03-02 17:20:23
 */

import React, { useState, useEffect } from "react";
import { Image, Input, Col, Row, AutoComplete, Select, Popconfirm, Radio } from 'antd';
import {
  DeleteOutlined, CaretUpOutlined, CaretDownOutlined, PlusOutlined
} from '@ant-design/icons';
import { LinkTypes } from 'components/banner';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  value?: any;
  onChange?: (value: any) => void;
  onDel?: () => void;
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

export const defaultValue = () => {
  return {
    key: NewGuid(),
    label: '子菜单',
    linkType: 1,
    linkUrl: '',
  }
}

//子菜单
const SubMenuItem: React.FC<IProps> = (props: IProps) => {
  const { value, onChange, onDel, onMoveUp, onMoveDown } = props;

  const handleChange = (e: any) => {
    let tempvalue = {
      ...value,
      ...e,
    }
    onChange && onChange(tempvalue);
  }

  return (
    <div className={styles.container}>
      <Row>
        <Col className={styles.title} >
          名称
        </Col>
        <Col >
          <Input style={{ width: "80px" }}
            value={value.label}
            onChange={e => { handleChange({ label: e.target.value }) }} />
        </Col>
        <Col className={styles.title} >
          跳转方式
        </Col>
        <Col >
          <Select
            style={{ width: "100px" }}
            value={value?.linkType}
            onChange={e => { handleChange({ linkType: e }) }}
            options={LinkTypes.map(item => ({
              value: item.id,
              label: item.title
            }))} />
        </Col>
        <Col className={styles.title} >
          跳转地址
        </Col>
        <Col >
          <Input style={{ width: "150px" }}
            value={value.linkUrl}
            onChange={e => { handleChange({ linkUrl: e.target.value }) }} />
        </Col>
        <Col className={styles.iconbar} flex={1}>
          <CaretUpOutlined
            className={styles.icon}
            onClick={() => { onMoveUp && onMoveUp() }} />
          <CaretDownOutlined
            className={styles.icon}
            onClick={() => { onMoveDown && onMoveDown() }} />
          <Popconfirm
            title="是否确定要删除?"
            onConfirm={() => { onDel && onDel() }}
          >
            <DeleteOutlined className={styles.icon} />
          </Popconfirm>
        </Col>
      </Row>
    </div>
  )
};

export default SubMenuItem;
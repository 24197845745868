/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\common\colorinput\index.jsx
 * @LastEditTime: 2023-02-03 12:54:43
 */
import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Image, Button, Upload, AutoComplete } from 'antd';
import _ from 'lodash';
import { BgColorsOutlined } from '@ant-design/icons'
import { SketchPicker } from 'react-color';
import { baseColors, advanceColors } from 'utils/componentdefine';
import styles from './index.module.scss';


interface IProps {
  customColors?: string[];
  value?: any;
  onChange?: (value: any) => void;
}

//颜色编辑
const ColorInput: React.FC<IProps> = (props: IProps) => {
  const { customColors, value, onChange } = props;
  const [visible, setVisible] = useState(false);
  const [color, setColor] = useState('#1890ff');

  const handleOK = () => {
    onChange && onChange(color);
    setVisible(false);
  }

  const handleCancel = () => {
    setVisible(false);
  }

  const handleChange = e => {
    onChange && onChange(e);
  }
  const tempColors=_.concat(customColors||[],baseColors);
  
  return (
    <Input.Group compact>
      <AutoComplete
        dropdownMatchSelectWidth={252}
        style={{ width: 300 }}
        options={tempColors.map((item,index) => {
          return {
            value: item,
            label: (
              <div key={`color-${index}`} className={styles.colorbar}>
                <div className={styles.colorbox}
                  style={{
                    backgroundColor: item,
                  }} />
                <span>{item}</span>
              </div>
            )
          }
        })}
        style={{
          width: "calc(100% - 32px)"
        }}
        value={value}
        onChange={handleChange} />
      <Button
        style={{ backgroundColor: value }}
        icon={<BgColorsOutlined style={{ color: "white", mixBlendMode: "difference" }} />}
        onClick={() => {
          setColor(value);
          setVisible(true);
        }} />
      <Modal
        width={320}
        okText="确认"
        cancelText="取消"
        visible={visible}
        onOk={handleOK}
        onCancel={handleCancel}
      >
        <SketchPicker
          presetColors={advanceColors}
          color={color}
          onChange={e => {
            // console.log(e);
            if (e.rgb.a === 1) {
              setColor(e.hex);
            } else {
              setColor(`rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`);
            }
          }}
        />
      </Modal>
    </Input.Group>

  );
};

export default ColorInput;

import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { selectBorderStyle, DatavDataSource } from 'utils/componentdefine';
import { executeFunctionFilter, dealParam, dealParamObj, getData } from 'utils/common';
import { Liquid } from '@ant-design/charts';
import { reqAgencyRequest } from 'api/api';
import styles from './index.module.scss';


export type DatavLiquidChartConfig = {
  type: String;  //类型 datavtext
  key: String; //主键
  data: DatavDataSource; //数据源
  shape: String; //circle | diamond | triangle | pin | rect
  customConfig: Object; //自定义配置
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
}

interface IProps {
  config: DatavLiquidChartConfig;
}

//水波图
const DatavLiquidChart: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const { config } = props;
  const [data, setData] = useState(0);
  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);

  useEffect(() => {
    getData(config.data, pageParam, setData);
    if (config.data.dataSource === 'api' && config.data.requestSec > 0) {
      const interval = setInterval(() => {
        getData(config.data, pageParam, setData);
      }, config.data.requestSec * 1000);
      // 组件卸载时清除定时器
      return () => {
        clearInterval(interval);
      }
    }
  }, [config, pageParam])

  const getStyle = () => {
    let style = {
      width: config.width,
      height: config.height,
      left: config.left,
      top: config.top,
      position: "absolute",
    };
    if (editComponentKey === config.key) {
      style.border = selectBorderStyle;
    }
    return style;
  }

  const getChartConfig = () => {
    let chartconfig = {
      percent: data,
      shape: config.shape,
      outline: {
        border: 8,
        distance: 0,
      },
      wave: {
        length: 128,
      },
    }

    chartconfig = {
      ...chartconfig,
      ...config.customConfig,
    }
    return chartconfig;
  }


  return (
    <Liquid
      style={getStyle()}
      {...getChartConfig()}
    />
  )

};

export default DatavLiquidChart;
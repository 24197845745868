/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\htmlviewedit\index.tsx
 * @LastEditTime: 2024-04-03 16:30:31
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, InputNumber, Radio, Switch, Button, AutoComplete, message } from 'antd';
import { HtmlSources } from 'components/htmlview';
import { NewGuid } from 'utils/common';
import UEditor, { getContent, setContent } from "components/ueditor/Ueditor";
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'htmlview',
  key: NewGuid(),
  title: 'HTML编辑',
  width: '100%',
  height: 'auto',
  htmlsource: 'content',
  content: '',
})

const HtmlViewEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  const [ueditorVisible, setUeditorVisible] = useState(false);
  const [html, setHtml] = useState('');
  const htmlSource = Form.useWatch('htmlsource', form);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
      setHtml(value.content)
    }
  }, [visible])

  const handleDesign = () => {
    setUeditorVisible(true);
    setContent(html);
  }

  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
    console.log(html);
    
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  const handleUEditorOk = () => {
    const content=getContent();
    setHtml(content);
    let tempConfig = _.cloneDeep(value);
    tempConfig.content=content;
    onChange && onChange(tempConfig);
    setUeditorVisible(false);
  }

  const handleUEditorCancel = () => {
    setUeditorVisible(false);
  }

  return (
    <div>
      <Modal
        title='编辑HTML'
        width={850}
        okText="确认"
        cancelText="取消"
        visible={visible}
        onOk={handleOK}
        onCancel={handleCancel}
        footer={[
          <Button onClick={()=>{onChange && onChange(form.getFieldsValue())}}>立即更新</Button>,
          <Button onClick={handleCancel}>取消</Button>,
          <Button type="primary" onClick={handleOK}>确认</Button>,
        ]}
      >
        <Form
          form={form}
          name="control-ref"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          preserve={false}
          onFieldsChange={handleFieldsChange}
        >
          <Form.Item
            name="title"
            label="标题"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="width"
            label="宽度"
            rules={[{ required: true }]}
          >
            <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
          </Form.Item>
          <Form.Item
            name="height"
            label="高度"
            rules={[{ required: true }]}
          >
            <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
          </Form.Item>
          <Form.Item
            name="htmlsource"
            label="来源"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              {HtmlSources.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
            </Radio.Group>
          </Form.Item>
          {htmlSource === 'content' ? (
            <Form.Item label="内容">
              <Button type="primary" onClick={handleDesign}>设计</Button>
            </Form.Item>) : ''}
        </Form>
      </Modal>
      <Modal title='内容编辑'
        visible={ueditorVisible}
        width={1000}
        onOk={handleUEditorOk}
        onCancel={handleUEditorCancel}>
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <UEditor />
        </div>
      </Modal>
    </div>
  )
};

export default HtmlViewEdit;

import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from "react-redux";
import { selectBorderStyle, DatavDataSource } from 'utils/componentdefine';
import { executeFunctionFilter, dealParam, dealParamObj, getData } from 'utils/common';
import ScrollBoard from '@jiaminghi/data-view-react/es/scrollBoard';
import { reqAgencyRequest } from 'api/api';
import styles from './index.module.scss';


export type DatavScrollBoardConfig = {
  type: String;  //类型 datavscrollboard
  key: String; //主键
  data: DatavDataSource; //数据源 [['data11','data12','data13'],['data21','data22','data23']]
  header: Array<String>; //表头 ['列1', '列2', '列3']
  rowNum: Number; //行数
  index: Boolean; //显示行号
  fontSize?: String; //字体
  customConfig: Object; //自定义配置  
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
  url?: String;  //外链地址  
  linkType: String;  //跳转方式
}

interface IProps {
  config: DatavScrollBoardConfig;
}

//轮播表
const DatavScrollBoard: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const { config } = props;
  const [data, setData] = useState([]);
  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  const editMode = useSelector((state: any) => state.global.editMode);
  const history = useHistory();

  useEffect(() => {
    getData(config.data, pageParam, setData);
    if (config.data.dataSource === 'api' && config.data.requestSec > 0) {
      const interval = setInterval(() => {
        getData(config.data, pageParam, setData);
      }, config.data.requestSec * 1000);
      // 组件卸载时清除定时器
      return () => {
        clearInterval(interval);
      }
    }
  }, [config, pageParam])

  const getStyle = () => {
    let style = {
      width: config.width,
      height: config.height,
      left: config.left,
      top: config.top,
      position: "absolute",
      fontSize: config?.fontSize || "15px",
    };
    if (editComponentKey === config.key) {
      style.border = selectBorderStyle;
    }
    return style;
  }

  const getChartConfig = () => {
    let chartconfig = {
      data: data,
      header: config.header,
      rowNum: config.rowNum,
      index: config.index,
    }

    chartconfig = {
      ...chartconfig,
      ...config.customConfig,
    }
    return chartconfig;
  }

  //   const globalStyles = css`
  //   .active-ring-name {
  //     width: auto !important;
  //   }
  // `;

  return (
    <ScrollBoard
      className={styles.container}
      style={getStyle()}
      config={{
        ...getChartConfig()
      }}

      onClick={(e) => {
        //row, ceil, rowIndex, columnIndex
        //编辑模式不触发点击事件，否则影响页面编辑操作
        if (editMode === 1) {
          return;
        }
        let subParam = {
          ...pageParam,
          ...e
        }

        let tempUrl = dealParam(config.url, subParam);

        switch (config?.linkType) {
          case '_self':
            history.push(tempUrl);
            break;
          case '_blank':
            window.open(tempUrl, '_blank');
            break;
          case '_back':
            history.goBack();
            break;

          default:
            break;
        }
      }}
    />

  )

};

export default DatavScrollBoard;
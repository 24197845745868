/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\manager\index.tsx
 * @LastEditTime: 2024-07-23 16:20:18
 */

import React, { useState, useEffect } from "react";
import { Layout, Menu, Select, Tooltip, Modal, Form, Input, Carousel, Image, Button, TreeSelect } from 'antd';
import _ from 'lodash';
import { useSelector, useDispatch } from "react-redux";
import { setOrganization, setLoginInfo, clearLoginInfo } from 'store/slices/globalSlice';
import { reqGetOrganizations } from 'api/api';
import logo from 'assets/muzhiyun-logo.png';
import PageHeader from '../pageheader';
import TemplateManager from '../templatemanager';
import ArticleGroup from '../articlegroup';
import ArticleManager from '../articlemanager';
import VideoGroup from '../videogroup';
import VideoManager from '../videomanager';
import LiveManager from '../livemanager';
import styles from './index.module.scss';
import PaperGroup from "pages/papergroup";
import PaperManager from "pages/papermanager";
import { codefans_net_CC2PY } from "utils/pinyin";

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

const customMenus = [
  {
    key: 'TemplateManager',
    title: '网站管理',
    // icon: (<LaptopOutlined />),
    component: (<TemplateManager />)
  },
  {
    key: 'ArticleGroup',
    title: '文章分类',
    // icon: (<LaptopOutlined />),
    component: (<ArticleGroup />)
  },
  {
    key: 'ArticleManager',
    title: '文章管理',
    // icon: (<LaptopOutlined />),
    component: (<ArticleManager />)
  },
  {
    key: 'PaperGroup',
    title: '电子报分类',
    // icon: (<LaptopOutlined />),
    component: (<PaperGroup />)
  },
  {
    key: 'PaperManager',
    title: '电子报管理',
    // icon: (<LaptopOutlined />),
    component: (<PaperManager />)
  },
  // {
  //   key: 'VideoGroup',
  //   title: '视频分类',
  //   // icon: (<LaptopOutlined />),
  //   component: (<VideoGroup />)
  // },
  // {
  //   key: 'VideoManager',
  //   title: '视频管理',
  //   // icon: (<LaptopOutlined />),
  //   component: (<div>视频管理</div>)
  // },
  // {
  //   key: 'LiveManager',
  //   title: '直播管理',
  //   // icon: (<LaptopOutlined />),
  //   component: (<div>直播管理</div>)
  // },
]

const defaultkey = 'TemplateManager';

//后台管理
const Manager: React.FC = () => {
  const dispatch = useDispatch();
  const organization = useSelector((state: any) => state.global.organization);

  const [selectKey, setSelectedKey] = React.useState(defaultkey);
  const [organizations, setOrganizations] = useState<any>([]);
  const [selectOrganization, setSelectOrganization] = useState<any>("");


  useEffect(() => {
    reqGetOrganizations().then(res => {
      setOrganizations(res.Data.items.filter((item:any)=>!_.isNull(item.originalGuid)));
    })
  }, []);

  useEffect(() => {
    if (organizations.length > 0) {
      handleOrganizationChange(organizations[0].organizationGuid);
    }
  }, [organizations]);


  const handleSelectMenu = (info: any) => {
    setSelectedKey(info.selectedKeys[0]);
  }

  const getComponent = () => {
    return _.find(customMenus, (item: any) => item.key === selectKey)
  }

  const handleOrganizationChange = (e: any) => {
    setSelectOrganization(e);
    let tempitem = _.find(organizations, o => o.organizationGuid === e);
    dispatch(setOrganization(tempitem));
  }

  return (
    // <div>111</div>
    <Layout>
      <Header >
        {/* <div className={styles.topbar}>
          <img src={logo} className={styles.logo} alt="logo" />
          <div className={styles.topselect}>
            <span style={{ color: "white" }}>机构：</span>
            <Select
              style={{ width: 200 }}
              value={selectOrganization}
              onChange={handleOrganizationChange}
              options={organizations.map((item: any) => {
                return {
                  value: item.organizationGuid,
                  label: item.organizationTitle
                }
              })}
            />
          </div>
          <div className={styles.topcenter} />
        </div> */}
        <PageHeader>
          <div className={styles.topselect}>
            <span style={{ color: "white", marginLeft: "10px" }}>机构：</span>
            {/* <Select
              style={{ width: 200 }}
              value={selectOrganization}
              onChange={handleOrganizationChange}
              options={organizations.map((item: any) => {
                return {
                  value: item.organizationGuid,
                  label: item.organizationTitle
                }
              })}
            /> */}

            <TreeSelect
              style={{ width: 250 }}
              showSearch
              treeDefaultExpandAll
              placeholder="请选择"
              dropdownStyle={{ maxHeight: 400, overflow: 'auto', minWidth: 250 }}
              value={selectOrganization}
              onChange={(e) => {
                setSelectOrganization(e);
                let tempitem = _.find(organizations, o => o.organizationGuid === e);
                dispatch(setOrganization(tempitem));
              }}
              treeData={organizations.map((item: any) => {
                return {
                  title: item.organizationTitle,
                  value: item.organizationGuid,
                }
              })}
              // treeNodeFilterProp="title"
              filterTreeNode={(input, treeNode) => {
                const title = treeNode?.title?.toString() || '';
                return title.toLowerCase().indexOf(input.toLowerCase()) >=
                  0 ||
                  codefans_net_CC2PY(title)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  codefans_net_CC2PY(title, true)
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
              }
              }
            />
          </div>
        </PageHeader>
      </Header>
      <Content className={styles.bottom}>
        <Layout style={{ height: '100%' }}>
          <Sider width={200}>
            <Menu
              mode="inline"
              defaultSelectedKeys={[selectKey]}
              // defaultOpenKeys={['sub1']}
              onSelect={handleSelectMenu}
              style={{ height: '100%' }}>
              {
                customMenus.map((item: any) => (
                  <Menu.Item key={item.key}>{item.title}</Menu.Item>
                ))
              }
            </Menu>
          </Sider>
          <Content className={styles.content}>
            {(getComponent())?.component}
          </Content>
        </Layout>
      </Content>
    </Layout>
  )
};

export default Manager;
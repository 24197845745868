/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\customtextedit\index.tsx
 * @LastEditTime: 2023-03-06 10:56:34
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Button, Select, Radio, Switch, Tabs, AutoComplete, InputNumber, message } from 'antd';
import { LinkTypes, Positions } from 'components/customtext';
import { EffectModes, EffectTypes, fontSizes, fontFamilys, justifyContentTypes, alignItemsTypes } from 'utils/componentdefine';
import FontInput from 'components/common/fontinput';
import ColorInput from 'components/common/colorinput';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';
import DataSourceInput from "components/common/datasourceinput";
import ImageInput from "components/common/imageinput";
import { reqGetSignature } from "api/api";

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}


const DatavPageEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);
  const [imageOSS, setImageOSS] = React.useState<any>({}) //图片oss地址

  useEffect(() => {
    //获取videoOSS地址
    reqGetSignature({ bucketType: 2, system: 'muzhiyun' }).then(res => {
      setImageOSS(res.Data[0]);
    })
  }, [])
  
  useEffect(() => {
    if (visible) {
      setFormValue(value);
      form.setFieldsValue(value);
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
  }

  return (
    <Modal
      title='编辑组'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item
            name="pageWidth"
            label="页面宽"
            tooltip="建议分辨率为：1920*1080"
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name="pageHeight"
            label="页面高"
            tooltip="建议分辨率为：1920*1080"
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name="bgurl"
            label="背景图"
          >
            <ImageInput dataOSS={imageOSS} />
          </Form.Item>
      </Form>
    </Modal>
  )
};

export default DatavPageEdit;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2023-02-13 17:56:37
 * @FilePath: \website\src\components\workspace\index.jsx
 * @LastEditTime: 2024-07-22 18:21:28
 */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { isLogin } from "utils/auth";
import _ from 'lodash';
import { parseParam } from "utils/common";
import { Card } from 'antd';
import styles from './index.module.scss';


export type WorkSpaceConfig = {
  type: String;  //类型 workspace
  key: String; //主键
  title: String; //标题
  backGroundUrl: String; //背景图
  items: String[]; //选择的id
  isNoHeader: Boolean;  //是否无标题框
}

interface IProps {
  config: WorkSpaceConfig;
}

//工作区
const WorkSpace: React.FC<IProps> = (props: IProps) => {
  const workSpaces = useSelector((state: any) => state.global.workSpaces);
  const organization = useSelector((state: any) => state.global.organization);
  const { config } = props;
  const items= config?.items || [];

  const loginUrl = `${process.env.REACT_APP_LOGIN_API_URL}/authentication${parseParam({
    redirectnew: window.location.origin,
    organizationId: organization.organizationGuid,
    backgroundImage: config.backGroundUrl || '',
  })}`;

  const defaultIconUrl = 'https://images.muzhiyun.cn/static/index/icon/sucaijia.png';

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = {
    backgroundImage: `url(${config.backGroundUrl})`,
  };
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  const dealNoHeader = (url) => {
    if (config?.isNoHeader) {
      return url.includes("?") ? `${url}&isNoHeader=true` : `${url}?isNoHeader=true`;
    } else {
      return url;
    }
  }

  return (
    <div
      className={styles.container}
      style={style}
    >
      <div className={styles.moduleItems}>
        {
          workSpaces.filter(workspace=> _.includes(items,workspace.id)).map(workspace => {
            return (
              <div
                key={workspace.id}
                className={styles.groupItem}
              >
                <Card
                  key={workspace.id}
                  className={styles.card}
                  bodyStyle={{
                    padding: 0,
                    borderRadius: 10,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <a
                    href={ dealNoHeader(isLogin() ? workspace.url : loginUrl)}
                    target={isLogin() ? "_blank" : "_self"}
                  >
                    <div className={styles.item}>
                      <img className={styles.icon} src={workspace.iconUrl || defaultIconUrl} />
                      <div className={styles.title}>
                        {workspace.title}
                      </div>
                      <div className={styles.description} style={{ WebkitBoxOrient: "vertical" }}>
                        {workspace.desc}
                      </div>
                    </div>
                  </a>
                </Card>
              </div>
            );
          })
        }
      </div>
    </div>
  )
};

export default WorkSpace;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\customvideoedit\index.tsx
 * @LastEditTime: 2024-04-25 15:36:38
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, InputNumber, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import { LinkTypes, Positions } from 'components/customvideo';
import { NewGuid } from 'utils/common';
import {reqGetSignature} from 'api/api';
import VideoInput from 'components/common/videoinput';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'customvideo',
  key: NewGuid(),
  title: '视频',
  width: 'auto',
  height: 'auto',
  aspectRatio: 'auto',
  controls: false,
  muted: true,
  loop: true,
  autoPlay: true,
  margin: '0',
  borderradius: '0',
  position: 'static',
  left: 'auto',
  top: 'auto',
  right: 'auto',
  bottom: 'auto',
  src: '',
  url: '',
  linkType: 'none',
})

const CustomVideoEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);
  const [videoOSS, setVideoOSS] = React.useState<any>({}) //视频oss地址

  useEffect(() => {
    //获取videoOSS地址
    reqGetSignature({ bucketType: 1, system: 'muzhiyun' }).then(res => {
      setVideoOSS(res.Data[0]);
    })

  }, [])

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])


  const handleOK = () => {    
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  return (
    <Modal
      title='编辑视频'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="src"
              label="视频地址"
              rules={[{ required: true }]}
            >
              {/* <Input /> */}
              <VideoInput dataOSS={videoOSS} />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="aspectRatio"
              label="宽高比"
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '4/3' }]} />
            </Form.Item>
            <Form.Item
              name="controls"
              label="控制条"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch/>
            </Form.Item>
            <Form.Item
              name="muted"
              label="静音"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch/>
            </Form.Item>
            <Form.Item
              name="loop"
              label="循环播放"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch/>
            </Form.Item>
            <Form.Item
              name="autoPlay"
              label="自动播放"
              rules={[{ required: true }]}
              valuePropName="checked"
              tooltip="开启自动播放必须静音才生效"
            >
              <Switch/>
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-2">
          <Form.Item
              name="position"
              label="定位"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {Positions.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="left"
              label="左"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="top"
              label="上"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="right"
              label="右"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="bottom"
              label="下"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="margin"
              label="外边距"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: '0' }]} />
            </Form.Item>
            <Form.Item
              name="borderradius"
              label="圆角"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: '0' }]} />
            </Form.Item>
            <Form.Item
              name="linkType"
              label="跳转方式"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {LinkTypes.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="url"
              label="跳转地址"
            >
              <Input />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  )
};

export default CustomVideoEdit;
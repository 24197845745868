/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\atriclelistedit\index.tsx
 * @LastEditTime: 2023-01-07 20:10:10
 */

import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Input, Select, AutoComplete, Button, message } from 'antd';
import { NewGuid } from 'utils/common';
import {reqGetArticleGroups} from 'api/api';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'paperlist',
  key: NewGuid(),
  title: '电子报',
  articlegroupid: '',
  width: '1200px',
})

const PaperListEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);
  const [articleGroups,setArticleGroups]= useState<any[]>([]);

  const organization = useSelector((state: any) => state.global.organization);

  useEffect(() => {
    if (visible) {
      setFormValue(value);
      form.setFieldsValue(value);
    }
  }, [visible])

  useEffect(()=>{
    const params={
      organizationId: organization?.organizationGuid,
      articletype: 3,
    }
    reqGetArticleGroups(params).then(res=>{      
      setArticleGroups(res.items);
    })
  },[])

  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑电子报'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="articlegroupid"
          label="电子报"
          rules={[{ required: true }]}
        >
          <Select options={[{ value: 'outid', label: '外部id' }].concat(
            articleGroups.map((item: any) => {
              return {
                value: item.id,
                label: item.title
              }
            })
          )} />
        </Form.Item>
        <Form.Item
          name="width"
          label="宽度"
          rules={[{ required: true }]}
        >
          <AutoComplete options={[{ value: '1200px' }, { value: '100%' }]} />
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default PaperListEdit;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\customtextedit\index.tsx
 * @LastEditTime: 2024-04-10 12:43:55
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Button, Select, Radio, Switch, Tabs, AutoComplete, message } from 'antd';
import { LinkTypes, Positions } from 'components/customtext';
import { EffectModes, EffectTypes, fontSizes, textAligns } from 'utils/componentdefine';
import FontInput from 'components/common/fontinput';
import ColorInput from 'components/common/colorinput';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';
import JsonInput from "components/common/jsoninput";
import FileInput from "components/common/fileinput";

const { TextArea } = Input;

interface IProps {
  imageOSS?: any;
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'customtext',
  key: NewGuid(),
  title: '文本',
  text: '',
  width: 'auto',
  height: 'auto',
  fontSize: '20px',
  fontFamily: '微软雅黑',
  color: 'black',
  gradualColor: '',
  backGroundColor: 'transparent',  //背景色
  fontWeight: false,
  intalic: false,
  underline: false,
  textAlign: 'left',
  position: 'static',
  left: 'auto',
  top: 'auto',
  right: 'auto',
  bottom: 'auto',
  url: '',
  linkType: 'none',
  effectType: 'none',
  customAnimation: {}, //自定义特效
  effectMode: 'init',
})

const CustomTextEdit: React.FC<IProps> = (props: IProps) => {
  const { imageOSS, visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
      //这里不清楚是不是antdesign的bug，form.setFieldsValue 并不会覆盖对象类的值
      //如原来界面中{a:1} 新的value是{b:1} 得到的结果是合并的内容{a:1,b:1}
      //因此需要重新单独对该字段赋值
      form.setFieldValue('customAnimation',tempvalue.customAnimation); 
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  const linkType = Form.useWatch('linkType', form);

  return (
    <Modal
      title='编辑文本'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="text"
              label="内容"
              rules={[{ required: true }]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="fontSize"
              label="字体大小"
              rules={[{ required: true }]}
            >
              <AutoComplete options={fontSizes} />
            </Form.Item>
            <Form.Item
              name="fontFamily"
              label="字体"
              rules={[{ required: true }]}
            >
              {/* <AutoComplete options={fontFamilys.map((item: any) => {
                return {
                  value: item,
                  label: item
                }
              })} /> */}
              <FontInput />
            </Form.Item>
            <Form.Item
              name="textAlign"
              label="对齐方式"
              rules={[{ required: true }]}
            >
              <Select options={textAligns.map(item => ({ value: item.setting, label: item.title }))} />
            </Form.Item>
            <Form.Item
              name="color"
              label="颜色"
              rules={[{ required: true }]}
            >
              {/* <AutoComplete options={[{ value: 'black' }, { value: 'white' }]} /> */}
              <ColorInput customColors={['inherit']} />
            </Form.Item>
            <Form.Item
              name="gradualColor"
              label="渐变颜色"
              tooltip="填入样式为：linear-gradient(to right, red, blue)"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="backGroundColor"
              label="背景色"
              rules={[{ required: true }]}
              tooltip="如使用渐变透明蒙版可设置： linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9))"
            >
              <ColorInput customColors={['transparent']} />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-2">
            <Form.Item
              name="position"
              label="定位"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {Positions.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="left"
              label="左"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="top"
              label="上"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="right"
              label="右"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="bottom"
              label="下"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="fontWeight"
              label="粗体"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="intalic"
              label="斜体"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="underline"
              label="下划线"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-3">
            <Form.Item
              name="linkType"
              label="跳转方式"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {LinkTypes.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            {linkType === 'downloadFile' ? (
              <Form.Item
                name="url"
                label="文件地址"
              >
                <FileInput dataOSS={imageOSS}/>
              </Form.Item>
            ) : (
              <Form.Item
                name="url"
                label="跳转地址"
              >
                <Input />
              </Form.Item>
            )}
            <Form.Item
              name="effectType"
              label="动画效果"
            >
              <Select options={EffectTypes.map(item => ({ value: item.setting, label: item.title }))} />
            </Form.Item>
            <Form.Item
              name="customAnimation"
              label="自定义动效"
              tooltip={<div>
                <p>{` 示例：
                  {
                    "type": "from",
                    "x": 80,
                    "opacity": 0,
                    "duration": 500
                  }`
                }</p>
                <p>参考配置： https://motion.ant.design/language/animate-term-cn</p>
              </div>}
            >
              <JsonInput valueType={"object"} />
            </Form.Item>
            <Form.Item
              name="effectMode"
              label="动效模式"
            >
              <Select options={EffectModes.map(item => ({ value: item.setting, label: item.title }))} />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  )
};

export default CustomTextEdit;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\containeredit\index.tsx
 * @LastEditTime: 2024-04-10 14:10:18
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Select, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import {
  JustifyContents, AlignItems, FlexDirections, DisPlays,
  ImageEffects, LinkTypes, Positions, FlexWraps
} from 'components/container';
import { NewGuid } from 'utils/common';
import { backdropFilters, EffectModes, EffectTypes, fontFamilys, fontSizes, ImageScales } from 'utils/componentdefine';
import ImageInput from 'components/common/imageinput';
import ColorInput from 'components/common/colorinput';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
// import type { DraggableData, DraggableEvent } from 'react-draggable';
// import Draggable from 'react-draggable';
import styles from './index.module.scss';
import JsonInput from "components/common/jsoninput";
import FileInput from "components/common/fileinput";

interface IProps {
  imageOSS?: any;
  visible: boolean;
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'container',
  key: NewGuid(),
  title: '容器',
  width: '100%',
  height: '200px',
  aspectRatio: 'auto',
  left: 'auto',
  top: 'auto',
  right: 'auto',
  bottom: 'auto',
  position: 'relative',
  margin: '0',
  padding: '0',
  borderradius: '0',
  display: 'flex',
  flexDirection: 'row',   //方向
  justifyContent: 'center',  //主轴对齐方式
  alignItems: 'center',  //侧轴对齐方式
  flexWrap: 'nowrap',  //不换行
  flex: 'none', //占比
  backGroundColor: 'transparent',  //背景色
  fontSize: 'inherit',
  fontFamily: 'inherit',
  color: 'inherit', //字色
  fontWeight: false,
  intalic: false,
  underline: false,
  linkType: 0, //跳转方式 
  linkUrl: '', //跳转链接  
  backGroundImageUrl: '', //背景图
  imageScale: 'cover', //图片缩放
  imageEffect: 'scroll', //图片效果
  effectType: 'none', //动画效果
  customAnimation: {}, //自定义特效
  effectMode: 'init', //动效模式
  topTransparent: false,  //置顶透明
  border: 'none', //边框
  boxShadow: 'none', //阴影
  tip: '', //文字提示
  overflow: 'hidden', //超出 visible | hidden | clip | scroll | auto
  hidescroll: false, //隐藏滚动条
  backdropFilter: 'none', //滤镜效果
  children: [],
})

const ContainerEdit: React.FC<IProps> = (props: IProps) => {
  const { imageOSS, visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
      //这里不清楚是不是antdesign的bug，form.setFieldsValue 并不会覆盖对象类的值
      //如原来界面中{a:1} 新的value是{b:1} 得到的结果是合并的内容{a:1,b:1}
      //因此需要重新单独对该字段赋值
      form.setFieldValue('customAnimation', tempvalue.customAnimation);
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  const linkType = Form.useWatch('linkType', form);

  return (
    <Modal
      title='编辑容器'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 18 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="aspectRatio"
              label="宽高比"
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '4/3' }]} />
            </Form.Item>
            <Form.Item
              name="position"
              label="定位"
            >
              <Radio.Group>
                {Positions.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="left"
              label="左"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="top"
              label="上"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="right"
              label="右"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="bottom"
              label="下"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="常规1" key="item-2">
            <Form.Item
              name="margin"
              label="外边距"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: '0' }]} />
            </Form.Item>
            <Form.Item
              name="padding"
              label="内边距"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: '0' }]} />
            </Form.Item>
            <Form.Item
              name="borderradius"
              label="圆角"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: '0' }]} />
            </Form.Item>
            <Form.Item
              name="border"
              label="边框"
              tooltip="solid 2px red 参考网站:https://www.runoob.com/cssref/pr-border.html"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="boxShadow"
              label="阴影"
              tooltip="1px 1px 10px 0px gray  水平位置 垂直位置 模糊距离 阴影大小 阴影颜色 https://www.runoob.com/cssref/css3-pr-box-shadow.html"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="overflow"
              label="超出"
            >
              <AutoComplete options={[{ value: 'hidden' }, { value: 'visible' }, { value: 'clip' }, { value: 'scroll' }, { value: 'auto' }]} />
            </Form.Item>
            <Form.Item
              name="hidescroll"
              label="隐藏滚动条"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="字体" key="item-3">
            <Form.Item
              name="fontSize"
              label="字体大小"
              rules={[{ required: true }]}
            >
              <AutoComplete options={fontSizes} />
            </Form.Item>
            <Form.Item
              name="fontFamily"
              label="字体"
              rules={[{ required: true }]}
            >
              <AutoComplete options={fontFamilys.map((item: any) => {
                return {
                  value: item,
                  label: item
                }
              })} />
            </Form.Item>
            <Form.Item
              name="color"
              label="字色"
              rules={[{ required: true }]}
            >
              {/* <AutoComplete options={[{ value: 'inherit' }, { value: 'black' }, { value: 'white' }]} /> */}
              <ColorInput customColors={['inherit']} />
            </Form.Item>
            <Form.Item
              name="fontWeight"
              label="粗体"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="intalic"
              label="斜体"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="underline"
              label="下划线"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-4">
            <Form.Item
              name="display"
              label="布局"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {DisPlays.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="flexDirection"
              label="方向"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {FlexDirections.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="justifyContent"
              label="主轴对齐方式"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {JustifyContents.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="alignItems"
              label="侧轴对齐方式"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {AlignItems.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="flexWrap"
              label="换行"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {FlexWraps.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="flex"
              label="占比"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'none' }, { value: '1' }]} />
            </Form.Item>
            <Form.Item
              name="linkType"
              label="跳转方式"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {LinkTypes.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            {linkType === 3 ? (
              <Form.Item
                name="linkUrl"
                label="文件地址"
              >
                <FileInput dataOSS={imageOSS}/>
              </Form.Item>
            ) : (
              <Form.Item
                name="linkUrl"
                label="跳转地址"
              >
                <Input />
              </Form.Item>
            )}


          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="其他" key="item-5">
            <Form.Item
              name="backGroundColor"
              label="背景色"
              rules={[{ required: true }]}
              tooltip="如使用渐变透明蒙版可设置： linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9))"
            >
              {/* <AutoComplete options={[{ value: 'transparent' }, { value: 'red' }, { value: 'green' }, { value: 'blue' }]} /> */}
              <ColorInput customColors={['transparent']} />
            </Form.Item>
            <Form.Item
              name="backdropFilter"
              label="滤镜效果"
              tooltip={{
                title: <div>
                  <div>滤镜效果开启的前提是容器在其他容器上层，且背景色为透明色,如:rbga(0,0,0,0.5)</div>
                  <div>对应的css属性为: backdrop-filter</div>
                  <li>毛玻璃效果： blur(2px)</li>
                  <li>明亮度: brightness(60%)</li>
                  <li>对比度: contrast(40%)</li>
                  <li>灰度: grayscale(40%)</li>
                  <li>旋转色相: hue-rotate(120deg)</li>
                  <li>反色: invert(70%)</li>
                  <li>饱和度: saturate(80%)</li>
                  <li>棕褐色: sepia(90%)</li>
                </div>
              }}
            >
              <AutoComplete options={backdropFilters} />
            </Form.Item>
            <Form.Item
              name="backGroundImageUrl"
              label="背景图"
            >
              <ImageInput dataOSS={imageOSS} />
            </Form.Item>
            <Form.Item
              name="imageScale"
              label="图片缩放"
              tooltip="参考网站： https://www.w3school.com.cn/tiy/c.asp?f=css_background-size&p=7"
            >
              <Radio.Group>
                {ImageScales.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="imageEffect"
              label="图片效果"
            >
              <Radio.Group>
                {ImageEffects.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="effectType"
              label="动画效果"
            >
              <Select options={EffectTypes.map(item => ({ value: item.setting, label: item.title }))} />
            </Form.Item>
            <Form.Item
              name="customAnimation"
              label="自定义动效"
              tooltip={<div>
                <p>{` 示例：
                  {
                    "type": "from",
                    "x": 80,
                    "opacity": 0,
                    "duration": 500
                  }`
                }</p>
                <p>参考配置： https://motion.ant.design/language/animate-term-cn</p>
              </div>}
            >
              <JsonInput valueType={"object"} />
            </Form.Item>
            <Form.Item
              name="effectMode"
              label="动效模式"
            >
              <Select options={EffectModes.map(item => ({ value: item.setting, label: item.title }))} />
            </Form.Item>
            <Form.Item
              name="topTransparent"
              label="置顶透明"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="tip"
              label="文字提示"
              tooltip={{ title: '仅在动画效果无时生效', icon: <InfoCircleOutlined /> }}
            >
              <Input />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal >
  )
};

export default ContainerEdit;
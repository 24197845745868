/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\pageparammodal\index.tsx
 * @LastEditTime: 2024-08-29 11:43:43
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Select, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import _ from 'lodash';
import styles from './index.module.scss';
import { useDispatch, useSelector } from "react-redux";
import JsonInput from "components/common/jsoninput";
import { setBaseParam, setPageApis } from "store/slices/globalSlice";
import MultiDataSourceInput from "./multidatasourceinput";
import TextArea from "antd/lib/input/TextArea";

interface IProps {
  visible: boolean;
  onVisibleChange: (value: boolean) => void;
}


const PageParamModal: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const { visible, onVisibleChange } = props;
  const [paramVisible, setParamVisible] = useState(false);
  const [form] = Form.useForm();

  // {
  //   param:
  //   apis:
  // }
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const baseParam = useSelector((state: any) => state.global.baseParam);
  const pageApis = useSelector((state: any) => state.global.pageApis);


  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        baseParam,
        pageApis,
      });
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      dispatch(setBaseParam(formdata.baseParam));
      dispatch(setPageApis(formdata.pageApis));
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
  }


  return (
    <Modal
      title='编辑参数'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button type="primary" onClick={()=>{
          setParamVisible(true)
          console.log('a');
          
        }}>查看当前参数</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item
          name="baseParam"
          label="页面参数"
          rules={[{ required: true }]}
        >
          <JsonInput valueType={"object"} />
        </Form.Item>
        <Form.Item
          name="pageApis"
          label="调用接口"
          rules={[{ required: true }]}
        >
          <MultiDataSourceInput />
        </Form.Item>
      </Form>

      <Modal
        title='当前页面参数'
        okText="确认"
        cancelText="取消"
        visible={paramVisible}
        footer={[
          <Button type="primary" onClick={()=>{setParamVisible(false)}}>确认</Button>,
        ]}
      >
        <div className={styles.container}>
          <TextArea
            style={{ width: '100%' }}
            autoSize
            // rows={10}
            value={JSON.stringify(pageParam, null, 2)}
           />
        </div>
      </Modal>
    </Modal>
  )
};

export default PageParamModal;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pagelist\index.tsx
 * @LastEditTime: 2022-11-22 16:20:27
 */

import React, { useState, useEffect } from "react";
import _ from 'lodash';
import styles from './index.module.scss';


//页面列表
const PageList: React.FC = () => {

  return (
    <div className={styles.container}>
      PageList
    </div>
  )
};

export default PageList;
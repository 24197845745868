
import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import * as datav from '@jiaminghi/data-view-react';
import styles from './index.module.scss';


export type DatavDecorationConfig = {
  type: String;  //类型 datavdecoration
  key: String; //主键
  decorationType: String; //类型 
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
}

interface IProps {
  config: DatavDecorationConfig;
}

//装饰
const DatavDecoration: React.FC<IProps> = (props: IProps) => {
  const { config } = props;

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);

  let style = {
    width: config.width,
    height: config.height,
    left: config.left,
    top: config.top,
    position: "absolute",
  };

  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  switch (config.decorationType) {
    case 'Decoration1':
      return (<datav.Decoration1 style={style}></datav.Decoration1>)
    case 'Decoration2':
      return (<datav.Decoration2 style={style}></datav.Decoration2>)
    case 'Decoration2(reverse)':
      return (<datav.Decoration2 reverse={true} style={style}></datav.Decoration2>)
    case 'Decoration3':
      return (<datav.Decoration2 style={style}></datav.Decoration2>)
    case 'Decoration4':
      return (<datav.Decoration4 style={style}></datav.Decoration4>)
    case 'Decoration4(reverse)':
      return (<datav.Decoration4 reverse={true} style={style}></datav.Decoration4>)
    case 'Decoration5':
      return (<datav.Decoration5 style={style}></datav.Decoration5>)
    case 'Decoration6':
      return (<datav.Decoration6 style={style}></datav.Decoration6>)
    case 'Decoration7':
      return (<datav.Decoration7 style={style}></datav.Decoration7>)
    case 'Decoration8':
      return (<datav.Decoration8 style={style}></datav.Decoration8>)
    case 'Decoration8(reverse)':
      return (<datav.Decoration8 reverse={true} style={style}></datav.Decoration8>)
    case 'Decoration9':
      return (<datav.Decoration9 style={style}></datav.Decoration9>)
    case 'Decoration10':
      return (<datav.Decoration10 style={style}></datav.Decoration10>)
    case 'Decoration11':
      return (<datav.Decoration11 style={style}></datav.Decoration11>)
    default:
      return (<datav.Decoration1 style={style}></datav.Decoration1>)
  }
};

export default DatavDecoration;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\loginstatusedit\index.tsx
 * @LastEditTime: 2024-04-03 16:25:55
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Select, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import { NewGuid } from 'utils/common';
import ImageInput from 'components/common/imageinput';
import _ from 'lodash';
import styles from './index.module.scss';
import ColorInput from "components/common/colorinput";
import { LayoutTypes } from "components/loginstatus";

interface IProps {
  imageOSS?: any;
  visible: boolean;
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'loginstatus',
  key: NewGuid(),
  title: '登录状态',
  backGroundUrl: '',
  color: 'white',
  layoutType: 'vertical',
})

const LoginStatusEdit: React.FC<IProps> = (props: IProps) => {
  const { imageOSS, visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  return (
    <Modal
      title='编辑按钮'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item
          name="backGroundUrl"
          label="背景图"
        >
          <ImageInput dataOSS={imageOSS} />
        </Form.Item>
        <Form.Item
          name="color"
          label="颜色"
          rules={[{ required: true }]}
        >
          <ColorInput customColors={['inherit']} />
        </Form.Item>
        <Form.Item
              name="layoutType"
              label="布局"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {LayoutTypes.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
      </Form>
    </Modal>
  )
};

export default LoginStatusEdit;
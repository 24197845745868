/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\templatemanager\index.tsx
 * @LastEditTime: 2024-05-06 16:55:10
 */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip, Modal, Form, Input, message, Radio, Popconfirm, InputNumber, Button, Card, Switch, Tag, Dropdown, Menu, Select } from 'antd';
import {
  EditOutlined, DeleteOutlined, CopyOutlined, PlusOutlined,
  SettingOutlined, LaptopOutlined, MobileOutlined, AreaChartOutlined, MoreOutlined
} from '@ant-design/icons';
import _ from 'lodash';
import moment from "moment";
import {
  reqGetWebSiteTemplates, reqAddWebSiteTemplate, reqUpdateWebSiteTemplate,
  reqRemoveWebSiteTemplate, reqGetPages, reqAddPage, reqUpdatePage,
  reqRemovePage,
  reqGetSignature,
  reqAddPageGroup,
  reqUpdatePageGroup,
  reqRemovePageGroup,
  reqCopyPage,
} from 'api/api';
import styles from './index.module.scss';
import ImageInput from "components/common/imageinput";

const { TextArea } = Input;
const { Meta } = Card;

const defaultTemplates = [{
  id: '123',
  title: "默认模板",
  pages: [{
    id: '123-1',
    title: '主页',
    url: '/',
    pageconfig: [],
  }, {
    id: '123-2',
    title: '新闻',
    url: '/news',
    pageconfig: [],
  }, {
    id: '123-3',
    title: '财经',
    url: '/finance',
    pageconfig: [],
  }],
  isused: true,
  updateAt: '2022-12-24 12:00:00',
}]

const PageStyles = [{
  value: 'pc',
  title: 'pc页面'
}, {
  value: 'mobile',
  title: '手机页面'
}, {
  value: 'datav',
  title: '大屏'
}]


// {
//   "accountid": "9208750090",
//   "createdAt": "2022-12-29T15:00:17.987Z",
//   "createdBy": "45e9a4f3-82ab-4146-a035-3aa5dcbf0193",
//   "id": "d33bbfcc-ff56-4acf-bd0b-126b8b5cec06",
//   "isused": false,
//   "organizationId": "445B67BD-CF1A-4EE9-A6F0-6DCC9BA6AAEF",
//   "pages": [],
//   "status": 0,
//   "title": "测试",
//   "updateAccountid": "9208750090",
//   "updatedAt": "2022-12-29T15:00:17.987Z",
//   "updatedBy": "45e9a4f3-82ab-4146-a035-3aa5dcbf0193",
//   "url": "/abc",
//   "version": 1
// }

const TemplateManager = () => {
  const [templates, setTemplates] = useState<any>([]);
  const [mode, setMode] = useState(0);  //0:无 1：新增 2:修改 3:复制
  const [templateModalVisible, setTemplateModalVisible] = useState(false);
  // const [editTemplate, setEditTemplate] = useState<any>(null);
  const [templateIndex, setTemplateIndex] = useState(-1);

  const [pagGroupModalVisible, setPageGroupModalVisible] = useState(false);
  const [pageGroupIndex, setPageGroupIndex] = useState(-1);

  const [pageModalVisible, setPageModalVisible] = useState(false);

  const [pageCopyModalVisible, setPageCopyModalVisible] = useState(false);
  // const [editPage, setEditPage] = useState<any>(null);
  // const [pageIndex, setPageIndex] = useState(-1);
  const [selectPage, setSelectPage] = useState<any>(null);

  const [imageOSS, setImageOSS] = useState({}); //图片oss地址

  const [formTemplate] = Form.useForm();
  const [formPageGroup] = Form.useForm();
  const [formPage] = Form.useForm();
  const [formPageCopy] = Form.useForm();

  const pageStyleValue = Form.useWatch('pageStyle', formPage);
  const pageCopyTemplateId = Form.useWatch('templateid', formPageCopy);

  const organization = useSelector((state: any) => state.global.organization);

  useEffect(() => {
    //获取ImageOss地址
    reqGetSignature({ bucketType: 2, system: 'muzhiyun' }).then(res => {
      setImageOSS(res.Data[0]);
    })
  }, [])

  useEffect(() => {
    refreshTempaltes();
  }, [organization])

  useEffect(() => {
    let tempPageGroups = _.find(templates, o => o.id === pageCopyTemplateId)?.pagegroups || [];
    if (!_.find(tempPageGroups, o => o.id === formPageCopy.getFieldValue('pagegroupid'))) {
      formPageCopy.setFieldValue('pagegroupid', null);
    }
  }, [pageCopyTemplateId])

  useEffect(() => {
    refreshTempaltes();
  }, [organization])

  //刷新模板
  const refreshTempaltes = () => {
    if (organization?.organizationGuid) {
      reqGetWebSiteTemplates({
        organizationId: organization?.organizationGuid
      }).then(res => {
        setTemplates(res.items);
      })
    }
  }

  //新增模板
  const handleAddTemplate = () => {
    formTemplate.setFieldsValue({
      title: '新建模板'
    });
    setMode(1);
    setTemplateModalVisible(true);
  }

  //修改模板
  const handleEditTemplate = (item: any) => {
    console.log(item);

    formTemplate.setFieldsValue({
      title: item.title,
      url: item.url,
      remark: item.remark,
    });
    setMode(2);
    setTemplateModalVisible(true);
  }

  //复制模板
  const handleCopyTemplate = (item: any) => {
    formTemplate.setFieldsValue({
      title: item.title + "_copy"
    });
    setMode(3);
    setTemplateModalVisible(true);
  }

  //删除模板
  const handleDelTemplate = (item: any) => {
    reqRemoveWebSiteTemplate(item.id).then(res => {
      refreshTempaltes();
    })
  }

  //启用、停用模板
  const handleTemplateUseChange = (item: any, use: boolean) => {
    let tempItem = {
      ...item,
      isused: !item.isused,
    }
    reqUpdateWebSiteTemplate(tempItem).then(res => {
      refreshTempaltes();
    })
  }

  //确定
  const handleTemplateOK = () => {
    formTemplate.validateFields().then(async formdata => {
      let res: any = {};
      let tempTemplate = templates[templateIndex];
      switch (mode) {
        case 1:  //新增
          res = await reqAddWebSiteTemplate({
            organizationId: organization.organizationGuid,
            title: formdata.title,
            url: formdata.url,
            remark: formdata.remark,
          });
          message.success(res.msg);
          refreshTempaltes();
          break;
        case 2:  //修改
          if (tempTemplate) {
            tempTemplate = {
              id: tempTemplate.id,
              ...formdata,
            }
            res = await reqUpdateWebSiteTemplate(tempTemplate);
            message.success(res.msg);
            refreshTempaltes();
          }
          break;
        case 3: //复制
          // if (tempTemplate) {
          //   tempTemplates.push({
          //     ..._.cloneDeep(tempTemplate),
          //     id: NewGuid(),
          //     title: formdata.title,
          //     isused: false,
          //     updateAt: moment().format("yyyy-MM-dd hh:mm:ss"),
          //   })
          //   setTemplates(tempTemplates);
          // }
          break;
        default:
          break;
      }
      setTemplateModalVisible(false);
    })
  }

  //取消
  const handleTemplateCancel = () => {
    setTemplateModalVisible(false);
  }

  //获取模板描述
  const getTemplateDes = (item: any) => {
    return (<div>
      <div>{`域名：${item.url}`}</div>
      <div>{`共包含${item.pages.length}个页面。`}</div>
    </div>)
  }

  const handleAddPage = () => {
    formTemplate.setFieldsValue({
      name: '新页面',
      url: '/',
    });
    setMode(1);
    setPageModalVisible(true);
  }

  const handleEditPage = (item: any) => {
    formPage.setFieldsValue({
      name: item.name,
      pageurl: item.pageurl,
      pageStyle: item?.pageStyle || 'pc',
      pageWidth: item.pageWidth || 0,
      pageHeight: item.pageHeight || 0,
      bgurl: item.bgurl,
      redirectUrl: item.redirectUrl || '',
      pagegroupid: item.pagegroupid,
    });
    setMode(2);
    setPageModalVisible(true);
  }

  const handleCopyPage = (item: any) => {
    formPageCopy.setFieldsValue({
      id: item.id,
      templateid: item.templateid,
      pagegroupid: item.pagegroupid,
      name: item.name + "_copy",
      pageurl: item.pageurl,
    });
    setMode(3);
    setPageCopyModalVisible(true);
  }

  const handleDesignPage = (item: any) => {
    window.open(`/pageedit?organizationId=${organization?.organizationGuid}&pageid=${item.id}`);
  }

  const handleDelPage = (item: any) => {
    console.log(item);

    reqRemovePage(item.id);
    refreshTempaltes();
  }

  const handlePageOK = () => {
    formPage.validateFields().then(async formdata => {
      console.log(formdata);
      let res: any = {};
      // let tempTemplates = _.cloneDeep(templates);
      // let tempPage = templates[templateIndex].pages[pageIndex];
      let tempPage: any = selectPage;
      switch (mode) {
        case 1:  //新增
          res = await reqAddPage({
            templateid: templates[templateIndex].id,
            name: formdata.name,
            pageurl: formdata.pageurl,
            pagecontent: [],
            pageStyle: formdata.pageStyle,
            pageWidth: formdata.pageWidth,
            pageHeight: formdata.pageHeight,
            bgurl: formdata.bgurl,
            redirectUrl: formdata.redirectUrl,
          });
          message.success(res.msg);
          refreshTempaltes();
          break;
        case 2:  //修改
          if (tempPage) {
            tempPage = {
              id: tempPage?.id,
              ...formdata,
            }
            console.log(tempPage);

            res = await reqUpdatePage(tempPage);
            message.success(res.msg);
            refreshTempaltes();
          }
          break;
        case 3: //复制
          // res = await reqAddPage({
          //   templateid: templates[templateIndex].id,
          //   name: formdata.name,
          //   pageurl: formdata.pageurl,
          //   pagecontent: tempPage.pagecontent,
          //   pageStyle: formdata.pageStyle,
          //   pageWidth: formdata.pageWidth,
          //   pageHeight: formdata.pageHeight,
          //   bgurl: formdata.bgurl,
          //   redirectUrl: formdata.redirectUrl,
          // });
          res = await reqCopyPage({
            templateid: templates[templateIndex].id,
            id: tempPage.id,
            ...formdata,
          });
          message.success(res.msg);
          refreshTempaltes();
          break;
        default:
          break;
      }

      setPageModalVisible(false);
    })
  }

  const handlePageCancel = () => {
    setPageModalVisible(false);
  }

  const handleAddPageGroup = () => {
    formPageGroup.setFieldsValue({
      title: '新分组'
    });
    setMode(1);
    setPageGroupModalVisible(true);
  }

  const handleEditPageGroup = (item: any) => {
    formPageGroup.setFieldsValue({
      title: item.title,
    });
    setMode(2);
    setPageGroupModalVisible(true);
  }

  const handleDelPageGroup = (item: any) => {
    reqRemovePageGroup(item.id).then(res => {
      refreshTempaltes();
    })
  }

  const handlePageGroupOK = () => {
    formPageGroup.validateFields().then(async formdata => {
      let res: any = {};
      let tempTemplate = templates[templateIndex];
      switch (mode) {
        case 1:  //新增
          res = await reqAddPageGroup({
            organizationId: organization.organizationGuid,
            templateid: tempTemplate.id,
            title: formdata.title,
            groupType: 1,
          });
          message.success(res.msg);
          refreshTempaltes();
          break;
        case 2:  //修改
          console.log(tempTemplate);

          if (tempTemplate) {
            tempTemplate = {
              id: tempTemplate.pagegroups[pageGroupIndex].id,
              ...formdata,
            }
            res = await reqUpdatePageGroup(tempTemplate);
            message.success(res.msg);
            refreshTempaltes();
          }
          break;
        default:
          break;
      }
      setPageGroupModalVisible(false);
    })
  }

  const handlePageGroupCancel = () => {
    setPageGroupModalVisible(false);
  }

  const getPageDes = (item: any) => {
    let tempgroup = _.find(templates[templateIndex]?.pagegroups, o => o.id === item.pagegroupid);
    return (
      <div>
        <div>
          {_.isNil(tempgroup) ? <Tag>未分组</Tag> : <Tag color="red">{tempgroup.title}</Tag>}
        </div>
        <div style={{ margin: "5px 0 0 2px" }}>{item.pageurl}</div>
      </div>
    )
  }

  const handlePageCopyOK = () => {
    formPageCopy.validateFields().then(async formdata => {
      console.log(formdata);
      let res: any = {};
      // let tempTemplates = _.cloneDeep(templates);

      res = await reqCopyPage(formdata);
      message.success(res.msg);
      refreshTempaltes();
      setPageCopyModalVisible(false);
    })
  }

  const handlePageCopyCancel = () => {
    setPageCopyModalVisible(false);
  }

  // console.log(_.concat({id: '',title: '未分组'}, (templates[templateIndex]?.pagegroups || [])));
  

  return (
    <div className={styles.container}>
      <div className={styles.topbar}>
        {/* <Button onClick={()=>{
          reqGetPageGroups({
            organizationId: organization?.organizationGuid
          }).then(res=>{
            console.log(res);
          })
          
        }}>test</Button> */}
        {templates.map((item: any, index: number) => (
          <Card
            key={`card${index}`}
            className={styles.card}
            style={index === templateIndex ? { border: "solid 1px" } : {}}
            onClick={() => { setTemplateIndex(index) }}
            actions={[
              <Tooltip title="编辑">
                <EditOutlined className={styles.icon} key="edit" onClick={() => { handleEditTemplate(item) }} />
              </Tooltip>,
              <Tooltip title="复制">
                <CopyOutlined className={styles.icon} key="copy" onClick={() => { handleCopyTemplate(item) }} />
              </Tooltip>,
              <Tooltip title="删除">
                <Popconfirm
                  title="是否确定要删除此模板?"
                  onConfirm={() => { handleDelTemplate(item) }}
                >
                  <DeleteOutlined className={styles.icon} key="del" />
                </Popconfirm>
              </Tooltip>
            ]}
            title={item.title}
            extra={<Switch
              checkedChildren="启用"
              unCheckedChildren="停用"
              checked={item.isused}
              onChange={check => { handleTemplateUseChange(item, check) }} />}
          >
            <Meta
              description={getTemplateDes(item)}
            />
          </Card>
        ))}
        <Tooltip title="新增">
          <div className={styles.add} onClick={handleAddTemplate}><PlusOutlined /></div>
        </Tooltip>
      </div>
      {/* 查询条件 */}
      {templates[templateIndex] && (
        <div className={styles.pagegroup}>
          <Tag color={pageGroupIndex === -1 ? "#2db7f5" : ""} >
            <span onClick={() => { setPageGroupIndex(-1) }}>全部</span>
          </Tag>
          {templates[templateIndex]?.pagegroups.map((item: any, index: number) => {
            return (
              <Tag key={`tag${item.id}`}
                color={pageGroupIndex === index ? "#2db7f5" : ""} >
                <span onClick={() => { setPageGroupIndex(index) }}>{item.title}</span>
                <Dropdown overlay={(
                  (
                    <Menu>
                      <Menu.Item onClick={() => { handleEditPageGroup(item) }}>
                        修改
                      </Menu.Item>
                      <Menu.Item >
                        <Popconfirm
                          title="是否确定要删除此分组?"
                          onConfirm={() => { handleDelPageGroup(item) }}
                        >删除
                        </Popconfirm>
                      </Menu.Item>
                    </Menu>
                  )
                )}>
                  <MoreOutlined />
                </Dropdown>
              </Tag>
            )
          })}
          <Tag color="red" >
            <span onClick={handleAddPageGroup}>新增</span>
            <PlusOutlined />
          </Tag>
        </div>
      )}
      {/* page页面 */}
      <div className={styles.content}>
        {templates[templateIndex] && templates[templateIndex].pages
          .filter((item: any) => pageGroupIndex === -1 || item.pagegroupid === templates[templateIndex].pagegroups[pageGroupIndex]?.id)
          .map((item: any, index: number) => (
            <Card
              key={`card${index}`}
              className={styles.card}
              style={item.id === selectPage?.id ? { border: "solid 1px" } : {}}
              onClick={() => { setSelectPage(item) }}
              actions={[
                <Tooltip title="编辑">
                  <EditOutlined className={styles.icon} key="edit" onClick={() => { handleEditPage(item) }} />
                </Tooltip>,
                <Tooltip title="复制">
                  <CopyOutlined className={styles.icon} key="copy" onClick={() => { handleCopyPage(item) }} />
                </Tooltip>,
                <Tooltip title="设计">
                  <SettingOutlined className={styles.icon} key="disign" onClick={() => { handleDesignPage(item) }} />
                </Tooltip>,
                <Tooltip title="删除">
                  <Popconfirm
                    title="是否确定要删除此页面?"
                    onConfirm={() => { handleDelPage(item) }}
                  >
                    <DeleteOutlined className={styles.icon} key="del" />
                  </Popconfirm>
                </Tooltip>
              ]}
              title={item.name}
              extra={item.pageStyle === 'mobile' ? <MobileOutlined /> : (item.pageStyle === 'datav' ? <AreaChartOutlined /> : <LaptopOutlined />)}
            >
              <Meta
                description={getPageDes(item)}
              />

            </Card>
          ))}

        <Tooltip title="新增">
          <div className={styles.add} onClick={handleAddPage}><PlusOutlined /></div>
        </Tooltip>
      </div>

      <Modal
        title="网站编辑"
        okText="确认"
        cancelText="取消"
        visible={templateModalVisible}
        onOk={handleTemplateOK}
        onCancel={handleTemplateCancel}
      >
        <Form
          form={formTemplate}
          name="control-ref"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Form.Item
            name="title"
            label="标题"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="url"
            label="域名"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="remark"
            label="备注"
          >
            <TextArea rows={4} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="页面编辑"
        okText="确认"
        cancelText="取消"
        visible={pageModalVisible}
        onOk={handlePageOK}
        onCancel={handlePageCancel}
      >
        <Form
          form={formPage}
          name="controlpage-ref"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Form.Item
            name="name"
            label="标题"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="pagegroupid"
            label="分组"
          >
            <Select options={_.concat({id: '',title: '未分组'}, (templates[templateIndex]?.pagegroups || [])).map((item: any) => ({ value: item.id, label: item.title }))} />
          </Form.Item>
          <Form.Item
            name="pageurl"
            label="地址"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="pageStyle"
            label="页面类型"
            rules={[{ required: true }]}
          >
            <Radio.Group >
              {PageStyles.map((item, index) => (
                <Radio key={`radio${index}`} value={item.value}>
                  {item.title}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          {/* 1920*1080 */}
          <Form.Item
            name="pageWidth"
            label="页面宽"
            tooltip="仅在大屏页面下生效，建议分辨率为：1920*1080"
            hidden={pageStyleValue !== 'datav'}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name="pageHeight"
            label="页面高"
            tooltip="仅在大屏页面下生效，建议分辨率为：1920*1080"
            hidden={pageStyleValue !== 'datav'}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            name="bgurl"
            label="背景图"
            tooltip="仅在大屏页面下生效"
            hidden={pageStyleValue !== 'datav'}
          >
            <ImageInput dataOSS={imageOSS} />
          </Form.Item>
          <Form.Item
            name="redirectUrl"
            label="跳转地址"
            tooltip="仅在PC页面下有效，用于主页自动跳转到手机端主页"
            hidden={pageStyleValue !== 'pc'}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="页面复制"
        okText="确认"
        cancelText="取消"
        visible={pageCopyModalVisible}
        onOk={handlePageCopyOK}
        onCancel={handlePageCopyCancel}
      >
        <Form
          form={formPageCopy}
          name="controlpage-ref"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Form.Item
            name="id"
            label="id"
            hidden
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="templateid"
            label="网站"
          >
            <Select options={templates.map((item: any) => ({ value: item.id, label: item.title }))} />
          </Form.Item>
          <Form.Item
            name="name"
            label="标题"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="pagegroupid"
            label="分组"
          >
            <Select options={(_.find(templates, o => o.id === pageCopyTemplateId)?.pagegroups || []).map((item: any) => ({ value: item.id, label: item.title }))} />
          </Form.Item>
          <Form.Item
            name="pageurl"
            label="地址"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="网站分组"
        okText="确认"
        cancelText="取消"
        visible={pagGroupModalVisible}
        onOk={handlePageGroupOK}
        onCancel={handlePageGroupCancel}
      >
        <Form
          form={formPageGroup}
          name="control-ref"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Form.Item
            name="title"
            label="标题"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
};

export default TemplateManager;
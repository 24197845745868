/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\customvideo\index.jsx
 * @LastEditTime: 2023-01-18 11:21:59
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { dealParam } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';


export type CustomFrameConfig = {
  type: String;  //类型 customframe
  key: String; //主键
  title: String; //标题
  width: String;
  height: String;
  src: String; //地址
}

interface IProps {
  config: CustomFrameConfig;
  subParam?: any; //父组件传给子组件的参数
}

//嵌套页面
const CustomFrame: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  let { config, subParam } = props;
  subParam = {
    ...pageParam,
    ...subParam,
  }

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = {
    width: config.width,
    height: config.height,
  };
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  return (
    // <div style={style}>

    // </div>
    <iframe
      style={style}
      // scrolling="no"
      frameBorder={0}
      // onLoad={onLoad}
      src={dealParam(config.src, subParam)}
      // ref={frameRef}
    />
  )
};

export default CustomFrame;
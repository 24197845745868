/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\papermanager\index.tsx
 * @LastEditTime: 2023-05-31 19:13:55
 */

import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  message, Card, Tabs, Tooltip, Modal, Form, Input, Select, Avatar, Radio, Carousel, Image, Button,
  Space, Popconfirm, Table, DatePicker as TDatePicker, InputNumber,
  AutoComplete
} from 'antd';
import {
  EyeOutlined, EditOutlined, DeleteOutlined, HighlightOutlined,
  EllipsisOutlined, SettingOutlined, PlusOutlined
} from '@ant-design/icons';
import _ from 'lodash';
import {
  reqGetArticles, reqAddArticle, reqUpdateArticle, reqRemoveArticle, reqGetArticleGroups,
  reqGetSignature, reqPdfToImage, reqGetChannels
} from 'api/api';
import PdfInput from 'components/common/pdfinput';
import styles from './index.module.scss';
import moment from "moment";
import { getFileNameWithOutExt, NewGuid } from "utils/common";

let DatePicker: any = TDatePicker;

interface IScriptConfig {
  id: string;
  articleid: string;
  title: string;
  url: string;
  shape?: string;
  coords?: any;
}

const PaperManager = () => {
  const [articles, setArticles] = useState<any>([]);
  const [articleGroups, setArticleGroups] = useState<any>([]);
  const [articleGroup, setArticleGroup] = useState('');  //条件
  const [publishDate, setPublishDate] = useState<moment.Moment | null>(null);  //条件
  const [modalVisible, setModalVisible] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const [scriptVisible, setScriptVisible] = useState(false);
  const [article, setArticle] = useState<any>({});
  const [openMode, setOpenMode] = useState(0); //0：浏览 1：新增 2：修改
  const [pdfOSS, setPdfOSS] = useState<any>({}) //图片oss地址

  const [scriptConfigs, setScriptConfigs] = useState<IScriptConfig[]>([]);  //电子报对应的稿件配置列表
  //const [scriptConfigs, setScriptConfigs] = useState<any>([]);  //电子报对应的稿件配置列表
  const [scriptConfig, setScriptConfig] = useState<IScriptConfig | null>(null);  //选中的配置
  const [scripts, setScripts] = useState<any>([]); //稿件列表
  const [selectScripts, setSelectScripts] = useState<any>([]); //选中的稿件列表

  const [scriptPublishDate, setScriptPublishDate] = useState<moment.Moment | null>(moment());  //稿件查询条件
  const [folderName, setFolderName] = useState('');  //稿件查询条件
  const [scriptTitle, setScriptTitle] = useState('');  //稿件查询条件

  //当前鼠标框选的矩形
  const [rect, setRect] = useState({
    SX: 0,
    SY: 0,
    EX: 0,
    EY: 0
  })
  const [addMap, setAddMap] = useState(false); //是否添加了第一个点
  const [isEditMap, setIsEditMap] = useState(false);  //点击开始标记

  const imgRef = useRef(null);
  const [form] = Form.useForm();

  const coverImage = Form.useWatch('coverImage', form);

  const organization = useSelector((state: any) => state.global.organization);

  const columns = [
    {
      title: '分类名称',
      dataIndex: 'articlegrouptitle',
      key: 'articlegrouptitle'
    },
    {
      title: '发布日期',
      dataIndex: 'publishdate',
      key: 'publishdate'
    },
    {
      title: '期数',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: '第几版',
      dataIndex: 'sequence',
      key: 'sequence'
    },
    {
      title: '版面简介',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '缩略图',
      key: 'coverImage',
      render: (_: any, record: any) => (
        <Avatar src={<Image src={record.coverImage} style={{ width: 32 }} />} />
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => { handleEdit(record) }}>修改</a>
          <Popconfirm
            title={`确认删除?`}
            onConfirm={() => { handleDel(record) }}>
            <a >删除</a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  useEffect(() => {
    handleRefresh();
    getArticleGroups();
  }, [organization]);

  useEffect(() => {
    if (scriptVisible) {
      handleRefreshScripts();
    }
  }, [scriptVisible]);

  useEffect(() => {
    handleRefreshScripts();
  }, [scriptPublishDate]);

  useEffect(() => {
    // handleRefresh();
    // getArticleGroups();

    reqGetSignature({ bucketType: 2, system: 'muzhiyun' }).then(res => {
      setPdfOSS(res.Data[0]);
    })
  }, []);


  const handleRefresh = () => {
    if (organization?.organizationGuid) {
      let params: any = {
        organizationId: organization?.organizationGuid,
        // articletype: '3',
        originalType: 103,
        page: 1,
        count: 999
      }
      if (articleGroup !== '') {
        params.articleGroup = articleGroup;
      }
      if (!_.isNil(publishDate)) {
        params.publishdate = publishDate.format('YYYY-MM-DD');
      }

      reqGetArticles(params).then(res => {
        console.log(res);

        setArticles(res.items.map((item: any) => {
          return {
            ...item,
            publishdate: moment(item.publishdate).format('YYYY-MM-DD'),
            articlegrouptitle: item.articlegroup.title
          }
        }));
      })
    }
  }

  const getArticleGroups = () => {
    if (organization?.organizationGuid) {
      const param = {
        organizationId: organization?.organizationGuid,
        articletype: 3,
      }
      reqGetArticleGroups(param).then(res => {
        setArticleGroups(res.items
          .sort((a: any, b: any) => { return moment(a.createdAt).diff(moment(b.createdAt), 'second') }));
      })
    }
  }

  const handleNew = () => {
    console.log(form.getFieldsValue());

    form.setFieldsValue({
      ...form.getFieldsValue(),
      // title: '',
      // description: '',
      coverImage: '',
      url: '',
    });
    setScriptConfigs([]);
    setScriptConfig(null);
    setOpenMode(1);
    setModalVisible(true);
  }

  const handleEdit = (record: any) => {
    console.log(record);

    setArticle(record);
    let tempdata: any = _.pick(record, ['articlegroupid', 'title',
      'description', 'sequence', 'url', 'coverImage']);

    tempdata.publishdate = moment(record.publishdate);

    form.setFieldsValue(tempdata);
    setScriptConfigs(record.content);
    setScriptConfig(null);

    setOpenMode(2);
    setModalVisible(true);
  }

  const handleDel = (record: any) => {
    reqRemoveArticle(record.id).then(res => {
      message.success(res.msg);
      handleRefresh();
    })
  }

  const handleOK = () => {
    console.log(1);

    form.validateFields().then(async (formdata) => {
      let res: any = {};
      if (openMode === 1) {
        res = await reqAddArticle({
          organizationId: organization.organizationGuid,
          originalType: 103,
          ...formdata,
          publishdate: formdata.publishdate.format('YYYY-MM-DD'),
          content: scriptConfigs
        });
        message.success(res.msg);
      }
      else if (openMode === 2) {
        res = await reqUpdateArticle({
          ...article,
          ...formdata,
          publishdate: formdata.publishdate.format('YYYY-MM-DD'),
          content: scriptConfigs
        });
        message.success(res.msg);
      }
      // console.log(formdata);

      setModalVisible(false);
      setOpenMode(0);
      handleRefresh();
    });
  }

  const handleCancel = () => {
    setModalVisible(false);
    setOpenMode(0);
  }

  //编辑图片的热区
  const handleDesignContent = () => {
    setContentVisible(true);
  }

  const handleContentOk = () => {
    setContentVisible(false);
  }

  const handleContentCancel = () => {
    setContentVisible(false);
  }

  const getArticleGroupOptions = () => {
    let tempitems = articleGroups.map((item: any) => ({
      value: item.id,
      label: item.title
    }));
    tempitems = [{
      value: '',
      label: '全部',
    }].concat(tempitems);
    return tempitems;
  }

  const handleAfterUpload = (pdfurl: any) => {
    const param = {
      fileName: getFileNameWithOutExt(pdfurl),
      fileUrl: pdfurl
    }
    reqPdfToImage(param).then(res => {
      console.log(res.dataInfo[0].path);

      form.setFieldsValue({
        coverImage: res.dataInfo[0].path
      })
    })
  }

  const handleMouseDown = (e: any) => {
    if (isEditMap) {
      const x = e.nativeEvent.offsetX + (e.nativeEvent.target as any).offsetLeft;
      const y = e.nativeEvent.offsetY + (e.nativeEvent.target as any).offsetTop;
      if (!addMap) {
        //开始新矩形
        setAddMap(true);
        setRect({
          SX: x,
          SY: y,
          EX: x,
          EY: y,
        })
      } else {
        //保存
        //naturalWidth naturalHeight 图片原始宽高度
        const naturalWidth = (imgRef?.current as any).naturalWidth;
        const naturalHeight = (imgRef?.current as any).naturalHeight;
        //clientWidth clientHeight 图片在当前界面的宽高度
        const clientWidth = (imgRef?.current as any).clientWidth;
        const clientHeight = (imgRef?.current as any).clientHeight;
        const temprect = {
          SX: rect.SX * naturalWidth / clientWidth,
          SY: rect.SY * naturalHeight / clientHeight,
          EX: x * naturalWidth / clientWidth,
          EY: y * naturalHeight / clientHeight,
        }
        let tempScriptConfigs = _.cloneDeep(scriptConfigs);
        let tempScriptConfig = _.find(tempScriptConfigs, item => item.id === scriptConfig?.id);
        if (tempScriptConfig) {
          tempScriptConfig.coords = {
            ...temprect
          }
          setScriptConfig(tempScriptConfig);
          setScriptConfigs(tempScriptConfigs);
        }

        setAddMap(false);
        setIsEditMap(false);
        setRect({
          SX: 0,
          SY: 0,
          EX: 0,
          EY: 0,
        })
      }
    }
  }

  const handleMouseMove = (e: any) => {
    const x = e.nativeEvent.offsetX + (e.nativeEvent.target as any).offsetLeft;
    const y = e.nativeEvent.offsetY + (e.nativeEvent.target as any).offsetTop;
    setRect({
      ...rect,
      EX: x,
      EY: y,
    })
  }

  const handleTest = () => {
    console.log(imgRef);
    const naturalWidth = (imgRef?.current as any).naturalWidth;
    const naturalHeight = (imgRef?.current as any).naturalHeight;
    console.log(naturalWidth, naturalHeight);

  }

  const handleOpenScripts = () => {
    setScriptVisible(true);
    setSelectScripts([]);
  }

  const handleRefreshScripts = () => {
    console.log('handleRefreshScripts');

    let params: any = {
      organizationId: organization?.organizationGuid,
      originalType: 104,
      page: 1,
      count: 999
    }
    if (!_.isNil(scriptPublishDate)) {
      params.publishdate = scriptPublishDate.format('YYYY-MM-DD');
    }
    reqGetArticles(params).then(res => {
      setScripts(res.items);
    })
  }

  const handleScriptOk = () => {
    let tempScriptConfigs = _.cloneDeep(scriptConfigs);
    selectScripts.forEach((item: any) => {
      tempScriptConfigs.push({
        id: NewGuid(),
        articleid: item.id,
        title: item.title,
        url: item.url,
        shape: 'rect',
      })
    });
    setScriptConfigs(tempScriptConfigs);
    setScriptVisible(false);
  }

  const handleScriptCancel = () => {
    setScriptVisible(false);
  }



  return (
    <div className={styles.container}>
      <div className={styles.title}>
        电子报管理
      </div>
      <div className={styles.tools}>
        <Select
          value={articleGroup}
          onChange={setArticleGroup}
          style={{ width: 120, marginRight: "5px" }}
          options={getArticleGroupOptions()} />
        <DatePicker value={publishDate} onChange={setPublishDate} />
        <Button type="primary" className={styles.btn} onClick={handleNew}>新增</Button>
        <Button type="primary" className={styles.btn} onClick={handleRefresh}>查询</Button>
      </div>
      <div className={styles.content}>
        <Table columns={columns}
          className={styles.table}
          scroll={{ y: '500px' }}
          dataSource={articles.filter((item: any) => { return articleGroup === '' || item.articlegroupid === articleGroup })} />
      </div>
      {/* <div style={{width:"80%",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <UEditor 
        // value={html} onChange={setHtml}
        />
      </div> */}
      <Modal title='编辑'
        maskClosable={false}
        visible={modalVisible}
        onOk={handleOK}
        onCancel={handleCancel}>
        <Form
          form={form}
          name="control-ref"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          preserve={false}
        >
          <Form.Item
            name="articlegroupid"
            label="电子报"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: "100%" }}
              options={articleGroups.map((item: any) => ({ value: item.id, label: item.title }))} />
          </Form.Item>
          <Form.Item
            name="publishdate"
            label="发布日期"
            rules={[{ required: true }]}
          >
            <DatePicker
              style={{ width: 120 }} />
          </Form.Item>
          <Form.Item
            name="title"
            label="期数"
            rules={[{ required: true }]}
          >
            <AutoComplete style={{ width: "120px" }} options={[{ value: '第001期' }]} />
          </Form.Item>
          <Form.Item
            name="sequence"
            label="第几版"
            rules={[{ required: true }]}
          >
            <InputNumber min={1} style={{ width: "120px" }} />
          </Form.Item>
          <Form.Item
            name="description"
            label="版面简介"
            rules={[{ required: true, max: 200 }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="url"
            label="pdf地址"
            rules={[{ required: true }]}
          >
            <PdfInput dataOSS={pdfOSS} afterUpload={handleAfterUpload} />
          </Form.Item>
          <Form.Item
            name="coverImage"
            label="图片地址"
            rules={[{ required: true }]}
          >
            <Input addonAfter={(<Avatar src={<Image src={coverImage} style={{ width: 32 }} />} />)} />
          </Form.Item>
          <Form.Item label="配置">
            <Button type="primary"
              style={{
                marginRight: "5px"
              }}
              disabled={coverImage === ''}
              onClick={handleDesignContent}>
              设置稿件
            </Button>
            {`已设置${scriptConfigs.length}个稿件`}
          </Form.Item>
        </Form>
      </Modal>

      <Modal title='设置稿件'
        maskClosable={false}
        visible={contentVisible}
        width={1200}
        onOk={handleContentOk}
        onCancel={handleContentCancel}>
        <div className={styles.contentbody}>
          <div className={styles.left}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
          >
            {addMap ? (
              <div className={styles.selectedrect} style={{
                left: Math.min(rect.SX, rect.EX),
                top: Math.min(rect.SY, rect.EY),
                width: Math.abs(rect.SX - rect.EX),
                height: Math.abs(rect.SY - rect.EY),
              }} />
            ) : ''}
            {scriptConfigs.map((item: any) => {
              if (_.isNil(item.coords)) return '';
              if (_.isNil(imgRef?.current)) return '';
              //naturalWidth naturalHeight 图片原始宽高度
              const naturalWidth = (imgRef?.current as any).naturalWidth;
              const naturalHeight = (imgRef?.current as any).naturalHeight;
              //clientWidth clientHeight 图片在当前界面的宽高度
              const clientWidth = (imgRef?.current as any).clientWidth;
              const clientHeight = (imgRef?.current as any).clientHeight;

              const SX = item.coords.SX * clientWidth / naturalWidth;
              const SY = item.coords.SY * clientHeight / naturalHeight;
              const EX = item.coords.EX * clientWidth / naturalWidth;
              const EY = item.coords.EY * clientHeight / naturalHeight;

              return (
                <div key={item.id}
                  className={scriptConfig?.id === item.id ? styles.selectedrect : styles.rect}
                  style={{
                    left: Math.min(SX, EX),
                    top: Math.min(SY, EY),
                    width: Math.abs(SX - EX),
                    height: Math.abs(SY - EY),
                  }} />
              )
            })}
            <img ref={imgRef} width="100%" src={coverImage} />
          </div>

          <div className={styles.right}>
            {scriptConfigs.map((item: any, index: number) => (
              <Card className={`${styles.card} ${item.id === scriptConfig?.id ? styles.selected : ''}`}
                size="small"
                onClick={() => {
                  setScriptConfig(item);
                  setIsEditMap(true);
                }}
                actions={[
                  <Tooltip title="标记">
                    <HighlightOutlined
                      className={`${styles.icon} ${_.isNil(item.coords) ? '' : styles.havecoords}`}
                      key="draw"
                      onClick={() => {
                        let tempScriptConfigs = _.cloneDeep(scriptConfigs);
                        let tempScriptConfig = _.find(tempScriptConfigs, o => o.id === item.id);
                        if (tempScriptConfig) {
                          tempScriptConfig.coords = null;
                          setScriptConfigs(tempScriptConfigs);
                          setIsEditMap(true);
                        }
                      }} />
                  </Tooltip>,
                  <Tooltip title="浏览">
                    <a href={item.url} target="_blank">
                      <EyeOutlined className={styles.icon} key="view" />
                    </a>
                  </Tooltip>,
                  <Tooltip title="删除">
                    <Popconfirm
                      title="是否确定要删除?"
                      onConfirm={() => {
                        let tempScriptConfigs = _.cloneDeep(scriptConfigs);
                        _.pullAt(tempScriptConfigs, index);
                        setScriptConfigs(tempScriptConfigs);
                      }}
                    >
                      <DeleteOutlined className={styles.icon} key="del" />
                    </Popconfirm>
                  </Tooltip>,
                ]}
              >
                {item.title}
              </Card>
            ))}
            <div className={styles.add} onClick={handleOpenScripts}>
              <PlusOutlined />
            </div>
          </div>
        </div>
      </Modal>

      <Modal title='选择稿件'
        visible={scriptVisible}
        onOk={handleScriptOk}
        onCancel={handleScriptCancel}>
        <div className={styles.scriptbody}>
          <div className={styles.top}>
            <div className={styles.item}>
              <div className={styles.title}>发布日期</div>
              <DatePicker style={{ width: "120px" }} value={scriptPublishDate}
                onChange={(e: any) => {
                  setScriptPublishDate(e);
                }} />
            </div>
            <div className={styles.item}>
              <div className={styles.title}>稿件夹</div>
              <Input value={folderName} onChange={e=>{setFolderName(e.target.value)}} style={{ width: "100px" }} />
            </div>
            <div className={styles.item}>
              <div className={styles.title}>标题</div>
              <Input value={scriptTitle} onChange={e=>{setScriptTitle(e.target.value)}} style={{ width: "100px" }} />
            </div>
          </div>
          <div className={styles.top}>

          </div>
          <div className={styles.bottom}>
            {scripts.filter((item: any) => {
              return (folderName === '' || item.folderName.indexOf(folderName) >= 0) &&
                (scriptTitle === '' || item.title.indexOf(scriptTitle) >= 0);
            })
              .map((item: any) => {
                const tempIndex = _.findIndex(selectScripts, (o: any) => o.id === item.id);
                return (
                  <div key={`card${item.id}`}
                    className={`${styles.item} ${tempIndex > -1 ? styles.selected : ''}`}
                    onClick={() => {
                      let tempSelectScripts = _.cloneDeep(selectScripts);
                      if (tempIndex === -1) {
                        tempSelectScripts.push(item);
                      } else {
                        _.pullAt(tempSelectScripts, tempIndex);
                      }
                      setSelectScripts(tempSelectScripts);
                    }}>
                    <div className={styles.left}>
                      <div className={styles.title}>
                        {item.title}
                      </div>
                      <div className={styles.subtitle}>
                        <div>
                          {`稿件夹：${item.folderName}`}
                        </div>
                        <div>
                          {`${item.publishdate}`}
                        </div>
                      </div>
                    </div>

                    <a className={styles.icon} href={item.url} target="_blank">
                      <EyeOutlined />
                    </a>
                  </div>
                )
              })}
          </div>
        </div>
      </Modal>
    </div>
  )
};

export default PaperManager;
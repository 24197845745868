/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\articlelist\index.jsx
 * @LastEditTime: 2023-03-06 16:52:52
 */
import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { reqGetArticlesdzb, reqGetPublishDates } from 'api/api';
import {
  CalendarOutlined, DoubleLeftOutlined, DoubleRightOutlined,
  ReloadOutlined, FileTextOutlined, LeftCircleOutlined,
  RightCircleOutlined, DownloadOutlined
} from '@ant-design/icons';
import { Popover, Calendar } from 'antd';
import _ from 'lodash';
import styles from './index.module.scss';
import moment from "moment";

//电子报
export type PaperListConfig = {
  type: String;  //类型 paperlist
  key: String; //主键
  title: String; //标题
  articlegroupid: String; //电子报
  width: String; //宽度
}

interface IProps {
  config: PaperListConfig;
}

interface IScriptConfig {
  id: string;
  articleid: string;
  title: string;
  url: string;
  shape?: string;
  coords?: any;
}

//电子报列表
const PaperList: React.FC<IProps> = (props: IProps) => {
  const { config } = props;
  const [publishDates, setPublishDates] = useState<string[]>([]); //所有发布日期
  const [publishDateIndex, setPublishDateIndex] = useState(-1); //当前日期
  const [papers, setPapers] = useState<any>([]);  //版面列表,一次查询一期的所有版面
  const [paperIndex, setPaperIndex] = useState(0); //版面Index 
  const [scriptIndex, setScriptIndex] = useState(-1); //版面中文章Index
  const [calendarVisible, setCalendarVisible] = useState(false);
  //naturalWidth naturalHeight 图片原始宽高度
  //clientWidth clientHeight 图片在当前界面的宽高度
  const [imgInfo, setImgInfo] = useState({
    naturalWidth: 0,
    naturalHeight: 0,
    clientWidth: 0,
    clientHeight: 0,
  })

  const urlSearch = new URLSearchParams(useLocation().search);
  const articlegroupid = config.articlegroupid === 'outid' ? urlSearch.get('articlegroupid') || '' : config.articlegroupid;
  const organization = useSelector((state: any) => state.global.organization);

  const imgRef = useRef(null);
  const frameRef = useRef(null);

  useEffect(() => {
    getPublishDates();
  }, [articlegroupid])

  useEffect(() => {
    getPapers();
  }, [publishDateIndex])

  const getPublishDates = () => {
    const params = {
      organizationId: organization?.organizationGuid,
      articlegroupid: articlegroupid as string,
    }
    reqGetPublishDates(params).then(res => {
      if (res.dataInfo.length > 0) {
        setPublishDates(res.dataInfo);
        setPublishDateIndex(res.dataInfo.length - 1);
      }
    })
  }

  const getPapers = () => {
    if (publishDateIndex < 0 || publishDateIndex >= publishDates.length) return;
    const params = {
      organizationId: organization?.organizationGuid,
      articlegroupid: articlegroupid as string,
      originalType: 103,
      publishdate: publishDates[publishDateIndex],
      page: 1,
      count: 9999
    }
    reqGetArticlesdzb(params).then(res => {
      setPapers(res.items.sort((a: any, b: any) => a.sequence - b.sequence).map((item: any) => {
        return {
          ...item,
          publishdate: moment(item.publishdate).format('YYYY-MM-DD'),
          articlegrouptitle: item.articlegroup.title
        }
      }));
      setPaperIndex(0);
      setScriptIndex(-1);
    })
  }

  const currentPaper = () => {
    if (papers.length === 0) return null;
    if (paperIndex < 0 || paperIndex >= papers.length) return null;
    const paper = papers[paperIndex];
    return paper;
  }

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);

  let style: any = {
    width: config.width,
  };
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  const getImage = () => {
    const paper = currentPaper();
    if (_.isNil(paper)) return '';
    return (
      <div className={styles.img}>
        <img onLoad={() => {
          setImgInfo({
            naturalWidth: (imgRef?.current as any).naturalWidth,
            naturalHeight: (imgRef?.current as any).naturalHeight,
            clientWidth: (imgRef?.current as any).clientWidth,
            clientHeight: (imgRef?.current as any).clientHeight,
          })
        }} src={paper?.coverImage} ref={imgRef} width="100%" />
        {paper.content.map((item: IScriptConfig, index: number) => {
          if (_.isNil(item.coords)) return '';
          if (_.isNil(imgRef?.current)) return '';
          if (imgInfo.naturalWidth === 0) return '';
          const { naturalWidth, naturalHeight, clientWidth, clientHeight } = imgInfo;

          const SX = item.coords.SX * clientWidth / naturalWidth;
          const SY = item.coords.SY * clientHeight / naturalHeight;
          const EX = item.coords.EX * clientWidth / naturalWidth;
          const EY = item.coords.EY * clientHeight / naturalHeight;
          return (
            <div key={item.id}
              className={styles.map}
              style={{
                left: Math.min(SX, EX),
                top: Math.min(SY, EY),
                width: Math.abs(SX - EX),
                height: Math.abs(SY - EY),
              }}
              onClick={() => {
                setScriptIndex(index);
              }} />
          )
        })}
      </div>
    )
  }

  const getMenu = () => {
    const paper = currentPaper();
    if (_.isNil(paper)) return '';
    return (
      <div className={styles.menu}>
        <div className={styles.menutop}>
          <div className={styles.menutitle}>{`第${paperIndex + 1}版:${paper.description}`}</div>
          <div className={paperIndex < papers.length - 1 ? styles.menubtn : styles.menubtndisable}
            onClick={() => {
              if (paperIndex < papers.length - 1) {
                setPaperIndex(paperIndex + 1);
              }
            }}>
            {'下一版>>'}
          </div>
        </div>
        <div className={styles.menubottom}>
          {paper.content.map((item: IScriptConfig, index: number) => (
            <li key={item.id}
              className={styles.item}
              onClick={() => { setScriptIndex(index) }}>
              {item.title}
            </li>
          ))}
        </div>
      </div>
    )
  }

  const onLoad = () => {
    // console.log('load');
    // var ifm:any = document.getElementById("iframeId");
    // console.log('ifm',ifm);
    // console.log('contentWindow',ifm.contentWindow);
    // console.log('body',ifm.contentWindow.document);
    // console.log('abc');

    // let ifm: any = frameRef.current;
    // console.log(ifm);
    // console.log(ifm.contentWindow);
    // console.log('3',ifm.contentWindow.Document);
    
    // console.log(ifm?.ownerDocument.body);
    // var subWeb = document.frames ? document.frames["iframeId"].document : ifm.contentDocument;
    //   if (ifm != null && subWeb != null) {
    //     setFrameHeight(subWeb.body.scrollHeight - 30);
    //   }
  };

  const getArticle = () => {
    const paper = currentPaper();
    if (_.isNil(paper)) return '';
    if (scriptIndex === -1) return '';
    if (scriptIndex >= paper.content.length) return '';
    const scriptConfig: IScriptConfig = paper.content[scriptIndex];
    return (
      <iframe
        id="iframeId"
        scrolling="no"
        frameBorder={0}
        onLoad={onLoad}
        src={scriptConfig.url}
        ref={frameRef}
        style={{
          height: "100%",
          width: "100%"
        }} />
    )
  }

  return (
    <div className={styles.container} style={style}>
      <div className={styles.left}>
        {getImage()}
        {scriptIndex === -1 ? '' : getMenu()}
      </div>
      <div className={styles.center}>
        <Popover
          showArrow={false}
          open={calendarVisible}
          onOpenChange={setCalendarVisible}
          placement="left"
          content={(<div style={{ width: '300px' }}>
            <Calendar
              value={publishDateIndex === -1 ? moment() : moment(publishDates[publishDateIndex])}
              fullscreen={false}
              disabledDate={(date) => {
                return publishDates.indexOf(date.format('YYYY-MM-DD')) < 0;
              }}
              onSelect={(date) => {
                setPublishDateIndex(publishDates.indexOf(date.format('YYYY-MM-DD')));
                setCalendarVisible(false);
              }}
            />
          </div>)} trigger="click">
          <div className={styles.btn}>
            <CalendarOutlined className={styles.icon} />
            往期
          </div>
        </Popover>
        <div className={publishDateIndex > 0 ? styles.btn : styles.btndisable}
          onClick={() => {
            if (publishDateIndex > 0) {
              setPublishDateIndex(publishDateIndex - 1);
            }
          }}>
          <DoubleLeftOutlined className={styles.icon} />
          上一期
        </div>
        <div className={publishDateIndex < publishDates.length - 1 ? styles.btn : styles.btndisable}
          onClick={() => {
            if (publishDateIndex < publishDates.length - 1) {
              setPublishDateIndex(publishDateIndex + 1);
            }
          }}>
          <DoubleRightOutlined className={styles.icon} />
          下一期
        </div>
        <div className={styles.btn}
          onClick={() => {
            setPublishDateIndex(publishDates.length - 1);
          }}>
          <ReloadOutlined className={styles.icon} />
          今日
        </div>
        <Popover
          showArrow={false}
          placement="left"
          content={(<div className={styles.paperlist}>
            {papers.map((item: any, index: number) => (
              <div key={item.id}
                className={paperIndex === index ? styles.selectpaperitem : styles.paperitem}
                onClick={() => {
                  setPaperIndex(index);
                }}>
                {`第${item.sequence}版:${item.description}`}
              </div>
            ))}
          </div>)}>
          <div className={styles.btn}>
            <FileTextOutlined className={styles.icon} />
            版次
          </div>
        </Popover>

        <div className={paperIndex > 0 ? styles.btn : styles.btndisable}
          onClick={() => {
            if (paperIndex > 0) {
              setPaperIndex(paperIndex - 1);
            }
          }}>
          <LeftCircleOutlined className={styles.icon} />
          上一版
        </div>
        <div className={paperIndex < papers.length - 1 ? styles.btn : styles.btndisable}
          onClick={() => {
            if (paperIndex < papers.length - 1) {
              setPaperIndex(paperIndex + 1);
            }
          }}>
          <RightCircleOutlined className={styles.icon} />
          下一版
        </div>
        <div className={styles.btn}
          onClick={() => {
            setScriptIndex(-1);
          }}>
          <CalendarOutlined className={styles.icon} />
          返回目录
        </div>
        <Popover
          showArrow={false}
          placement="left"
          content={(<div className={styles.downloadlist}>
            {papers.map((item: any, index: number) => (
              <a
                className={styles.downloaditem}
                key={item.id}
                rel="noopener noreferrer"
                target="_blank"
                href={item.url}
              >
                {`第${item.sequence}版:${item.description}`}
              </a>
            ))}
          </div>)}>
          <div className={styles.btn}>
            <DownloadOutlined className={styles.icon} />
            下载
          </div>
        </Popover>

      </div>
      <div className={styles.right}>
        {scriptIndex === -1 ? getMenu() : getArticle()}
      </div>
    </div>
  )
};

export default PaperList;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\customslideredit\index.tsx
 * @LastEditTime: 2024-04-08 18:09:24
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, InputNumber, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import { LinkTypes, Positions } from 'components/customvideo';
import { NewGuid } from 'utils/common';
import { reqGetSignature } from 'api/api';
import VideoInput from 'components/common/videoinput';
import _ from 'lodash';
import styles from './index.module.scss';
import JsonInput from "components/common/jsoninput";
import DataSourceInput from "components/common/datasourceinput";

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'customslider',
  key: NewGuid(),
  title: '轮播图',
  width: '100%',
  height: 'auto',
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: true,
  dots: true,
  speed: 500,
  smoothScroll: false,
  autoplay: false,
  autoplaySpeed: 2000,
  useDataSource: false,
  data: {
    dataSource: 'static', //数据源类型
    staticData: [], //静态数据 [{"value": "标题"}]
    requestUrl: '', //api地址
    requestType: 'GET', //请求方式
    headers: {}, //请求头
    body: {}, //请求参数
    filters: 'return res;', //数据过滤器
    requestSec: 0, //多少秒一次请求，为0表示只请求1次
  },
  customConfig: {},
})

const CustomSliderEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])

  const handleOK = () => {    
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  return (
    <Modal
      title='编辑轮播图'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="infinite"
              label="循环播放"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="slidesToShow"
              label="每屏个数"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name="slidesToScroll"
              label="滚动个数"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name="speed"
              label="滚动速度"
              rules={[{ required: true }]}
            >
              <InputNumber min={100} />
            </Form.Item>
            <Form.Item
              name="smoothScroll"
              label="平滑滚动"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="autoplay"
              label="自动播放"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="autoplaySpeed"
              label="播放速度"
              rules={[{ required: true }]}
            >
              <InputNumber min={100} />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-2">
            <Form.Item
              name="arrows"
              label="显示按钮"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="dots"
              label="显示进度"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="customConfig"
              label="自定义配置"
              tooltip="参考配置：https://react-slick.neostack.com/docs/api#arrows"
            >
              <JsonInput valueType={"object"} />
            </Form.Item>
            <Form.Item
              name="useDataSource"
              label="使用数据源"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="data"
              label="数据源"
            // rules={[{ required: true }]}
            >
              <DataSourceInput valueType='array' />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  )
};

export default CustomSliderEdit;
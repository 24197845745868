
import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { getViewFromConfig } from '../pageview';
import styles from './index.module.scss';


export type DatavGroupConfig = {
  type: String;  //类型 datavtext
  key: String; //主键
  title: String; //标题
  children?: any[];  //子配置children?: any[];  //子配置
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
}

interface IProps {
  config: DatavGroupConfig;
}

//组
const DatavGroup: React.FC<IProps> = (props: IProps) => {
  const { config } = props;

  return getViewFromConfig(config.children)
};

export default DatavGroup;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\common\imageinput\index.jsx
 * @LastEditTime: 2024-04-25 10:35:21
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UploadFile } from "antd/lib/upload/interface";
import {
  Modal, Form, Input, Image, Button, Upload, message, Popconfirm,
  Tooltip, Pagination
} from 'antd';
import { UploadOutlined, DeleteOutlined, DownloadOutlined } from '@ant-design/icons';
import { NewGuid, downLoadFile, getExtension } from 'utils/common';
import { reqGetArticles, reqAddArticle, reqRemoveArticle } from 'api/api';
import _ from 'lodash';
import styles from './index.module.scss';

const typeList = ["*.*"];

interface IProps {
  dataOSS?: any;
  value?: any;
  onChange?: (value: any) => void;
}

//文件url
//这里文件保存跟reqGetArticles文章管理合并，articlegroupid: null, title: 标题,url: 地址, originalType: 11
const FileInput: React.FC<IProps> = (props: IProps) => {
  const { dataOSS, value, onChange } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [url, setUrl] = useState('');
  const [images, setImages] = useState([]);
  const [inputTitle, setInputTitle] = useState('');
  const [pageCurrent, setPageCurrent] = useState(1);

  const organization = useSelector((state: any) => state.global.organization);

  const pageSize = 5;

  const handleChange = (e: any) => {
    onChange && onChange(e.target.value);
  }

  // 获取 .后缀名
  const getExtension = (name: string) => {
    return name.substring(name.lastIndexOf("."))
  }

  // 获取 文件名
  const getFileName = (name: string) => {
    return name.substring(0, name.lastIndexOf("."))
  }

  // 上传前检查
  const handleBeforeUpload = async (file: any, fileList: any) => {
    let filename = '';
    if (inputTitle === '') {
      filename = getFileName(file.name);
      setInputTitle(filename);
    } else {
      filename = inputTitle;
    }
    const suffix = getExtension(file.name).toLowerCase();
    // 重新修改文件名称 这里保留文件名+guid 既防止重复又在下载时可以获取原始文件名
    file.url = dataOSS.key + `${NewGuid()}_${filename}` + suffix;
    return true
  }

  const handleData = (file: any) => {
    return {
      OSSAccessKeyId: dataOSS.accessid,
      Signature: dataOSS.signature,
      key: file.url,
      policy: dataOSS.policy,
    };
  }

  // 通过本地文件上传状态变化
  const handleUploadChange = async (info: any) => {
    console.log(info);
    if (info.file.status === "done") {
      const tempurl = `${dataOSS.host}/${info.file.url}`;
      const param = {
        organizationId: organization.organizationGuid,
        articlegroupid: null,
        title: inputTitle,
        description: '',
        url: tempurl,
        originalType: 11,
      }
      reqAddArticle(param).then(res => {
        refreshImages();
      })
      setUrl(tempurl);
      setInputTitle('');
      onChange && onChange(tempurl);
    }
  };

  const handleClick = () => {
    refreshImages();
    setUrl(value);
    setInputTitle('');
    setModalVisible(true);
  }

  const refreshImages = () => {
    const params = {
      organizationId: organization?.organizationGuid,
      originalType: 11,
      page: 1,
      count: 999
    }
    reqGetArticles(params).then(res => {
      setImages(res.items);
      setPageCurrent(1);
    })
  }

  const handleOK = () => {
    onChange && onChange(url);
    setModalVisible(false);
  }

  const handleCancel = () => {
    setModalVisible(false);
  }

  //删除image
  const handleRemoveImage = (item: any) => {
    reqRemoveArticle(item.id).then(res => {
      //这里需要调用删除oss文件的代码
      refreshImages();
    })
  }

  return (
    <Input.Group compact>
      <Input
        style={{
          width: "calc(100% - 32px)"
        }}
        className={styles.input}
        value={value}
        onChange={handleChange} />
      <Button icon={<UploadOutlined />} onClick={handleClick} />
      <Modal
        title='上传文件'
        okText="确认"
        cancelText="取消"
        visible={modalVisible}
        onOk={handleOK}
        onCancel={handleCancel}
      >
        <div className={styles.container}>
          <div className={styles.top}>
            {images.slice((pageCurrent - 1) * pageSize, (pageCurrent - 1) * pageSize + pageSize)
              .map((item: any, index: number) => (
                <div
                  key={`img${index}`}
                  className={styles.imgitem}
                  style={{ borderColor: url === item.url ? "#1890ff" : "#d0d0d0" }}
                  onClick={() => { setUrl(item.url) }}>
                  {/* <Image preview={false} src={item.url} className={styles.img} /> */}
                  <div className={styles.title}>{`${item.title}${getExtension(item.url)}`}</div>
                  <Tooltip title="下载文件">
                    <DownloadOutlined className={styles.icon}
                      onClick={() => {
                        downLoadFile(item.url);
                      }} />
                  </Tooltip>
                  <Popconfirm
                    title={`确认删除?`}
                    onConfirm={() => { handleRemoveImage(item) }}>
                    <Tooltip title="删除">
                      <DeleteOutlined className={styles.icon} />
                    </Tooltip>
                  </Popconfirm>
                </div>
              ))}
          </div>
          <Pagination
            showTotal={total => `共${images.length}条`}
            total={images.length}
            current={pageCurrent}
            pageSize={pageSize}
            onChange={(page) => { setPageCurrent(page) }} />

          <div className={styles.bottom}>
            <span className={styles.title}>标题</span>
            <Input value={inputTitle} onChange={e => { setInputTitle(e.target.value) }} />
            <Upload
              name="file"
              accept={typeList.join(",")}
              multiple={false}
              showUploadList={false}
              action={dataOSS?.host}
              beforeUpload={handleBeforeUpload}
              data={handleData}
              onChange={handleUploadChange}
            >
              <Tooltip title="上传">
                <Button icon={<UploadOutlined />} />
              </Tooltip>
            </Upload>
          </div>
        </div>
      </Modal>
    </Input.Group>
  );
};

export default FileInput;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\datavContent\datavscatterchartedit\index.tsx
 * @LastEditTime: 2024-03-08 15:53:16
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Button, Select, Radio, Switch, Tabs, AutoComplete, InputNumber, message } from 'antd';
import { lineChartTypes } from 'utils/componentdefine';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';
import DataSourceInput from "components/common/datasourceinput";
import JsonInput from "components/common/jsoninput";

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

const defaultdata=
[
    { "x": 1, "y": 4.181 },
    { "x": 2, "y": 4.665 },
    { "x": 3, "y": 5.296 },
    { "x": 4, "y": 5.365 },
    { "x": 5, "y": 5.448 },
    { "x": 6, "y": 5.744 },
    { "x": 7, "y": 5.653 },
    { "x": 8, "y": 5.844 },
    { "x": 9, "y": 6.362 },
    { "x": 10, "y": 6.38 },
    { "x": 11, "y": 6.311 },
    { "x": 12, "y": 6.457 },
    { "x": 13, "y": 6.479 },
    { "x": 14, "y": 6.59 },
    { "x": 15, "y": 6.74 },
    { "x": 16, "y": 6.58 },
    { "x": 17, "y": 6.852 },
    { "x": 18, "y": 6.531 },
    { "x": 19, "y": 6.682 },
    { "x": 20, "y": 7.013 },
    { "x": 21, "y": 6.82 },
    { "x": 22, "y": 6.647 },
    { "x": 23, "y": 6.951 },
    { "x": 24, "y": 7.121 },
    { "x": 25, "y": 7.143 },
    { "x": 26, "y": 6.914 },
    { "x": 27, "y": 6.941 },
    { "x": 28, "y": 7.226 },
    { "x": 29, "y": 6.898 },
    { "x": 30, "y": 7.392 },
    { "x": 31, "y": 6.938 }
  ]

export const defaultValue = () => ({
  type: 'datavscatterchart',
  key: NewGuid(),
  title: '散点图',
  data: {
    dataSource: 'static', //数据源类型
    staticData: defaultdata, //静态数据 []
    requestUrl: '', //api地址
    requestType: 'GET', //请求方式
    headers: {}, //请求头
    body: {}, //请求参数
    filters: 'return res;', //数据过滤器
    requestSec: 0, //多少秒一次请求，为0表示只请求1次
  },
  xField: 'x',
  yField: 'y',
  colorField: '',
  color: [],
  sizeField: '',
  size: [],
  shapeField: '',
  shape: [],
  customConfig: {},
  width: 600,
  height: 300,
  left: 0,
  top: 0,
})

const DatavScatterChartEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])

  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑Chart'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="data"
              label="数据源"
            // rules={[{ required: true }]}
            >
              <DataSourceInput valueType='array'/>
            </Form.Item>
            <Form.Item
              name="left"
              label="左"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="top"
              label="上"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-2">
            <Form.Item
              name="xField"
              label="x坐标"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="yField"
              label="y坐标"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="colorField"
              label="颜色字段"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="color"
              label="颜色"
            >
              <JsonInput valueType="array"/>
            </Form.Item>
            <Form.Item
              name="sizeField"
              label="大小字段"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="size"
              label="大小"
            >
              <JsonInput valueType="array"/>
            </Form.Item>
            <Form.Item
              name="shapeField"
              label="形状字段"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="shape"
              label="形状"
            >
              <JsonInput valueType="array"/>
            </Form.Item>
            <Form.Item
              name="customConfig"
              label="自定义配置"
              tooltip= "参考配置： https://ant-design-charts.antgroup.com/api/plots/scatter"
            >
              <JsonInput valueType={"object"}/>
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  )
};

export default DatavScatterChartEdit;
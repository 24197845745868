import { useDispatch } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, InputNumber, Radio, Switch, Tabs, AutoComplete, Button, Drawer, Select, Tree, Tooltip, message, Popconfirm, Dropdown, DrawerProps } from 'antd';
import {
  EditOutlined, DeleteOutlined, CaretUpOutlined, CaretDownOutlined,
  CopyOutlined, LockOutlined, UnlockOutlined, MinusCircleOutlined, PlusOutlined, MinusOutlined
} from '@ant-design/icons';
import DatavPageEdit from './datavpageedit';
import DatavTextEdit, { defaultValue as datavtextDefaultValue } from './datavtextedit';
import DatavTimerEdit, { defaultValue as datavtimerDefaultValue } from './datavtimeredit';
import DatavPictureEdit, { defaultValue as datavpictureDefaultValue } from './datavpictureedit';
import DatavBorderBoxEdit, { defaultValue as datavborderboxDefaultValue } from './datavborderboxedit';
import DatavDecorationEdit, { defaultValue as datavdecorationDefaultValue } from './datavdecorationedit';
import DatavNumberEdit, { defaultValue as datavnumberDefaultValue } from './datavnumberedit';
import DatavLineChartEdit, { defaultValue as datavlinechartDefaultValue } from './datavlinechartedit';
import DatavAreaChartEdit, { defaultValue as datavareachartDefaultValue } from './datavareachartedit';
import DatavColumnChartEdit, { defaultValue as datavcolumnchartDefaultValue } from './datavcolumnchartedit';
import DatavBarChartEdit, { defaultValue as datavbarchartDefaultValue } from './datavbarchartedit';
import DatavPieChartEdit, { defaultValue as datavpiechartDefaultValue } from './datavpiechartedit';
import DatavScatterChartEdit, { defaultValue as datavscatterchartDefaultValue } from './datavscatterchartedit';
import DatavRadarChartEdit, { defaultValue as datavradarchartDefaultValue } from './datavradarchartedit';
import DatavLiquidChartEdit, { defaultValue as datavliquidchartDefaultValue } from './datavliquidchartedit';
import DatavWordCloudChartEdit, { defaultValue as datavwordcloudchartDefaultValue } from './datavwordcloudchartedit';
import DatavActiveRingChartEdit, { defaultValue as datavactiveringchartDefaultValue } from './datavactiveringchartedit';
import DatavCapsuleChartEdit, { defaultValue as datavcapsulechartDefaultValue } from './datavcapsulechartedit';
import DatavPercentPondEdit, { defaultValue as datavpercentpondDefaultValue } from './datavpercentpondedit';
import DatavScrollBoardEdit, { defaultValue as datavscrollboardDefaultValue } from './datavscrollboardedit';
import DatavScrollRankingBoardEdit, { defaultValue as datavscrollrankingboardDefaultValue } from './datavscrollrankingboardedit';
import DatavVideoEdit, { defaultValue as datavvideoDefaultValue } from './datavvideoedit';
import DatavFrameEdit, { defaultValue as datavframeDefaultValue } from './datavframeedit';
import DatavGroupEdit, { defaultValue as datavgroupDefaultValue } from './datavgroupedit';
import styles from './index.module.scss';
import _ from "lodash";
import { getViewFromConfig } from "components/pageview";
import { setEditComponentKeys } from "store/slices/globalSlice";
import { checkJSON, NewGuid, setItemPosition } from "utils/common";
import { defaultDatavBgUrl } from "utils/componentdefine";
import { AnyAction } from "@reduxjs/toolkit";


interface IProps {
  page: any;
  onPageChange: (value: any) => void;
  scale: number;  //大屏模式下的比例
  pageConfig: any;
  onPageConfigChange: (value: any) => void;
  drawerVisible: boolean;
  onDrawerVisibleChange: (value: boolean) => void;
}

//datav编辑
const DatavContent: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const contentRef = useRef(null);

  const [placement, setPlacement] = useState<DrawerProps['placement']>('right');

  const [control, setControl] = useState('datavtext');  //Select选中的内容  
  const [editKeys, SetEditKeys] = useState<any>([]);
  const [selectNodes, setSelectNodes] = useState<any>([]);

  const [datavPageVisible, SetDatavPageVisible] = useState(false);
  const [datavTextVisible, SetDatavTextVisible] = useState(false);
  const [datavTimerVisible, SetDatavTimerVisible] = useState(false);
  const [datavPictureVisible, SetDatavPictureVisible] = useState(false);
  const [datavBorderBoxVisible, SetDatavBorderBoxVisible] = useState(false);
  const [datavDecorationVisible, SetDatavDecorationVisible] = useState(false);
  const [datavNumberVisible, SetDatavNumberVisible] = useState(false);
  const [datavLineChartVisible, SetDatavLineChartVisible] = useState(false);
  const [datavAreaChartVisible, SetDatavAreaChartVisible] = useState(false);
  const [datavColumnChartVisible, SetDatavColumnChartVisible] = useState(false);
  const [datavBarChartVisible, SetDatavBarChartVisible] = useState(false);
  const [datavPieChartVisible, SetDatavPieChartVisible] = useState(false);
  const [datavScatterChartVisible, SetDatavScatterChartVisible] = useState(false);
  const [datavRadarChartVisible, SetDatavRadarChartVisible] = useState(false);
  const [datavLiquidChartVisible, SetDatavLiquidChartVisible] = useState(false);
  const [datavWordCloudChartVisible, SetDatavWordCloudChartVisible] = useState(false);
  const [datavActiveRingChartVisible, SetDatavActiveRingChartVisible] = useState(false);
  const [datavCapsuleChartVisible, SetDatavCapsuleChartVisible] = useState(false);
  const [datavPercentPondVisible, SetDatavPercentPondVisible] = useState(false);
  const [datavScrollBoardVisible, SetDatavScrollBoardVisible] = useState(false);
  const [datavScrollRankingBoardVisible, SetDatavScrollRankingBoardVisible] = useState(false);
  const [datavVideoVisible, SetDatavVideoVisible] = useState(false);
  const [datavFrameVisible, SetDatavFrameVisible] = useState(false);
  const [datavGroupVisible, SetDatavGroupVisible] = useState(false);

  // const [scale, setScale] = useState(50); //大屏模式下的比例
  const [translateX, setTranslateX] = useState(0); //大屏模式下的位移
  const [translateY, setTranslateY] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); //当前窗口的宽度
  const [screenHeight, setScreenHeight] = useState(window.innerHeight); //当前窗口的高度
  const [moveScreenLeft, setMoveScreenLeft] = useState(0); //缩略图中的移动屏幕对应的Left和Top
  const [moveScreenTop, setMoveScreenTop] = useState(0);
  const [SX, setSX] = useState(0); //moveScreen 
  const [SY, setSY] = useState(0);
  const [startMove, setStartMove] = useState(false);
  const [MSX, setMSX] = useState(0); //mainScreen 起始点坐标
  const [MSY, setMSY] = useState(0);
  const [MStartMove, setMStartMove] = useState(false); //鼠标开始拖动
  const [MCursor, setMCursor] = useState('default');  //mainScreen 鼠标状态 
  const [selectRect, setSelectRect] = useState({ left: 0, top: 0, width: 0, height: 0 });  //选中时的黄色矩形 
  const [dragRect, setDragRect] = useState({ left: 0, top: 0, width: 0, height: 0 });  //拖动时的绿色矩形 

  const [lastClickTime, setLastClickTime] = useState(0);  //记录上一次鼠标点击时间，用于双击事件
  const [thumbnailVisible, setThumbnailVisible] = useState(true);


  const pastecomponentStr = localStorage.getItem("datavpastecomponent") || '';

  const { page, onPageChange, scale, pageConfig, onPageConfigChange,
    drawerVisible, onDrawerVisibleChange } = props;

  const Controls = [{
    value: 'datavtext',
    label: '标题',
  }, {
    value: 'datavtimer',
    label: '时间器',
  }, {
    value: 'datavpicture',
    label: '背景图',
  }, {
    value: 'datavborderbox',
    label: '边框',
  }, {
    value: 'datavdecoration',
    label: '装饰',
  }, {
    value: 'datavnumber',
    label: '数字翻牌器',
  }, {
    value: 'datavlinechart',
    label: '折线图',
  }, {
    value: 'datavareachart',
    label: '面积图',
  }, {
    value: 'datavcolumnchart',
    label: '柱状图',
  }, {
    value: 'datavbarchart',
    label: '条形图',
  }, {
    value: 'datavpiechart',
    label: '饼图',
  }, {
    value: 'datavscatterchart',
    label: '散点图',
  }, {
    value: 'datavradarchart',
    label: '雷达图',
  }, {
    value: 'datavliquidchart',
    label: '水波图',
  }, {
    value: 'datavwordcloudchart',
    label: '词云图',
  }, {
    value: 'datavactiveringchart',
    label: '动态环图',
  }, {
    value: 'datavcapsulechart',
    label: '胶囊柱图',
  }, {
    value: 'datavpercentpond',
    label: '进度池',
  }, {
    value: 'datavscrollboard',
    label: '轮播表',
  }, {
    value: 'datavscrollrankingboard',
    label: '排名轮播表',
  }, {
    value: 'datavvideo',
    label: '视频',
  }, {
    value: 'datavframe',
    label: '嵌套页面',
  }, {
    value: 'datavpastecomponent',
    disabled: pastecomponentStr === '',
    label: pastecomponentStr === '' ? `剪贴板` : `剪贴板(${JSON.parse(pastecomponentStr)?.title})`,
  }]

  useEffect(() => {
    dispatch(setEditComponentKeys(editKeys));
  }, [editKeys])

  //浏览器resize的时候获取最新的screenWidth screenHeight
  useEffect(() => {
    // 监听窗口大小变化
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      // 清除监听
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //禁止框选文本设置，防止代码中实现的移动或框选与浏览器本身的框选文本有冲突
  useEffect(() => {

    const handleSelectStart = (e: any) => {
      e.preventDefault();
    };

    if (contentRef.current) {
      (contentRef.current as any).addEventListener('selectstart', handleSelectStart);
    }

    return () => {
      if (contentRef.current) {
        (contentRef.current as any).removeEventListener('selectstart', handleSelectStart);
      }
    };
  }, []);

  const getDefaultConfig = () => {
    switch (control) {
      case 'datavtext':
        return datavtextDefaultValue();
      case 'datavtimer':
        return datavtimerDefaultValue();
      case 'datavpicture':
        return datavpictureDefaultValue();
      case 'datavborderbox':
        return datavborderboxDefaultValue();
      case 'datavdecoration':
        return datavdecorationDefaultValue();
      case 'datavnumber':
        return datavnumberDefaultValue();
      case 'datavlinechart':
        return datavlinechartDefaultValue();
      case 'datavareachart':
        return datavareachartDefaultValue();
      case 'datavcolumnchart':
        return datavcolumnchartDefaultValue();
      case 'datavbarchart':
        return datavbarchartDefaultValue();
      case 'datavpiechart':
        return datavpiechartDefaultValue();
      case 'datavscatterchart':
        return datavscatterchartDefaultValue();
      case 'datavradarchart':
        return datavradarchartDefaultValue();
      case 'datavliquidchart':
        return datavliquidchartDefaultValue();
      case 'datavwordcloudchart':
        return datavwordcloudchartDefaultValue();
      case 'datavactiveringchart':
        return datavactiveringchartDefaultValue();
      case 'datavcapsulechart':
        return datavcapsulechartDefaultValue();
      case 'datavpercentpond':
        return datavpercentpondDefaultValue();
      case 'datavscrollboard':
        return datavscrollboardDefaultValue();
      case 'datavscrollrankingboard':
        return datavscrollrankingboardDefaultValue();
      case 'datavvideo':
        return datavvideoDefaultValue();
      case 'datavframe':
        return datavframeDefaultValue();
      case 'datavgroup':
        return datavgroupDefaultValue();
      case 'datavpastecomponent':  //剪贴板
        return getPastComponent();
      default:
        return datavtextDefaultValue();
    }
  }

  //缩略图的宽高以配置的页面为准
  const thumbnailWidth = 200;
  const thumbnailHeight = (thumbnailWidth - 10) * page.pageHeight / page.pageWidth + 10;

  //缩略图中对应的屏幕宽高(蓝框部分)
  let moveScreenWidth = thumbnailWidth;
  let moveScreenHeight = thumbnailHeight;
  //自适应比例
  const autoScale = Math.round(Math.max((screenWidth - 100) / page.pageWidth * 100, (screenHeight - 164) / page.pageHeight * 100));
  //如果当前比例大于自适应比例，则缩略图中的屏幕小于缩略图的大小
  if (scale > autoScale) {
    moveScreenWidth = Math.round(thumbnailWidth * screenWidth / (page.pageWidth * scale / 100 + 100));
    moveScreenHeight = Math.round(thumbnailHeight * (screenHeight - 64) / (page.pageHeight * scale / 100 + 100));
  }

  useEffect(() => {
    if (scale > autoScale) {
      setMoveScreenLeft(0);
      setMoveScreenTop(0);
    }
  }, [scale])

  //根据moveScreenLeft,moveScreenTop计算translateX translateY
  useEffect(() => {
    let tempTranslateX = -moveScreenLeft / (thumbnailWidth - 10) * page.pageWidth * scale / 100;
    let tempTranslateY = -moveScreenTop / (thumbnailHeight - 10) * page.pageHeight * scale / 100;
    setTranslateX(tempTranslateX);
    setTranslateY(tempTranslateY);
  }, [moveScreenLeft, moveScreenTop])

  //根据key获取config
  // const getConfigFromKey = (config: any, key: string) => {
  //   for (let index = 0; index < config.length; index++) {
  //     const item = config[index];
  //     if (item.key === key) {
  //       return item;
  //     }
  //   }
  //   return null;
  // }

  const getConfigFromKey = (config: any, key: string) => {
    for (let index = 0; index < config.length; index++) {
      const item = config[index];
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        let tempvalue: any = getConfigFromKey(item.children, key);
        if (!_.isNull(tempvalue)) {
          return tempvalue;
        }
      }
    }
    return null;
  }

  //根据key保存config
  const setConfigFromKey = (config: any, key: string) => {
    let tempPageConfig = _.cloneDeep(pageConfig);
    let tempConfig = getConfigFromKey(tempPageConfig, key);
    Object.assign(tempConfig, config);
    onPageConfigChange && onPageConfigChange(tempPageConfig);
  }

  const getEditValue = () => {
    if (editKeys.length === 0) return null;
    return getConfigFromKey(pageConfig, editKeys[0]);
  }

  const handleSetEditValue = (value: any) => {
    if (editKeys.length === 0) return;
    setConfigFromKey(value, editKeys[0]);
  }

  const handleThumbnailMouseDown = (e: any) => {
    const x = e.nativeEvent.offsetX + (e.nativeEvent.target as any).offsetLeft;
    const y = e.nativeEvent.offsetY + (e.nativeEvent.target as any).offsetTop;
    if (x >= moveScreenLeft && x <= (moveScreenLeft + moveScreenWidth) &&
      y >= moveScreenTop && y <= (moveScreenTop + moveScreenHeight)) {
      setStartMove(true);
      setSX(x);
      setSY(y);
    }
    // console.log('MouseDown', x, y);
  }

  const handleThumbnailMouseMove = (e: any) => {
    const x = e.nativeEvent.offsetX + (e.nativeEvent.target as any).offsetLeft;
    const y = e.nativeEvent.offsetY + (e.nativeEvent.target as any).offsetTop;
    //如果鼠标在右边框附近或下边框附近x,y坐标会乱跳，蓝框也会乱跳，这里在边线直接不处理
    if (x >= thumbnailWidth || y >= thumbnailHeight) return;
    if (startMove) {
      let tempMoveScreenLeft = moveScreenLeft + x - SX;
      let tempMoveScreenTop = moveScreenTop + y - SY;
      if (tempMoveScreenLeft < 0) {
        tempMoveScreenLeft = 0;
      }
      if (tempMoveScreenTop < 0) {
        tempMoveScreenTop = 0;
      }
      if (tempMoveScreenLeft > thumbnailWidth - moveScreenWidth) {
        tempMoveScreenLeft = thumbnailWidth - moveScreenWidth
      }
      if (tempMoveScreenTop > thumbnailHeight - moveScreenHeight) {
        tempMoveScreenTop = thumbnailHeight - moveScreenHeight
      }
      setMoveScreenLeft(tempMoveScreenLeft);
      setMoveScreenTop(tempMoveScreenTop);
      setSX(x);
      setSY(y);
    }
    // console.log('MouseMove', x, y);
  }

  const handleThumbnailMouseUp = (e: any) => {
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    handleThumbnailMouseMove(e);
    setStartMove(false);
    // console.log('MouseUp', x, y);
  }

  const handleThumbnailMouseLeave = () => {
    setStartMove(false);
  }

  //关闭抽屉
  const handleDrawerClose = () => {
    onDrawerVisibleChange && onDrawerVisibleChange(false);
  }

  //重设SelectRect
  const resetSelectRect = (configs: any) => {
    if (configs.length > 0) {
      // 初始化外边框的左上角坐标和右下角坐标
      let left = configs[0].left;
      let top = configs[0].top;
      let right = configs[0].left + configs[0].width;
      let bottom = configs[0].top + configs[0].height;

      // 遍历数组，更新外边框的坐标
      for (let i = 1; i < configs.length; i++) {
        left = Math.min(left, configs[i].left);
        top = Math.min(top, configs[i].top);
        right = Math.max(right, configs[i].left + configs[i].width);
        bottom = Math.max(bottom, configs[i].top + configs[i].height);
      }

      setSelectRect({
        left,
        top,
        width: right - left,
        height: bottom - top,
      })
    } else {
      setSelectRect({ left: 0, top: 0, width: 0, height: 0 })
    }
  }

  //使某个config处于选中状态
  const selectConfigs = (configs: any) => {
    // console.log('selectConfigs', configs);

    //模拟点击该节点,使tree中的对应节点处于选中状态
    // if (configs.length > 0 && drawerVisible) {
    //   const div = document.getElementById(`node-${configs[configs.length - 1].key}`);
    //   if (div) {
    //     div.click();
    //   }
    // }

    SetEditKeys(configs.map((item: any) => item.key));
    setSelectNodes(configs);

    resetSelectRect(configs);
    // console.log('end');

  }

  //添加组件
  const handleAddItem = () => {
    const defaultConfig = getDefaultConfig();
    const tempconfig = _.cloneDeep(pageConfig);
    tempconfig.push(defaultConfig);
    onPageConfigChange && onPageConfigChange(tempconfig);

    //添加的组件处于选中状态
    selectConfigs([defaultConfig]);
  }

  //编辑组件
  const handleEditComponent = (nodeData: any) => {
    SetEditKeys([nodeData?.key]);
    switch (nodeData?.type) {
      case 'datavtext':
        SetDatavTextVisible(true);
        break;
      case 'datavtimer':
        SetDatavTimerVisible(true);
        break;
      case 'datavpicture':
        SetDatavPictureVisible(true);
        break;
      case 'datavborderbox':
        SetDatavBorderBoxVisible(true);
        break;
      case 'datavdecoration':
        SetDatavDecorationVisible(true);
        break;
      case 'datavnumber':
        SetDatavNumberVisible(true);
        break;
      case 'datavlinechart':
        SetDatavLineChartVisible(true);
        break;
      case 'datavareachart':
        SetDatavAreaChartVisible(true);
        break;
      case 'datavcolumnchart':
        SetDatavColumnChartVisible(true);
        break;
      case 'datavbarchart':
        SetDatavBarChartVisible(true);
        break;
      case 'datavpiechart':
        SetDatavPieChartVisible(true);
        break;
      case 'datavscatterchart':
        SetDatavScatterChartVisible(true);
        break;
      case 'datavradarchart':
        SetDatavRadarChartVisible(true);
        break;
      case 'datavliquidchart':
        SetDatavLiquidChartVisible(true);
        break;
      case 'datavwordcloudchart':
        SetDatavWordCloudChartVisible(true);
        break;
      case 'datavactiveringchart':
        SetDatavActiveRingChartVisible(true);
        break;
      case 'datavcapsulechart':
        SetDatavCapsuleChartVisible(true);
        break;
      case 'datavpercentpond':
        SetDatavPercentPondVisible(true);
        break;
      case 'datavscrollboard':
        SetDatavScrollBoardVisible(true);
        break;
      case 'datavscrollrankingboard':
        SetDatavScrollRankingBoardVisible(true);
        break;
      case 'datavvideo':
        SetDatavVideoVisible(true);
        break;
      case 'datavframe':
        SetDatavFrameVisible(true);
        break;
      case 'datavgroup':
        SetDatavGroupVisible(true);
        break;
      default:
        break;
    }
  }

  const moveUpConfigFromKey = (config: any, key: string) => {
    const tempconfig = _.cloneDeep(config);
    const tempindex = _.findIndex(tempconfig, (o: any) => o.key === key);
    if (tempindex > 0) {
      [tempconfig[tempindex], tempconfig[tempindex - 1]] = [tempconfig[tempindex - 1], tempconfig[tempindex]];
    }

    for (let index = 0; index < tempconfig.length; index++) {
      const item = tempconfig[index];
      if (item.children) {
        item.children = moveUpConfigFromKey(item.children, key);
      }
    }
    return tempconfig;
  }

  const moveDownConfigFromKey = (config: any, key: string) => {
    const tempconfig = _.cloneDeep(config);
    const tempindex = _.findIndex(tempconfig, (o: any) => o.key === key);
    if ((tempindex >= 0) && (tempindex < tempconfig.length - 1)) {
      [tempconfig[tempindex], tempconfig[tempindex + 1]] = [tempconfig[tempindex + 1], tempconfig[tempindex]];
    }

    for (let index = 0; index < tempconfig.length; index++) {
      const item = tempconfig[index];
      if (item.children) {
        item.children = moveDownConfigFromKey(item.children, key);
      }
    }
    return tempconfig;
  }


  //上移组件
  const handleMoveUpComponent = (nodeData: any) => {
    const tempconfig = moveUpConfigFromKey(pageConfig, nodeData?.key);
    onPageConfigChange && onPageConfigChange(tempconfig);
  }

  //下移组件
  const handleMoveDownComponent = (nodeData: any) => {
    const tempconfig = moveDownConfigFromKey(pageConfig, nodeData?.key);
    onPageConfigChange && onPageConfigChange(tempconfig);
  }

  //复制组件
  const handleCopyComponent = (nodeData: any) => {
    // SetEditKeys([nodeData?.key]);
    const tempconfig = getConfigFromKey(pageConfig, nodeData?.key);
    console.log(tempconfig);

    const str = JSON.stringify(tempconfig);
    localStorage.setItem("datavpastecomponent", str);  //利用localStorage实现跨页面复制粘贴
    message.success('复制成功');
  }

  //重新生成key ,为了防止和源重复，top和left各加20
  // const newKeyFromConfig = (config: any, xOffset: number = 20, yOffset: number = 20) => {
  //   let tempconfig = _.cloneDeep(config);
  //   tempconfig.key = NewGuid();
  //   setItemPosition(tempconfig, tempconfig.left + xOffset, tempconfig.top + yOffset, tempconfig.width, tempconfig.height);
  //   return tempconfig;
  // }

  //重新生成key
  const newKeyFromConfig = (config: any, xOffset: number = 20, yOffset: number = 20) => {
    const tempconfig = _.cloneDeep(config);
    tempconfig.key = NewGuid();
    setItemPosition(tempconfig, tempconfig.left + xOffset, tempconfig.top + yOffset, tempconfig.width, tempconfig.height);
    if (tempconfig.children) {
      tempconfig.children = tempconfig.children.map((item: any) => {
        return newKeyFromConfig(item, 0, 0);
      })
    }
    return tempconfig;
  }

  //获取粘贴组件
  const getPastComponent = () => {
    const str = localStorage.getItem("datavpastecomponent") || '';
    let tempconfig = JSON.parse(str);
    //吧tempconfig里的key重新new一个guid
    tempconfig = newKeyFromConfig(tempconfig);
    return tempconfig;
  }

  const delConfigFromKey = (config: any, key: string) => {
    const tempconfig = _.cloneDeep(config);
    _.remove(tempconfig, (o: any) => o.key === key);

    for (let index = 0; index < tempconfig.length; index++) {
      const item = tempconfig[index];
      if (item.children) {
        item.children = delConfigFromKey(item.children, key);
      }
    }
    return tempconfig;
  }

  //删除组件
  const handleDelComponent = (nodeData: any) => {
    const tempconfig = delConfigFromKey(pageConfig, nodeData?.key);
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs([]);
  }

  //锁定组件
  const handleLockComponent = (nodeData: any) => {
    nodeData.lock = true;
    setConfigFromKey(nodeData, nodeData?.key)
  }

  //解锁组件
  const handleUnLockComponent = (nodeData: any) => {
    nodeData.lock = false;
    setConfigFromKey(nodeData, nodeData?.key)
  }

  //获取鼠标所在坐标
  const getRealMouseOffset = (e: any) => {
    const contentOffsetX = 51;
    const contentOffsety = 115;

    const x = Math.round((e.nativeEvent.x - contentOffsetX) * 100 / scale - translateX * 100 / scale);
    const y = Math.round((e.nativeEvent.y - contentOffsety) * 100 / scale - translateY * 100 / scale);
    return { x, y };
  }

  //获取拖拽后的矩形坐标
  const getDragRect = (moveX: number, moveY: number) => {
    const minWidth = 50; //拖动后最小的宽和高
    const minHeight = 50;
    let tempMoveX = moveX;
    let tempMoveY = moveY;
    let tempRect = { left: 0, top: 0, width: 0, height: 0 };
    if (MCursor === 'move') {
      tempRect = {
        width: selectRect.width,
        height: selectRect.height,
        left: selectRect.left + moveX,
        top: selectRect.top + moveY
      }
    } else if (MCursor === 'nw-resize') {  //左上
      tempMoveX = Math.min(moveX, selectRect.width - minWidth);
      tempMoveY = Math.min(moveY, selectRect.height - minHeight);
      tempRect = {
        width: selectRect.width - tempMoveX,
        height: selectRect.height - tempMoveY,
        left: selectRect.left + tempMoveX,
        top: selectRect.top + tempMoveY
      }
    } else if (MCursor === 'sw-resize') {  //左下
      tempMoveX = Math.min(moveX, selectRect.width - minWidth);
      tempMoveY = Math.max(moveY, minHeight - selectRect.height);
      tempRect = {
        width: selectRect.width - tempMoveX,
        height: selectRect.height + tempMoveY,
        left: selectRect.left + tempMoveX,
        top: selectRect.top
      }
    } else if (MCursor === 'w-resize') {  //左   
      tempMoveX = Math.min(moveX, selectRect.width - minWidth);
      tempRect = {
        width: selectRect.width - tempMoveX,
        height: selectRect.height,
        left: selectRect.left + tempMoveX,
        top: selectRect.top
      }
    } else if (MCursor === 'ne-resize') {  //右上  
      tempMoveX = Math.max(moveX, minWidth - selectRect.width);
      tempMoveY = Math.min(moveY, selectRect.height - minHeight);
      tempRect = {
        width: selectRect.width + tempMoveX,
        height: selectRect.height - tempMoveY,
        left: selectRect.left,
        top: selectRect.top + tempMoveY
      }
    } else if (MCursor === 'se-resize') {  //右下
      tempMoveX = Math.max(moveX, minWidth - selectRect.width);
      tempMoveY = Math.max(moveY, minHeight - selectRect.height);
      tempRect = {
        width: selectRect.width + tempMoveX,
        height: selectRect.height + tempMoveY,
        left: selectRect.left,
        top: selectRect.top
      }
    } else if (MCursor === 'e-resize') {  //右
      tempMoveX = Math.max(moveX, minWidth - selectRect.width);
      tempRect = {
        width: selectRect.width + tempMoveX,
        height: selectRect.height,
        left: selectRect.left,
        top: selectRect.top
      }
    } else if (MCursor === 'n-resize') { //上
      tempMoveY = Math.min(moveY, selectRect.height - minHeight);
      tempRect = {
        width: selectRect.width,
        height: selectRect.height - tempMoveY,
        left: selectRect.left,
        top: selectRect.top + tempMoveY
      }
    } else if (MCursor === 's-resize') { //下
      tempMoveY = Math.max(moveY, minHeight - selectRect.height);
      tempRect = {
        width: selectRect.width,
        height: selectRect.height + tempMoveY,
        left: selectRect.left,
        top: selectRect.top
      }
    } else if (MCursor === 'default') { //框选
      //moveX moveY
      tempRect = {
        width: Math.abs(moveX),
        height: Math.abs(moveY),
        left: Math.min(MSX, moveX + MSX),
        top: Math.min(MSY, moveY + MSY),
      }
    }
    return tempRect;
  }

  //根据坐标查找组件
  const getItemFromPoint = (x: number, y: number) => {
    for (let index = pageConfig.length - 1; index >= 0; index--) {
      const item = pageConfig[index];
      if (!item?.lock) {
        if (x >= (item.left - 2) && x <= (item.left + item.width + 2) &&
          y >= (item.top - 2) && y <= (item.top + item.height + 2)) {
          return item;
        }
      }
    }
    return null;
  }

  //根据坐标查找组件
  const getItemsFromRect = (left: number, top: number, width: number, height: number) => {
    let items = [];
    for (let index = 0; index < pageConfig.length; index++) {
      const item = pageConfig[index];
      if (!item?.lock) {
        if (item.left >= left && (item.left + item.width) <= (left + width) &&
          item.top >= top && (item.top + item.height) <= (top + height)) {
          items.push(item);
        }
      }
    }
    return items;
  }

  const handleMouseDown = (e: any) => {
    const realMouseOffset = getRealMouseOffset(e);
    // console.log(e);
    //左键
    if (e.button === 0) {
      const item = getItemFromPoint(realMouseOffset.x, realMouseOffset.y);
      if (e.ctrlKey) {
        //多选模式 如果选中的item，已选则剔除，否则添加
        if (!_.isNull(item)) {
          let tempSelectNodes = _.cloneDeep(selectNodes);
          if (!editKeys.includes(item.key)) {
            tempSelectNodes.push(item);
          } else {
            tempSelectNodes = tempSelectNodes.filter((item: any) => item.key !== item.key);
          }
          console.log(tempSelectNodes);

          selectConfigs(tempSelectNodes);
        }
      } else {
        //处理双击事件
        const clickTime = new Date().getTime();
        console.log(clickTime, lastClickTime, clickTime - lastClickTime);

        if (clickTime - lastClickTime < 300) {
          console.log('doubleclick');

          // 双击操作
          if (!_.isNull(item)) {
            //如果选中则设置选中
            selectConfigs([item]);
            handleEditComponent(item);
            return;
          }
        }
        setLastClickTime(clickTime);

        if (MCursor === 'default') {
          if (_.isNull(item)) {
            //如果没选中，则清空选中
            selectConfigs([]);
            //增加框选逻辑,框选使用dragRect,MCursor为default
            setMStartMove(true);
            setMSX(realMouseOffset.x);
            setMSY(realMouseOffset.y);
            setDragRect({
              left: realMouseOffset.x,
              top: realMouseOffset.y,
              width: 0,
              height: 0,
            })
          } else {
            //如果选中则设置选中
            selectConfigs([item]);
            //如果右侧面板打开，进入编辑界面
            if (drawerVisible) {
              handleEditComponent(item);
            }
          }
        } else if (MCursor === 'move') {
          if (_.isNull(item)) {
            //如果没选中，则清空选中
            selectConfigs([]);
          } else {
            //如果右侧面板打开，进入编辑界面
            if (drawerVisible) {
              selectConfigs([item]);
              handleEditComponent(item);
            } else {
              //如果选中，则判断item是否在选中列表中，如果在的话，则开始移动，否则重新选中
              if (editKeys.includes(item.key)) {
                //拖动组件逻辑
                setMStartMove(true);
                setMSX(realMouseOffset.x);
                setMSY(realMouseOffset.y);
                setDragRect({
                  ...selectRect
                })
              } else {
                selectConfigs([item]);
              }
            }
          }
        } else {
          setMStartMove(true);
          setMSX(realMouseOffset.x);
          setMSY(realMouseOffset.y);
        }
      }
    } else if (e.button === 2) {
      //右键
      setMSX(realMouseOffset.x);
      setMSY(realMouseOffset.y);
    }
  }

  const handleMouseMove = (e: any) => {
    const realMouseOffset = getRealMouseOffset(e);
    //拖动组件逻辑
    if (MStartMove) {
      const tempRect = getDragRect(realMouseOffset.x - MSX, realMouseOffset.y - MSY);
      setDragRect(tempRect);
    } else {
      //鼠标状态逻辑
      if (selectNodes.length > 0) {
        const { x, y } = realMouseOffset;
        const { left, top, width, height } = selectRect;
        let xState = 'out';  //x坐标的状态: out in left right 边框内 边框外 靠近左边框 靠近右边框
        let yState = 'out'; //y坐标的状态: out in top bottom 边框内 边框外 靠近上边框 靠近下边框

        if (x >= (left - 2) && x <= (left + 2)) {
          xState = 'left';
        } else if (x >= (left + width - 2) && x <= (left + width + 2)) {
          xState = 'right';
        } else if (x > (left + 2) && x < (left + width - 2)) {
          xState = 'in';
        } else {
          xState = 'out';
        }

        if (y >= (top - 2) && y <= (top + 2)) {
          yState = 'top';
        } else if (y >= (top + height - 2) && y <= (top + height + 2)) {
          yState = 'bottom';
        } else if (y > (top + 2) && y < (top + height - 2)) {
          yState = 'in';
        } else {
          yState = 'out';
        }

        if (xState === 'left' && yState === 'top') {
          setMCursor('nw-resize'); //左上
        } else if (xState === 'left' && yState === 'bottom') {
          setMCursor('sw-resize'); //左下
        } else if (xState === 'left' && yState === 'in') {
          setMCursor('w-resize'); //左
        } else if (xState === 'right' && yState === 'top') {
          setMCursor('ne-resize'); //右上
        } else if (xState === 'right' && yState === 'bottom') {
          setMCursor('se-resize');  //右下
        } else if (xState === 'right' && yState === 'in') {
          setMCursor('e-resize'); //右
        } else if (xState === 'in' && yState === 'top') {
          setMCursor('n-resize'); //上
        } else if (xState === 'in' && yState === 'bottom') {
          setMCursor('s-resize'); //下
        } else if (xState === 'in' && yState === 'in') {
          setMCursor('move'); //移动
        } else {
          setMCursor('default');
        }
      } else {
        setMCursor('default');
      }
    }
  }

  const handleMouseUp = (e: any) => {
    const realMouseOffset = getRealMouseOffset(e);
    //左键
    if (e.button === 0) {
      //拖动组件逻辑
      if (MStartMove) {
        setMStartMove(false);
        const tempRect = getDragRect(realMouseOffset.x - MSX, realMouseOffset.y - MSY);
        if (tempRect.width === 0 && tempRect.height === 0) {
          return;
        } else {
          if (MCursor === 'default') {
            //框选
            let items = getItemsFromRect(tempRect.left, tempRect.top, tempRect.width, tempRect.height);
            // console.log('框选', items);

            selectConfigs(items);
          } else if (MCursor === 'move') {
            //移动
            let tempPageConfig = _.cloneDeep(pageConfig);
            selectNodes.forEach((item: any) => {
              let selectNode = getConfigFromKey(tempPageConfig, item.key);
              const left = selectNode.left + tempRect.left - selectRect.left;
              const top = selectNode.top + tempRect.top - selectRect.top;
              const width = selectNode.width;
              const height = selectNode.height;
              setItemPosition(selectNode, left, top, width, height);
              setItemPosition(item, left, top, width, height);
            });
            selectConfigs(selectNodes);
            onPageConfigChange && onPageConfigChange(tempPageConfig);
          } else {
            //缩放
            let tempPageConfig = _.cloneDeep(pageConfig);
            //tempRect:new  selectRect:old
            selectNodes.forEach((item: any) => {
              let selectNode = getConfigFromKey(tempPageConfig, item.key);
              const left = (selectNode.left - selectRect.left) * tempRect.width / selectRect.width + tempRect.left;
              const top = (selectNode.top - selectRect.top) * tempRect.height / selectRect.height + tempRect.top;
              const width = selectNode.width * tempRect.width / selectRect.width;
              const height = selectNode.height * tempRect.height / selectRect.height;
              setItemPosition(selectNode, left, top, width, height);
              setItemPosition(item, left, top, width, height);
            });
            selectConfigs(selectNodes);
            onPageConfigChange && onPageConfigChange(tempPageConfig);
          }
          setDragRect({ left: 0, top: 0, width: 0, height: 0 });
        }
      }
    }
  }

  //双击直接编辑组件
  const handleDoubleClick = (e: any) => {
    // console.log('doubleclick');
    // const realMouseOffset = getRealMouseOffset(e);
    // for (let index = pageConfig.length - 1; index >= 0; index--) {
    //   const item = pageConfig[index];
    //   if (realMouseOffset.x >= item.left && realMouseOffset.x <= (item.left + item.width) &&
    //     realMouseOffset.y > item.top && realMouseOffset.y <= (item.top + item.height)) {
    //     console.log('doubleclick');

    //     selectConfig(item);
    //     handleEditComponent(item);
    //     break;
    //   }
    // }
  }

  //页面编辑
  const handleEditPage = () => {
    SetDatavPageVisible(true);
  }

  //锁定
  const handleLock = () => {
    if (selectNodes.length !== 1) return;
    selectNodes[0].lock = true;
    setConfigFromKey(selectNodes[0], selectNodes[0].key);
  }

  //解锁
  const handleUnLock = () => {
    if (selectNodes.length !== 1) return;
    selectNodes[0].lock = false;
    setConfigFromKey(selectNodes[0], selectNodes[0].key);
  }

  const handleAdd = () => {
    const defaultConfig = getDefaultConfig();
    const tempconfig = _.cloneDeep(pageConfig);
    setItemPosition(defaultConfig, MSX, MSY, defaultConfig.width, defaultConfig.height);
    tempconfig.push(defaultConfig);
    onPageConfigChange && onPageConfigChange(tempconfig);

    //添加的组件处于选中状态
    selectConfigs([defaultConfig]);
  }

  //复制
  const handleCopy = () => {
    if (selectNodes.length !== 1) return;
    const tempconfig = getConfigFromKey(pageConfig, selectNodes[0].key);
    const str = JSON.stringify(tempconfig);
    localStorage.setItem("datavpastecomponent", str);  //利用localStorage实现跨页面复制粘贴
    message.success('复制成功');
  }

  //粘贴
  const handlePaste = () => {
    const str = localStorage.getItem("datavpastecomponent") || '';
    let pasteconfig = JSON.parse(str);
    //吧tempconfig里的key重新new一个guid ,并位置放在鼠标所在位置
    pasteconfig = newKeyFromConfig(pasteconfig, MSX - pasteconfig.left, MSY - pasteconfig.top);

    const tempconfig = _.cloneDeep(pageConfig);
    tempconfig.push(pasteconfig);
    onPageConfigChange && onPageConfigChange(tempconfig);

    //添加的组件处于选中状态
    selectConfigs([pasteconfig]);
  }

  //删除
  const handleDel = () => {
    if (selectNodes.length === 0) return;
    let tempconfig = pageConfig;
    selectNodes.forEach((item: any) => {
      tempconfig = delConfigFromKey(tempconfig, item.key);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs([]);
  }

  //成组 必须选中多个组件
  const handleGroup = () => {
    if (selectNodes.length <= 1) return;
    let groupConfig = datavgroupDefaultValue();
    groupConfig.children = selectNodes;

    //取子组件的外边框作为group的left top width height
    let rect = selectNodes.reduce((acc: any, curr: any) => {
      let left = Math.min(acc.left, curr.left);
      let top = Math.min(acc.top, curr.top);
      let right = Math.max(acc.left + acc.width, curr.left + curr.width);
      let bottom = Math.max(acc.top + acc.height, curr.top + curr.height);
      return {
        left: left,
        top: top,
        width: right - left,
        height: bottom - top
      };
    });
    groupConfig.left = rect.left;
    groupConfig.top = rect.top;
    groupConfig.width = rect.width;
    groupConfig.height = rect.height;

    let tempconfig = _.cloneDeep(pageConfig);
    _.remove(tempconfig, (o: any) => editKeys.includes(o.key));
    tempconfig.push(groupConfig);
    onPageConfigChange && onPageConfigChange(tempconfig);
    //添加的组件处于选中状态
    selectConfigs([groupConfig]);
  }

  //解组 只允许选中组
  const handleCancelGroup = () => {
    if (selectNodes.length !== 1) return;
    if (selectNodes[0].type !== 'datavgroup') return;
    let tempconfig = _.cloneDeep(pageConfig);
    _.remove(tempconfig, (o: any) => editKeys.includes(o.key));
    tempconfig = tempconfig.concat(selectNodes[0].children);
    onPageConfigChange && onPageConfigChange(tempconfig);
    //解组后子组件处于选中状态
    selectConfigs(selectNodes[0].children);
  }

  //置顶
  const handleTop = () => {
    if (selectNodes.length !== 1) return;
    const tempconfig = _.cloneDeep(pageConfig);
    const tempindex = _.findIndex(tempconfig, (o: any) => o.key === selectNodes[0].key);
    if ((tempindex >= 0) && (tempindex < tempconfig.length - 1)) {
      [tempconfig[tempindex], tempconfig[tempconfig.length - 1]] = [tempconfig[tempconfig.length - 1], tempconfig[tempindex]];
    }
    onPageConfigChange && onPageConfigChange(tempconfig);
  }

  //置底
  const handleBottom = () => {
    if (selectNodes.length !== 1) return;
    const tempconfig = _.cloneDeep(pageConfig);
    const tempindex = _.findIndex(tempconfig, (o: any) => o.key === selectNodes[0].key);
    if (tempindex > 0) {
      [tempconfig[tempindex], tempconfig[0]] = [tempconfig[0], tempconfig[tempindex]];
    }
    onPageConfigChange && onPageConfigChange(tempconfig);
  }

  //左对齐
  const handleHalignLeft = () => {
    if (selectNodes.length <= 1) return;
    let tempconfig = _.cloneDeep(pageConfig);
    const left = selectRect.left;
    let tempSelectNodes: any[] = [];
    selectNodes.forEach((item: any) => {
      let tempItem = getConfigFromKey(tempconfig, item.key);
      setItemPosition(tempItem, left, tempItem.top, tempItem.width, tempItem.height);
      tempSelectNodes.push(tempItem);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs(tempSelectNodes);
  }

  //右对齐
  const handleHalignRight = () => {
    if (selectNodes.length <= 1) return;
    let tempconfig = _.cloneDeep(pageConfig);
    const right = selectRect.left + selectRect.width;
    let tempSelectNodes: any[] = [];
    selectNodes.forEach((item: any) => {
      let tempItem = getConfigFromKey(tempconfig, item.key);
      setItemPosition(tempItem, right - tempItem.width, tempItem.top, tempItem.width, tempItem.height);
      tempSelectNodes.push(tempItem);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs(tempSelectNodes);
  }

  //左右居中对齐
  const handleHalignCenter = () => {
    if (selectNodes.length <= 1) return;
    let tempconfig = _.cloneDeep(pageConfig);
    const left = selectRect.left;
    const width = selectRect.width;
    const center = left + width / 2;
    let tempSelectNodes: any[] = [];
    selectNodes.forEach((item: any) => {
      let tempItem = getConfigFromKey(tempconfig, item.key);
      setItemPosition(tempItem, center - tempItem.width / 2, tempItem.top, tempItem.width, tempItem.height);
      tempSelectNodes.push(tempItem);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs(tempSelectNodes);
  }

  //左右完全对齐
  const handleHalignAll = () => {
    if (selectNodes.length <= 1) return;
    let tempconfig = _.cloneDeep(pageConfig);
    const left = selectNodes[0].left;
    const width = selectNodes[0].width;
    let tempSelectNodes: any[] = [];
    selectNodes.forEach((item: any) => {
      let tempItem = getConfigFromKey(tempconfig, item.key);
      setItemPosition(tempItem, left, tempItem.top, width, tempItem.height);
      tempSelectNodes.push(tempItem);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs(tempSelectNodes);
  }

  //左右间隔排列
  const handleHalignLine = () => {
    if (selectNodes.length <= 1) return;
    let tempconfig = _.cloneDeep(pageConfig);
    //按照left从小到大排序
    selectNodes.sort((a: any, b: any) => a.left - b.left);

    //获取总宽度
    let sumWidth = selectNodes.reduce((pre: number, cur: any) => {
      return pre + cur.width;
    }, 0);
    //计算间隔 如果间隔小于0，则等于0
    let distance = (selectRect.width - sumWidth) / (selectNodes.length - 1);
    if (distance < 0) {
      distance = 0;
    }

    let left = selectNodes[0].left;
    let tempSelectNodes: any[] = [];
    selectNodes.forEach((item: any) => {
      let tempItem = getConfigFromKey(tempconfig, item.key);
      setItemPosition(tempItem, left, tempItem.top, tempItem.width, tempItem.height);
      left = left + tempItem.width + distance;
      tempSelectNodes.push(tempItem);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs(tempSelectNodes);
  }

  //上对齐
  const handleValignTop = () => {
    if (selectNodes.length <= 1) return;
    let tempconfig = _.cloneDeep(pageConfig);
    const top = selectRect.top;
    let tempSelectNodes: any[] = [];
    selectNodes.forEach((item: any) => {
      let tempItem = getConfigFromKey(tempconfig, item.key);
      setItemPosition(tempItem, tempItem.left, top, tempItem.width, tempItem.height);
      tempSelectNodes.push(tempItem);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs(tempSelectNodes);
  }

  //下对齐
  const handleValignBottom = () => {
    if (selectNodes.length <= 1) return;
    let tempconfig = _.cloneDeep(pageConfig);
    const bottom = selectRect.top + selectRect.height;
    let tempSelectNodes: any[] = [];
    selectNodes.forEach((item: any) => {
      let tempItem = getConfigFromKey(tempconfig, item.key);
      setItemPosition(tempItem, tempItem.left, bottom - tempItem.height, tempItem.width, tempItem.height);
      tempSelectNodes.push(tempItem);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs(tempSelectNodes);
  }

  //上下居中对齐
  const handleValignCenter = () => {
    if (selectNodes.length <= 1) return;
    let tempconfig = _.cloneDeep(pageConfig);
    const top = selectRect.top;
    const height = selectRect.height;
    const center = top + height / 2;
    let tempSelectNodes: any[] = [];
    selectNodes.forEach((item: any) => {
      let tempItem = getConfigFromKey(tempconfig, item.key);
      setItemPosition(tempItem, tempItem.left, center - tempItem.height / 2, tempItem.width, tempItem.height);
      tempSelectNodes.push(tempItem);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs(tempSelectNodes);
  }

  //上下完全对齐
  const handleValignAll = () => {
    if (selectNodes.length <= 1) return;
    let tempconfig = _.cloneDeep(pageConfig);
    const top = selectNodes[0].top;
    const height = selectNodes[0].height;
    let tempSelectNodes: any[] = [];
    selectNodes.forEach((item: any) => {
      let tempItem = getConfigFromKey(tempconfig, item.key);
      setItemPosition(tempItem, tempItem.left, top, tempItem.width, height);
      tempSelectNodes.push(tempItem);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs(tempSelectNodes);
  }

  //上下间隔排列
  const handleValignLine = () => {
    if (selectNodes.length <= 1) return;
    let tempconfig = _.cloneDeep(pageConfig);

    //按照left从小到大排序
    selectNodes.sort((a: any, b: any) => a.top - b.top);

    //获取总高度
    let sumHeight = selectNodes.reduce((pre: number, cur: any) => {
      return pre + cur.height;
    }, 0);
    //计算间隔 如果间隔小于0，则等于0
    let distance = (selectRect.height - sumHeight) / (selectNodes.length - 1);
    if (distance < 0) {
      distance = 0;
    }

    let top = selectNodes[0].top;
    let tempSelectNodes: any[] = [];
    selectNodes.forEach((item: any) => {
      let tempItem = getConfigFromKey(tempconfig, item.key);
      setItemPosition(tempItem, tempItem.left, top, tempItem.width, tempItem.height);
      top = top + tempItem.height + distance;
      tempSelectNodes.push(tempItem);
    });
    onPageConfigChange && onPageConfigChange(tempconfig);
    selectConfigs(tempSelectNodes);
  }

  //全选
  const handleSelectAll=()=>{
    selectConfigs(pageConfig);
  }

  //右键菜单
  const getRightMenu = () => {
    return [
      {
        label: '页面编辑',
        key: 'pageedit',
        disabled: selectNodes.length > 0,
        onClick: handleEditPage,
      },
      {
        label: '锁定',
        key: 'lock',
        disabled: !(selectNodes.length === 1 && !selectNodes[0].lock),
        onClick: handleLock,
      },
      {
        label: '解锁',
        key: 'unlock',
        disabled: !(selectNodes.length === 1 && selectNodes[0].lock),
        onClick: handleUnLock,
      },
      {
        label: `添加(${Controls.find(item => item.value === control)?.label})`,
        key: 'add',
        onClick: handleAdd,
      },
      {
        label: '复制',
        key: 'copy',
        disabled: !(selectNodes.length === 1),
        onClick: handleCopy,
      },
      {
        label: checkJSON(pastecomponentStr, 'object') ? `粘贴(${JSON.parse(pastecomponentStr)?.title})` : '粘贴',
        // label: '粘贴',
        key: 'paste',
        disabled: pastecomponentStr === '',
        onClick: handlePaste,
      },
      {
        label: '删除',
        key: 'del',
        disabled: selectNodes.length === 0,
        onClick: handleDel,
      },
      {
        label: '成组',
        key: 'group',
        disabled: selectNodes.length <= 1,
        onClick: handleGroup,
      },
      {
        label: '解组',
        key: 'cancelgroup',
        disabled: !(selectNodes.length === 1 && selectNodes[0].type === 'datavgroup'),
        onClick: handleCancelGroup,
      },
      {
        label: '置顶',
        key: 'top',
        disabled: !(selectNodes.length === 1),
        onClick: handleTop,
      },
      {
        label: '置底',
        key: 'bottom',
        disabled: !(selectNodes.length === 1),
        onClick: handleBottom,
      },
      {
        label: '水平对齐',
        key: 'Halign',
        disabled: (selectNodes.length === 1),
        children: [
          {
            key: 'HalignLeft',
            label: '左对齐',
            onClick: handleHalignLeft,
          },
          {
            key: 'HalignRight',
            label: '右对齐',
            onClick: handleHalignRight,
          },
          {
            key: 'HalignCenter',
            label: '居中对齐',
            onClick: handleHalignCenter,
          },
          {
            key: 'Halignall',
            label: '完全对齐',
            onClick: handleHalignAll,
          },
          {
            key: 'HalignLine',
            label: '间隔排列',
            onClick: handleHalignLine,
          },
        ],
      },
      {
        label: '垂直对齐',
        key: 'Valign',
        disabled: (selectNodes.length === 1),
        children: [
          {
            key: 'ValignTop',
            label: '上对齐',
            onClick: handleValignTop,
          },
          {
            key: 'ValignBottom',
            label: '下对齐',
            onClick: handleValignBottom,
          },
          {
            key: 'ValignCenter',
            label: '居中对齐',
            onClick: handleValignCenter,
          },
          {
            key: 'ValignAll',
            label: '完全对齐',
            onClick: handleValignAll,
          },
          {
            key: 'ValignLine',
            label: '间隔排列',
            onClick: handleValignLine,
          },
        ],
      },
      {
        label: '全选',
        key: 'selectall',
        onClick: handleSelectAll,
      },
    ]
  }

  const handleTitleRender = (nodeData: any) => {
    return (
      <div className={styles.node}
        key={`node-${nodeData.key}`}
        id={`node-${nodeData.key}`}
        onClick={() => {
          setSelectNodes([nodeData]);
          SetEditKeys([nodeData?.key]);
        }}>
        <Tooltip title={nodeData.type}>
          <div className={styles.title}>
            {nodeData.title}
          </div>
        </Tooltip>
        <Tooltip title="编辑">
          <EditOutlined className={styles.icon} onClick={() => { handleEditComponent(nodeData) }} />
        </Tooltip>
        <Tooltip title="上移">
          <CaretUpOutlined className={styles.icon} onClick={() => { handleMoveUpComponent(nodeData) }} />
        </Tooltip>
        <Tooltip title="下移">
          <CaretDownOutlined className={styles.icon} onClick={() => { handleMoveDownComponent(nodeData) }} />
        </Tooltip>
        <Tooltip title="复制">
          <CopyOutlined className={styles.icon} onClick={() => { handleCopyComponent(nodeData) }} />
        </Tooltip>
        <Tooltip title="删除">
          <Popconfirm
            title="是否确定要删除此组件?"
            onConfirm={() => { handleDelComponent(nodeData) }}
          >
            <DeleteOutlined className={styles.icon} />
          </Popconfirm>
        </Tooltip>
        {
          nodeData?.lock ? (
            <Tooltip title="解锁">
              <LockOutlined className={styles.icon} style={{ color: 'red' }} onClick={() => { handleUnLockComponent(nodeData) }} />
            </Tooltip>
          ) : (
            <Tooltip title="锁定">
              <UnlockOutlined className={styles.icon} onClick={() => { handleLockComponent(nodeData) }} />
            </Tooltip>
          )
        }

      </div>
    )
  }

  //渲染右边抽屉
  const renderDrawer = () => {
    return (
      <Drawer
        title="模块列表"
        onClose={handleDrawerClose}
        open={drawerVisible}
        mask={false}
        placement={placement}
      // getContainer={false}
      // style={{ position: 'absolute' }}
      >
        <div className={styles.toolbar} >
          <Select
            className={styles.select}
            value={control}
            onChange={(e) => {
              setControl(e);
            }}
            options={Controls} />
          <Button className={styles.btn} onClick={handleAddItem}>
            添加组件
          </Button>
          {/* <Button onClick={() => {
            console.log(pageConfig);
          }}>test</Button> */}
        </div>
        <div className={styles.tree}>
          <Tree
            showLine
            draggable
            blockNode
            treeData={pageConfig}
            titleRender={handleTitleRender}
            selectedKeys={editKeys}
          />
        </div>
        <Radio.Group
          value={placement}
          onChange={e => {
            setPlacement(e.target.value)
          }}>
          <Radio value="left" >靠左</Radio>
          <Radio value="right" >靠右</Radio>
        </Radio.Group>
      </Drawer>
    )
  }

  return (
    <div className={styles.datavcontent}>

      <Dropdown overlayStyle={{ zIndex: 999999 }} menu={{ items: getRightMenu() }} trigger={['contextMenu']}>
        <div ref={contentRef} className={styles.configcontent}
          style={{
            width: `${page.pageWidth}px`,
            height: `${page.pageHeight}px`,
            backgroundImage: `url(${_.isEmpty(page.bgurl) ? defaultDatavBgUrl : page.bgurl})`,
            transform: `translate(50px, 50px) translate(${translateX}px, ${translateY}px) scale(${scale / 100})`,
            cursor: MCursor
          }}
          onMouseMove={handleMouseMove}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onDoubleClick={handleDoubleClick}>
          {getViewFromConfig(pageConfig)}

          {/* 选中多组件时的最外黄色边框 */}
          {selectNodes.length > 1 && (
            <div style={{
              position: 'absolute',
              width: `${selectRect.width}px`,
              height: `${selectRect.height}px`,
              left: `${selectRect.left}px`,
              top: `${selectRect.top}px`,
              border: 'dashed 1px yellow',
            }} />
          )}

          {selectNodes.map((selectNode: any) => (
            <div key={`selectrect-${selectNode.key}`} style={{
              position: 'absolute',
              width: `${selectNode.width}px`,
              height: `${selectNode.height}px`,
              left: `${selectNode.left}px`,
              top: `${selectNode.top}px`,
              border: 'dashed 1px red',
            }} />
          ))}

          {/* 拖动组件时的边框 */}
          {MStartMove && (
            <div style={{
              position: 'absolute',
              width: `${dragRect.width}px`,
              height: `${dragRect.height}px`,
              left: `${dragRect.left}px`,
              top: `${dragRect.top}px`,
              border: 'dashed 1px green',
            }} />
          )}

        </div>
      </Dropdown>

      {/* 缩略图 */}
      {thumbnailVisible && (
        <div className={styles.thumbnail}
          style={{
            width: '200px',
            height: `${thumbnailHeight}px`,
            left: '0px',
            top: `calc(100vh - ${thumbnailHeight + 65}px)`,
          }}
          onMouseDown={handleThumbnailMouseDown}
          onMouseMove={handleThumbnailMouseMove}
          onMouseUp={handleThumbnailMouseUp}
          onMouseLeave={handleThumbnailMouseLeave}
        >
          <div className={styles.thumbnailcontent}>
            {`${MSX}*${MSY}:`}
            {`${Math.round(translateX)}-${Math.round(translateY)}`}
          </div>
          <div className={styles.movescreen}
            style={{
              width: `${moveScreenWidth}px`,
              height: `${moveScreenHeight}px`,
              left: `${moveScreenLeft}px`,
              top: `${moveScreenTop}px`,
            }} />
        </div>
      )}

      <Button
        style={{
          position: "absolute",
          left: 2,
          top: "calc(100% - 28px)",
          zIndex: 100,
        }}
        type="primary"
        shape="circle"
        size="small"
        icon={thumbnailVisible ? <MinusOutlined /> : <PlusOutlined />}
        onClick={() => {
          setThumbnailVisible(!thumbnailVisible);
        }} />

      {renderDrawer()}


      <DatavTextEdit
        visible={datavTextVisible}
        onVisibleChange={SetDatavTextVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavTimerEdit
        visible={datavTimerVisible}
        onVisibleChange={SetDatavTimerVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavPictureEdit
        visible={datavPictureVisible}
        onVisibleChange={SetDatavPictureVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavBorderBoxEdit
        visible={datavBorderBoxVisible}
        onVisibleChange={SetDatavBorderBoxVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavDecorationEdit
        visible={datavDecorationVisible}
        onVisibleChange={SetDatavDecorationVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavNumberEdit
        visible={datavNumberVisible}
        onVisibleChange={SetDatavNumberVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavLineChartEdit
        visible={datavLineChartVisible}
        onVisibleChange={SetDatavLineChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavAreaChartEdit
        visible={datavAreaChartVisible}
        onVisibleChange={SetDatavAreaChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavColumnChartEdit
        visible={datavColumnChartVisible}
        onVisibleChange={SetDatavColumnChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavBarChartEdit
        visible={datavBarChartVisible}
        onVisibleChange={SetDatavBarChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavPieChartEdit
        visible={datavPieChartVisible}
        onVisibleChange={SetDatavPieChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavScatterChartEdit
        visible={datavScatterChartVisible}
        onVisibleChange={SetDatavScatterChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavRadarChartEdit
        visible={datavRadarChartVisible}
        onVisibleChange={SetDatavRadarChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavLiquidChartEdit
        visible={datavLiquidChartVisible}
        onVisibleChange={SetDatavLiquidChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavWordCloudChartEdit
        visible={datavWordCloudChartVisible}
        onVisibleChange={SetDatavWordCloudChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavActiveRingChartEdit
        visible={datavActiveRingChartVisible}
        onVisibleChange={SetDatavActiveRingChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavCapsuleChartEdit
        visible={datavCapsuleChartVisible}
        onVisibleChange={SetDatavCapsuleChartVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavPercentPondEdit
        visible={datavPercentPondVisible}
        onVisibleChange={SetDatavPercentPondVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavScrollBoardEdit
        visible={datavScrollBoardVisible}
        onVisibleChange={SetDatavScrollBoardVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavScrollRankingBoardEdit
        visible={datavScrollRankingBoardVisible}
        onVisibleChange={SetDatavScrollRankingBoardVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavVideoEdit
        visible={datavVideoVisible}
        onVisibleChange={SetDatavVideoVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavFrameEdit
        visible={datavFrameVisible}
        onVisibleChange={SetDatavFrameVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />
      <DatavGroupEdit
        visible={datavGroupVisible}
        onVisibleChange={SetDatavGroupVisible}
        value={getEditValue()}
        onChange={handleSetEditValue}
      />

      <DatavPageEdit
        visible={datavPageVisible}
        onVisibleChange={SetDatavPageVisible}
        value={page}
        onChange={(value: any) => {
          onPageChange && onPageChange(value);
          console.log(value);
          SetDatavPageVisible(false);
        }}
      />
    </div>
  )
}

export default DatavContent;

/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\workspaceedit\index.tsx
 * @LastEditTime: 2024-07-22 18:23:51
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Checkbox, Select, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import { NewGuid } from 'utils/common';
import ImageInput from 'components/common/imageinput';
import _ from 'lodash';
import styles from './index.module.scss';
import { useSelector } from "react-redux";

interface IProps {
  imageOSS?: any;
  visible: boolean;
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'workspace',
  key: NewGuid(),
  title: '工作区',
  backGroundUrl: '',
  items: [],
  isNoHeader: false,
})

const WorkSpaceEdit: React.FC<IProps> = (props: IProps) => {
  const { imageOSS, visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);
  const workSpaces = useSelector((state: any) => state.global.workSpaces);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  return (
    <Modal
      title='编辑工作区'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
      >
        <Form.Item
          name="backGroundUrl"
          label="背景图"
          // rules={[{ required: true }]}
        >
          <ImageInput dataOSS={imageOSS} />
        </Form.Item>

        <Form.Item
          name="items"
          label="工作区"
          rules={[{ required: true }]}
        >
          <Checkbox.Group options={workSpaces.map((item:any)=>({
            label: item.title, 
            value: item.id, 
          }))} />
        </Form.Item>
         <Form.Item
          name="isNoHeader"
          label="隐藏标题框"
          rules={[{ required: true }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default WorkSpaceEdit;
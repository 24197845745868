/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2023-02-13 17:56:37
 * @FilePath: \website\src\components\loginstatus\index.jsx
 * @LastEditTime: 2023-04-11 10:48:18
 */

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setOrganization, setLoginInfo, clearLoginInfo } from 'store/slices/globalSlice';
import { getUserInfo, isLogin } from 'utils/auth';
import _ from 'lodash';
import { parseParam } from "utils/common";
import { selectBorderStyle } from 'utils/componentdefine';
import { Button, Divider } from 'antd';
import styles from './index.module.scss';

//定位方式
export const LayoutTypes = [{
  id: 0,
  title: '横向',
  setting: 'vertical',
}, {
  id: 1,
  title: '纵向',
  setting: 'horizontal',
},]


export type LoginStatusConfig = {
  type: String;  //类型 loginstatus
  key: String; //主键
  title: String; //标题
  backGroundUrl: String; //背景图
  color: String;  //字颜色 default white
  layoutType: String; //水平还是垂直  horizontal | vertical  default vertical
}

interface IProps {
  config: LoginStatusConfig;
}

//登录状态
const LoginStatus: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const organization = useSelector((state: any) => state.global.organization);
  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  const { config } = props;
  const layoutType = config?.layoutType || "vertical";
  const color = config?.color || "white";

  let nickName = '';
  if (isLogin()) {
    const user = getUserInfo();
    nickName = user?.nick || "";
  }

  const loginUrl = `${process.env.REACT_APP_LOGIN_API_URL}/authentication${parseParam({
    // redirect: '/',
    redirectnew: window.location.origin,
    organizationId: organization.organizationGuid,
    backgroundurl: config.backGroundUrl || '',
  })}`;

  const handleLogout = () => { 
    //这里固定写死domain
    const options={
      domain: '.mediasaas.cn',
    }
    dispatch(clearLoginInfo(options));
    window.location.href = window.location.href;
  }

  let style = {
    color: color,
  };
  if (layoutType == "vertical") {  //横向
    style.display = "flex";
    style.alignItems = "center";
  } else {  //纵向
    style.display = "flex";
    style.flexDirection = "column";
    style.alignItems = "center";
  }

  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  if (isLogin()) {
    return (
      <div style={style}>
        {/* <span>{nickName}</span > */}
        <Button type="text" style={{ color: color }}>{nickName}</Button>
        <Divider type={layoutType}
          style={{
            borderColor: color,
            margin: "0",
          }} />
        <Button type="link" onClick={handleLogout}>退出</Button>
      </div >
    )
  } else {
    return (
      <div className={styles.container} style={style}>
        <Button href={loginUrl} target="_self" type="link">登录</Button>
      </div >
    )
  }
};

export default LoginStatus;
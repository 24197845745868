/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\articlelist\index.jsx
 * @LastEditTime: 2023-03-06 16:52:52
 */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { Menu } from 'antd';
import _ from 'lodash';
import styles from './index.module.scss';


export const Directions = [{
  id: 0,
  title: '横向',
  setting: 'row',
}, {
  id: 1,
  title: '纵向',
  setting: 'column',
}]

export const DisplayTypes = [{
  id: 0,
  title: '图',
  setting: 'image',
}, {
  id: 1,
  title: '文',
  setting: 'text',
}, {
  id: 2,
  title: '图加文',
  setting: 'imageandtext',
}]

export const SubItemDirections = [{
  id: 0,
  title: '上图下文',
  setting: 'column',
}, {
  id: 1,
  title: '上文下图',
  setting: 'column-reverse',
}, {
  id: 2,
  title: '左图右文',
  setting: 'row',
}, {
  id: 3,
  title: '左文右图',
  setting: 'row-reverse',
}]

export type ArticleListConfig = {
  type: String;  //类型 articlelist
  key: String; //主键
  title: String; //标题
  articlegroupid: String; //文章分类
  width: String; //宽度
  height: String;  //高度
  direction: String; //排列方向 row column  横向 纵向
  moretitle: String; //更多描述
  displaytype: String; //展示方式 图 文 图加文
  imagewidth: String; //图宽
  imageheight: String; //图高
  textwidth: String; //文宽
  textheight: String; //文高
  subitemcount: Number; //子项个数
  subitemdirection: String; //图文排列 上图下文 上文下图 左图右文 左文右图  
}


interface IProps {
  config: ArticleListConfig;
  getItems: (articlegroupid: String) => any;
}

//文章列表
const ArticleList: React.FC<IProps> = (props: IProps) => {
  const { config, getItems } = props;
  const [items, setItems] = useState([]);

  const urlSearch = new URLSearchParams(useLocation().search);
  const articlegroupid = urlSearch.get('articlegroupid') || 'empty';

  useEffect(async () => {
    if (config.articlegroupid === 'outid') {
      const tempItems = await getItems(articlegroupid);
      setItems(tempItems);
    } else {
      const tempItems = await getItems(config.articlegroupid || 'empty');
      setItems(tempItems);
    }
  }, [config.articlegroupid])

  const getSubItem = (item: any, index: Number) => {
    switch (config.displaytype) {
      case "image":
        return (
          <a href={item.originalType===101 ? item.url : `/article?articleid=${item.id}`}>
            <img src={item.coverImage}
              style={{
                width: config.imagewidth,
                height: config.imageheight,
                margin: "4px",
              }} />
          </a>
        )
      case "text":
        return (
          <div
            className={styles.text}
            style={{
              width: config.textwidth,
              height: config.textheight,
            }}
            onClick={() => {
              window.open(item.originalType===101 ? item.url : `/article?articleid=${item.id}`)
            }} >
            {item.title}
          </div>)
      case "imageandtext":
        return (
          <div
            style={{
              // width: config.textwidth,
              // height: config.textheight,
              display: "flex",
              flexDirection: config.subitemdirection,
              alignItems: "center",
              margin: "4px",
            }} >
            <img src={item.coverImage}
              style={{
                width: config.imagewidth,
                height: config.imageheight,
              }} />
            <div
              className={styles.text}
              style={{
                width: config.textwidth,
                height: config.textheight,
              }}
              onClick={() => {
                window.open(item.originalType===101 ? item.url : `/article?articleid=${item.id}`)
              }} >
              {item.title}
            </div>
          </div>)
      default:
        return '';
    }
  }

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = {
    width: config.width,
    height: config.height,
  };
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  return (
    <div className={styles.container} style={style}>
      <div className={styles.main}
        style={{
          flexDirection: config.direction,
        }}>
        {items && _.take(items, config.subitemcount).map((item: any, index: Number) => {
          return getSubItem(item, index);
        })}
      </div>
      {config.moretitle !== '' && (
        <div className={styles.moretitle}>
          <a target="_blank"
            href={`/articlepagelist?articlegroupid=${config.articlegroupid}`}>
            {config.moretitle}
          </a>
        </div>
      )}

    </div>
  )
};

export default ArticleList;
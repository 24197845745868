
import React, { useState, useEffect } from "react";
import { Divider, Typography } from 'antd';
import styles from './index.module.scss';
const { version } = require('../../../package.json');

const { Title, Paragraph, Text, Link } = Typography;

const Version = () => {
  useEffect(() => {

  }, []);

  return (
    <div className={styles.container}>
      <Typography>
        <Title>版本更新</Title>
        <Title level={2}>2024-09-04</Title>
        <Paragraph>
          <li>
            1.大屏增加页面参数配置
            2.大屏增加页面调用接口配置
            3.大屏各支持数据源组件，增加参数传入。
            4.如果大屏中多个组件公用一个接口返回内容，可以由页面层统一调用接口，传入页面参数，
            组件的数据源通过访问页面参数的方式实现。避免过于频繁访问后台接口。            
          </li>
        </Paragraph>
        <Title level={2}>2024-08-23</Title>
        <Paragraph>
          <li>
            1.大屏编辑页面增加右键全选菜单
            2.大屏组件数据源配置的Url和参数支持常用日期参数，
            如：{`@{today} 
            @{yearstart} @{yearend} 
            @{quarterstart} @{quarterend} 
            @{monthstart} @{monthend} 
            @{weekstart} @{weekstart} 
            同时支持加减运算，如： @{monthend+1} 代表下月初`}
          </li>
        </Paragraph>
        <Title level={2}>2024-07-23</Title>
        <Paragraph>
          <li>
            1.机构选择增加搜索框
          </li>
        </Paragraph>
        <Title level={2}>2024-07-22</Title>
        <Paragraph>
          <li>
            1.工作区和自定义工作区增加是否隐藏标题栏功能
          </li>
        </Paragraph>
        <Title level={2}>2024-06-12</Title>
        <Paragraph>
          <li>
            1.增加自动保存功能
          </li>
        </Paragraph>
        <Title level={2}>2024-06-06</Title>
        <Paragraph>
          <li>
            1.大屏图表JSON编辑页面增加JSON生成器功能，支持从Excel中快速生成JSON数据
          </li>
          <li>
            2.修复大屏图表JSON编辑页面全选不生效问题
          </li>
        </Paragraph>

        <Title level={2}>2024-06-05</Title>
        <Paragraph>
          <li>
            1.大屏编辑页面增加缩略图显示隐藏功能
          </li>
          <li>
            2.大屏编辑页面增加双击组件进入编辑页面功能
          </li>
        </Paragraph>

        <Title level={2}>2024-06-04</Title>
        <Paragraph>
          <li>
            1.大屏背景图和文字增加斜45度旋转和公转的动效
          </li>
          <li>
            2.大屏右键菜单增加水平对齐及垂直对齐功能
          </li>
        </Paragraph>

        <Title level={2}>2024-05-21</Title>
        <Paragraph>
          <li>
            1.大屏数字翻牌器和背景图增加点击事件
          </li>
        </Paragraph>

        <Title level={2}>2024-05-20</Title>
        <Paragraph>
          <li>
            1.大屏组件视频增加轮播效果
          </li>
          <li>
            2.增加嵌套页面组件
          </li>
          <li>
            3.按钮组件增加回退页面属性
          </li>
          <li>
            4.大屏文本和轮播图增加点击事件
          </li>
        </Paragraph>

        <Title level={2}>2024-05-14</Title>
        <Paragraph>
          <li>
            1.网站管理增加页面分组功能
          </li>
          <li>
            2.网站管理增加跨网站复制功能
          </li>
        </Paragraph>

        <Title level={2}>2024-05-10</Title> 
        <Paragraph>
          <li>
            1.增加页面参数及URL参数，并使所有组件都支持该参数
          </li>
          <li>
            2.容器增加滤镜效果功能
          </li>
          <li>
            3.容器和文本增加文件下载功能
          </li>
        </Paragraph>

        <Title level={2}>2024-04-29</Title>
        <Paragraph>
          <li>
            1.导航条增加字体相关属性
          </li>
          <li>
            2.图片增加点击放大功能
          </li>
          <li>
            3.视频增加控制条、静音、循环播放、自动播放属性
          </li>
          <li>
            4.视频增加点击放大功能
          </li>
          <li>
            5.增加新的动效模式：向下滚动
          </li>
        </Paragraph>

        <Title level={2}>2024-04-10</Title>
        <Paragraph>
          <li>
            1.容器、视频、图片增加宽高比属性
          </li>
          <li>
            2.容器的背景色、字色及文本的字色支持传参
          </li>
          <li>
            3.容器增加超出属性和隐藏滚动条属性
          </li>
        </Paragraph>

        <Title level={2}>2024-04-08</Title>
        <Paragraph>
          <li>
            轮播图增加了数据源属性，解决了轮播图不支持接口组件问题
          </li>
        </Paragraph>

        <Title level={2}>2024-04-02</Title>
        <Paragraph>
          <li>
            增加了接口组件，支持通过接口获取数据
          </li>
        </Paragraph>

        <Title level={2}>2024-04-01</Title>
        <Paragraph>
          <li>
            1.文本组件增加对齐方式
          </li>
          <li>
            2.文本组件支持换行
          </li>
        </Paragraph>

        <Title level={2}>2024-03-26</Title>
        <Paragraph>
          <li>
            1.修复横幅宽度超宽bug
          </li>
          <li>
            2.调整轮播图组件的左右翻页按钮和进度条到组件内部，并支持子组件的动效触发
          </li>
          <li>
            3.容器、图片、视频、文本增加了右、下属性，容器增加了阴影属性
          </li>
        </Paragraph>

        <Title level={2}>2024-03-08</Title>
        <Paragraph>
          <li>
            1.增加帮助文档浏览
          </li>
          <li>
            2.增加版本更新
          </li>
          <li>
            3.所有图表默认增加预览数据
          </li>
        </Paragraph>

        <Title level={2}>2024-01-04</Title>
        <Paragraph>
          <li>
            1.数据大屏增加右键菜单
          </li>
          <li>
            2.增加ctrl键多选，增加多选移动
          </li>
          <li>
            3.增加框选及框选的移动、缩放
          </li>
          <li>
            4.增加成组、解组功能
          </li>
          <li>
            5.增加锁定、解锁、置顶、置底功能
          </li>
          <li>
            6.上传图片增加分页功能
          </li>
          <li>
            7.数据大屏支持云模板库功能
          </li>
        </Paragraph>

        <Title level={2}>2023-12-27</Title>
        <Paragraph>
          <li>
            增加音频组件
          </li>
        </Paragraph>

        <Title level={2}>2023-12-22</Title>
        <Paragraph>
          <li>
            1.增加动态环图组件
          </li>
          <li>
            2.增加胶囊柱图组件
          </li>
          <li>
            3.增加进度池组件
          </li>
          <li>
            4.增加轮播图组件
          </li>
          <li>
            5.增加排名轮播图组件
          </li>
          <li>
            6.增加自定义配置
          </li>
          <li>
            7.增加视频组件
          </li>
        </Paragraph>

        <Title level={2}>2023-12-20</Title>
        <Paragraph>
          <li>
            1.增加边框组件
          </li>
          <li>
            2.增加装饰组件
          </li>
          <li>
            3.增加放大缩小功能
          </li>
        </Paragraph>

        <Title level={2}>2023-11-21</Title>
        <Paragraph>
          <li>
            1.增加雷达图组件
          </li>
          <li>
            2.增加词云组件
          </li>
          <li>
            2.自定义字体支持otf格式
          </li>
        </Paragraph>
        <Title level={2}>2023-10-31</Title>
        <Paragraph>
          <li>
            1.增加面积图组件
          </li>
          <li>
            2.增加柱状图组件
          </li>
          <li>
            3.增加条形图组件
          </li>
          <li>
            4.增加饼图组件
          </li>
          <li>
            5.增加水波图组件
          </li>
        </Paragraph>

        <Title level={2}>2023-10-30</Title>
        <Paragraph>
          <li>
            1.增加折线图组件
          </li>
          <li>
            2.json编辑器
          </li>
        </Paragraph>

        <Title level={2}>2023-10-27</Title>
        <Paragraph>
          <li>
            1.增加数据源配置（静态数据、api数据、反向代理、数据过滤器、复制粘贴数据源、定时刷新数据）
          </li>
          <li>
            2.增加标题组件
          </li>
          <li>
            3.增加背景框组件
          </li>
          <li>
            4.增加时间器组件
          </li>
          <li>
            5.增加数字翻牌器组件
          </li>
        </Paragraph>

        <Title level={2}>2023-10-24</Title>
        <Paragraph>
          <li>
            数据大屏基础框架搭建
          </li>
        </Paragraph>

        <Title level={2}>2023-10-19</Title>
        <Paragraph>
          <li>
            增加自定义字体
          </li>
          <li>
            文本组件支持渐变颜色
          </li>
          <li>
            容器支持渐变背景色和背景图的叠加效果
          </li>
        </Paragraph>
        <Title level={2}>2023-09-25</Title>
        <Paragraph>
          <li>
            增加是手机端编辑页面，并支持PC站点自动跳转手机端页面功能
          </li>
        </Paragraph>
        <Title level={2}>2023-07-11</Title>
        <Paragraph>
          <li>
            容器增加tip属性
          </li>
        </Paragraph>
        <Title level={2}>2023-07-07</Title>
        <Paragraph>
          <li>
            1.增加自定义工作区
          </li>
          <li>
            2.增加自定义文章列表
          </li>
          <li>
            3.容器支持渐变背景色
          </li>
        </Paragraph>
        <Title level={2}>2023-06-08</Title>
        <Paragraph>
          <li>
            增加电子报组件
          </li>
        </Paragraph>
        <Title level={2}>2023-03-02</Title>
        <Paragraph>
          <li>
            1.增加测试环境同步云模板库导正式环境功能
          </li>
          <li>
            2.按钮增加气泡模式
          </li>
          <li>
            3.气泡组件自定义样式
          </li>
        </Paragraph>
        <Title level={2}>2023-02-22</Title>
        <Paragraph>
          <li>
            增加容器的置顶透明效果
          </li>
        </Paragraph>
        <Title level={2}>2023-02-14</Title>
        <Paragraph>
          <li>
            1.增加工作区组件
          </li>
          <li>
            2.增加颜色
          </li>
          <li>
            3.图片鼠标移入动效
          </li>
          <li>
            4.容器增加内边距、外边距
          </li>
          <li>
            5.增加登录状态组件
          </li>
        </Paragraph>
        <Title level={2}>2023-02-09</Title>
        <Paragraph>
          <li>
            增加云组件库功能
          </li>
        </Paragraph>
        <Title level={2}>2023-02-06</Title>
        <Paragraph>
          <li>
            1.增加颜色录入控件
          </li>
          <li>
            2.增加图片地址录入控件
          </li>
          <li>
            3.容器增加字色属性
          </li>
          <li>
            4.按钮增加图标
          </li>
          <li>
            5.导航界面调整
          </li>
          <li>
            6.图片增加鼠标动画效果
          </li>
        </Paragraph>
        <Title level={2}>2023-01-19</Title>
        <Paragraph>
          <li>
            1.容器增加背景图
          </li>
          <li>
            2.容器增加动效
          </li>
          <li>
            3.字体增加动效
          </li>
          <li>
            4.增加视频组件
          </li>
          <li>
            5.增加按钮组件
          </li>
        </Paragraph>
        <Title level={2}>2023-01-08</Title>
        <Paragraph>
          <li>
            完善功能
          </li>
        </Paragraph>

        <Title level={2}>2023-01-02</Title>
        <Paragraph>
          <li>
            完成基本框架
          </li>
        </Paragraph>
      </Typography>
    </div>
  )
};

export default Version;
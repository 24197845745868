/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-07-28 22:16:24
 * @FilePath: \website\src\api\api.ts
 * @LastEditTime: 2024-07-03 18:06:05
 */

import { post, put, del, get, } from "./ajax";
import { parseParam } from "../utils/common";

//根据token获取用户信息
export const reqGetAuth = async () => {
  const data = await get(`/v1/account/auth`);
  return data;
};

//登录
export const reqLogin = async (param: { phone: string; password: string }) => {
  const data = await post(`/api/login`, param);
  return data;
};

//获取机构列表
export const reqGetOrganizations = async () => {
  const data = await get(`/api/organizations/page?pageindex=1&pagesize=999&keyword=`);
  return data;
};

//查询模板
export const reqGetWebSiteTemplates = async (param: { organizationId?: string, url?: string, id?: string }) => {
  const data = await get(`/v1/websittemplates${parseParam(param)}`);
  return data;
};

//新增模板
// * @apiParam {String} title 网站模板标题.
// * @apiParam {String} organizationId 机构id.
// * @apiParam {String} url 网址.
export const reqAddWebSiteTemplate = async (param: { organizationId: string, title: string, url: string, remark: string }) => {
  const data = await post(`/v1/websittemplates`, param);
  return data;
};

//修改模板
export const reqUpdateWebSiteTemplate = async (param: { id: string, title: string, url: string, remark: string, isused: boolean }) => {
  const data = await put(`/v1/websittemplates/${param.id}`, param);
  return data;
};

//删除模板
export const reqRemoveWebSiteTemplate = async (id: string) => {
  const data = await del(`/v1/websittemplates/${id}`);
  return data;
};


//查询页面
export const reqGetPages = async (param: { organizationId?: string, templateId?: string, templateurl?: string, pageid?: string }) => {
  const data = await get(`/v1/pages/websittemplate/by${parseParam({
    ...param,
    page: 1,
    count: 999
  })}`);
  return data;
};

//新增页面
// * @apiParam {String} templateid 模板id
// * @apiParam {String} name 标题
// * @apiParam {json} [pagecontent] 页面里的框架结构
// * @apiParam {String} pageurl 预览地址
// * @apiParam {String} pageStyle 页面类型 pc:pc页面 mobile:手机页面 datav:大屏
// * @apiParam {Number} pageWidth 页面宽度 仅datav有效
// * @apiParam {Number} pageHeight 页面高度 仅datav有效
// * @apiParam {String} redirectUrl 手机端跳转地址 仅pc页面有效
export const reqAddPage = async (param: {
  templateid: string,
  name: string,
  pageurl: string,
  pagecontent: any,
  pageStyle?: string,
  pageWidth?: number,
  pageHeight?: number,
  bgurl?: string,
  redirectUrl?: string,
  pagegroupid?: string,
}) => {
  const data = await post(`/v1/pages/for/website`, param);
  return data;
};

//修改页面
export const reqUpdatePage = async (param: {
  id: string,
  name: string,
  pageurl: string,
  pagecontent: any,
  pageStyle?: string,
  pageWidth?: number,
  pageHeight?: number,
  bgurl?: string,
  redirectUrl?: string,
  pagegroupid?: string,
}) => {
  const data = await put(`/v1/pages/for/website/${param.id}`, param);
  return data;
};

//删除页面
export const reqRemovePage = async (id: string) => {
  const data = await del(`/v1/pages/for/website/${id}`);
  return data;
};

//复制页面
//  * @apiHeader {String} token 拇指云token.
//    *
//    * @apiParam {String} templateid 模板id
//    * @apiParam {String} pagegroupid 页面组id
//    * @apiParam {String} id 页面id
//    * @apiParam {String} name 标题
//    * @apiParam {String} pageurl 预览地址
//   */
export const reqCopyPage = async (param: {
  id: string,
  templateid: string,
  pagegroupid?: string,
  name: string,
  pageurl: string,
}) => {
  const data = await post(`/v1/pages/for/website/copy`, param);
  return data;
};

//查询文章分组
export const reqGetArticleGroups = async (param: { organizationId: string, articletype?: number }) => {
  const tempParam = {
    ...param,
    page: 1,
    count: 999
  }
  const data = await get(`/v1/articlegroups${parseParam(tempParam)}`);
  return data;
};

//新增文章分组
// * @apiParam {String} organizationId 机构id
// * @apiParam {Number} articletype 类型 0：文章，1：视频，2：直播 3:电子报
// * @apiParam {String} title 名称
export const reqAddArticleGroup = async (param: { organizationId: string, articletype: number, title: string }) => {
  const data = await post(`/v1/articlegroups`, param);
  return data;
};

//修改文章分组
export const reqUpdateArticleGroup = async (param: { id: string, articletype: number, title: string }) => {
  const data = await put(`/v1/articlegroups/${param.id}`, param);
  return data;
};

//删除文章分组
export const reqRemoveArticleGroup = async (id: string) => {
  const data = await del(`/v1/articlegroups/${id}`);
  return data;
};

//获取稿件列表
export const reqGetPublishDates = async (params: { organizationId?: string, articlegroupid?: string }) => {
  const data = await get(`/v1/contents/for/website/group/date${parseParam(params)}`);
  return data;
}

//查询分组
export const reqGetPageGroups = async (params: {
  organizationId?: string, templateid?: string, groupType?: number, page?: number, count?: number
}) => {
  const data = await get(`/v1/pagegroups${parseParam(params)}`);
  return data;
};

//新增分组
// * @apiParam {String} organizationId 机构id
// * @apiParam {String} templateid 模板id
// * @apiParam {String} title 标题
// * @apiParam {Number} groupType 分组类型 1页面 
export const reqAddPageGroup = async (param: { organizationId: string, templateid?: string, title: string,groupType: number }) => {
  const data = await post(`/v1/pagegroups`, param);
  return data;
};

//修改分组
export const reqUpdatePageGroup = async (param: { id: string, title: string }) => {
  const data = await put(`/v1/pagegroups/${param.id}`, param);
  return data;
};

//删除分组
export const reqRemovePageGroup = async (id: string) => {
  const data = await del(`/v1/pagegroups/${id}`);
  return data;
};



//查询文章
// * @apiParam {String} organizationId 机构id
// * @apiParam {String} [articlegroupid] 文章分组id
// * @apiParam {String} [articletype] 文章类型：0：文章，1：视频，2：直播 3:电子报 
// * @apiParam {String} [articleid]  文章id
// * @apiParam {String} [publishdate]  发布日期

export const reqGetArticles = async (params: {
  organizationId?: string, articlegroupid?: string, originalType?: number,
  articletype?: string, articleid?: string, publishdate?: string, page?: number, count?: number
}) => {
  const data = await get(`/v1/contents/for/website${parseParam(params)}`);
  return data;
};

//新增文章
// * @apiParam {String} articlegroupid 分类id
// * @apiParam {String} organizationId 机构id
// * @apiParam {String} [coverImage] 封面
// * @apiParam {String} title 标题
// * @apiParam {String} description 简介
// * @apiParam {String} url 外链地址
// * @apiParam {Number} [systemid] 内容来源 0是发布系统 1拇指云系统 默认是0
// * @apiParam {Number} [originalType] 来源类型 0: 稿件 1:专题 5:音频 6:视频 7:图集 8:图片 9:pdf 10:font 11:file 101:外链 102：内容 103:电子报版面 104:电子报稿件
// * @apiParam {Number} [pageurl] 网页urimage.png
// * @apiParam {Number} [content] 内容
// * @apiParam {Number} [sequence] 顺序号 对应电子报的版面顺序
// * @apiParam {Number} [publishdate] 发布日期
export const reqAddArticle = async (param: { organizationId: string, articletype: number, title: string }) => {
  const data = await post(`/v1/contents/for/website`, param);
  return data;
};

//修改文章
export const reqUpdateArticle = async (param: { id: string, articletype: number, title: string }) => {
  const data = await put(`/v1/contents/for/website/${param.id}`, param);
  return data;
};

//删除文章
export const reqRemoveArticle = async (id: string) => {
  const data = await del(`/v1/contents/for/website/${id}`);
  return data;
};



//获取OSS  type: 1:音频 2：图片 system：muzhiyun
export const reqGetSignature = async (params: { bucketType: number, system: string }) => {
  const data = await get(`/api/signature${parseParam(params)}`);
  // const data = await get(`/api/signature?bucketType=1&system=muzhiyun`);
  return data;
};


//查询公共组件
// * @apiParam {String} name 组件名
// * @apiParam {String} tag 标签
export const reqGetComponents = async (params: {
  name?: string, tag?: string, pageStyle: string
}) => {
  const tempparams = {
    ...params,
    page: 1,
    count: 999,
  }
  const data = await get(`/v1/componentlists/website/find${parseParam(tempparams)}`);
  return data;
};

//新增公共组件
// * @apiParam {String} name 标题
// * @apiParam {String} pageStyle 页面类型 pc:pc页面 mobile:手机页面 datav:大屏
// * @apiParam {String} tag 标签
// * @apiParam {Number} [content] 内容
// * @apiParam {String} comment 备注
export const reqAddComponent = async (param: { name: string, pageStyle: string, tag: string[], content: any, comment: string }) => {
  const data = await post(`/v1/componentlists/website/create`, param);
  return data;
};

//测试环境下同步到正式环境
export const reqAddComponentProd = async (param: { name: string, pageStyle: string, tag: string[], content: any, comment: string }) => {
  const data = await post(`/v2/componentlists/website/create`, param);
  return data;
};

export const reqUpdateComponent = async (param: { id: string, name: string, pageStyle: string, tag: string[], content: any, comment: string }) => {
  const data = await put(`/v1/componentlists/website/${param.id}`, param);
  return data;
};

export const reqRemoveComponent = async (id: string) => {
  const data = await del(`/v1/componentlists/website/${id}`);
  return data;
};


//获取导航按钮（未登录）
export const reqGetWorkSpaces = async (organizationid: string, domain: string) => {
  const data = await get(`/api/WorkSpaces/${organizationid}/domain${parseParam({ domain })}`);
  return data;
};


//获取导航按钮（已登录）
export const reqGetWorkSpacesByUser = async (domain: string) => {
  const data = await get(`/api/WorkSpaces/user/modellets${parseParam({ domain })}`);
  return data;
};

//pdf转图片
export const reqPdfToImage = async (param: { fileName: string, fileUrl: string }) => {
  const data = await post(`/v1/news/pdf/to/images`, param);
  return data;
}

//获取频道
export const reqGetChannels = async (originalGuid: string) => {
  const data = await get(`/api/channelCloudSpaces/organizations/${originalGuid}`);
  return data;
};

//获取公共稿件夹
export const reqGetFolders = async (param: { channelids: string[] }) => {
  const data = await post(`/api/folders/materials/by/channel`, param);
  return data;
}

//获取稿件列表
export const reqGetStoryMedias = async (param: { channelid: string, mediaid: string, rundownDate: string, folderGuid: string }) => {
  const data = await get(`/api/story2medias${parseParam(param)}`);
  return data;
}


//reqGetArticles 无token版本
export const reqGetArticlesdzb = async (params: {
  organizationId?: string, articlegroupid?: string, originalType?: number,
  articletype?: string, articleid?: string, publishdate?: string, page?: number, count?: number
}) => {
  const data = await get(`/v1/contents/for/website/dzb${parseParam(params)}`);
  return data;
};


//获取背景图
export const reqGetCoverImage = async (param: { osspath: string; }) => {
  const data = await post(`/api/materials/create/image/byvideo`, param);
  return data;
};

//通过反向代理访问服务
export const reqAgencyRequest = async (param: { requestUrl: string, requestType: string, headers: {}, body: {} }) => {
  const res = await post(`/datav/api/agency/requesturl`, param, false);
  return res.data;
}


export const reqGetDatavVersion = async () => {
  const data = await get(`/datav/api/version`);
  return data;
}
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\customvideo\index.jsx
 * @LastEditTime: 2023-01-18 11:21:59
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { dealParam } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';


export type CustomAudioConfig = {
  type: String;  //类型 customaudio
  key: String; //主键
  title: String; //标题
  src: String; //音频地址
}

interface IProps {
  config: CustomAudioConfig;
  subParam?: any; //父组件传给子组件的参数
}

//音频
const CustomAudio: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  let { config, subParam } = props;
  subParam = {
    ...pageParam,
    ...subParam,
  }

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = {
    width: '100%',
  };
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  return (
    <audio
      style={{ width: "100%" }}
      src={dealParam(config.src, subParam)}
      controls
    />
  )
};

export default CustomAudio;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\jsonedit\index.tsx
 * @LastEditTime: 2024-06-06 15:04:32
 */

import React, { useState, useEffect } from "react";
import _ from 'lodash';
import moment from 'moment';
import { NewGuid } from 'utils/common';
import { Tooltip, Modal, Form, Input, Button, Popover, message } from 'antd';
import PageHeader from '../pageheader';
import { PlusOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { useDispatch } from "react-redux";
import { stringify } from "querystring";

const { TextArea } = Input;

//json页面编辑
const JsonEdit: React.FC = () => {
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");
  const [outputValue, setOutputValue] = useState("");

  const handleTurnObj = () => {
    let rows = inputValue.split("\n");
    // 创建一个二维数组来存储行列数据
    let table: any = [];

    // 遍历行数组，拆分每行的列数据并存储到二维数组中
    rows.forEach(row => {
      if (row.trim() !== "") {
        let columns = row.split("\t");
        table.push(columns);
      }
    });

    if (table.length <= 1) {
      message.error("至少需要2行数据");
      return;
    }

    const columnCount = table[0].length;
    for (let index = 0; index < table.length; index++) {
      const row = table[index];
      if (row.length !== columnCount) {
        message.error(`首行数量:${columnCount}与第${index + 1}行数量:${row.length}不一致`);
        return;
      }
    }

    let json: any = [];
    for (let i = 1; i < table.length; i++) {
      let obj: any = {};
      for (let j = 0; j < table[0].length; j++) {
        obj[table[0][j]] = table[i][j];
      }
      json.push(obj);
    }

    setOutputValue(JSON.stringify(json, null, 2));
    message.success(`处理成功，共${table.length - 1}条数据`);
  }

  const handleTurnArray = () => {
    let rows = inputValue.split("\n");
    // 创建一个二维数组来存储行列数据
    let table: any = [];

    // 遍历行数组，拆分每行的列数据并存储到二维数组中
    rows.forEach(row => {
      if (row.trim() !== "") {
        let columns = row.split("\t");
        table.push(columns);
      }
    });

    if (table.length < 1) {
      message.error("至少需要1行数据");
      return;
    }

    const columnCount = table[0].length;
    for (let index = 0; index < table.length; index++) {
      const row = table[index];
      if (row.length !== columnCount) {
        message.error(`首行数量:${columnCount}与第${index + 1}行数量:${row.length}不一致`);
        return;
      }
    }

    setOutputValue(JSON.stringify(table, null, 2));
    message.success(`处理成功，共${table.length}条数据`);
  }

  return (
    <div className={styles.container}>

      <div className={styles.left} >
        <TextArea
          style={{
            height: "100%"
          }}
          placeholder="从Excel中粘贴数据"
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
        />
      </div>
      <div className={styles.center} >
        <Button type="primary" onClick={handleTurnObj}>转对象</Button>
        <div style={{ height: "10px" }}></div>
        <Button type="primary" onClick={handleTurnArray}>转数组</Button>
      </div>
      <div className={styles.right} >
        <TextArea
          style={{
            height: "100%"
          }}
          value={outputValue}
          onChange={e => setOutputValue(e.target.value)}
        />
      </div>
    </div>
  )
};

export default JsonEdit;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\datavContent\datavliquidchartedit\index.tsx
 * @LastEditTime: 2024-03-08 15:19:47
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Button, Select, Radio, Switch, Tabs, AutoComplete, InputNumber, message } from 'antd';
import { liquidChartTypes } from 'utils/componentdefine';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';
import DataSourceInput from "components/common/datasourceinput";
import JsonInput from "components/common/jsoninput";

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'datavliquidchart',
  key: NewGuid(),
  title: '水波图',
  data: {
    dataSource: 'static', //数据源类型
    staticData: 0.58, //静态数据 0-1之间
    requestUrl: '', //api地址
    requestType: 'GET', //请求方式
    headers: {}, //请求头
    body: {}, //请求参数
    filters: 'return res;', //数据过滤器
    requestSec: 0, //多少秒一次请求，为0表示只请求1次
  },
  shape: 'circle',
  customConfig: {},
  width: 600,
  height: 300,
  left: 0,
  top: 0,
})

const DatavLiquidChartEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])

  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑Chart'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="data"
              label="数据源"
            // rules={[{ required: true }]}
            >
              <DataSourceInput valueType='number' />
            </Form.Item>
            <Form.Item
              name="shape"
              label="图表类型"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {liquidChartTypes.map(item => (<Radio key={item.value} value={item.value}>{item.label}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="customConfig"
              label="自定义配置"
              tooltip="参考配置： https://ant-design-charts.antgroup.com/api/plots/liquid"
            >
              <JsonInput valueType={"object"} />
            </Form.Item>
            <Form.Item
              name="left"
              label="左"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="top"
              label="上"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  )
};

export default DatavLiquidChartEdit;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\common\imageinput\index.jsx
 * @LastEditTime: 2023-02-10 16:28:59
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UploadFile } from "antd/lib/upload/interface";
import { Modal, Form, Input, Image, Button, Upload, message, Popconfirm, Tooltip } from 'antd';
import { UploadOutlined, DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { NewGuid, getExtension, getFileNameWithOutExt } from 'utils/common';
import { reqGetArticles, reqAddArticle, reqRemoveArticle, reqGetCoverImage } from 'api/api';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  dataOSS?: any;
  value?: any;
  onChange?: (value: any) => void;
}

//音频url
//这里视频保存跟reqGetArticles文章管理合并，articlegroupid: 'audio', title: 标题,url: 地址, originalType: 5
const AudioInput: React.FC<IProps> = (props: IProps) => {
  const { dataOSS, value, onChange } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [url, setUrl] = useState('');
  const [audios, setAudios] = useState([]);
  const [inputTitle, setInputTitle] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewFile, setPreviewFile] = useState('');

  const organization = useSelector((state: any) => state.global.organization);


  const handleChange = (e: any) => {
    onChange && onChange(e.target.value);
  }

  // // 获取 .后缀名
  // const getExtension = (name: string) => {
  //   return name.substring(name.lastIndexOf("."))
  // }

  // // 获取 文件名
  // const getFileName = (name: string) => {
  //   return name.substring(0, name.lastIndexOf("."))
  // }

  // 上传前检查
  const handleBeforeUpload = async (file: any, fileList: any) => {
    if (inputTitle === '') {
      setInputTitle(getFileNameWithOutExt(file.name));
    }
    const suffix = getExtension(file.name).toLowerCase();
    // 重新修改文件名称
    file.url = dataOSS.key + NewGuid() + suffix;
    return true
  }

  const handleData = (file: any) => {
    return {
      OSSAccessKeyId: dataOSS.accessid,
      Signature: dataOSS.signature,
      key: file.url,
      policy: dataOSS.policy,
    };
  }

  // 通过本地文件上传状态变化
  const handleUploadChange = async (info: any) => {
    if (info.file.status === "done") {
      console.log(info);
      const tempurl = `${dataOSS.host}/${info.file.url}`;
      const param = {
        organizationId: organization.organizationGuid,
        articlegroupid: null,
        title: inputTitle,
        description: '',
        url: tempurl,
        originalType: 5,
      }
      console.log('param', param);
      reqAddArticle(param).then(res => {
        refreshAudios();
      })
      setUrl(tempurl);
      setInputTitle('');
      onChange && onChange(tempurl);
    }
  };

  const handleClick = () => {
    refreshAudios();
    setUrl(value);
    setInputTitle('');
    setModalVisible(true);
  }

  const refreshAudios = () => {
    const params = {
      organizationId: organization?.organizationGuid,
      originalType: 5,
      page: 1,
      count: 999
    }
    reqGetArticles(params).then(res => {
      console.log('refreshAudios', res);
      setAudios(res.items);
    })
  }

  const handleOK = () => {
    onChange && onChange(url);
    setModalVisible(false);
  }

  const handleCancel = () => {
    setModalVisible(false);
  }

  //删除
  const handleRemoveAudio = (item: any) => {
    reqRemoveArticle(item.id).then(res => {
      //这里需要调用删除oss文件的代码
      refreshAudios();
    })
  }

  //播放
  const handlePreview = (item: any) => {
    setPreviewVisible(true);
    setPreviewFile(item.url);
    console.log('a');
  }

  return (
    <Input.Group compact>
      <Button className={styles.audio}
        icon={<PlayCircleOutlined />}
        onClick={() => {
          if (_.isEmpty(value)) return;
          setPreviewVisible(true);
          setPreviewFile(value)
        }} />
      <Input
        style={{
          width: "calc(100% - 64px)"
        }}
        className={styles.input}
        value={value}
        onChange={handleChange} />
      <Button icon={<UploadOutlined />} onClick={handleClick} />
      <Modal
        title='上传音频'
        okText="确认"
        cancelText="取消"
        visible={modalVisible}
        onOk={handleOK}
        onCancel={handleCancel}
      >
        <div className={styles.container}>
          <div className={styles.top}>
            {audios.map((item: any, index: number) => (
              <div
                key={`img${index}`}
                className={styles.imgitem}
                style={{ borderColor: "#d0d0d0" }}
                onClick={() => { setUrl(item.url) }}>
                <div className={styles.img}
                  style={{ backgroundImage: `url(${item.coverImage})` }}
                  onClick={() => { handlePreview(item); }}>
                  <PlayCircleOutlined />
                </div>
                {/* <Image src={item.coverImage} className={styles.img} /> */}
                <div className={styles.title}>{item.title}</div>
                <Popconfirm
                  title={`确认删除?`}
                  onConfirm={() => { handleRemoveAudio(item) }}>
                  <Tooltip title="删除">
                    <DeleteOutlined className={styles.icon} />
                  </Tooltip>
                </Popconfirm>
              </div>
            ))}
          </div>
          <div className={styles.bottom}>
            <span className={styles.title}>标题</span>
            <Input value={inputTitle} onChange={e => { setInputTitle(e.target.value) }} />
            <Upload
              name="file"
              // accept={typeList.join(",")}
              accept=".mp3,.aac,.wav,.m4a,.wma,.s48"
              multiple={false}
              showUploadList={false}
              action={dataOSS?.host}
              beforeUpload={handleBeforeUpload}
              data={handleData}
              onChange={handleUploadChange}
            >
              <Tooltip title="上传">
                <Button icon={<UploadOutlined />} />
              </Tooltip>
            </Upload>
          </div>
        </div>
      </Modal>
      <Modal
        destroyOnClose
        onCancel={() => { setPreviewVisible(false) }}
        visible={previewVisible}
        footer={null}
        title={null}>
        <div style={{ textAlign: 'center' }}>
          <audio 
            controls
            src={previewFile}>
          </audio>
        </div>
      </Modal>

    </Input.Group>
  );
};

export default AudioInput;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\common\imageinput\index.jsx
 * @LastEditTime: 2023-02-10 16:28:59
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UploadFile } from "antd/lib/upload/interface";
import { Modal, Form, Input, Select, Image, Button, Upload, message, Popconfirm, Tooltip } from 'antd';
import { UploadOutlined, DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { NewGuid, getExtension, getFileNameWithOutExt } from 'utils/common';
import { fontFamilys } from 'utils/componentdefine';
import { reqGetArticles, reqAddArticle, reqRemoveArticle, reqGetSignature } from 'api/api';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  value?: any;
  onChange?: (value: any) => void;
}

const typeList = ['.ttf','.otf'];

//字体输入框
//这里视频保存跟reqGetArticles文章管理合并，articlegroupid: 'font', title: 字体名,url: 地址, originalType: 10,
const FontInput: React.FC<IProps> = (props: IProps) => {
  const { value, onChange } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [url, setUrl] = useState('');
  const [fonts, setFonts] = useState([]);
  const [inputTitle, setInputTitle] = useState('');
  const [dataOSS, setDataOSS] = React.useState({}) //oss地址

  useEffect(() => {
    //获取videoOSS地址
    reqGetSignature({ bucketType: 1, system: 'muzhiyun' }).then(res => {
      setDataOSS(res.Data[0]);
    });
    refreshFonts();
    console.log('value', value);
  }, [])

  const organization = useSelector((state: any) => state.global.organization);


  const handleChange = (e: string) => {
    onChange && onChange(e.join(','));
    console.log(e);
  }


  // 上传前检查
  const handleBeforeUpload = async (file: any, fileList: any) => {
    if (inputTitle === '') {
      setInputTitle(getFileNameWithOutExt(file.name));
    }
    const suffix = getExtension(file.name).toLowerCase();
    // 重新修改文件名称
    file.url = dataOSS.key + NewGuid() + suffix;
    return true
  }

  const handleData = (file: any) => {
    return {
      OSSAccessKeyId: dataOSS.accessid,
      Signature: dataOSS.signature,
      key: file.url,
      policy: dataOSS.policy,
    };
  }

  // 通过本地文件上传状态变化
  const handleUploadChange = async (info: any) => {
    if (info.file.status === "done") {
      console.log(info);
      const tempurl = `${dataOSS.host}/${info.file.url}`;
      const param = {
        organizationId: organization.organizationGuid,
        articlegroupid: null,
        title: inputTitle,
        description: '',
        url: tempurl,
        originalType: 10,
      }
      console.log('param', param);
      reqAddArticle(param).then(res => {
        refreshFonts();
      })
      setUrl(tempurl);
      setInputTitle('');
      onChange && onChange(tempurl);
    }
  };

  const handleClick = () => {
    refreshFonts();
    setUrl(value);
    setInputTitle('');
    setModalVisible(true);
  }

  const refreshFonts = () => {
    const params = {
      organizationId: organization?.organizationGuid,
      originalType: 10,
      page: 1,
      count: 999
    }
    reqGetArticles(params).then(res => {
      console.log('refreshFonts', res);
      setFonts(res.items);
    })
  }

  const handleOK = () => {
    setModalVisible(false);
  }

  const handleCancel = () => {
    setModalVisible(false);
  }

  //删除
  const handleRemoveFont = (item: any) => {
    reqRemoveArticle(item.id).then(res => {
      //这里需要调用删除oss文件的代码
      refreshFonts();
    })
  }

  return (
    <Input.Group compact>
      <Select
        mode="tags"
        style={{ width: 'calc(100% - 32px)' }}
        placeholder="可多选字体"
        onChange={handleChange}
        value={(value || '') === '' ? [] : value.split(',')}
        options={
          fontFamilys.map(item => { return { value: item, label: item } })
            .concat(fonts.map(item => { return { value: item.title, label: `${item.title}(自定义)` } }))
        }
      />
      <Button icon={<UploadOutlined />} onClick={handleClick} />
      <Modal
        title='上传文件'
        okText="确认"
        cancelText="取消"
        visible={modalVisible}
        onOk={handleOK}
        onCancel={handleCancel}
      >
        <div className={styles.container}>
          <div className={styles.top}>
            {fonts.map((item: any, index: number) => (
              <div
                key={`font${index}`}
                className={styles.item}
                style={{ borderColor: url === item.url ? "#1890ff" : "#d0d0d0" }}
                onClick={() => { setUrl(item.url) }}>
                <div className={styles.title}>{item.title}</div>
                <Popconfirm
                  title={`确认删除?`}
                  onConfirm={() => { handleRemoveFont(item) }}>
                  <Tooltip title="删除">
                    <DeleteOutlined className={styles.icon} />
                  </Tooltip>
                </Popconfirm>
              </div>
            ))}
          </div>
          <div className={styles.bottom}>
            <span className={styles.title}>标题</span>
            <Input value={inputTitle} onChange={e => { setInputTitle(e.target.value) }} />
            <Upload
              name="file"
              accept={typeList.join(",")}
              multiple={false}
              showUploadList={false}
              action={dataOSS?.host}
              beforeUpload={handleBeforeUpload}
              data={handleData}
              onChange={handleUploadChange}
            >
              <Tooltip title="上传">
                <Button icon={<UploadOutlined />} />
              </Tooltip>
            </Upload>
          </div>
        </div>
      </Modal>
    </Input.Group>
  );
};

export default FontInput;
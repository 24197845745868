import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from "react-redux";
import { selectBorderStyle, DatavDataSource } from 'utils/componentdefine';
import { executeFunctionFilter, convertToNumber, dealParam, dealParamObj, getData } from 'utils/common';
import { reqAgencyRequest } from 'api/api';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import styles from './index.module.scss';

TweenOne.plugins.push(Children);

export type DatavNumberConfig = {
  type: String;  //类型 datavtext
  key: String; //主键
  title: String; //标题 
  data: DatavDataSource; //内容
  thousand: Boolean;  //千分数
  decimal: Boolean; //小数
  prefix: String; //前缀
  suffix: String; //后缀
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
  fontSize: String; //字体大小
  fontFamily: String; //字体
  color: String;  //颜色
  gradualColor: String; //渐变颜色
  fontWeight: Boolean; //粗体
  intalic: Boolean; //斜体
  underline: Boolean; //下划线
}

interface IProps {
  config: DatavNumberConfig;
}

//数字翻牌器
const DatavNumber: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const { config } = props;
  const [num, setNum] = useState(0); //Num
  const [animation, setAnimation] = useState(null);

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  const editMode = useSelector((state: any) => state.global.editMode);
  const className = `${styles.container} ${config.intalic ? styles.italic : styles.unitalic} ${config.underline ? styles.underline : styles.ununderline}`;

  const history = useHistory();

  useEffect(() => {
    getData(config.data, pageParam, setNum);
    if (config.data.dataSource === 'api' && config.data.requestSec > 0) {
      const interval = setInterval(() => {
        getData(config.data, pageParam, setNum);
      }, config.data.requestSec * 1000);
      // 组件卸载时清除定时器
      return () => {
        clearInterval(interval);
      }
    }
  }, [config, pageParam])

  useEffect(() => {
    setAnimation({
      Children: {
        value: typeof num === 'number' ? num : 0,
        floatLength: config.decimal ? 2 : 0,
        formatMoney: config.thousand ? { thousand: ',', decimal: '.' } : false,
      },
      duration: 1000,
    })
  }, [num]);

  const getStyle = () => {
    let style = {
      width: config.width,
      height: config.height,
      left: config.left,
      top: config.top,
      fontSize: config.fontSize,
      fontFamily: config.fontFamily.replace(/([^,]+)/g, "'$1'"),  //font1,font2=>'font1','font2'
      color: config.color,
      fontWeight: config.fontWeight ? "bold" : "normal",
      display: "flex",
      justifyContent: config?.justifyContent || "center",
      alignItems: config?.alignItems || "center",
      alignItems: "center",
      overflow: "hidden",
      position: "absolute",
    };

    //渐变颜色处理
    if (!_.isEmpty(config?.gradualColor)) {
      style = {
        ...style,
        backgroundImage: config.gradualColor,
        color: 'transparent',
        WebkitBackgroundClip: 'text',
      }
    }
    if (editComponentKey === config.key) {
      style.border = selectBorderStyle;
    }

    return style;
  }

  return (
    <div className={className}
      style={getStyle()}
      onClick={() => {
        //编辑模式不触发点击事件，否则影响页面编辑操作
        if (editMode === 1) {
          return;
        }
        switch (config?.linkType) {
          case '_self':
            history.push(dealParam(config?.url, pageParam));
            break;
          case '_blank':
            window.open(dealParam(config?.url, pageParam), '_blank');
            break;
          case '_back':
            history.goBack();
            break;

          default:
            break;
        }
      }}>
      {config.prefix}
      <TweenOne
        animation={animation}>
        0
      </TweenOne>
      {config.suffix}
    </div>
  )
};


export default DatavNumber;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\atriclelistedit\index.tsx
 * @LastEditTime: 2023-01-07 20:10:10
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, InputNumber, Select, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import { Directions, DisplayTypes, SubItemDirections } from 'components/articlelist';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
  articleGroups: any; //文章分组
}

export const defaultValue = () => ({
  type: 'customarticlelist',
  key: NewGuid(),
  title: '文章列表(自)',
  articlegroupid: '',
  subitemcount: 5,
})

const CustomArticleListEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange, articleGroups } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])


  const handleOK = () => {    
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑文章列表(自)'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >

        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true }]}
          tooltip={{
            placement: "right",
            title: <div>
              <div>{"子元素属性支持包含内容格式为：${xxx}"}</div>
              <div>{"支持： url(链接地址) coverImage(缩略图) title(标题) index(顺序号) id"}</div>
            </div>, icon: <InfoCircleOutlined />
          }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="articlegroupid"
          label="文章分类"
          rules={[{ required: true }]}
        >
          <Select options={[{ value: 'outid', label: '外部id' }].concat(
            articleGroups.map((item: any) => {
              return {
                value: item.id,
                label: item.title
              }
            })
          )} />
        </Form.Item>
        <Form.Item
          name="subitemcount"
          label="展示数量"
          rules={[{ required: true }]}
        >
          <InputNumber min={1} />
        </Form.Item>
      </Form>
    </Modal>
  )
};

export default CustomArticleListEdit;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2024-03-08 17:36:07
 * @FilePath: \website\src\components\datavpicture\index.jsx
 * @LastEditTime: 2024-06-04 14:28:45
 */

import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from "react-redux";
import { dealParam } from 'utils/common';
import { selectBorderStyle } from 'utils/componentdefine';
import styles from './index.module.scss';


export type DatavPictureConfig = {
  type: String;  //类型 datavpicture
  key: String; //主键
  backGroundImageUrl: String; //背景图
  opacity?: Number;  //透明度
  imageScale: String, //图片缩放
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
  url?: String;  //外链地址  
  linkType: String;  //跳转方式
  animationType: String; //动效
  animationDuration: String, //动效时间
  animationDelay: String,  //动效延迟
}

interface IProps {
  config: DatavPictureConfig;
}

//背景框
const DatavPicture: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const { config } = props;

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  const editMode = useSelector((state: any) => state.global.editMode);
  const history = useHistory();

  const keyframes = `@keyframes rotateAnimation {
    0% {
      transform: translateX(-50%) rotateX(75deg) rotateZ(0);
    }
    100% {
      transform: translateX(-50%) rotateX(75deg) rotateZ(360deg);
    }
  }`;

  //动态添加动画示例代码
  useEffect(() => {
    // const style = document.createElement('style');
    // style.type = 'text/css';
    // style.innerHTML = keyframes;
    // document.head.appendChild(style);
  }, []);


  let style = {
    width: config.width,
    height: config.height,
    left: config.left,
    top: config.top,
    // background: config?.backGroundColor || "transparent",
    opacity: config.opacity || 1,
    backgroundImage: `url("${config.backGroundImageUrl}")`,
    backgroundSize: config?.imageScale || "cover",
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    // backgroundSize: "100% 100%",


    // WebkitMaskImage: 'none',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    position: "absolute",

    animationDuration: config?.animationDuration || '', //动效时间
    animationDelay: config?.animationDelay || '',  //动效延迟
  };

  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }


  return (
    <div
      className={styles[config?.animationType || '']}
      style={style}
      onClick={() => {
        //编辑模式不触发点击事件，否则影响页面编辑操作
        if (editMode === 1) {
          return;
        }
        switch (config?.linkType) {
          case '_self':
            history.push(dealParam(config?.url, pageParam));
            break;
          case '_blank':
            window.open(dealParam(config?.url, pageParam), '_blank');
            break;
          case '_back':
            history.goBack();
            break;

          default:
            break;
        }
      }}>
    </div>
  )

};

export default DatavPicture;
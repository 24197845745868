/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\common\datasourceinput\index.tsx
 * @LastEditTime: 2024-08-23 09:44:42
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { DatavDataSource } from 'utils/componentdefine';
import _ from 'lodash';
import styles from './index.module.scss';
import DataSourceInput from "components/common/datasourceinput";
import { JustifyContents } from "components/container";

interface IProps {
  value?: DatavDataSource[];
  onChange?: (value: DatavDataSource[]) => void;
}


//多行数据源录入
const MultiDataSourceInput: React.FC<IProps> = (props: IProps) => {
  const { value, onChange } = props;

  const handleAdd = () => {
    let tempvalue: any = _.cloneDeep(value);
    tempvalue.push({
      dataSource: 'api', //数据源类型
      staticData: '',
      requestUrl: '', //api地址
      requestType: 'GET', //请求方式
      headers: {}, //请求头
      body: {}, //请求参数
      filters: 'return res;', //数据过滤器
      requestSec: 0, //多少秒一次请求，为0表示只请求1次
    });
    onChange && onChange(tempvalue);
  }

  const handleEdit = (e: DatavDataSource, index: number) => {
    let tempvalue: any = _.cloneDeep(value);
    tempvalue[index] = e;
    onChange && onChange(tempvalue);
  }

  const handleDel = (index: number) => {
    let tempvalue: any = _.cloneDeep(value);
    tempvalue.splice(index, 1);
    onChange && onChange(tempvalue);
  }

  return (
    <div className={styles.container}>
      <div className={styles.row} style={{ justifyContent: "flex-end" }}>
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd} />
      </div>
      {(value || []).map((item, index) => (
        <div className={styles.row}>
          <DataSourceInput
            key={`datasource${index}`}
            optionfilter='api'
            value={item}
            onChange={(e) => {
              handleEdit(e, index)
            }} />
          <Button
            type="primary"
            icon={<MinusOutlined />}
            onClick={() => {
              handleDel(index);
            }} />
        </div>
      ))}
    </div>
  );
};

export default MultiDataSourceInput;
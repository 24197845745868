/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\htmlview\index.jsx
 * @LastEditTime: 2023-03-06 16:36:52
 */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { Menu } from 'antd';
import _ from 'lodash';
import styles from './index.module.scss';

//内容来源
export const HtmlSources = [{
  id: 0,
  title: '外部id',
  setting: 'outid',
}, {
  id: 1,
  title: '内容',
  setting: 'content',
}]

export type HtmlViewConfig = {
  type: String;  //类型 articlelist
  key: String; //主键
  title: String; //标题
  width: String; 
  height: String;
  htmlsource: String;  //来源
  content: String; //内容
}


interface IProps {
  config: HtmlViewConfig;
  getArticleContent: (articleid: String) => any;
}

//html编辑
const HtmlView: React.FC<IProps> = (props: IProps) => {
  const { config, getArticleContent } = props;
  const [html,setHtml]= useState('');

  const urlSearch = new URLSearchParams(useLocation().search);
  const articleid = urlSearch.get('articleid') || '';

  useEffect(async ()=>{  
    if (config.htmlsource==='content'){
      setHtml(config.content);
    } else {
      if (articleid!==''){
        const temparticle= await getArticleContent(articleid);
        setHtml(temparticle);
      }
    }
  },[config])

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = {
    width: config.width,
    height: config.height,
  };
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }


  return (
    <div style={style}>
      <span dangerouslySetInnerHTML={{
        __html: html
      }}
      />
    </div>
  )
};

export default HtmlView;
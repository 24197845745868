/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-07-29 13:44:47
 * @FilePath: \website\src\store\index.ts
 * @LastEditTime: 2022-12-30 10:25:05
 */
// 引入
import { configureStore } from '@reduxjs/toolkit'
import counterReducer from "./slices/counterSlice";
import globalReducer from './slices/globalSlice';
// import questiontypesReducer from './slices/questiontypesSlice';

export default configureStore({
  reducer: {
    rootCounter: counterReducer,
    global: globalReducer,
    // questiontypes: questiontypesReducer,
  }
})
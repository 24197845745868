/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\atriclelistedit\index.tsx
 * @LastEditTime: 2023-01-07 20:10:10
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, InputNumber, Select, Radio, Switch, Tabs, AutoComplete, Button, message } from 'antd';
import { Directions, DisplayTypes, SubItemDirections } from 'components/articlelist';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
  articleGroups: any; //文章分组
}

export const defaultValue = () => ({
  type: 'articlelist',
  key: NewGuid(),
  title: '文章列表',
  articlegroupid: '',
  width: '100%',
  height: 'auto',
  direction: 'column',   //方向
  moretitle: '更多。。',
  displaytype: 'text', //展示方式 图 文 图加文
  imagewidth: 'auto',
  imageheight: 'auto',
  textwidth: '90%',
  textheight: 'auto',
  subitemcount: 5,
  subitemdirection: 'row-reverse',
})

const ArticleListEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange, articleGroups } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑文章列表'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">

            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="articlegroupid"
              label="文章分类"
              rules={[{ required: true }]}
            >
              <Select options={[{ value: 'outid', label: '外部id' }].concat(
                articleGroups.map((item: any) => {
                  return {
                    value: item.id,
                    label: item.title
                  }
                })
              )} />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="direction"
              label="方向"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {Directions.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="moretitle"
              label="更多描述"
            >
              <Input />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-2">
            <Form.Item
              name="displaytype"
              label="展示方式"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {DisplayTypes.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="imagewidth"
              label="图宽"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="imageheight"
              label="图高"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="textwidth"
              label="文宽"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="textheight"
              label="文高"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="subitemcount"
              label="展示数量"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} />
            </Form.Item>
            <Form.Item
              name="subitemdirection"
              label="图文排列方式"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {SubItemDirections.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>


      </Form>
    </Modal>
  )
};

export default ArticleListEdit;
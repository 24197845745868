
import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { selectBorderStyle, DatavDataSource } from 'utils/componentdefine';
import { executeFunctionFilter, dealParam, dealParamObj,getData } from 'utils/common';
import ActiveRingChart from '@jiaminghi/data-view-react/es/activeRingChart';
import { reqAgencyRequest } from 'api/api';
import styles from './index.module.scss';


export type DatavActiveRingChartConfig = {
  type: String;  //类型 datavactiveringchart
  key: String; //主键
  data: DatavDataSource; //数据源 [{name:'',value: 23}]
  showOriginValue: Boolean; //显示原始值
  customConfig: Object; //自定义配置
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
}

interface IProps {
  config: DatavActiveRingChartConfig;
}

//动态环图
const DatavActiveRingChart: React.FC<IProps> = (props: IProps) => {
  const { config } = props;
  const [data, setData] = useState([]);
  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  const pageParam = useSelector((state: any) => state.global.pageParam);

  useEffect(() => {
    getData(config.data, pageParam, setData);
    if (config.data.dataSource === 'api' && config.data.requestSec > 0) {
      const interval = setInterval(() => {
        getData(config.data, pageParam, setData);
      }, config.data.requestSec * 1000);
      // 组件卸载时清除定时器
      return () => {
        clearInterval(interval);
      }
    }
  }, [config])

  const getStyle = () => {
    let style = {
      width: config.width,
      height: config.height,
      left: config.left,
      top: config.top,
      position: "absolute",
    };
    if (editComponentKey === config.key) {
      style.border = selectBorderStyle;
    }
    return style;
  }

  const getChartConfig = () => {
    let chartconfig = {
      data: data,
      showOriginValue: config.showOriginValue,
      radius: '75%',
      activeRadius: '85%',
    }

    chartconfig = {
      ...chartconfig,
      ...config.customConfig,
    }
    return chartconfig;
  }

  

  return (
    <div className={styles.container}
      style={getStyle()}>
      <ActiveRingChart
        style={{ width: "100%", height: "100%" }}
        config={{
          ...getChartConfig()
        }}
      />
    </div>

  )

};

export default DatavActiveRingChart;
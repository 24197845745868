/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\customtextedit\index.tsx
 * @LastEditTime: 2023-03-06 10:56:34
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Button, Select, Radio, Switch, Tabs, AutoComplete, InputNumber, message } from 'antd';
import { TimeFormats, fontSizes } from 'utils/componentdefine';
import FontInput from 'components/common/fontinput';
import ColorInput from 'components/common/colorinput';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'datavtimer',
  key: NewGuid(),
  title: '时间器',
  format: 'YYYY-MM-DD HH:mm:ss', //时间格式
  width: 600,
  height: 100,
  left: 0,
  top: 0,
  fontSize: '40px',
  fontFamily: '微软雅黑',
  color: 'white',
  gradualColor: '',
  fontWeight: false,
  intalic: false,
  underline: false,
})

const DatavTimerEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑标题'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="format"
              label="格式"
              rules={[{ required: true }]}
            >
              <AutoComplete options={TimeFormats} />
            </Form.Item>
            <Form.Item
              name="left"
              label="左"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="top"
              label="上"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-2">
            <Form.Item
              name="fontSize"
              label="字体大小"
              rules={[{ required: true }]}
            >
              <AutoComplete options={fontSizes} />
            </Form.Item>
            <Form.Item
              name="fontFamily"
              label="字体"
              rules={[{ required: true }]}
            >
              <FontInput />
            </Form.Item>
            <Form.Item
              name="color"
              label="颜色"
              rules={[{ required: true }]}
            >
              {/* <AutoComplete options={[{ value: 'black' }, { value: 'white' }]} /> */}
              <ColorInput customColors={['inherit']} />
            </Form.Item>
            <Form.Item
              name="gradualColor"
              label="渐变颜色"
              tooltip="填入样式为：linear-gradient(to right, red, blue)"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="fontWeight"
              label="粗体"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="intalic"
              label="斜体"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="underline"
              label="下划线"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  )
};

export default DatavTimerEdit;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\customvideo\index.jsx
 * @LastEditTime: 2024-07-26 11:42:17
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from 'antd';
import { selectBorderStyle } from 'utils/componentdefine';
import { dealParam } from 'utils/common';
import _, { isNil, isNull } from 'lodash';
import styles from './index.module.scss';

//跳转方式
export const LinkTypes = [{
  id: 0,
  title: '不跳转',
  setting: 'none',
}, {
  id: 1,
  title: '页面跳转',
  setting: '_self',
}, {
  id: 2,
  title: '弹窗跳转',
  setting: '_blank',
}, {
  id: 3,
  title: '放大视频',
  setting: 'preview',
},]

//定位方式
export const Positions = [{
  id: 0,
  title: '默认',
  setting: 'static',
}, {
  id: 1,
  title: '绝对位置',
  setting: 'absolute',
},]

export type CustomVideoConfig = {
  type: String;  //类型 customvideo
  key: String; //主键
  title: String; //标题
  width?: String; //宽度
  height?: String;  //高度 
  aspectRatio?: String; //宽高比
  controls?: Boolean; //控制条
  muted?: Boolean; //静音
  loop?: Boolean; //循环
  autoPlay?: Boolean; //自动播放
  margin?: String;  //外边距
  borderradius?: String; //圆角
  position?: String; //定位方式
  left?: String; //左 
  top?: String;  //上
  right?: String;  //右
  bottom?: String;  //下
  src: String; //视频地址
  url?: String;  //外链地址
  linkType: String;  //跳转方式
}

interface IProps {
  config: CustomVideoConfig;
  subParam?: any; //父组件传给子组件的参数
}

//视频
const CustomVideo: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  let { config, subParam } = props;
  subParam = {
    ...pageParam,
    ...subParam,
  }

  const [visible, setVisible] = useState(false);

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = {
    width: config.width,
    height: config.height,
    aspectRatio: config?.aspectRatio || "auto",
    margin: config.margin,
    borderradius: config.borderradius,
    position: config.position,
    left: config?.left || "auto",
    top: config?.top || "auto",
    right: config?.right || "auto",
    bottom: config?.bottom || "auto",
  };
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  const getPreview = () => {
    if (config.linkType === 'preview') {
      return (
        <Modal
          width={1200}
          destroyOnClose
          open={visible}
          onOk={() => { setVisible(false) }}
          onCancel={() => { setVisible(false) }}
          footer={[]}>
          <video style={{ width: '100%', maxHeight: '600px' }}
            // poster={playItem.keyframe}
            controls='controls'
            autoPlay
            // muted
            src={dealParam(config.src, subParam)}>
          </video>
        </Modal>
      )
    } else {
      return ''
    }
  }

  const getVideo = () => {
    return (
      <>
        <video
          style={style}
          controls={config?.controls}
          muted={isNil(config?.muted) ? true : config?.muted}
          loop={isNil(config?.loop) ? true : config?.loop}
          autoPlay={isNil(config?.autoPlay) ? true : config?.autoPlay}
          src={dealParam(config.src, subParam)}
          onClick={() => {
            if (config.linkType === 'preview') {
              setVisible(true);
            }
          }}
        />
        {getPreview()}
      </>
    )
  }

  //处理自定义工作区问题
  let linkUrl = dealParam(config.url, subParam);
  let strs = linkUrl.split("&target=");
  linkUrl = strs[0];
  let linkType;
  if (strs.length > 1) {
    linkType = strs[1];
  }

  return (config.linkType === 'none' || config.linkType === 'preview') ? getVideo() : (
    <a
      href={linkUrl}
      target={linkType || config.linkType}>
      {getVideo()}
    </a>
  )

};

export default CustomVideo;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\customvideo\index.jsx
 * @LastEditTime: 2023-01-18 11:21:59
 */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { dealParam } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';
import { setEditComponentKey } from "store/slices/globalSlice";

export type DatavVideoConfig = {
  type: String;  //类型 datavscrollrankingboard
  key: String; //主键
  src: String;  //视频地址
  srclist: String[]; //多地址 多地址有内容以多地址为准，否则以单地址为准
  controls?: Boolean; //控制条
  muted?: Boolean; //静音
  loop?: Boolean; //循环
  autoPlay?: Boolean; //自动播放
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
}

interface IProps {
  config: DatavVideoConfig;
}

//datav视频
const DatavVideo: React.FC<IProps> = (props: IProps) => {
  const { config } = props;
  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  const [currentSourceIndex, setCurrentSourceIndex] = useState(0);

  const videoSources = (config?.srclist || []).length === 0 ? [config.src] : config.srclist;

  
  const videoRef = useRef(null);

  useEffect(() => {
    //如果地址列表数量超过1则一个视频播完播放下一个
    if (videoSources.length > 1) {
      const video = videoRef.current;
      const handleVideoEnded = () => {
        setCurrentSourceIndex(prevIndex => (prevIndex + 1) % videoSources.length);
      };

      video.addEventListener('ended', handleVideoEnded);
      return () => {
        video.removeEventListener('ended', handleVideoEnded);
      };
    }
  }, [config]);

  const getStyle = () => {
    let style = {
      width: config.width,
      height: config.height,
      left: config.left,
      top: config.top,
      position: "absolute",
    };
    if (editComponentKey === config.key) {
      style.border = selectBorderStyle;
    }
    return style;
  }

  return (
    <video
      ref={videoRef}
      style={getStyle()}
      controls={config?.controls}
      muted={config?.muted}
      type={config?.srctype}
      //只有单地址loop才生效，多地址自动为false，否则无法触发一个视频播完播下一个视频事件
      loop={videoSources.length===1 ? config?.loop: false}  
      autoPlay={config?.autoPlay}
      src={ videoSources[currentSourceIndex]}
    />
  )

};

export default DatavVideo;
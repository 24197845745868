
import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { selectBorderStyle, DatavDataSource } from 'utils/componentdefine';
import { executeFunctionFilter, dealParam, dealParamObj, getData } from 'utils/common';
import { Column } from '@ant-design/charts';
import { reqAgencyRequest } from 'api/api';
import styles from './index.module.scss';


export type DatavColumnChartConfig = {
  type: String;  //类型 datavtext
  key: String; //主键
  data: DatavDataSource; //数据源
  xField: String; //横坐标字段
  yField: String; //纵坐标字段
  seriesField: String; //分组坐标字段 可为空
  groupField: String; //二级分组字段 可为空
  isGroup: Boolean;  //是否分组
  isStack: Boolean;  //是否堆叠
  // chartType: String; //类型：stack堆叠 group分组
  isPercent: Boolean; //百分比
  customConfig: Object; //自定义配置
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
}

interface IProps {
  config: DatavColumnChartConfig;
}

//柱状图
const DatavColumnChart: React.FC<IProps> = (props: IProps) => {
  const { config } = props;
  const [data, setData] = useState([]);
  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  const pageParam = useSelector((state: any) => state.global.pageParam);

  useEffect(() => {
    getData(config.data, pageParam, setData);
    if (config.data.dataSource === 'api' && config.data.requestSec > 0) {
      const interval = setInterval(() => {
        getData(config.data, pageParam, setData);
      }, config.data.requestSec * 1000);
      // 组件卸载时清除定时器
      return () => {
        clearInterval(interval);
      }
    }
  }, [config])

  const getStyle = () => {
    let style = {
      width: config.width,
      height: config.height,
      left: config.left,
      top: config.top,
      position: "absolute",
    };
    if (editComponentKey === config.key) {
      style.border = selectBorderStyle;
    }
    return style;
  }

  const getChartConfig = () => {
    let chartconfig = {
      data: data,
      xField: config.xField,
      yField: config.yField,
      seriesField: config.seriesField,
      groupField: config.groupField,
      // theme: 'dark',
    }
    if (config.isStack) {
      chartconfig.isStack = true;
    }
    if (config.isGroup) {
      chartconfig.isGroup = true;
    }

    if (config.isPercent) {
      chartconfig.isPercent = true;
    }

    chartconfig = {
      ...chartconfig,
      ...config.customConfig,
    }
    return chartconfig;
  }

  return (
    <Column
      style={getStyle()}
      {...getChartConfig()}
    />
  )

};

export default DatavColumnChart;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\customtextedit\index.tsx
 * @LastEditTime: 2023-03-06 10:56:34
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Button, Select, Radio, Switch, Tabs, AutoComplete, InputNumber, message } from 'antd';
import { NewGuid } from 'utils/common';
import { borderBoxTypes } from 'utils/componentdefine';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'datavborderbox',
  key: NewGuid(),
  title: '边框',
  borderBoxType: 'BorderBox1',
  width: 800,
  height: 300,
  left: 0,
  top: 0,
})

const DatavBorderBoxEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);
  const [borderBoxDisPlay, setBorderBoxDisPlay] = useState<any>(<div />);

  useEffect(() => {

  }, [])

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
      setBorderBoxDisPlay(borderBoxTypes.find(item => item.value === tempvalue.borderBoxType)?.display);
    }
  }, [visible])

  const handleOK = () => {    
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑边框'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Form.Item
          name="title"
          label="标题"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="borderBoxType"
          label="类型"
          rules={[{ required: true }]}
        >
          <Select style={{ width: '100px' }} onChange={e => {
            setBorderBoxDisPlay(borderBoxTypes.find(item => item.value === e)?.display);
          }}>
            {
              borderBoxTypes.map((item: any, index: number) => {
                return (
                  <Select.Option key={`option${index}`} value={item.value} label={item.label}>
                    <span>{item.label}</span>
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          name="left"
          label="左"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="top"
          label="上"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="width"
          label="宽度"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item
          name="height"
          label="高度"
          rules={[{ required: true }]}
        >
          <InputNumber />
        </Form.Item>
      </Form>
      <div style={{ width: '100%', height: '100px', display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '70%', height: '100%', backgroundColor: 'black' }}>
            {borderBoxDisPlay}
          </div>
        </div>
    </Modal>
  )
};

export default DatavBorderBoxEdit;
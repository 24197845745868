/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\custombutton\index.jsx
 * @LastEditTime: 2024-07-26 12:23:08
 */
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from "react-redux";
import { Button, Popover } from 'antd';
import * as Icon from '@ant-design/icons';
import { getViewFromConfig } from '../pageview';
import { getIcons, icontoElement, selectBorderStyle } from 'utils/componentdefine';
import { isColor, dealParam } from 'utils/common';
import Color from 'color';
import styles from './index.module.scss';

//跳转方式
export const LinkTypes = [{
  id: 1,
  title: '页面跳转',
  setting: '_self',
}, {
  id: 2,
  title: '弹窗跳转',
  setting: '_blank',
}, {
  id: 3,
  title: '页面返回',
  setting: '_back',
},]

export const ButtonTypes = [{
  id: 0,
  title: '默认',
  setting: 'default',
}, {
  id: 1,
  title: '实体',
  setting: 'primary',
}, {
  id: 2,
  title: '链接',  //可以变色的按钮
  setting: 'link',
}, {
  id: 3,
  title: '文字',
  setting: 'text',
}, {
  id: 4,
  title: '透明',
  setting: 'ghost',
},]


export const ButtonShapes = [{
  id: 0,
  title: '默认',
  setting: 'default',
}, {
  id: 1,
  title: '圆形',
  setting: 'circle',
}, {
  id: 2,
  title: '圆角',
  setting: 'round',
},]

export const ButtonSizes = [{
  id: 0,
  title: '大',
  setting: 'large',
}, {
  id: 1,
  title: '中',
  setting: 'middle',
}, {
  id: 2,
  title: '小',
  setting: 'small',
},]

export type CustomButtonConfig = {
  type: String;  //类型 container
  key: String; //主键
  title: String; //标题 
  text: String; //内容
  buttonType: String; //类型
  size: String; //大小
  shape: String; //形状
  color: String; //字色
  fontFamily: String; //字体
  fontWeight: Boolean; //粗体
  intalic: Boolean; //斜体
  underline: Boolean; //下划线
  backGroundColor: String; //背景色
  icon: String; //图标
  url?: String;  //外链地址  
  linkType: String;  //跳转方式
  children?: any[];  //子配置  这里的children包裹在<Popover></Popover>组件中
}

interface IProps {
  config: CustomButtonConfig;
  subParam?: any; //父组件传给子组件的参数
}

//按钮
const CustomButton: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  let { config, subParam } = props;
  subParam = {
    ...pageParam,
    ...subParam,
  }
  const [popoverVisible, setPopoverVisible] = useState(false);

  const history = useHistory();

  const getClassName = () => {
    let className = `${config.intalic ? styles.italic : styles.unitalic} ${config.underline ? styles.underline : styles.ununderline}`;

    if (config.buttonType === 'ghost') {
      className = className + ' ' + styles.ghost;
    }
    if (config.buttonType === 'primary' && isColor(config?.backGroundColor)) {
      className = className + ' ' + styles.primary;
    }
    return className;
  }

  const getStyles = () => {
    const fontStyles = {};
    if (config?.fontFamily) {
      fontStyles.fontFamily = config.fontFamily.replace(/([^,]+)/g, "'$1'")
    }
    if (config?.fontWeight) {
      fontStyles.fontWeight = config.fontWeight ? "bold" : "normal"
    }


    if (config.buttonType === 'primary' && isColor(config?.backGroundColor)) {
      return {
        ...fontStyles,
        color: config?.color || '',
        '--backgroudcolor': Color(config.backGroundColor),
        '--hoverbackgroudcolor': Color(config.backGroundColor).lighten(0.25),
      }
    }
    //link 鼠标移动时字体颜色用backGroundColor 来替代
    if (config.buttonType === 'link' && isColor(config?.color) && isColor(config?.backGroundColor)) {
      return {
        ...fontStyles,
        '--color': Color(config.color),
        '--hovercolor': Color(config.backGroundColor),
      }
    }
    return {
      ...fontStyles,
      color: config?.color || '',
    };
  }

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = getStyles();
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  const getButton = () => {
    //处理自定义工作区问题
    let linkUrl = dealParam(config.url, subParam);
    let strs = linkUrl.split("&target=");
    linkUrl = strs[0];
    let linkType;
    if (strs.length > 1) {
      linkType = strs[1];
    }

    return (
      <Button
        className={getClassName()}
        style={style}
        shape={config.shape}
        size={config.size}
        type={config.buttonType}
        icon={icontoElement(config.icon)}
        href={config.url === "" ? undefined : linkUrl}
        target={config.url === "" ? undefined : (linkType || config.linkType)}
        onClick={() => {
          if (config.linkType === '_back') {
            history.goBack();
          }
        }}
      >
        {dealParam(config.text, subParam)}
      </Button>
    )
  }

  if (_.isNil(config?.children) || config.children.length === 0) {
    return getButton();
  }

  return (
    <Popover
      showArrow={false}
      placement="bottom"
      overlayClassName={styles.overlay}
      content={config.children.length === 1 ? getViewFromConfig(config.children, true, subParam) :
        (<div>{getViewFromConfig(config.children, true, subParam)}</div>)}
    >
      {getButton()}
    </Popover>
  )
};

export default CustomButton;
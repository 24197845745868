
export const CubeColors = ["gray", "yellow", "white", "blue", "red", "green", "orange"];

export const getPllColor = (index: number) => {
  return CubeColors[(index + 3) % 7];
}

export const BaseCubeData = [
  [1, 1, 1, 1, 1, 1, 1, 1, 1],
  [2, 2, 2, 2, 2, 2, 2, 2, 2],
  [3, 3, 3, 3, 3, 3, 3, 3, 3],
  [4, 4, 4, 4, 4, 4, 4, 4, 4],
  [5, 5, 5, 5, 5, 5, 5, 5, 5],
  [6, 6, 6, 6, 6, 6, 6, 6, 6],
]

export const PllDatas = [{
  title: 'Ua',
  index: 1,
  data: [
    [2, 2, 2],
    [3, 0, 3],
    [0, 1, 0],
    [1, 3, 1]
  ]
}, {
  title: 'Ub',
  index: 2,
  data: [
    [2, 2, 2],
    [3, 1, 3],
    [0, 3, 0],
    [1, 0, 1]
  ]
}, {
  title: 'H',
  index: 3,
  data: [
    [2, 0, 2],
    [3, 1, 3],
    [0, 2, 0],
    [1, 3, 1]
  ]
}, {
  title: 'Z',
  index: 4,
  data: [
    [1, 0, 1],
    [2, 3, 2],
    [3, 2, 3],
    [0, 1, 0]
  ]
}, {
  title: 'Aa',
  index: 5,
  data: [
    [2, 2, 0],
    [1, 3, 2],
    [3, 0, 3],
    [0, 1, 1]
  ]
}, {
  title: 'Ab',
  index: 6,
  data: [
    [2, 2, 3],
    [0, 3, 0],
    [1, 0, 2],
    [3, 1, 1]
  ]
}, {
  title: 'E',
  index: 7,
  data: [
    [1, 2, 3],
    [0, 3, 2],
    [3, 0, 1],
    [2, 1, 0]
  ]
}, {
  title: 'T',
  index: 8,
  data: [
    [2, 2, 3],
    [0, 1, 2],
    [3, 0, 0],
    [1, 3, 1]
  ]
}, {
  title: 'F',
  index: 9,
  data: [
    [2, 0, 3],
    [0, 3, 2],
    [3, 2, 0],
    [1, 1, 1]
  ]
}, {
  title: 'V',
  index: 10,
  data: [
    [2, 2, 0],
    [1, 0, 3],
    [0, 3, 2],
    [3, 1, 1]
  ]
}, {
  title: 'Y',
  index: 11,
  data: [
    [2, 2, 0],
    [1, 3, 3],
    [0, 1, 2],
    [3, 0, 1]
  ]
}, {
  title: 'Ja',
  index: 12,
  data: [
    [3, 3, 0],
    [1, 1, 1],
    [2, 2, 3],
    [0, 0, 2]
  ]
}, {
  title: 'Jb',
  index: 13,
  data: [
    [1, 2, 2],
    [3, 1, 1],
    [2, 3, 3],
    [0, 0, 0]
  ]
}, {
  title: 'Rb',
  index: 14,
  data: [
    [1, 2, 1],
    [2, 1, 3],
    [0, 3, 2],
    [3, 0, 0]
  ]
}, {
  title: 'Ra',
  index: 15,
  data: [
    [1, 1, 2],
    [3, 2, 1],
    [2, 0, 3],
    [0, 3, 0]
  ]
}, {
  title: 'Gc',
  index: 16,
  data: [
    [2, 0, 3],
    [0, 1, 2],
    [3, 3, 0],
    [1, 2, 1]
  ]
}, {
  title: 'Gd',
  index: 17,
  data: [
    [2, 1, 3],
    [0, 0, 2],
    [3, 2, 0],
    [1, 3, 1]
  ]
}, {
  title: 'Ga',
  index: 18,
  data: [
    [2, 3, 3],
    [0, 1, 2],
    [3, 2, 0],
    [1, 0, 1]
  ]
}, {
  title: 'Gb',
  index: 19,
  data: [
    [2, 0, 3],
    [0, 2, 2],
    [3, 1, 0],
    [1, 3, 1]
  ]
}, {
  title: 'Na',
  index: 20,
  data: [
    [0, 2, 2],
    [3, 1, 1],
    [2, 0, 0],
    [1, 3, 3]
  ]
}, {
  title: 'Nb',
  index: 21,
  data: [
    [2, 2, 0],
    [1, 1, 3],
    [0, 0, 2],
    [3, 3, 1]
  ]
},
]
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\empty\index.jsx
 * @LastEditTime: 2024-08-29 17:08:26
 */
// import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Tooltip, Modal, Form, Input, Select, Avatar, Menu, Carousel, Image, Button,
  ConfigProvider, Popover, Statistic, InputNumber, Checkbox, Dropdown
} from 'antd';
import { RightCircleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import _, { constant } from 'lodash';
import UEditor, { getContent, setContent } from "components/ueditor/Ueditor";
import { SketchPicker } from 'react-color';
import type { MenuProps } from 'antd';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import { OverPack, Element, Parallax } from 'rc-scroll-anim';
import Texty, { ITextyMode, ITextyType } from 'rc-texty';
import { Player } from 'video-react';
import * as Icon from '@ant-design/icons';
import { reqGetSignature, reqGetWorkSpaces } from 'api/api';
import ImageInput from 'components/common/imageinput';
import { templateRender, parseParam, urlEncode, dealParam, dealDateParam } from 'utils/common';
import styles from './index.module.scss';
import { AlignItems } from "components/container";
import { ajax, post } from 'api/ajax';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import Slider from "react-slick";
import { testData, lineData } from './data';
import { Column, Line } from '@ant-design/charts';
import { NewGuid, screenShotAndSaveAliOss } from 'utils/common';
import html2canvas from 'html2canvas';
import moment from "moment";
import BaseCube from './cube/basecube';
import PllCube from './cube/pllcube';
import { setPageParam,setBaseParam,setUrlParam,setApiParam } from "store/slices/globalSlice";
// import ReactPlayer from 'react-player';

// var TweenOneGroup = TweenOne.TweenOneGroup;
const { TextArea } = Input;
const { Countdown } = Statistic;


const Empty = () => {
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(200);
  const [parentHeight, setParentHeight] = useState(200);
  const pageParam = useSelector((state: any) => state.global.pageParam);


  useEffect(() => {
    // const interval = setInterval(() => {
    //   setry(prev => (prev + 1) % 356);
    // }, 100);

    // return () => clearInterval(interval); // 清除定时器
  }, []);



  return (
    <div className={styles.container}>
      <div className={styles.child}>
        {JSON.stringify(pageParam, null, 2)}
        <Button onClick={() => {
          dispatch(setBaseParam({ "a": 2 }));
        }}>
          test
        </Button>
        <Button onClick={() => {
          dispatch(setApiParam({ "a": 3,"b":5 }));
        }}>
          test1
        </Button>
      </div>
    </div>
  )
};

export default Empty;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2023-02-13 17:56:37
 * @FilePath: \website\src\components\customworkspace\index.jsx
 * @LastEditTime: 2024-07-22 18:21:49
 */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { isLogin } from "utils/auth";
import _ from 'lodash';
import { parseParam } from "utils/common";
import { Card } from 'antd';
import { getViewFromConfig } from '../pageview';
import styles from './index.module.scss';


export type CustomWorkSpaceConfig = {
  type: String;  //类型 workspace
  key: String; //主键
  title: String; //标题
  items: String[]; //选择的id
  isNoHeader: Boolean;  //是否无标题框
  children?: any[];  //子配置
}

interface IProps {
  config: CustomWorkSpaceConfig;
}

//自定义工作区
const CustomWorkSpace: React.FC<IProps> = (props: IProps) => {
  const workSpaces = useSelector((state: any) => state.global.workSpaces);
  const organization = useSelector((state: any) => state.global.organization);
  const { config } = props;
  const items = config?.items || [];

  const defaultIconUrl = 'https://images.muzhiyun.cn/static/index/icon/sucaijia.png';

  const loginUrl = `${process.env.REACT_APP_LOGIN_API_URL}/authentication${parseParam({
    redirectnew: window.location.origin,
    organizationId: organization.organizationGuid,
    backgroundImage: '',
  })}&target=_self`;

  const dealNoHeader = (url) => {
    if (config?.isNoHeader) {
      return url.includes("?") ? `${url}&isNoHeader=true` : `${url}?isNoHeader=true`;
    } else {
      return url;
    }
  }

  return workSpaces.filter(workspace => _.includes(items, workspace.id)).map((workspace, index) => {
    //url iconUrl title desc
    const subParam = {
      ...workspace,
      index: index + 1,
      url: dealNoHeader(isLogin() ? workspace.url : loginUrl),
      iconUrl: workspace.iconUrl || defaultIconUrl
    }
    return getViewFromConfig(config.children, true, subParam);
  })
};

export default CustomWorkSpace;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\datavContent\datavpictureedit\index.tsx
 * @LastEditTime: 2024-06-04 14:14:03
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Button, Select, Radio, Switch, Tabs, AutoComplete, InputNumber, message } from 'antd';
import { TimeFormats, fontSizes, ImageScales, LinkTypes, animationTypes } from 'utils/componentdefine';
import FontInput from 'components/common/fontinput';
import ColorInput from 'components/common/colorinput';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';
import ImageInput from "components/common/imageinput";
import { reqGetSignature } from "api/api";

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'datavpicture',
  key: NewGuid(),
  title: '背景图',
  backGroundImageUrl: '', //图片地址
  opacity: 1,  //透明度
  imageScale: 'contain', //图片缩放
  width: 800,
  height: 800,
  left: 0,
  top: 0,
  url: '',
  linkType: 'none',
  animationType: 'none',
  animationDuration: '',
  animationDelay: '',
})

const DatavPictureEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);
  const [imageOSS, setImageOSS] = React.useState<any>({}) //图片oss地址

  useEffect(() => {
    //获取videoOSS地址
    reqGetSignature({ bucketType: 2, system: 'muzhiyun' }).then(res => {
      setImageOSS(res.Data[0]);
    })
  }, [])

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])

  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑背景图'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="backGroundImageUrl"
              label="地址"
              rules={[{ required: true }]}
            >
              <ImageInput dataOSS={imageOSS} />
            </Form.Item>
            <Form.Item
              name="opacity"
              label="透明度"
              tooltip="0-1之间 0表示全透明"
            >
              <InputNumber min={0} max={1} />
              {/* <ColorInput customColors={['transparent']} /> */}
            </Form.Item>
            <Form.Item
              name="imageScale"
              label="图片缩放"
              tooltip="参考网站： https://www.w3school.com.cn/tiy/c.asp?f=css_background-size&p=7"
            >
              <Radio.Group>
                {ImageScales.map(item => (<Radio value={item.setting} key={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="left"
              label="左"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="top"
              label="上"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-2">
            <Form.Item
              name="animationType"
              label="动效"
            >
              <Radio.Group>
                {animationTypes.map(item => (<Radio value={item.value} key={item.value}>{item.label}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="animationDuration"
              label="动效时长"
            >
              <AutoComplete options={[{ value: '20s' }]} />
            </Form.Item>
            <Form.Item
              name="animationDelay"
              label="动效延时"
            >
              <AutoComplete options={[{ value: '0s' }]} />
            </Form.Item>
            <Form.Item
              name="linkType"
              label="跳转方式"
              rules={[{ required: true }]}
              tooltip="编辑模式下不支持跳转，请点击预览，在预览方式下跳转"
            >
              <Radio.Group>
                {LinkTypes.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="url"
              label="跳转地址"
            >
              <Input />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  )
};

export default DatavPictureEdit;
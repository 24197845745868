/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\empty\cube\index.tsx
 * @LastEditTime: 2024-08-27 15:19:06
 */
// import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Tooltip, Modal, Form, Input, Select, Avatar, Menu, Carousel, Image, Button,
  ConfigProvider, Popover, Statistic, InputNumber, Checkbox, Dropdown
} from 'antd';
import { CubeColors } from '../config/cubeconfig'
import styles from './index.module.scss';

//数组6*9 top bottom front right back left  top bottom 从上向下看依次排列 其他从外边看依次排列
const BaseCube = (props: any) => {
  const { tx, ty, tz, rx, ry, rz, cubedata, shownum } = props;

  useEffect(() => {
  }, []);

  return (
    <div
      style={{
        transform: ` rotateX(${rx}deg) rotateY(${ry}deg) rotateZ(${rz}deg) translateX(${tx}px) translateY(${ty}px) translateZ(${tz}px)`
        // transform: `translateX(10px) translateY(0px) translateZ(0px) rotateX(0deg) rotateY(45deg) rotateZ(0deg)`
      }}
      className={styles.cube}>
      <div className={styles.top}
      >
        {cubedata[0].map((item: number, index: number) => (
          <div
            key={`top${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
        {/* <div className={styles.grid} style={{ borderTop: "25px solid green" }} >1</div>
        <div className={styles.grid} >2</div>
        <div className={styles.grid} >3</div>
        <div className={styles.grid} >4</div>
        <div className={styles.grid} >5</div>
        <div className={styles.grid} >6</div>
        <div className={styles.grid} >7</div>
        <div className={styles.grid} >8</div>
        <div className={styles.grid} >9</div> */}
      </div>

      <div className={styles.bottom}>
        {cubedata[1].map((item: number, index: number) => (
          <div
            key={`bottom${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>

      <div className={styles.front}>
        {cubedata[2].map((item: number, index: number) => (
          <div
            key={`front${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>
      <div className={styles.right}>
        {cubedata[3].map((item: number, index: number) => (
          <div
            key={`right${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>
      <div className={styles.back}>
        {cubedata[4].map((item: number, index: number) => (
          <div
            key={`back${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>
      <div className={styles.left}>
        {cubedata[5].map((item: number, index: number) => (
          <div
            key={`top${index}`}
            className={styles.grid}
            style={{ backgroundColor: CubeColors[item % 7] }}>
            {shownum && (index + 1)}
          </div>
        ))}
      </div>

    </div>
  )
};

export default BaseCube;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\pagemenu\index.jsx
 * @LastEditTime: 2023-03-02 17:30:01
 */
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { dealParam } from 'utils/common';
import { selectBorderStyle } from 'utils/componentdefine';
import { Menu } from 'antd';
import _ from 'lodash';
import styles from './index.module.scss';


export type PageMenuConfig = {
  type: String;  //类型 container
  key: String; //主键
  title: String; //标题
  items?: any[];  //菜单配置 //{key: '1',label: '',linkType: 0,linkUrl: 'main',items: []}
  backgroundColor: String; //背景色
  fontSize?: String; //字体大小
  fontFamily?: String; //字体
  color?: String; //字色
  fontWeight?: Boolean; //粗体
  intalic?: Boolean; //斜体
  underline?: Boolean; //下划线
}

interface IProps {
  config: PageMenuConfig;
  subParam?: any; //父组件传给子组件的参数
}

//导航
const PageMenu: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const history = useHistory();
  const pathname = useLocation().pathname;
  let {config, subParam} = props;
  subParam = {
    ...pageParam,
    ...subParam,
  }
  // const [current, setCurrent] = useState(getConfigFromLinkUrl(config.items,pathname)?.key);
  const [current, setCurrent] = useState('');

  // console.log(config);

  useEffect(() => {
    const tempconfig = getConfigFromLinkUrl(config.items, pathname);
    tempconfig && setCurrent(tempconfig.key);
  }, [])


  const getConfigFromKey = (config: any, key: string) => {
    for (let index = 0; index < config.length; index++) {
      const item = config[index];
      if (item.key === key) {
        return item;
      }
      if (item.items) {
        let tempvalue: any = getConfigFromKey(item.items, key);
        if (!_.isNull(tempvalue)) {
          return tempvalue;
        }
      }
    }
    return null;
  }

  const getConfigFromLinkUrl = (config: any, linkUrl: string) => {
    if (!config) {
      return null;
    }
    for (let index = 0; index < config.length; index++) {
      const item = config[index];
      if (item.linkUrl === linkUrl) {
        return item;
      }
      if (item.items) {
        let tempvalue: any = getConfigFromKey(item.items, linkUrl);
        if (!_.isNull(tempvalue)) {
          return tempvalue;
        }
      }
    }
    return null;
  }

  const handleOnClick = e => {
    setCurrent(e.key);
    const currentconfig = getConfigFromKey(config.items, e.key);
    if (currentconfig?.linkType === 1) {
      history.push(currentconfig?.linkUrl)
    }
    else if (currentconfig?.linkType === 2) {
      window.open(currentconfig?.linkUrl);
    }
  }
  const getItems = () => {
    return config?.items.map(item => {
      const mainmenu = {
        label: item?.label,
        key: item?.key,
      }
      if (!_.isNil(item.items)) {
        mainmenu.children = item.items.map(subitem => {
          return {
            label: subitem?.label,
            key: subitem?.key,
          }
        })
      }
      return mainmenu;
    })
  }

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = {
    backgroundColor: config?.backgroundColor,
    color: "inherit",
    border: "0",
    fontSize: config?.fontSize || "inherit",
    fontFamily: config.fontFamily || "inherit",
    color: dealParam(config?.color, subParam) || "inherit",
    fontWeight: config?.fontWeight ? "bold" : "normal",
  };
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  const className = `${config?.intalic ? styles.italic : styles.unitalic} ${config?.underline ? styles.underline : styles.ununderline}`;

  return (
    <Menu
      style={style}
      className={className}
      mode="horizontal"
      selectedKeys={[current]}
      onClick={handleOnClick}
      items={getItems()} />
  )
};

export default PageMenu;
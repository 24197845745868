/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\common\datasourceinput\index.tsx
 * @LastEditTime: 2024-08-23 09:44:42
 */
import React, { useState, useEffect } from "react";
import {
  Button, Input, Tag, Modal, Row, Col, Select, InputNumber,
  Tooltip, message, notification, Divider
} from 'antd';
import { AlignRightOutlined, QuestionCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { showColors, DatavDataSource } from 'utils/componentdefine';
import _ from 'lodash';
import styles from './index.module.scss';
import { checkJSON, dealParam, dealParamObj, executeFunction, executeFunctionFilter } from "utils/common";
import { get } from "api/ajax";
import { reqAgencyRequest, reqGetDatavVersion } from "api/api";
import JsonInput from "../jsoninput";
import { useSelector } from "react-redux";

const { TextArea } = Input;

interface IProps {
  valueType?: 'string' | 'number' | 'object' | 'array';
  value?: DatavDataSource;
  optionfilter?: string; //'static;api'
  onChange?: (value: DatavDataSource) => void;
}

const datasourceoptions = [{
  value: 'static',
  label: '静态数据'
}, {
  value: 'api',
  label: 'api请求'
}, {
  value: 'param',
  label: '参数'
}]

//datav的数据源录入
const DataSourceInput: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const { value, onChange, valueType } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const [editValue, setEditValue] = useState<any>({}); //当前编辑的内容，打开窗口时，需吧对象转为字符串，确认时判断字符串是否为正确对象，成功后才可保存
  const [requestRes, setRequestRes] = useState<any>(''); //测试请求结果
  const [filterData, setFilterData] = useState<any>(''); //测试过滤器结果
  const [paramData, setParamData] = useState<any>(''); //测试参数结果

  const handleOK = () => {
    // if (valueType === 'json' && editValue.dataSource === 'static' &&
    //   (!checkJSON(editValue.staticData))) {
    //   message.error('静态数据不合法');
    //   return;
    // }
    if (!checkJSON(editValue.headers)) {
      message.error('请求头不合法');
      return;
    }
    if (!checkJSON(editValue.body)) {
      message.error('请求参数不合法');
      return;
    }
    onChange && onChange({
      ...editValue,
      headers: JSON.parse(editValue.headers),
      body: JSON.parse(editValue.body),
    })
    setModalVisible(false);
  }

  const handleCancel = () => {
    setModalVisible(false);
  }

  const handleClick = () => {
    setEditValue({
      ...value,
      headers: JSON.stringify(value?.headers, null, 2),
      body: JSON.stringify(value?.body, null, 2),
    });
    setModalVisible(true);
  }

  const changeEditValue = (e: any) => {
    setEditValue({
      ...editValue,
      ...e,
    })
  }

  const handelTestRequest = async () => {
    if (!checkJSON(editValue.headers)) {
      message.warn('请求头格式错误');
      return;
    }
    if (!checkJSON(editValue.body)) {
      message.warn('请求参数错误');
      return;
    }
    const params = {
      "requestUrl": dealParam(editValue.requestUrl, pageParam),
      "requestType": editValue.requestType,
      "headers": JSON.parse(editValue.headers),
      "body": dealParamObj(JSON.parse(editValue.body), pageParam),
    }

    const data = await reqAgencyRequest(params);
    // console.log(data);
    setRequestRes(data);
    // notification.open({
    //   message: '请求结果',
    //   description: JSON.stringify(data, null, 2),
    // });
  }

  //测试过滤器
  const handelTestFilter = async () => {
    const result = executeFunctionFilter(editValue.filters, requestRes);
    setFilterData(result);
  }

  //测试参数
  const handelTestParam = async () => {
    const result = executeFunctionFilter(editValue?.param || "return res;", pageParam);
    setParamData(result);
  }

  //复制数据源
  const handleCopy = () => {
    const str = JSON.stringify(editValue);
    localStorage.setItem("datasourceclipboard", str);  //利用localStorage实现跨页面复制粘贴
    message.success('复制成功');
  }

  //粘贴数据源
  const handlePaste = () => {
    const str = localStorage.getItem("datasourceclipboard") || '';
    let tempEditValue = JSON.parse(str);
    setEditValue(tempEditValue);
    message.success('粘贴成功');
  }

  const getValueInput = () => {
    switch (value?.dataSource) {
      case 'static':
        return `(静态数据) ${valueType === 'string' ? value?.staticData : JSON.stringify(value?.staticData)}`
      case 'api':
        return `(api) ${value?.requestUrl}`;
      case 'param':
        return `(参数) ${value?.param}`;
      default:
        return '';
    }
  }

  const getOptions = () => {
    const { optionfilter } = props;
    if (optionfilter) {
      return datasourceoptions.filter(item => optionfilter.includes(item.value));
    } else {
      return datasourceoptions;
    }
  }

  const titleflex = '120px';
  const contentflex = 'auto';
  return (
    <Input.Group compact>
      <Input
        // disabled
        style={{ width: 'calc(100% - 32px)' }}
        value={getValueInput()}
      />
      <Button icon={<AlignRightOutlined />} onClick={handleClick} />
      <Modal
        title='数据源配置'
        okText="确认"
        cancelText="取消"
        visible={modalVisible}
        footer={[
          <Button onClick={handleCopy}>复制数据源</Button>,
          <Button disabled={_.isNil(localStorage.getItem("datasourceclipboard"))} onClick={handlePaste}>粘贴数据源</Button>,
          <Button onClick={handleCancel}>取消</Button>,
          <Button type="primary" onClick={handleOK}>确认</Button>,
        ]}
        // onOk={handleOK}
        onCancel={handleCancel}
      >
        <div className={styles.container}>
          <Row className={styles.row}>
            <Col className={styles.title} flex={titleflex}>
              数据源类型
            </Col>
            <Col className={styles.content} flex={contentflex}>
              <Select
                style={{ width: '200px' }}
                options={getOptions()}
                value={editValue?.dataSource || ''}
                onChange={(e) => { changeEditValue({ dataSource: e }) }} />
            </Col>
          </Row>
          {
            editValue.dataSource === 'static' && (
              <Row className={styles.row}>
                <Col className={styles.title} flex={titleflex}>
                  静态数据
                </Col>
                <Col className={styles.content} flex={contentflex}>
                  {valueType === 'string' && (
                    <Input value={editValue?.staticData || ''}
                      onChange={(e) => { changeEditValue({ staticData: e.target.value }) }} />
                  )}
                  {valueType === 'number' && (
                    <InputNumber value={editValue?.staticData || ''}
                      onChange={(e) => { changeEditValue({ staticData: e }) }} />
                  )}
                  {(valueType === 'object' || valueType === 'array') && (
                    <JsonInput valueType={valueType}
                      value={editValue?.staticData}
                      onChange={(e) => { changeEditValue({ staticData: e }) }} />
                  )}
                </Col>
              </Row>
            )
          }
          {
            editValue.dataSource === 'api' && (
              <div>
                <Row className={styles.row}>
                  <Col className={styles.title} flex={titleflex}>
                    请求频率
                    <Tooltip title="多少秒更新一次数据，0则代表只更新一次">
                      <QuestionCircleOutlined className={styles.tip} />
                    </Tooltip>
                  </Col>
                  <Col className={styles.content} flex={contentflex}>
                    <InputNumber
                      style={{ width: '100px' }}
                      value={editValue?.requestSec}
                      min={0}
                      onChange={(e) => { changeEditValue({ requestSec: e }) }} />
                  </Col>
                </Row>
                <Row className={styles.row}>
                  <Col className={styles.title} flex={titleflex}>
                    请求地址
                    <Tooltip title={(
                      <div>
                        http://api/test?id=:id
                      </div>
                    )}>
                      <QuestionCircleOutlined className={styles.tip} />
                    </Tooltip>
                  </Col>
                  <Col className={styles.content} flex={contentflex}>
                    <Input
                      style={{ width: '100%' }}
                      value={editValue?.requestUrl}
                      onChange={(e) => { changeEditValue({ requestUrl: e.target.value }) }} />
                  </Col>
                </Row>
                <Row className={styles.row}>
                  <Col className={styles.title} flex={titleflex}>
                    请求方式
                  </Col>
                  <Col className={styles.content} flex={contentflex}>
                    <Select
                      style={{ width: '200px' }}
                      options={[{ value: 'GET' }, { value: 'POST' }]}
                      value={editValue?.requestType}
                      onChange={(e) => { changeEditValue({ requestType: e }) }} />
                  </Col>
                </Row>
                <Row className={styles.row}>
                  <Col className={styles.title} flex={titleflex}>
                    请求头
                    <Tooltip title={`{
                      "token": "xxxx"
                    }`}>
                      <QuestionCircleOutlined className={styles.tip} />
                    </Tooltip>
                  </Col>
                  <Col className={styles.content} flex={contentflex}>
                    <TextArea
                      style={{ width: '100%' }}
                      rows={3}
                      value={editValue?.headers}
                      onChange={(e) => {
                        changeEditValue({ headers: e.target.value })
                      }}
                      onDoubleClick={() => {
                        if (checkJSON(editValue.headers)) {
                          changeEditValue({ headers: JSON.stringify(JSON.parse(editValue.headers), null, 2) })
                        }
                      }} />
                    {
                      !checkJSON(editValue.headers) && (
                        <Tooltip title="错误的对象结构">
                          <InfoCircleOutlined className={styles.warn} />
                        </Tooltip>
                      )
                    }
                  </Col>
                </Row>
                {editValue?.requestType === 'POST' && (
                  <Row className={styles.row}>
                    <Col className={styles.title} flex={titleflex}>
                      请求参数
                      <Tooltip title={`{
                      "id": ":id"
                    }`}>
                        <QuestionCircleOutlined className={styles.tip} />
                      </Tooltip>
                    </Col>
                    <Col className={styles.content} flex={contentflex}>
                      <TextArea
                        style={{ width: '100%' }}
                        rows={4}
                        value={editValue?.body}
                        onChange={(e) => { changeEditValue({ body: e.target.value }) }}
                        onDoubleClick={() => {
                          if (checkJSON(editValue.body)) {
                            changeEditValue({ body: JSON.stringify(JSON.parse(editValue.body), null, 2) })
                          }
                        }} />
                      {
                        !checkJSON(editValue.body) && (
                          <Tooltip title="错误的对象结构">
                            <InfoCircleOutlined className={styles.warn} />
                          </Tooltip>
                        )
                      }
                    </Col>
                  </Row>
                )}
                <Row className={styles.row}>
                  <Col className={styles.title} flex={titleflex}>
                    数据过滤器
                    <Tooltip title={`function filter(res) {***},请填***部分内容`}>
                      <QuestionCircleOutlined className={styles.tip} />
                    </Tooltip>
                  </Col>
                  <Col className={styles.content} flex={contentflex}>
                    <TextArea
                      style={{ width: '100%' }}
                      rows={4}
                      value={editValue?.filters}
                      onChange={(e) => { changeEditValue({ filters: e.target.value }) }} />
                  </Col>
                </Row>
                <div className={styles.debugcontent}>
                  <div className={styles.content}>
                    <Button type="primary" onClick={handelTestRequest}>测试请求</Button>
                    <TextArea
                      style={{ marginTop: "2px" }}
                      // autoSize
                      rows={3}
                      value={typeof requestRes === 'object' ? JSON.stringify(requestRes, null, 2) : requestRes}
                    // onChange={(e) => { setRequestRes(e.target.value) }} 
                    />
                  </div>
                  <div className={styles.content}>
                    <Button type="primary" onClick={handelTestFilter}>测试过滤器</Button>
                    {/* <Button type="primary" onClick={()=>{
                      console.log(editValue.filters);
                      const obj=JSON.parse(requestRes)
                      console.log(obj);
                      console.log('aa',JSON.parse(JSON.stringify(obj)));
                      
                      const result = executeFunctionFilter(editValue.filters, obj);
                      console.log('result',result);
                      
                    }}>test</Button> */}
                    <TextArea
                      style={{ marginTop: "2px" }}
                      // autoSize
                      rows={3}
                      value={typeof filterData === 'object' ? JSON.stringify(filterData, null, 2) : filterData} />
                  </div>
                </div>
              </div>
            )
          }
          {
            editValue.dataSource === 'param' && (
              <div>
                <Row className={styles.row}>
                  <Col className={styles.title} flex={titleflex}>
                    页面参数
                    <Tooltip title={`function filter(res) {***},请填***部分内容`}>
                      <QuestionCircleOutlined className={styles.tip} />
                    </Tooltip>
                  </Col>
                  <Col className={styles.content} flex={contentflex}>
                    <TextArea
                      style={{ width: '100%' }}
                      rows={4}
                      value={editValue?.param || "return res;"}
                      onChange={(e) => { changeEditValue({ param: e.target.value }) }} />
                  </Col>
                </Row>
                <div className={styles.debugcontent}>
                  <div className={styles.content}>
                    <Button type="primary" onClick={handelTestParam}>测试参数</Button>
                    <TextArea
                      style={{ marginTop: "2px" }}
                      // autoSize
                      rows={3}
                      value={typeof paramData === 'object' ? JSON.stringify(paramData, null, 2) : paramData} />
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </Modal>
    </Input.Group>
  );
};

export default DataSourceInput;
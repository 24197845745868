import React from "react";
import * as Icon from '@ant-design/icons';
import * as datav from '@jiaminghi/data-view-react';
import _ from 'lodash';

//动画效果
export const EffectTypes = [{
  id: 0,
  title: '无',
  setting: 'none',
}, {
  id: 1,
  title: '左',
  setting: 'left',
}, {
  id: 2,
  title: '右',
  setting: 'right',
}, {
  id: 3,
  title: '上',
  setting: 'top',
}, {
  id: 4,
  title: '下',
  setting: 'bottom',
}, {
  id: 5,
  title: '渐隐',
  setting: 'alpha',
}, {
  id: 6,
  title: '缩小',
  setting: 'scale',
}, {
  id: 7,
  title: '缩小X',
  setting: 'scaleX',
}, {
  id: 8,
  title: '缩小Y',
  setting: 'scaleY',
}, {
  id: 9,
  title: '放大',
  setting: 'scaleBig',
}, {
  id: 10,
  title: '左上',
  setting: 'lefttop',
},
{
  id: -1,
  title: '自定义',
  setting: 'custom',
},
]

//动画效果
export const EffectModes = [{
  id: 0,
  title: '进场动画',
  setting: 'init',
}, {
  id: 1,
  title: '鼠标移入',
  setting: 'mousein',
}, {
  id: 2,
  title: '屏幕中间',
  setting: 'screencenter',
},{
  id: 3,
  title: '向下滚动',
  setting: 'scrolldown',
},]

export const getAnimation = (effectType: String, customAnimation: Object) => {
  const duration = 5000;
  let animation = {};
  switch (effectType) {
    case 'left':
      animation = {
        type: "from",
        x: -80,
        opacity: 0,
        duration,
      }
      break;
    case 'right':
      animation = {
        type: "from",
        x: 80,
        opacity: 0,
        duration,
      }
      break;
    case 'top':
      animation = {
        type: "from",
        y: -80,
        opacity: 0,
        duration,
      }
      break;
    case 'bottom':
      animation = {
        type: "from",
        y: 80,
        opacity: 0,
        duration,
      }
      break;
    case 'alpha':
      animation = {
        type: "from",
        opacity: 0,
        duration,
      }
      break;
    case 'scale':
      animation = {
        type: "from",
        scale: 0,
        opacity: 0,
        duration,
      }
      break;
    case 'scaleX':
      animation = {
        type: "from",
        scaleX: 0,
        opacity: 0,
        duration,
      }
      break;
    case 'scaleY':
      animation = {
        type: "from",
        scaleY: 0,
        opacity: 0,
        duration,
      }
      break;
    case 'scaleBig':
      animation = {
        type: "from",
        scale: 1.4,
        opacity: 0,
        duration,
      }
      break;
    case 'lefttop':
      animation = {
        type: "from",
        x: -80,
        y: -80,
        opacity: 0,
        duration,
      }
      break;
    case 'custom':
      animation = customAnimation || {};
      break;
    default:
      break;
  }
  return animation;
}
export const getMouseAnimation = (effectType: String, customAnimation: Object) => {
  const duration = 500;
  let animation = {};
  switch (effectType) {
    case 'left':
      animation = {
        type: "from",
        x: -10,
        duration,
      }
      break;
    case 'right':
      animation = {
        type: "from",
        x: 10,
        duration,
      }
      break;
    case 'top':
      animation = {
        type: "from",
        y: -10,
        duration,
      }
      break;
    case 'bottom':
      animation = {
        type: "from",
        y: 10,
        duration,
      }
      break;
    case 'alpha':
      animation = {
        type: "from",
        opacity: 0.8,
        duration,
      }
      break;
    case 'scale':
      animation = {
        type: "from",
        scale: 0.9,
        duration,
      }
      break;
    case 'scaleX':
      animation = {
        type: "from",
        scaleX: 0,
        duration,
      }
      break;
    case 'scaleY':
      animation = {
        type: "from",
        scaleY: 0,
        duration,
      }
      break;
    case 'scaleBig':
      animation = {
        type: "from",
        scale: 1.3,
        duration,
      }
      break;
    case 'lefttop':
      animation = {
        type: "from",
        x: -10,
        y: -10,
        duration,
      }
      break;
    case 'custom':
      animation = customAnimation || {};
      break;
    default:
      break;
  }
  return animation;
}

export const getIcons = () => {
  let tempIcons = _.mapKeys(Icon, (value, key) => {
    if (_.isFunction(value)) return 'remove';
    if (key.indexOf("TwoTone") !== -1) return 'remove';
    return key;
  });
  Reflect.deleteProperty(tempIcons, 'remove');
  return tempIcons
}

//根据图标名称返回图标
export const icontoElement = (name: string, fontsize: string = 'auto', color: string = 'auto') => {
  if (_.has(Icon, name)) {
    return React.createElement(Icon && (Icon as any)[name],
      {
        style: {
          fontSize: fontsize,
          color: color,
        }
      })
  } else {
    return ''
  }
}
//颜色选择控件 下拉列表中的颜色
export const baseColors = ['white', 'black', 'grey', 'red', 'blue', 'green', 'yellow', 'brown', 'aqua',
  'blueviolet', 'burlywood', 'chartreuse', 'chocolate', 'coral', 'cornflowerblue', 'crimson', 'cyan',
  'darkcyan', 'darkgoldenrod', 'darkgreen', 'darkkhaki', 'darkorange', 'deeppink', 'dodgerblue', 'fuchsia',
  'gold', 'goldenrod', 'greenyellow', 'hotpink', 'indianred', 'lawngreen', 'lemonchiffon', 'lightpink',
  'lightseagreen', 'lightskyblue', 'lime', 'indigo', 'khaki', 'magenta', 'maroon', 'mediumpurple', 'mediumseagreen',
  'mediumslateblue', 'mediumvioletred', 'orange', 'orangered', 'pink', 'purple', 'tan', 'wheat']
//颜色选择控件 高级界面中的颜色
export const advanceColors = [
  '#d9d9d9', '#595959', '#1f1f1f', '#ffa39e', '#f5222d', '#820014', '#ff9c6e', '#fa541c', '#871400',
  '#ffd591', '#fa8c16', '#873800', '#fadb14', '#bae637', '#5b8c00', '#73d13d', '#389e0d', '#5cdbd3',
  '#13c2c2', '#40a9ff', '#0050b3', '#85a5ff', '#597ef7', '#1d39c4', '#b37feb', '#531dab', '#ffadd2',
  '#f759ab', '#c41d7f']

//用于显示tags等颜色
export const showColors = [
  '#ffa39e', '#40a9ff', '#73d13d', '#f759ab', '#871400', '#fadb14', '#f5222d', '#c41d7f']

export const selectBorderStyle = '1px dashed red';

export const fontSizes = [
  {
    value: 'inherit',
    label: '继承'
  },
  {
    value: 'auto',
    label: '自动'
  },
  {
    value: '16px',
    label: 'p标签',
  },
  {
    value: '32px',
    label: 'h1标签',
  },
  {
    value: '24px',
    label: 'h2标签',
  },
  {
    value: '18.72px',
    label: 'h3标签',
  },
  {
    value: '16px',
    label: 'h4标签',
  },
  {
    value: '13.28px',
    label: 'h5标签',
  },
  {
    value: '12px',
    label: 'h6标签',
  },
]

//字体
export const fontFamilys = ['inherit', '微软雅黑', '黑体', '宋体', '仿宋-GB2312', '隶书', '楷体', '幼圆', '华文彩云', '华文宋体', '华文楷体', '华文行楷']

//图片缩放
export const ImageScales = [{
  id: 0,
  title: '铺满',
  setting: 'cover',  //css background-size:cover;
}, {
  id: 1,
  title: '适应高度',
  setting: 'auto 100%',
}, {
  id: 2,
  title: '适应宽度',
  setting: '100% auto',
}, {
  id: 3,
  title: '不缩放',
  setting: 'auto',
}, {
  id: 4,
  title: '包裹',
  setting: 'contain',
}, {
  id: 5,
  title: '铺满（变形）',
  setting: '100% 100%',
}]

export const textAligns = [{
  id: 0,
  title: '居左',
  setting: 'left',  
}, {
  id: 1,
  title: '居中',
  setting: 'center',
}, {
  id: 2,
  title: '居右',
  setting: 'right',
}]

//左右对齐
export const justifyContentTypes = [
  {
    value: 'flex-start',
    label: '居左'
  },
  {
    value: 'center',
    label: '居中'
  },
  {
    value: 'flex-end',
    label: '居右',
  }
]

//上下对齐
export const alignItemsTypes = [
  {
    value: 'flex-start',
    label: '居上'
  },
  {
    value: 'center',
    label: '居中'
  },
  {
    value: 'flex-end',
    label: '居下',
  }
]

export const backdropFilters=[
  {
    value: 'none',
    label: '无: none',
  },
  {
    value: 'blur(2px)',
    label: '毛玻璃效果: blur(2px)',
  },
  {
    value: 'brightness(60%)',
    label: '明亮度: brightness(60%)',
  },
  {
    value: 'contrast(40%)',
    label: '对比度: contrast(40%)',
  },
  {
    value: 'grayscale(40%)',
    label: '灰度: grayscale(40%)',
  },
  {
    value: 'hue-rotate(120deg)',
    label: '旋转色相: hue-rotate(120deg)',
  },
  {
    value: 'invert(70%)',
    label: '反色: invert(70%)',
  },
  {
    value: 'saturate(80%)',
    label: '饱和度: saturate(80%)',
  },
  {
    value: 'sepia(90%)',
    label: '棕褐色: sepia(90%)',
  },           
]

//跳转方式
export const LinkTypes = [{
  id: 0,
  title: '不跳转',
  setting: 'none',
}, {
  id: 1,
  title: '页面跳转',
  setting: '_self',
}, {
  id: 2,
  title: '弹窗跳转',
  setting: '_blank',
}, {
  id: 3,
  title: '页面返回',
  setting: '_back',
},]

export type DatavDataSource = {
  dataSource: 'static' | 'api' | 'param'; //数据源类型
  staticData: any; //静态数据 [{"value": "标题"}]
  requestUrl: string; //api地址
  requestType: 'GET' | 'POST'; //请求方式
  headers: any;  //请求头 {}
  body: any; //请求参数 {}
  filters: string; //数据过滤器
  requestSec: number; //多少秒一次请求，为0表示只请求1次
  param: string; //参数
}; //内容

//时间格式
export const TimeFormats = [
  { value: 'YYYY-MM-DD HH:mm:ss' },
  { value: 'YYYY-MM-DD HH:mm:ss a' },
  { value: 'YYYY-MM-DD' },
  { value: 'HH:mm:ss' },
  { value: 'YY年M月D日 HH时mm分ss秒' },
  { value: 'YY年M月D日' },
  { value: 'YY年M月D日 dddd' },
  { value: 'HH时mm分ss秒' },
]

//折线图类型  line折线图 curve曲线图 ladder1 ladder2阶梯图vh hvh
export const lineChartTypes = [
  {
    value: 'line',
    label: '折线图'
  },
  {
    value: 'curve',
    label: '曲线图'
  },
  {
    value: 'ladder1',
    label: '阶梯图一',
  },
  {
    value: 'ladder2',
    label: '阶梯图二',
  },
]

//柱状图类型 stack堆叠 group分组
export const columnChartTypes = [
  {
    value: 'normal',
    label: '普通'
  },
  {
    value: 'stack',
    label: '堆叠'
  },
  {
    value: 'group',
    label: '分组'
  }
]

//饼图类型 pie饼图 ring环形图
export const pieChartTypes = [
  {
    value: 'pie',
    label: '饼图'
  },
  {
    value: 'ring',
    label: '环形图'
  }
]

//circle | diamond | triangle | pin | rect
export const liquidChartTypes = [
  {
    value: 'circle',
    label: '圆形'
  },
  {
    value: 'diamond',
    label: '钻石'
  },
  {
    value: 'triangle',
    label: '三角形'
  },
  {
    value: 'pin',
    label: '水滴'
  },
  {
    value: 'rect',
    label: '矩形'
  },
]

//词云图形状 archimedean:椭圆 rectangular:矩形
export const wordCloudChartTypes = [
  {
    value: 'archimedean',
    label: '椭圆'
  },
  {
    value: 'rectangular',
    label: '矩形'
  }
]

//datav动效类型
export const animationTypes = [
  {
    value: 'none',
    label: '无'
  },
  {
    value: 'roundself',
    label: '斜45°自转'
  },
  {
    value: 'roundcenter',
    label: '公转'
  }
]

//边框类型
export const borderBoxTypes = [
  {
    value: 'BorderBox1',
    label: '类型1',
    display: (<datav.BorderBox1 />)
  },
  {
    value: 'BorderBox2',
    label: '类型2',
    display: (<datav.BorderBox2 />)
  },
  {
    value: 'BorderBox3',
    label: '类型3',
    display: (<datav.BorderBox3 />)
  },
  {
    value: 'BorderBox4',
    label: '类型4',
    display: (<datav.BorderBox4 />)
  },
  {
    value: 'BorderBox5',
    label: '类型5',
    display: (<datav.BorderBox5 />)
  },
  {
    value: 'BorderBox6',
    label: '类型6',
    display: (<datav.BorderBox6 />)
  },
  {
    value: 'BorderBox7',
    label: '类型7',
    display: (<datav.BorderBox7 />)
  },
  {
    value: 'BorderBox8',
    label: '类型8',
    display: (<datav.BorderBox8 />)
  },
  {
    value: 'BorderBox9',
    label: '类型9',
    display: (<datav.BorderBox9 />)
  },
  {
    value: 'BorderBox10',
    label: '类型10',
    display: (<datav.BorderBox10 />)
  },
  {
    value: 'BorderBox11',
    label: '类型11',
    display: (<datav.BorderBox11 />)
  },
  {
    value: 'BorderBox12',
    label: '类型12',
    display: (<datav.BorderBox12 />)
  },
  {
    value: 'BorderBox13',
    label: '类型13',
    display: (<datav.BorderBox13 />)
  },
]

//装饰类型
export const decorationTypes = [
  {
    value: 'Decoration1',
    label: '类型1',
    display: (<datav.Decoration1 />)
  },
  {
    value: 'Decoration2',
    label: '类型2',
    display: (<datav.Decoration2 />)
  },
  {
    value: 'Decoration2(reverse)',
    label: '类型2翻转',
    display: (<datav.Decoration2 reverse={true} />)
  },
  {
    value: 'Decoration3',
    label: '类型3',
    display: (<datav.Decoration3 />)
  },
  {
    value: 'Decoration4',
    label: '类型4',
    display: (<datav.Decoration4 />)
  },
  {
    value: 'Decoration4(reverse)',
    label: '类型4翻转',
    display: (<datav.Decoration4 reverse={true} />)
  },
  {
    value: 'Decoration5',
    label: '类型5',
    display: (<datav.Decoration5 />)
  },
  {
    value: 'Decoration6',
    label: '类型6',
    display: (<datav.Decoration6 />)
  },
  {
    value: 'Decoration7',
    label: '类型7',
    display: (<datav.Decoration7 />)
  },
  {
    value: 'Decoration8',
    label: '类型8',
    display: (<datav.Decoration8 />)
  },
  {
    value: 'Decoration8(reverse)',
    label: '类型8翻转',
    display: (<datav.Decoration8 reverse={true} />)
  },
  {
    value: 'Decoration9',
    label: '类型9',
    display: (<datav.Decoration9 />)
  },
  {
    value: 'Decoration10',
    label: '类型10',
    display: (<datav.Decoration10 />)
  },
  {
    value: 'Decoration11',
    label: '类型11',
    display: (<datav.Decoration11 />)
  },
]

export const defaultDatavBgUrl = 'https://images.muzhiyun.cn/muzhicloud/9208750090/user/2023-12-29/8da8dc97-6729-e100-38c8-4acaee230295.png';
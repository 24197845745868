/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\common\pdfinput\index.tsx
 * @LastEditTime: 2023-05-30 17:50:54
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UploadFile } from "antd/lib/upload/interface";
import { Modal, Form, Input, Image, Button, Upload, message, Popconfirm, Tooltip } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { getExtension, NewGuid } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';

const typeList = [".pdf"];

interface IProps {
  dataOSS?: any;
  value?: any;
  onChange?: (value: any) => void;
  afterUpload?: (value: any) => void;
}

//PDFurl
//这里pdf保存跟reqGetArticles文章管理合并，articlegroupid: 'pdf', title: 标题,url: 地址, originalType: 9
const PdfInput: React.FC<IProps> = (props: IProps) => {
  const { dataOSS, value, onChange,afterUpload } = props;

  const handleChange = (e: any) => {
    onChange && onChange(e.target.value);
  }

  const handleData = (file: any) => {
    return {
      OSSAccessKeyId: dataOSS.accessid,
      Signature: dataOSS.signature,
      key: file.url,
      policy: dataOSS.policy,
    };
  }

  // 通过本地文件上传状态变化
  const handleUploadChange = async (info: any) => {
    if (info.file.status === "done") {
      const tempurl = `${dataOSS.host}/${info.file.url}`;
      /////////
      onChange && onChange(tempurl);
      afterUpload && afterUpload(tempurl);
    }
  };

  const handleBeforeUpload = async (file: any, fileList: any) => {
    const suffix = getExtension(file.name).toLowerCase();
    // 重新修改文件名称
    file.url = dataOSS.key + NewGuid() + suffix;
    return true
  }

  return (
    <Input.Group compact>
      <Input style={{
        width: "calc(100% - 32px)"
      }}
        value={value}
        onChange={handleChange} />
      <Upload
        name="file"
        accept={typeList.join(",")}
        multiple={false}
        showUploadList={false}
        action={dataOSS?.host}
        data={handleData}
        beforeUpload={handleBeforeUpload}
        onChange={handleUploadChange}
      >
        <Button icon={<UploadOutlined />} />
      </Upload>
    </Input.Group>
  );
};

export default PdfInput;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\container\index.jsx
 * @LastEditTime: 2024-04-10 13:14:35
 */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { dealParam, NewGuid, downLoadFile } from 'utils/common';
import _ from 'lodash';
import { getViewFromConfig } from '../pageview';
import { getAnimation, getMouseAnimation } from 'utils/componentdefine';
import TweenOne from 'rc-tween-one';
import { OverPack, Parallax } from 'rc-scroll-anim';
import { Tooltip } from 'antd';
import styles from './index.module.scss';

//方向
export const DisPlays = [{
  id: 0,
  title: '默认',
  setting: 'inline',
}, {
  id: 1,
  title: 'flex',
  setting: 'flex',
}]

//方向
export const FlexDirections = [{
  id: 0,
  title: '横向',
  setting: 'row',  //css background-size:cover;
}, {
  id: 1,
  title: '纵向',
  setting: 'column',
}]

//主轴对齐方式
export const JustifyContents = [{
  id: 0,
  title: '局左',
  setting: 'flex-start',  //css background-size:cover;
}, {
  id: 1,
  title: '居右',
  setting: 'flex-end',
}, {
  id: 2,
  title: '居中',
  setting: 'center',
}, {
  id: 3,
  title: '平分',
  setting: 'space-around',
}, {
  id: 4,
  title: '贴边',
  setting: 'space-between',
}]

//侧轴对齐方式
export const AlignItems = [{
  id: 0,
  title: '居上',
  setting: 'flex-start',  //css background-size:cover;
}, {
  id: 1,
  title: '居下',
  setting: 'flex-end',
}, {
  id: 2,
  title: '居中',
  setting: 'center',
}]

//图片缩放
export const ImageScales = [{
  id: 0,
  title: '铺满',
  setting: 'cover',  //css background-size:cover;
}, {
  id: 1,
  title: '适应高度',
  setting: 'auto 100%',
}, {
  id: 2,
  title: '适应宽度',
  setting: '100% auto',
}, {
  id: 3,
  title: '不缩放',
  setting: 'auto',
}]

//图片效果
export const ImageEffects = [{
  id: 0,
  title: '默认',
  setting: 'scroll',  //css background-attachment:scroll;
}, {
  id: 1,
  title: '固定，不跟随页面滚动',
  setting: 'fixed',
}]

//跳转方式
export const LinkTypes = [{
  id: 0,
  title: '不跳转',
  setting: 0,
}, {
  id: 1,
  title: '页面跳转',
  setting: 1,
}, {
  id: 2,
  title: '弹窗跳转',
  setting: 2,
}, {
  id: 3,
  title: '下载文件',
  setting: 3,
},]

//定位方式
export const Positions = [{
  id: 0,
  title: '默认',
  setting: 'static',
}, {
  id: 1,
  title: '相对位置',
  setting: 'relative',
}, {
  id: 2,
  title: '固定位置',
  setting: 'fixed',
}, {
  id: 3,
  title: '绝对位置',
  setting: 'absolute',
},]

export const FlexWraps = [{
  id: 0,
  title: '不换行',
  setting: 'nowrap',
}, {
  id: 1,
  title: '换行',
  setting: 'wrap',
},]


export type ContainerConfig = {
  type: String;  //类型 container
  key: String; //主键
  title: String; //标题
  width?: String; //宽度
  height?: String;  //高度
  aspectRatio?: String; //宽高比
  position?: String; //定位方式 
  left?: String; //左
  top?: String; //上
  right?: String;  //右
  bottom?: String;  //下
  margin?: String;  //外边距
  padding?: String; //内边距
  borderradius?: String; //圆角******
  backGroundColor?: String;  //背景色 linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9))   
  fontSize?: String; //字体大小
  fontFamily?: String; //字体
  color?: String; //字色
  fontWeight?: Boolean; //粗体
  intalic?: Boolean; //斜体
  underline?: Boolean; //下划线
  display?: String; //布局
  flexDirection?: String;  //方向
  justifyContent?: String; //主轴对齐方式
  alignItems?: String;  //侧轴对齐方式
  flexWrap?: String;  //换行
  flex?: String;  //占比
  linkType?: Number;  //跳转方式 
  linkUrl?: String;  //跳转链接  
  backGroundImageUrl?: String;  //背景图
  imageScale?: String;   //图片缩放
  imageEffect?: String;  //图片效果
  effectType?: String; //动画效果
  customAnimation?: Object; //自定义动效
  effectMode?: String; //动效模式 进场动画/鼠标移入动画
  topTransparent?: Boolean;  //置顶透明 通常用于页头栏置顶透明效果
  border?: String; //边框
  boxShadow?: String;  //阴影
  tip: String; //提示
  overflow?: String; //超出
  hidescroll?: Boolean; //隐藏滚动条 
  backdropFilter?: String; //滤镜
  children?: any[];  //子配置
}

interface IProps {
  config: ContainerConfig;
  effectvisible?: true;
  subParam?: any; //父组件传给子组件的参数
}

//容器
const Container: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const history = useHistory();
  let { config, effectvisible, subParam } = props;
  subParam = {
    ...pageParam,
    ...subParam,
  }
  const effectType = config.effectType || 'none';
  const effectMode = config.effectMode || 'init';

  const [paused, setPaused] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [custommoment, setCustommoment] = useState(0);


  const getZindex = () => {
    if (config.position === 'absolute') {
      return "99";
    }
    if (config.position === 'fixed') {
      return "999";
    }
    return "auto";
  }

  let style = {
    width: config.width,
    height: config.height,
    aspectRatio: config?.aspectRatio || "auto",
    left: config?.left || "auto",
    top: config?.top || "auto",
    right: config?.right || "auto",
    bottom: config?.bottom || "auto",
    margin: config.margin,
    padding: config.padding || "0",
    borderRadius: config.borderradius,
    // background: config.backGroundColor,
    display: config.display || "flex",
    flexDirection: config.flexDirection,
    justifyContent: config.justifyContent,
    alignItems: config.alignItems,
    flexWrap: config.flexWrap || "nowrap",
    flex: config.flex,
    position: config.position || "relative",
    cursor: config?.linkType || 0 === 0 ? "default" : "pointer",
    backgroundImage: _.isEmpty(config?.backGroundImageUrl) ? '' : `url("${dealParam(config?.backGroundImageUrl, subParam)}")`,
    backgroundSize: config?.imageScale || "cover",
    // display: "block",
    backgroundAttachment: config?.imageEffect || "scroll",
    backgroundPosition: "50% 50%",
    backgroundRepeat: "no-repeat",
    zIndex: getZindex(),

    fontSize: config?.fontSize || "inherit",
    fontFamily: config.fontFamily || "inherit",
    color: dealParam(config?.color, subParam) || "inherit",
    fontWeight: config?.fontWeight ? "bold" : "normal",
    border: config?.border || "none",
    boxShadow: config?.boxShadow || "none",
    // overflow: "hidden",
    overflow: config?.overflow || "hidden",
    backdropFilter: config?.backdropFilter || "none",
  }

  const backGroundColor = dealParam(config?.backGroundColor, subParam);
  //渐变色处理
  if (backGroundColor.includes('linear-gradient')) {
    let tempbackgroundImage = backGroundColor;
    if (!_.isEmpty(style.backgroundImage)) {
      tempbackgroundImage = tempbackgroundImage + ', ' + style.backgroundImage;  //图片叠加渐变色
    }
    style = {
      ...style,
      backgroundImage: tempbackgroundImage,
    }
  } else {
    style = {
      ...style,
      backgroundColor: backGroundColor,
    }
  }

  const className = `${config?.hidescroll ? styles.hidescroll : ''} ${config?.intalic ? styles.italic : styles.unitalic} ${config?.underline ? styles.underline : styles.ununderline}`;

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  //置顶透明效果
  const scrollTop = useSelector((state: any) => state.global.scrollTop);
  if (config.topTransparent) {
    if (scrollTop === 0) {
      style.background = 'transparent';
    }
  }

  //滚动条方向
  const scrollDirection = useSelector((state: any) => state.global.scrollDirection);

  //向下滚动效果触发
  useEffect(() => {
    if (effectMode === 'scrolldown') {  //scrolldown
      if (scrollDirection === 1) {
        setPaused(false);
        setReverse(true);
        setCustommoment(null);
      } else {
        setPaused(false);
        setReverse(false);
        setCustommoment(0);
      }
    }
  }, [scrollDirection]);

  const handleClick = () => {
    //处理自定义工作区问题
    let linkUrl = dealParam(config?.linkUrl, subParam);
    let strs = linkUrl.split("&target=");
    linkUrl = strs[0];
    let linkType;
    if (strs.length > 1) {
      //只处理_self情况
      if (strs[1] === "_self") {
        linkType = 1;
      }
    }

    switch (linkType || config?.linkType) {
      case 1:
        window.location.href = linkUrl;
        break;
      case 2:
        window.open(linkUrl);
        break;
      case 3:
        //下载文件
        let fileUrl = linkUrl;
        downLoadFile(fileUrl);
        break;
      default:
        break;
    }
  }

  const key = `${config.key}${config?.index || ''}`;
  // const key = NewGuid();

  if (effectType === 'none') {
    const getDiv = () => {
      return (<div
        key={key}
        id={key}
        className={className}
        style={style}
        onClick={handleClick}
      >
        {getViewFromConfig(config.children, effectvisible, subParam)}
      </div>)
    }
    if (!_.isNil(config?.tip) && config.tip !== "") {
      return (<Tooltip id={key} key={key} title={dealParam(config.tip, subParam)}>
        {getDiv()}
      </Tooltip>)
    } else {
      return getDiv();
    }
  }

  if (effectMode === 'init') return effectvisible ?
    (
      <OverPack
        key={key}
        id={key}
        targetId="maincontent"
        replay={true}>
        <TweenOne
          className={className}
          style={style}
          onClick={handleClick}
          animation={getAnimation(effectType, config?.customAnimation)} >
          {getViewFromConfig(config.children, effectvisible, subParam)}
        </TweenOne>
      </OverPack>) : ''

  if (effectMode === 'mousein') {
    return (
      <TweenOne
        key={key}
        id={key}
        className={className}
        style={style}
        onClick={handleClick}
        reverse={reverse}
        moment={custommoment}
        animation={getMouseAnimation(effectType, config?.customAnimation)}
        paused={paused}
        onMouseEnter={() => {
          setPaused(false);
          setReverse(true);
          setCustommoment(null);
        }}
        onMouseLeave={() => {
          setPaused(false);
          setReverse(false);
          setCustommoment(0);
        }}
      >
        {getViewFromConfig(config.children, effectvisible, subParam)}
      </TweenOne>
    )
  }

  if (effectMode === 'scrolldown') {
    return (
      <TweenOne
        key={key}
        id={key}
        className={className}
        style={style}
        onClick={handleClick}
        reverse={reverse}
        moment={custommoment}
        animation={getMouseAnimation(effectType, config?.customAnimation)}
        paused={paused}
      >
        {getViewFromConfig(config.children, effectvisible, subParam)}
      </TweenOne>
    )
  }

  if (effectMode === 'screencenter') {
    return (
      <Parallax
        key={key}
        id={key}
        className={className}
        style={style}
        onClick={handleClick}
        targetId="editcontent"
        animation={{
          playScale: [0.5, 0.8],
          ...getAnimation(effectType, config?.customAnimation),
        }}
      >
        {getViewFromConfig(config.children, effectvisible, subParam)}
      </Parallax>
    )
  }
};

export default Container;
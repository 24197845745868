
import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { selectBorderStyle, DatavDataSource } from 'utils/componentdefine';
import { executeFunctionFilter, dealParam, dealParamObj, getData } from 'utils/common';
import { Pie } from '@ant-design/charts';
import { reqAgencyRequest } from 'api/api';
import styles from './index.module.scss';


export type DatavPieChartConfig = {
  type: String;  //类型 datavtext
  key: String; //主键
  data: DatavDataSource; //数据源
  colorField: String; //分类字段
  angleField: String; //值字段
  seriesField: String; //分组坐标字段 可为空
  chartType: String; //类型：pie饼图 ring环形图
  customConfig: Object; //自定义配置
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
}

interface IProps {
  config: DatavPieChartConfig;
}

//饼图
const DatavPieChart: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const { config } = props;
  const [data, setData] = useState([]);
  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);

  useEffect(() => {
    getData(config.data, pageParam, setData);
    if (config.data.dataSource === 'api' && config.data.requestSec > 0) {
      const interval = setInterval(() => {
        getData(config.data, pageParam, setData);
      }, config.data.requestSec * 1000);
      // 组件卸载时清除定时器
      return () => {
        clearInterval(interval);
      }
    }
  }, [config, pageParam])

  const getStyle = () => {
    let style = {
      width: config.width,
      height: config.height,
      left: config.left,
      top: config.top,
      position: "absolute",
    };
    if (editComponentKey === config.key) {
      style.border = selectBorderStyle;
    }
    return style;
  }

  const getChartConfig = () => {
    let chartconfig = {
      data: data,
      angleField: config.angleField,
      colorField: config.colorField,
      seriesField: config.seriesField,
      // theme: 'dark',
      // renderer: 'svg',
    }
    if (config.chartType === 'ring') {
      chartconfig.innerRadius = 0.6;
    }

    chartconfig = {
      ...chartconfig,
      ...config.customConfig,
    }
    return chartconfig;
  }

  return (
    <Pie
      style={getStyle()}
      {...getChartConfig()}
    />
  )

};

export default DatavPieChart;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\customtextedit\index.tsx
 * @LastEditTime: 2023-03-06 10:56:34
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Button, Select, Radio, Switch, Tabs, AutoComplete, InputNumber, message } from 'antd';
import { NewGuid } from 'utils/common';
import _ from 'lodash';
import DataSourceInput from "components/common/datasourceinput";
import JsonInput from "components/common/jsoninput";
import styles from './index.module.scss';
import VideoInput from "components/common/videoinput";
import { reqGetSignature } from "api/api";
import VideoListInput from "components/common/videolistinput";

interface IProps {
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'datavvideo',
  key: NewGuid(),
  title: '视频',
  src: 'https://terra-1-g.djicdn.com/851d20f7b9f64838a34cd02351370894/2022Showreel_%E5%AE%98%E7%BD%91%E9%A6%96%E9%A1%B5%E8%A7%86%E9%A2%91_1200x720_OP7(1).mp4',
  srclist: [],
  controls: false,
  muted: true,
  loop: true,
  autoPlay: true,
  width: 300,
  height: 300,
  left: 0,
  top: 0,
})

const DatavVideoEdit: React.FC<IProps> = (props: IProps) => {
  const { visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);
  const [videoOSS, setVideoOSS] = React.useState<any>({}) //视频oss地址

  useEffect(() => {
    //获取videoOSS地址
    reqGetSignature({ bucketType: 1, system: 'muzhiyun' }).then(res => {
      setVideoOSS(res.Data[0]);
    })

  }, [])

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
    }
  }, [visible])

  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑视频'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="src"
              label="视频地址"
            >
              <VideoInput dataOSS={videoOSS} />
            </Form.Item>
            <Form.Item
              name="srclist"
              label="视频列表"
            >
              <VideoListInput dataOSS={videoOSS} />
            </Form.Item>
            <Form.Item
              name="left"
              label="左"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="top"
              label="上"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <InputNumber />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-2">
          <Form.Item
              name="controls"
              label="控制条"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch/>
            </Form.Item>
            <Form.Item
              name="muted"
              label="静音"
              rules={[{ required: true }]}
              valuePropName="checked"
            >
              <Switch/>
            </Form.Item>
            <Form.Item
              name="loop"
              label="循环播放"
              rules={[{ required: true }]}
              valuePropName="checked"
              tooltip="视频列表方式此设置无效，自动循环播放"
            >
              <Switch/>
            </Form.Item>
            <Form.Item
              name="autoPlay"
              label="自动播放"
              rules={[{ required: true }]}
              valuePropName="checked"
              tooltip="开启自动播放必须静音才生效"
            >
              <Switch/>
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  )
};

export default DatavVideoEdit;

/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-07-29 23:01:59
 * @FilePath: \website\src\store\slices\globalSlice.ts
 * @LastEditTime: 2023-02-13 18:08:24
 */

//全局变量，存储登录用户信息等
//这里使用redux直接调用方法存储
import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { clearProfile, setProfile } from 'utils/auth';

const spaceName = 'global'; //命名空间
//初始值
const initialState = {
  organization: {},  //所选机构
  loginInfo: {},
  workSpaces: [],  //工作区
  editComponentKey: '', //编辑的组件key
  editComponentKeys: [], //编辑的组件key(多选)
  scrollTop: 0,  //当前页面的滚动位置，用于页头仅在顶部时透明
  scrollDirection: 0, //滚动方向 1：向下滚动  -1：向上滚动 
  pageParam: {}, //合并后的页面参数( 优先级：页面参数<url参数<api参数)
  baseParam: {}, //页面保存的参数
  apiParam: {}, //api返回的参数
  urlParam: {}, //url带的参数
  pageApis: [], //页面保存的api
  editMode: 0,  //0：非编辑模式 1：编辑模式
}

export const slice = createSlice({
  name: spaceName, // 命名空间，在调用action的时候会默认的设置为action的前缀,保证唯一.不重名 
  initialState,
  reducers: {
    setOrganization(state, action) {
      state.organization = {
        ...state.organization,
        ...action.payload
      }
    },
    setWorkSpaces(state, action) {
      state.workSpaces = action.payload
    },
    setEditComponentKey(state, action) {
      state.editComponentKey = action.payload
    },
    setEditComponentKeys(state, action) {
      state.editComponentKeys = action.payload
    },
    setScrollTop(state, action) {
      if (action.payload > state.scrollTop) {
        state.scrollDirection = 1
      } else if (action.payload < state.scrollTop) {
        state.scrollDirection = -1
      } else {
        state.scrollDirection = 0
      }
      state.scrollTop = action.payload
    },
    //登录时调用
    setLoginInfo(state, action) {
      state.loginInfo = {
        ...state.loginInfo,
        ...action.payload,
      };
      const profile = {
        id: action.payload.id,
        username: action.payload.username,
        realname: action.payload.realname,
        admin: action.payload.admin
      };

      // Cookies.set("token", action.payload.token);
      // Cookies.set("profile", JSON.stringify(profile));
      setProfile(action.payload.token, profile); //这里结构不太一致，但是也没有调用，暂时不管

      // sessionStorage.setItem("token", action.payload.token);
      // sessionStorage.setItem("profile", JSON.stringify(profile));
    },
    //登出时调用
    clearLoginInfo(state, action) {
      state.loginInfo = {}
      clearProfile(action.payload);
      // sessionStorage.clear();
    },
    setPageParam(state, action) {
      state.pageParam = action.payload
    },
    setBaseParam(state, action) {
      state.baseParam = action.payload;
      state.pageParam = {
        ...state.baseParam,
        ...state.urlParam,
        ...state.apiParam,
      }
    },
    setApiParam(state, action) {
      state.apiParam = action.payload;
      state.pageParam = {
        ...state.baseParam,
        ...state.urlParam,
        ...state.apiParam,
      }
    },
    setUrlParam(state, action) {
      state.urlParam = action.payload;
      state.pageParam = {
        ...state.baseParam,
        ...state.urlParam,
        ...state.apiParam,
      }
    },
    setPageApis(state, action) {
      state.pageApis = action.payload
    },
    setEditMode(state, action) {
      state.editMode = action.payload
    },
  }
})

export const {
  setOrganization, setWorkSpaces, setLoginInfo, clearLoginInfo, setEditComponentKey,
  setEditComponentKeys, setScrollTop, setPageParam, setBaseParam, setApiParam, setUrlParam,
  setPageApis, setEditMode
} = slice.actions;
export default slice.reducer;
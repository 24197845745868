/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\index.tsx
 * @LastEditTime: 2024-06-06 18:37:03
 */

import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button, Drawer, Tree, Popconfirm, Tooltip, Select, Popover, Divider,
  message, Input, ConfigProvider, Slider, Modal, Form, Dropdown, Switch
} from "antd";
import {
  EditOutlined, DeleteOutlined, CaretUpOutlined, CaretDownOutlined,
  CopyOutlined, PlusOutlined, MinusOutlined, DownOutlined
} from '@ant-design/icons';
import type { DataNode, TreeProps } from 'antd/es/tree';
import ComponentList from './componentlist';
import DatavContent from './datavContent';
import BannerEdit, { defaultValue as bannerDefaultValue } from './banneredit';
import ContainerEdit, { defaultValue as containerDefaultValue } from './containeredit';
import PageMenuEdit, { defaultValue as pageMenuDefaultValue } from './pagemenuedit';
import ArticleListEdit, { defaultValue as articleListDefaultValue } from './atriclelistedit';
import CustomArticleListEdit, { defaultValue as customArticleListDefaultValue } from './customatriclelistedit';
import HtmlViewEdit, { defaultValue as htmlViewDefaultValue } from './htmlviewedit';
import PictureEdit, { defaultValue as pictureDefaultValue } from './pictureedit';
import CustomTextEdit, { defaultValue as customTextDefaultValue } from './customtextedit';
import CustomVideoEdit, { defaultValue as customVideoDefaultValue } from './customvideoedit';
import CustomAudioEdit, { defaultValue as customAudioDefaultValue } from './customaudioedit';
import CustomButtonEdit, { defaultValue as customButtonDefaultValue } from './custombuttonedit';
import CustomSliderEdit, { defaultValue as customSliderDefaultValue } from './customslideredit';
import WorkSpaceEdit, { defaultValue as workSpaceDefaultValue } from './workspaceedit';
import CustomWorkSpaceEdit, { defaultValue as customWorkSpaceDefaultValue } from './customworkspaceedit';
import CustomApiEdit, { defaultValue as customApiDefaultValue } from './customapiedit';
import LoginStatusEdit, { defaultValue as loginStatusDefaultValue } from './loginstatusedit';
import PaperListEdit, { defaultValue as paperListDefaultValue } from './paperlistedit';
import CustomFrameEdit, { defaultValue as customFrameDefaultValue } from './customframeedit';
import _ from 'lodash';
import PageHeader from '../pageheader';
import PageView, { getViewFromConfig } from 'components/pageview';
import {
  reqGetPages, reqUpdatePage, reqGetArticleGroups, reqGetSignature, reqGetOrganizations, reqGetWorkSpaces,
  reqGetWebSiteTemplates, reqGetArticles, reqAgencyRequest
} from 'api/api';
import { NewGuid, loadFont, dealParam, dealParamObj, executeFunctionFilter } from "utils/common";
import {
  setOrganization, setWorkSpaces, setEditComponentKey, setScrollTop,
  setPageParam, setBaseParam, setApiParam, setPageApis, setEditMode
} from "store/slices/globalSlice";
import styles from './index.module.scss';
import PageParamModal from "./pageparammodal";
import { DatavDataSource } from "utils/componentdefine";

const { Option } = Select;

//页面编辑
const PageEdit: React.FC = () => {
  const dispatch = useDispatch();

  const [componentListVisible, SetComponentListVisible] = useState(false);
  const [drawerVisible, SetDrawerVisible] = useState(false);
  const [paramVisible, SetParamVisible] = useState(false);

  const [bannerVisible, SetBannerVisible] = useState(false);
  const [containerVisible, SetContainerVisible] = useState(false);
  const [pageMenuVisible, SetPageMenuVisible] = useState(false);
  const [pictureVisible, SetPictureVisible] = useState(false);
  const [customVideoVisible, SetCustomVideoVisible] = useState(false);
  const [customAudioVisible, SetCustomAudioVisible] = useState(false);
  const [customTextVisible, SetCustomTextVisible] = useState(false);
  const [customButtonVisible, SetCustomButtonVisible] = useState(false);
  const [customSliderVisible, SetCustomSliderVisible] = useState(false);
  const [workSpaceVisible, SetWorkSpaceVisible] = useState(false);
  const [customWorkSpaceVisible, SetCustomWorkSpaceVisible] = useState(false);
  const [customApiVisible, SetCustomApiVisible] = useState(false);
  const [loginStatusVisible, SetLoginStatusVisible] = useState(false);
  const [articleListVisible, SetArticleListVisible] = useState(false);
  const [customArticleListVisible, SetCustomArticleListVisible] = useState(false);
  const [paperListVisible, SetPaperListVisible] = useState(false);
  const [htmlViewVisible, SetHtmlViewVisible] = useState(false);
  const [customFrameVisible, SetCustomFrameVisible] = useState(false);


  const [editKey, SetEditKey] = useState('');
  const [selectNode, setSelectNode] = useState<any>(null);
  const [control, setControl] = useState('banner');

  const [pageConfig, SetPageConfig] = useState([])
  const [pageStyle, SetPageStyle] = useState('pc');
  const [redirectUrl, SetRedirectUrl] = useState('');  //手机端页面

  const [page, setPage] = useState<any>({});
  const [articleGroups, setArticleGroups] = useState<any>([]);

  const [autoSave, SetAutoSave] = useState(false);  //自动保存

  const pathname = useLocation().pathname;
  const host = window.location.host;
  const urlSearch = new URLSearchParams(useLocation().search);
  const organizationId = urlSearch.get('organizationId') || '';
  const pageid = urlSearch.get('pageid') || '';

  const pastecomponentStr = localStorage.getItem("pastecomponent") || '';

  const [imageOSS, setImageOSS] = React.useState<any>({}) //图片oss地址

  //这块处理datav的一些参数
  const [scale, setScale] = useState(50); //大屏模式下的比例

  const pageParam = useSelector((state: any) => state.global.pageParam);
  const baseParam = useSelector((state: any) => state.global.baseParam);
  const apiParam = useSelector((state: any) => state.global.apiParam);
  const pageApis = useSelector((state: any) => state.global.pageApis);

  const Controls = [{
    value: 'banner',
    label: '横幅',
  }, {
    value: 'container',
    label: '容器',
  }, {
    value: 'pagemenu',
    label: '导航',
  }, {
    value: 'picture',
    label: '图片',
  }, {
    value: 'customvideo',
    label: '视频',
  }, {
    value: 'customaudio',
    label: '音频',
  }, {
    value: 'customtext',
    label: '文本',
  }, {
    value: 'custombutton',
    label: '按钮',
  }, {
    value: 'customslider',
    label: '轮播图',
  }, {
    value: 'workspace',
    label: '工作区',
  }, {
    value: 'customworkspace',
    label: '工作区(自)',
  }, {
    value: 'customapi',
    label: '接口',
  }, {
    value: 'loginstatus',
    label: '登录状态',
  }, {
    value: 'articlelist',
    label: '文章列表',
  }, {
    value: 'customarticlelist',
    label: '文章列表(自)',
  }, {
    value: 'paperlist',
    label: '电子报',
  }, {
    value: 'htmlview',
    label: 'Html视窗',
  }, {
    value: 'customframe',
    label: '嵌套页面',
  }, {
    value: 'pastecomponent',
    disabled: pastecomponentStr === '',
    label: pastecomponentStr === '' ? `剪贴板` : `剪贴板(${JSON.parse(pastecomponentStr)?.title})`,
  }]

  const ScaleOptions = [
    {
      label: '200%',
      value: 200,
    },
    {
      label: '150%',
      value: 150,
    },
    {
      label: '100%',
      value: 100,
    },
    {
      label: '50%',
      value: 50,
    },
    {
      label: '自适应',
      value: 0,
    },
  ]



  //初始化数据
  useEffect(() => {
    message.config({
      top: 100,
    });

    setEditMode(1);

    //根据pageid获取config
    reqGetPages({ pageid }).then(res => {
      console.log(res);

      if (res.items.length === 1) {
        setPage(res.items[0]);
        SetPageConfig(res.items[0].pagecontent);
        SetPageStyle(res.items[0]?.pageStyle || 'pc');
        SetRedirectUrl(res.items[0]?.redirectUrl || '');
        // dispatch(setPageParam(res.items[0].pageparams));
        dispatch(setBaseParam(res.items[0]?.pageparams?.baseParam || {}));
        dispatch(setPageApis(res.items[0]?.pageparams?.pageApis || []));
      }
    });

    reqGetOrganizations().then(res => {
      let tempitem = _.find(res.Data.items, o => o.organizationGuid === organizationId);
      dispatch(setOrganization(tempitem));
    })

    //根据机构id获取文章分类
    reqGetArticleGroups({ organizationId }).then(res => {
      console.log('reqGetArticleGroups', res);

      setArticleGroups(res.items.filter((item: any) => item.articletype === 0));
    })

    //获取ImageOss地址
    reqGetSignature({ bucketType: 2, system: 'muzhiyun' }).then(res => {
      setImageOSS(res.Data[0]);
    })

    //获取工作区
    reqGetWorkSpaces(organizationId, host).then(res => {
      console.log(res);
      // setWorkSpaces(res.Data);
      dispatch(setWorkSpaces(res.Data));
    })

    //加载自定义字体
    reqGetArticles({
      organizationId,
      originalType: 10,
      page: 1,
      count: 999
    }).then(res => {
      res.items.forEach((item: any) => {
        loadFont(item);
      });
    })
  }, []);

  //处理选中状态
  useEffect(() => {
    dispatch(setEditComponentKey(editKey));
  }, [editKey])

  //处理自动保存
  useEffect(() => {
    if (autoSave) {
      const interval = setInterval(handleSave, 120 * 1000);
      // 组件卸载时清除定时器
      return () => {
        clearInterval(interval);
      }
    }
  }, [autoSave])

  //处理pageApis变化后调用接口
  useEffect(() => {
    const getData = (api: DatavDataSource) => {
      const params = {
        "requestUrl": dealParam(api.requestUrl, pageParam),
        "requestType": api.requestType,
        "headers": api.headers,
        "body": dealParamObj(api.body, pageParam),
      }
      reqAgencyRequest(params).then(res => {
        const result = executeFunctionFilter(api.filters, res);
        let tempApiParam = _.cloneDeep(apiParam);
        tempApiParam = {
          ...tempApiParam,
          ...result,
        }
        dispatch(setApiParam(tempApiParam));
      })
    }

    pageApis.forEach((api: any) => {
      getData(api);
    });

    let tempApiTimers: any[] = [];
    pageApis.filter((api: any) => api.requestSec > 0).forEach((api: any) => {
      const interval = setInterval(() => {
        getData(api);
      }, api.requestSec * 1000);

      tempApiTimers.push(interval);
    });
    return () => {
      tempApiTimers.forEach((timer: any) => {
        clearInterval(timer); // 清除定时器
      })
    }
  }, [pageApis]);



  //是否可以插入子集
  const canInsertChildren = (type: string) => {
    return _.includes(['container', 'banner', 'custombutton', 'customslider', 'customarticlelist', 'customworkspace', 'customapi'], type);
  }

  //打开抽屉
  const handleDrawerOpen = () => {
    SetDrawerVisible(!drawerVisible);
  }

  //保存
  const handleSave = () => {
    let tempPage = _.cloneDeep(page);
    tempPage.pagecontent = _.cloneDeep(pageConfig);
    tempPage.pageparams = {
      baseParam,
      pageApis,
    };
    delete tempPage.title; //删除title 否则报错

    reqUpdatePage(tempPage).then(res => {
      console.log('保存成功');

      message.success({
        content: "保存成功",
        style: {
          zIndex: '999999'
        }
      });

      // message.success('保存成功');
    })
  }

  //关闭抽屉
  const handleDrawerClose = () => {
    SetDrawerVisible(false);
  }

  // const getTreeData=(treedata: any)=>{
  //   return treedata.map((item: any)=>{
  //     return {
  //       key: item?.key,
  //       title: item?.title,
  //       // icon: <CarryOutOutlined />,
  //       children: item?.children ? getTreeData(item?.children) : null,
  //     }
  //   })
  // }

  const getDefaultConfig = () => {
    switch (control) {
      case 'banner':
        return bannerDefaultValue();
      case 'container':
        return containerDefaultValue();
      case 'pagemenu':
        return pageMenuDefaultValue();
      case 'picture':
        return pictureDefaultValue();
      case 'customvideo':
        return customVideoDefaultValue();
      case 'customaudio':
        return customAudioDefaultValue();
      case 'customtext':
        return customTextDefaultValue();
      case 'custombutton':
        return customButtonDefaultValue();
      case 'customslider':
        return customSliderDefaultValue();
      case 'workspace':
        return workSpaceDefaultValue();
      case 'customworkspace':
        return customWorkSpaceDefaultValue();
      case 'customapi':
        return customApiDefaultValue();
      case 'loginstatus':
        return loginStatusDefaultValue();
      case 'articlelist':
        return articleListDefaultValue();
      case 'customarticlelist':
        return customArticleListDefaultValue();
      case 'paperlist':
        return paperListDefaultValue();
      case 'htmlview':
        return htmlViewDefaultValue();
      case 'customframe':
        return customFrameDefaultValue();
      case 'pastecomponent':
        return getPastComponent();
      default:
        return bannerDefaultValue();
    }
  }

  //根据key获取config
  const getConfigFromKey = (config: any, key: string) => {
    for (let index = 0; index < config.length; index++) {
      const item = config[index];
      if (item.key === key) {
        return item;
      }
      if (item.children) {
        let tempvalue: any = getConfigFromKey(item.children, key);
        if (!_.isNull(tempvalue)) {
          return tempvalue;
        }
      }
    }
    return null;
  }

  //根据key获取Parent  parentConfig参数默认传null  如果是最外层则返回null
  const getParentFromKey = (config: any, key: string, parentConfig: any) => {
    for (let index = 0; index < config.length; index++) {
      const item = config[index];
      if (item.key === key) {
        return parentConfig;
      }
      if (item.children) {
        let tempvalue: any = getParentFromKey(item.children, key, item);
        if (!_.isNull(tempvalue)) {
          return tempvalue;
        }
      }
    }
    return null;
  }

  //根据key保存config
  const setConfigFromKey = (config: any, key: string) => {
    let tempPageConfig = _.cloneDeep(pageConfig);
    let tempConfig = getConfigFromKey(tempPageConfig, key);
    Object.assign(tempConfig, config);
    //tempConfig = { ...config }; 不能用这种方法，这种会给tempConfig新分配了个地址    
    SetPageConfig(tempPageConfig);
  }

  //添加config
  const addConfigFromKey = (config: any, addconfig: any, parentKey: string, index: number) => {
    const tempconfig = _.cloneDeep(config);
    const parentItem = getConfigFromKey(tempconfig, parentKey);

    if (_.isNull(parentItem)) {
      tempconfig.splice(index, 0, addconfig);
    } else {

      if (_.isNil(parentItem?.children)) {
        parentItem.children = [addconfig];
      } else {
        parentItem.children.splice(index, 0, addconfig);
      }
    }
    return tempconfig;
  }

  const delConfigFromKey = (config: any, key: string) => {
    const tempconfig = _.cloneDeep(config);
    _.remove(tempconfig, (o: any) => o.key === key);

    for (let index = 0; index < tempconfig.length; index++) {
      const item = tempconfig[index];
      if (item.children) {
        item.children = delConfigFromKey(item.children, key);
      }
    }
    return tempconfig;
  }

  const moveUpConfigFromKey = (config: any, key: string) => {
    const tempconfig = _.cloneDeep(config);
    const tempindex = _.findIndex(tempconfig, (o: any) => o.key === key);
    if (tempindex > 0) {
      [tempconfig[tempindex], tempconfig[tempindex - 1]] = [tempconfig[tempindex - 1], tempconfig[tempindex]];
    }

    for (let index = 0; index < tempconfig.length; index++) {
      const item = tempconfig[index];
      if (item.children) {
        item.children = moveUpConfigFromKey(item.children, key);
      }
    }
    return tempconfig;
  }

  const moveDownConfigFromKey = (config: any, key: string) => {
    const tempconfig = _.cloneDeep(config);
    const tempindex = _.findIndex(tempconfig, (o: any) => o.key === key);
    if ((tempindex >= 0) && (tempindex < tempconfig.length - 1)) {
      [tempconfig[tempindex], tempconfig[tempindex + 1]] = [tempconfig[tempindex + 1], tempconfig[tempindex]];
    }

    for (let index = 0; index < tempconfig.length; index++) {
      const item = tempconfig[index];
      if (item.children) {
        item.children = moveDownConfigFromKey(item.children, key);
      }
    }
    return tempconfig;
  }

  //keyTo指的是parent的key，如果是最外层则key=''
  const moveConfigFromKey = (config: any, keyFrom: string, keyTo: string, index: number) => {
    let tempconfig = _.cloneDeep(config);
    const moveconfig = getConfigFromKey(tempconfig, keyFrom);
    if (!_.isNull(moveconfig)) {
      tempconfig = delConfigFromKey(tempconfig, keyFrom);
      tempconfig = addConfigFromKey(tempconfig, moveconfig, keyTo, index);
    }
    return tempconfig;
  }

  //添加同级组件
  const handleAddItem = () => {
    const defaultConfig = getDefaultConfig();
    if (_.isNull(selectNode)) {
      const tempconfig = addConfigFromKey(pageConfig, defaultConfig, '', 0);
      SetPageConfig(tempconfig);
    } else {
      const parentItem = getParentFromKey(pageConfig, selectNode.key, null);
      const parentKey = _.isNull(parentItem) ? '' : parentItem.key;
      const index: number = _.isNull(parentItem) ?
        _.findIndex(pageConfig, (o: any) => o.key === selectNode.key)
        :
        _.findIndex(parentItem.children, (o: any) => o.key === selectNode.key);
      const tempconfig = addConfigFromKey(pageConfig, defaultConfig, parentKey, index + 1);
      SetPageConfig(tempconfig);
    }
  }

  //添加下级组件
  const handleAddSubItem = () => {
    const defaultConfig = getDefaultConfig();
    if (_.isNull(selectNode)) {
      const tempconfig = addConfigFromKey(pageConfig, defaultConfig, '', 0);
      SetPageConfig(tempconfig);
    } else {
      const index: number = _.isNil(selectNode?.children) ? 0 : selectNode.children.length;
      const tempconfig = addConfigFromKey(pageConfig, defaultConfig, selectNode?.key, index);
      SetPageConfig(tempconfig);
    }
  }

  //删除组件
  const handleDelComponent = (nodeData: any) => {
    const tempconfig = delConfigFromKey(pageConfig, nodeData?.key);
    SetPageConfig(tempconfig);
  }

  //编辑组件
  const handleEditComponent = (nodeData: any) => {
    SetEditKey(nodeData?.key);
    switch (nodeData?.type) {
      case 'banner':
        SetBannerVisible(true);
        break;
      case 'container':
        SetContainerVisible(true);
        break;
      case 'pagemenu':
        SetPageMenuVisible(true);
        break;
      case 'picture':
        SetPictureVisible(true);
        break;
      case 'customvideo':
        SetCustomVideoVisible(true);
        break;
      case 'customaudio':
        SetCustomAudioVisible(true);
        break;
      case 'customtext':
        SetCustomTextVisible(true);
        break;
      case 'custombutton':
        SetCustomButtonVisible(true);
        break;
      case 'customslider':
        SetCustomSliderVisible(true);
        break;
      case 'workspace':
        SetWorkSpaceVisible(true);
        break;
      case 'customworkspace':
        SetCustomWorkSpaceVisible(true);
        break;
      case 'customapi':
        SetCustomApiVisible(true);
        break;
      case 'loginstatus':
        SetLoginStatusVisible(true);
        break;
      case 'articlelist':
        SetArticleListVisible(true);
        break;
      case 'customarticlelist':
        SetCustomArticleListVisible(true);
        break;
      case 'paperlist':
        SetPaperListVisible(true);
        break;
      case 'htmlview':
        SetHtmlViewVisible(true);
        break;
      case 'customframe':
        SetCustomFrameVisible(true);
        break;
      default:
        break;
    }
  }

  //上移组件
  const handleMoveUpComponent = (nodeData: any) => {
    const tempconfig = moveUpConfigFromKey(pageConfig, nodeData?.key);
    SetPageConfig(tempconfig);
  }

  //下移组件
  const handleMoveDownComponent = (nodeData: any) => {
    const tempconfig = moveDownConfigFromKey(pageConfig, nodeData?.key);
    SetPageConfig(tempconfig);
  }

  //拖拽组件
  const onDrop: TreeProps['onDrop'] = info => {
    // console.log(info);
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    // console.log(dragKey, dropKey, info.dropPosition, info.dropToGap);
    //dropToGap=false 时放到dropKey的children里的第一个位置
    //dropToGap=true 时放到dropKey的后边，如果info.dropPosition=-1，则放到第一个位置（根节点才会出现这种情况）
    if (info.dropToGap) {
      const parentitem = getParentFromKey(pageConfig, dropKey as string, null);
      if (_.isNull(parentitem) || canInsertChildren(parentitem.type)) {
        const parentkey = _.isNull(parentitem) ? '' : parentitem.key;
        const index = info.dropPosition === -1 ? 0 : info.dropPosition;
        const tempconfig = moveConfigFromKey(pageConfig, dragKey as string, parentkey, index);
        SetPageConfig(tempconfig);
      }
    } else {
      if (canInsertChildren((info.node as any).type)) {
        const tempconfig = moveConfigFromKey(pageConfig, dragKey as string, dropKey as string, 0);
        SetPageConfig(tempconfig);
      }
    }
  }

  //复制组件
  const handleCopyComponent = (nodeData: any) => {
    SetEditKey(nodeData?.key);
    const tempconfig = getConfigFromKey(pageConfig, nodeData?.key);
    const str = JSON.stringify(tempconfig);
    localStorage.setItem("pastecomponent", str);  //利用localStorage实现跨页面复制粘贴
    message.success('复制成功');
  }

  //获取粘贴组件
  const getPastComponent = () => {
    const str = localStorage.getItem("pastecomponent") || '';
    let tempconfig = JSON.parse(str);
    //吧tempconfig里的key重新new一个guid
    tempconfig = newKeyFromConfig(tempconfig);
    return tempconfig;
  }

  //重新生成key
  const newKeyFromConfig = (config: any) => {
    const tempconfig = _.cloneDeep(config);
    tempconfig.key = NewGuid();
    if (tempconfig.children) {
      tempconfig.children = tempconfig.children.map((item: any) => {
        return newKeyFromConfig(item);
      })
    }
    return tempconfig;
  }

  //预览
  const handlePreview = () => {
    const url = "http://" + page.url + page.pageurl;
    window.open(url);
  }

  //页面滚动事件
  const handleScroll = (event: any) => {
    const scrollTop = event.target.scrollTop;
    dispatch(setScrollTop(scrollTop));
  }

  const handleTitleRender = (nodeData: any) => {
    return (
      <div className={styles.node}
        onClick={() => {
          setSelectNode(nodeData);
          SetEditKey(nodeData?.key);
        }}>
        {/* <Tooltip title={nodeData.type}> */}
        <div className={styles.title}>
          {nodeData.title}
        </div>
        {/* </Tooltip> */}
        <Tooltip title="编辑">
          <EditOutlined className={styles.icon} onClick={() => { handleEditComponent(nodeData) }} />
        </Tooltip>
        <Tooltip title="上移">
          <CaretUpOutlined className={styles.icon} onClick={() => { handleMoveUpComponent(nodeData) }} />
        </Tooltip>
        <Tooltip title="下移">
          <CaretDownOutlined className={styles.icon} onClick={() => { handleMoveDownComponent(nodeData) }} />
        </Tooltip>
        <Tooltip title="复制">
          <CopyOutlined className={styles.icon} onClick={() => { handleCopyComponent(nodeData) }} />
        </Tooltip>
        <Tooltip title="删除">
          <Popconfirm
            title="是否确定要删除此组件?"
            onConfirm={() => { handleDelComponent(nodeData) }}
          >
            <DeleteOutlined className={styles.icon} />
          </Popconfirm>
        </Tooltip>
      </div>
    )
  }

  //添加云组件到根目录
  const handleSelectComponent = (component: any) => {
    const tempconfig: any = _.cloneDeep(pageConfig);
    component.content.forEach((item: any) => {
      tempconfig.push(newKeyFromConfig(item));
    });
    SetPageConfig(tempconfig);
  }

  //渲染右边抽屉
  const renderDrawer = () => {
    return (
      <Drawer
        title="模块列表"
        onClose={handleDrawerClose}
        open={drawerVisible}
        mask={false}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <div className={styles.toolbar} >
          <Select
            className={styles.select}
            value={control}
            onChange={(e) => {
              setControl(e);
              // console.log(e);

            }}
            options={Controls} />
          <Button className={styles.btn} onClick={handleAddItem}>
            加到同级
          </Button>
          <Button className={styles.btn} onClick={handleAddSubItem}
            disabled={!canInsertChildren(selectNode?.type)}>
            加到下级
          </Button>
          {/* <div onClick={()=>{
            console.log(pageConfig);
            
          }}>
            a
          </div> */}
        </div>
        <div className={styles.tree}>
          <Tree
            showLine
            draggable
            blockNode
            // onDragEnter={onDragEnter}
            onDrop={onDrop}
            treeData={pageConfig}
            titleRender={handleTitleRender}
          />
        </div>
        <BannerEdit
          visible={bannerVisible}
          onVisibleChange={SetBannerVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <ContainerEdit
          imageOSS={imageOSS}
          visible={containerVisible}
          onVisibleChange={SetContainerVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <PageMenuEdit
          visible={pageMenuVisible}
          onVisibleChange={SetPageMenuVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <PictureEdit
          imageOSS={imageOSS}
          visible={pictureVisible}
          onVisibleChange={SetPictureVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <CustomVideoEdit
          visible={customVideoVisible}
          onVisibleChange={SetCustomVideoVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <CustomAudioEdit
          visible={customAudioVisible}
          onVisibleChange={SetCustomAudioVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <CustomTextEdit
          imageOSS={imageOSS}
          visible={customTextVisible}
          onVisibleChange={SetCustomTextVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <CustomButtonEdit
          visible={customButtonVisible}
          onVisibleChange={SetCustomButtonVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <CustomSliderEdit
          visible={customSliderVisible}
          onVisibleChange={SetCustomSliderVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <WorkSpaceEdit
          visible={workSpaceVisible}
          onVisibleChange={SetWorkSpaceVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <CustomWorkSpaceEdit
          visible={customWorkSpaceVisible}
          onVisibleChange={SetCustomWorkSpaceVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <CustomApiEdit
          visible={customApiVisible}
          onVisibleChange={SetCustomApiVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <LoginStatusEdit
          visible={loginStatusVisible}
          onVisibleChange={SetLoginStatusVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <ArticleListEdit
          articleGroups={articleGroups}
          visible={articleListVisible}
          onVisibleChange={SetArticleListVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <CustomArticleListEdit
          articleGroups={articleGroups}
          visible={customArticleListVisible}
          onVisibleChange={SetCustomArticleListVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <PaperListEdit
          visible={paperListVisible}
          onVisibleChange={SetPaperListVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <HtmlViewEdit
          visible={htmlViewVisible}
          onVisibleChange={SetHtmlViewVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
        <CustomFrameEdit
          visible={customFrameVisible}
          onVisibleChange={SetCustomFrameVisible}
          value={getConfigFromKey(pageConfig, editKey)}
          onChange={value => { setConfigFromKey(value, editKey) }}
        />
      </Drawer>
    )
  }

  return (
    <div className={styles.container}>
      <ConfigProvider>
        <div className={styles.pageheader} >
          <PageHeader>
            <div className={styles.topbar}>
              <div className={styles.pagename}
                onClick={() => { SetParamVisible(true) }}>
                {`${page.name}: ${page.pageurl}`}
              </div>
              <div className={styles.topcenter}>
                {pageStyle === 'datav' ? (
                  <div className={styles.scaletool}>
                    比例：
                    <Select
                      className={styles.customselect}
                      popupClassName={styles.dropdown}
                      value={{ value: scale, label: `${scale}%` }}
                      labelInValue
                      onChange={e => {
                        if (e.value === 0) {
                          //计算自适应比例,宽度以当前屏幕宽度扣减左右各50px
                          //高度以当前屏幕高度扣减上下各50px再扣除上边工具栏64px
                          const tempScale = Math.round(Math.max((window.innerWidth - 100) / page.pageWidth * 100, (window.innerHeight - 164) / page.pageHeight * 100));
                          setScale(tempScale);
                        } else {
                          setScale(e.value);
                        }
                      }}
                    >
                      {ScaleOptions.map(item => (
                        <Option style={{
                          backgroundColor: "#001529",
                          color: "white"
                        }}
                          value={item.value} >
                          {item.label}
                        </Option>
                      ))}
                    </Select>

                    <MinusOutlined className={styles.btn} />
                    <div className={styles.slider}>
                      <Slider min={10} max={200} value={scale} onChange={setScale} />
                    </div>
                    <PlusOutlined className={styles.btn} />
                    {`${scale}-${page.pageWidth}*${page.pageHeight}`}
                  </div>
                ) : ''}
              </div>
              <Popover
                // style={{backgroundColor:"green"}}
                // color="#efefe4"
                showArrow={false}
                open={componentListVisible}
                onOpenChange={SetComponentListVisible}
                placement="bottom"
                content={(<ComponentList
                  pageStyle={pageStyle}
                  pageWidth={page.pageWidth}
                  pageHeight={page.pageHeight}
                  onSelectComponent={handleSelectComponent}
                  // clipboardContent={pastecomponentStr === '' ? null : JSON.parse(pastecomponentStr)}
                  pageContent={pageConfig}
                  visible={componentListVisible} />)} trigger="click">
                <Button className={styles.btn} type="text" >云模板库</Button>
              </Popover>
              {/* <Button className={styles.btn} type="text" onClick={() => {
                console.log(page);
              }}>test</Button> */}
              <Button className={styles.btn} type="text" onClick={handleDrawerOpen}>组件列表</Button>
              <Button className={styles.btn} type="text" onClick={handleSave}>
                保存
                <Dropdown
                  overlayStyle={{ zIndex: 999999 }}
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          <div style={{
                            display: "flex",
                            alignItems: "center"
                          }}>
                            <span>自动保存&nbsp;</span>
                            <Switch
                              checkedChildren="开启"
                              unCheckedChildren="关闭"
                              checked={autoSave}
                              onChange={checked => {
                                SetAutoSave(checked)
                              }} />
                          </div>
                        ),
                      }]
                  }}
                >
                  <DownOutlined style={{ color: "white" }} />
                </Dropdown></Button>

              <Button className={styles.btn} type="text" onClick={handlePreview}>预览</Button>
              {/* <Button className={styles.btn} type="text" >|</Button> */}
            </div>
          </PageHeader>
        </div>
        {pageStyle === 'pc' ? (
          <div className={styles.pccontent} id="editcontent" onScroll={handleScroll}>
            {getViewFromConfig(pageConfig)}
          </div>
        ) : ''}
        {pageStyle === 'mobile' ? (
          <div className={styles.phonecontent}
            style={{ backgroundImage: `url('phone.png')` }}
            id="editcontent">
            <div className={styles.content} >
              {getViewFromConfig(pageConfig)}
            </div>
          </div>
        ) : ''}
        {/*datav*/}
        {pageStyle === 'datav' ? (
          <DatavContent
            scale={scale}
            page={page}
            onPageChange={setPage}
            pageConfig={pageConfig}
            onPageConfigChange={SetPageConfig}
            drawerVisible={drawerVisible}
            onDrawerVisibleChange={SetDrawerVisible} />
        ) : ''}

        {/* 渲染右边抽屉 */}
        {(pageStyle === 'pc' || pageStyle === 'mobile') ? renderDrawer() : ''}

        <PageParamModal
          visible={paramVisible}
          onVisibleChange={SetParamVisible}
        />
      </ConfigProvider>
    </div>
  )
};

export default PageEdit;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2023-02-13 17:56:37
 * @FilePath: \website\src\components\customapi\index.jsx
 * @LastEditTime: 2024-04-10 10:32:12
 */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectBorderStyle, DatavDataSource } from 'utils/componentdefine';
import _ from 'lodash';
import { executeFunctionFilter, dealParam, dealParamObj, getData } from "utils/common";
import { getViewFromConfig } from '../pageview';
import { reqAgencyRequest } from 'api/api';
import styles from './index.module.scss';


export type CustomApiConfig = {
  type: String;  //类型 customapi
  key: String; //主键
  title: String; //标题
  data: DatavDataSource; //数据源
  children?: any[];  //子配置
}

interface IProps {
  config: CustomApiConfig;
}

//自定义接口
const CustomApi: React.FC<IProps> = (props: IProps) => {
  const { config } = props;
  const [data, setData] = useState([]);
  const pageParam = useSelector((state: any) => state.global.pageParam);

  useEffect(() => {
    getData(config.data, pageParam, setData);
    if (config.data.dataSource === 'api' && config.data.requestSec > 0) {
      const interval = setInterval(() => {
        getData(config.data, pageParam, setData);
      }, config.data.requestSec * 1000);
      // 组件卸载时清除定时器
      return () => {
        clearInterval(interval);
      }
    }
  }, [config])

  return data.map((item, index) => {
    let tempchildren = (config?.children || []).map(children => {
      return {
        ...children,
        index
      }
    })
    // console.log('tempchildren',tempchildren);
    return getViewFromConfig(tempchildren, true, item);
  })
};

export default CustomApi;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\pictureedit\index.tsx
 * @LastEditTime: 2024-04-10 10:15:42
 */

import React, { useEffect, useState, useRef } from "react";
import { Modal, Form, Input, Image, InputNumber, Radio, Switch, Tabs, AutoComplete, Button, Select, message } from 'antd';
import { LinkTypes, Positions } from 'components/picture';
import { NewGuid } from 'utils/common';
import { UploadOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import ImageInput from 'components/common/imageinput';
import _ from 'lodash';
import styles from './index.module.scss';
import { EffectModes, EffectTypes } from "utils/componentdefine";
import JsonInput from "components/common/jsoninput";

interface IProps {
  imageOSS?: any;
  visible: boolean,
  onVisibleChange: (value: boolean) => void;
  value: any;
  onChange: (value: any) => void;
}

export const defaultValue = () => ({
  type: 'picture',
  key: NewGuid(),
  title: '图片',
  width: 'auto',
  height: 'auto',
  aspectRatio: 'auto',
  margin: '0',
  borderradius: '0',
  position: 'static',
  left: 'auto',
  top: 'auto',
  right: 'auto',
  bottom: 'auto',
  src: '',
  url: '',
  linkType: 'none',
  effectType: 'none', //动画效果
  customAnimation: {}, //自定义特效
  effectMode: 'init', //动效模式
})

const PictureEdit: React.FC<IProps> = (props: IProps) => {
  const { imageOSS, visible, onVisibleChange, value, onChange } = props;
  const [form] = Form.useForm();
  const [formValue, setFormValue] = useState<any>(null);

  useEffect(() => {
    if (visible) {
      let tempvalue = {
        ...defaultValue(),
        ...value,
      }
      setFormValue(tempvalue);
      form.setFieldsValue(tempvalue);
      //这里不清楚是不是antdesign的bug，form.setFieldsValue 并不会覆盖对象类的值
      //如原来界面中{a:1} 新的value是{b:1} 得到的结果是合并的内容{a:1,b:1}
      //因此需要重新单独对该字段赋值
      form.setFieldValue('customAnimation',tempvalue.customAnimation); 
    }
  }, [visible])


  const handleOK = () => {
    form.validateFields().then(formdata => {
      let tempConfig = {
        ...value,
        ...formdata,
      }
      onChange && onChange(tempConfig);
      onVisibleChange(false);
    }).catch(errorInfo => {
      message.error(errorInfo.errorFields[0]?.errors)      
    })
  }

  const handleCancel = () => {
    onVisibleChange(false);
    onChange && onChange(formValue);
  }

  const handleFieldsChange = (_: any, allFields: any) => {
    // let tempConfig: any = {};
    // allFields.forEach((element: any) => {
    //   tempConfig[element.name[0]] = element.value;
    // });
    // onChange && onChange(tempConfig);
  }

  return (
    <Modal
      title='编辑图片'
      width={650}
      okText="确认"
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      footer={[
        <Button onClick={() => { onChange && onChange(form.getFieldsValue()) }}>立即更新</Button>,
        <Button onClick={handleCancel}>取消</Button>,
        <Button type="primary" onClick={handleOK}>确认</Button>,
      ]}
    >
      <Form
        form={form}
        name="control-ref"
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        preserve={false}
        onFieldsChange={handleFieldsChange}
      >
        <Tabs tabPosition="left" defaultActiveKey="item-1" >
          <Tabs.TabPane className={styles.tabpane} tab="常规" key="item-1">
            <Form.Item
              name="title"
              label="标题"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="src"
              label="图片地址"
              rules={[{ required: true }]}
            >
              {/* <Input addonAfter={<UploadOutlined />}/> */}
              <ImageInput dataOSS={imageOSS} />
            </Form.Item>
            <Form.Item
              name="width"
              label="宽度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="height"
              label="高度"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '100%' }]} />
            </Form.Item>
            <Form.Item
              name="aspectRatio"
              label="宽高比"
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '4/3' }]} />
            </Form.Item>
            <Form.Item
              name="position"
              label="定位"
              rules={[{ required: true }]}
            >
              <Radio.Group>
                {Positions.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="left"
              label="左"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="top"
              label="上"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="right"
              label="右"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
            <Form.Item
              name="bottom"
              label="下"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: 'auto' }, { value: '50%' }]} />
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane className={styles.tabpane} tab="高级" key="item-2">
            <Form.Item
              name="margin"
              label="外边距"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: '0' }]} />
            </Form.Item>
            <Form.Item
              name="borderradius"
              label="圆角"
              rules={[{ required: true }]}
            >
              <AutoComplete options={[{ value: '0' }]} />
            </Form.Item>
            <Form.Item
              name="linkType"
              label="跳转方式"
              rules={[{ required: true }]}
              tooltip="跳转方式如果时放大图片，则点击图片弹出放大后的图片，如果当前图片不够清晰，则可在跳转地址中填入高清图片，用于放大图片"
            >
              <Radio.Group>
                {LinkTypes.map(item => (<Radio key={item.id} value={item.setting}>{item.title}</Radio>))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="url"
              label="跳转地址"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="effectType"
              label="动画效果"
            >
              <Select options={EffectTypes.map(item => ({ value: item.setting, label: item.title }))} />
            </Form.Item>
            <Form.Item
              name="customAnimation"
              label="自定义动效"
              tooltip={<div>
                <p>{` 示例：
                  {
                    "type": "from",
                    "x": 80,
                    "opacity": 0,
                    "duration": 500
                  }`
                }</p>
                <p>参考配置： https://motion.ant.design/language/animate-term-cn</p>
              </div>}
            >
              <JsonInput valueType={"object"} />
            </Form.Item>
            <Form.Item
              name="effectMode"
              label="动效模式"
            >
              <Select options={EffectModes.map(item => ({ value: item.setting, label: item.title }))} />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>
      </Form>
    </Modal>
  )
};

export default PictureEdit;
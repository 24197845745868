/*
 * @Description: 
 * @Version: 1.0
 * @Autor: junr
 * @Date: 2021-05-03 14:05:36
 * @LastEditTime: 2023-02-26 20:12:08
 */
import axios from 'axios'
import { Spin, message } from 'antd';
// import { getToken } from 'utils/common'
import ReactDOM from 'react-dom';
import Cookies from 'js-cookie';
import { clearProfile, getToken } from 'utils/auth';


// 当前正在请求的数量
let requestCount = 0

// 显示loading
function showLoading(loadinginfo: string) {
  if (requestCount === 0) {

    let dom = document.createElement('div')
    dom.setAttribute('id', 'loading')
    dom.setAttribute('style', 'loading')
    document.body.appendChild(dom)
    ReactDOM.render(<Spin tip={loadinginfo} size="large" delay={300} />, dom)
  }
  requestCount++
}

// 隐藏loading
function hideLoading() {
  requestCount--
  if (requestCount === 0) {
    document.body.removeChild(document.getElementById('loading') as any)
  }
}

/**
 * 
 * 访问后端数据接口
 * @param {string} url - 访问路径，不带基础路径
 * @param {object} data - 发送数据 
 * @param {string} type - 发送类型：GET、POST
 * @returns {promise}
 */
export const ajax = (url: string, data?: any, type = 'GET', bLoad = true,
  loadinginfo = '加载中....') => {
  // 等待窗口处理，防抖处理
  // 参考 https://zhuanlan.zhihu.com/p/266491467
  if (bLoad) { showLoading(loadinginfo) }
  return new Promise((resolve, reject) => {
    let headers = {
      token: getToken()
    }


    let promise: any;
    if (type.toLowerCase() === 'get') {
      promise = axios.get(url, {
        headers
      });
    } else if (type.toLowerCase() === 'post') {
      promise = axios.post(url, data, {
        headers
      });
    } else if (type.toLowerCase() === 'put') {
      promise = axios.put(url, data, {
        headers
      });
    }
    else if (type.toLowerCase() === 'delete') {
      promise = axios.delete(url, {
        headers
      });
    }

    const sCode = 'Result'

    promise.then((response: any) => {
      if (bLoad) { hideLoading(); }
      // console.log("response",response);

      if (response.status === 200) {
        let res = response.data;
        resolve(res);
        // if (!res.hasOwnProperty(sCode)) {
        //   message.error(`解析数据出错${JSON.stringify(res)}`);
        // } else {
        //   if (res[sCode] = 200) {
        //     // console.log("res",res);
        //     res[sCode] = 0;
        //     resolve(res)
        //   } else {
        //     reject(res)
        //     message.info(`提示信息：${res.Message}（错误码：${res[sCode]}）`)
        //   }
        // }
      } else {
        let res = response.data;
        reject(res);
        // console.log(res);
        // message.error(`接口返回错误：${res.Message}（错误码：${res[sCode]}）`)
      }
    }).catch((error: any) => {
      // loading.style.display = 'none';
      if (bLoad) { hideLoading(); }
      let res = error
      if (typeof (res.response) !== 'undefined') {
        res = error.response
      }
      if (bLoad) {
        message.error(`服务器返回错误：${res.data ? res.data.message : ""}${res.status ? '（错误码：' + res.status + ')' : ""}`)
      }

      //token无效
      if (res.data.code === 401) {
        // Cookies.remove("profile");
        // Cookies.remove("token");    
        clearProfile();
      }
      // reject(res);
    })
  })
}

export const post = (url: string, data?: any, bLoad = true, loadinginfo = '加载中....'): any => {
  return ajax(url, data, 'POST', bLoad, loadinginfo)
}

export const put = (url: string, data?: any, bLoad = true, loadinginfo = '加载中....'): any => {
  return ajax(url, data, 'PUT', bLoad, loadinginfo)
}

export const get = (url: string, bLoad = true, loadinginfo = '加载中....'): any => {
  return ajax(url, {}, 'GET', bLoad, loadinginfo)
}

export const del = (url: string, bLoad = true, loadinginfo = '加载中....'): any => {
  return ajax(url, {}, 'DELETE', bLoad, loadinginfo)
}

/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\common\videoinput\index.jsx
 * @LastEditTime: 2024-04-25 14:59:35
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { UploadFile } from "antd/lib/upload/interface";
import { Modal, Form, Input, Image, Button, Upload, message, Popconfirm, Tooltip } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { NewGuid, getExtension, getFileNameWithOutExt } from 'utils/common';
import { reqGetArticles, reqAddArticle, reqRemoveArticle, reqGetCoverImage } from 'api/api';
import VideoInput from '../videoinput';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  dataOSS?: any;
  value?: any[];
  onChange?: (value: any) => void;
}

//视频轮播地址url
const VideoListInput: React.FC<IProps> = (props: IProps) => {
  const { dataOSS, value, onChange } = props;

  const handleAdd = () => {
    if ((value || []).length === 0) {
      onChange && onChange(['']);
    } else {
      let tempValue = _.cloneDeep(value || []);
      tempValue.push('');
      onChange && onChange(tempValue);
    }
  }

  const handleUpdate = (src, index) => {
    if (index < 0 || index >= (value || []).length) return;
    let tempValue = _.cloneDeep(value || []);
    tempValue[index] = src;
    onChange && onChange(tempValue);
  }

  const handleRemove = (index) => {
    if (index < 0 || index >= (value || []).length) return;
    let tempValue = _.cloneDeep(value);
    _.pullAt(tempValue, index);
    onChange && onChange(tempValue);
  }

  return (
    <div className={styles.container}>
      <div className={styles.row} >
        <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd} >
          
        </Button>
      </div>
      {(value || []).map((item, index) => (
        <div key={`videoitem${index}`} className={styles.row}>
          <VideoInput dataOSS={dataOSS} value={item} onChange={(src) => { handleUpdate(src, index) }} />
          <Popconfirm
            title={`确认删除?`}
            onConfirm={() => { handleRemove(index) }}>
            <Button type="primary" icon={<MinusOutlined />} />
          </Popconfirm>
        </div>
      ))}
    </div>
  );
};

export default VideoListInput;
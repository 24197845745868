/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\customtext\index.jsx
 * @LastEditTime: 2024-07-26 11:38:14
 */
import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { getAnimation, getMouseAnimation, selectBorderStyle } from 'utils/componentdefine';
import { dealParam, getLocalDownLoadUrl, getDownLoadFileName } from 'utils/common';
import { fontFamilys } from 'utils/componentdefine';
import TweenOne from 'rc-tween-one';
import { OverPack, Parallax } from 'rc-scroll-anim';
import styles from './index.module.scss';

//跳转方式
export const LinkTypes = [{
  id: 0,
  title: '不跳转',
  setting: 'none',
}, {
  id: 1,
  title: '页面跳转',
  setting: '_self',
}, {
  id: 2,
  title: '弹窗跳转',
  setting: '_blank',
}, {
  id: 3,
  title: '下载文件',
  setting: 'downloadFile',
},]

//定位方式
export const Positions = [{
  id: 0,
  title: '默认',
  setting: 'static',
}, {
  id: 1,
  title: '绝对位置',
  setting: 'absolute',
},]



export type CustomTextConfig = {
  type: String;  //类型 container
  key: String; //主键
  title: String; //标题 
  text: String; //内容
  width?: String; //宽度
  height?: String;  //高度 
  fontSize: String; //字体大小
  fontFamily: String; //字体
  color: String;  //颜色
  gradualColor: String; //渐变颜色
  backGroundColor?: String;  //背景色 linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9))   
  fontWeight: Boolean; //粗体
  intalic: Boolean; //斜体
  underline: Boolean; //下划线
  textAlign?: String; //对齐方式
  position?: String; //定位方式
  left?: String; //左 
  top?: String;  //上
  right?: String;  //右
  bottom?: String;  //下
  url?: String;  //外链地址  
  linkType: String;  //跳转方式
  effectType?: String; //动画效果
  customAnimation?: Object; //自定义动效
  effectMode?: String; //动效模式 进场动画/鼠标移入动画
}

interface IProps {
  config: CustomTextConfig;
  effectvisible?: true;
  subParam?: any; //父组件传给子组件的参数
}

//文字
const CustomText: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  let { config, subParam } = props;
  subParam = {
    ...pageParam,
    ...subParam,
  }
  const effectvisible = props.effectvisible;
  const effectType = config.effectType || 'none';
  const effectMode = config.effectMode || 'init';

  const [paused, setPaused] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [custommoment, setCustommoment] = useState(0);

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);

  //滚动条方向
  const scrollDirection = useSelector((state: any) => state.global.scrollDirection);

  //向下滚动效果触发
  useEffect(() => {
    if (effectMode === 'scrolldown') {  //scrolldown
      if (scrollDirection === 1) {
        setPaused(false);
        setReverse(true);
        setCustommoment(null);
      } else {
        setPaused(false);
        setReverse(false);
        setCustommoment(0);
      }
    }
  }, [scrollDirection]);

  const getTxt = () => {
    const className = `${config.intalic ? styles.italic : styles.unitalic} ${config.underline ? styles.underline : styles.ununderline}`;
    let style = {
      width: config.width,
      height: config.height,
      position: config.position,
      left: config?.left || "auto",
      top: config?.top || "auto",
      right: config?.right || "auto",
      bottom: config?.bottom || "auto",
      fontSize: config.fontSize,
      fontFamily: config.fontFamily.replace(/([^,]+)/g, "'$1'"),  //font1,font2=>'font1','font2'
      color: dealParam(config.color, subParam),
      fontWeight: config.fontWeight ? "bold" : "normal",
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      overflow: "hidden",
      textAlign: config?.textAlign || "left",
      whiteSpace: "pre-wrap",
      background: config?.backGroundColor || "transparent",
      // mixBlendMode:"difference",  //反色
    };
    //渐变颜色处理
    if (!_.isEmpty(config?.gradualColor)) {
      style = {
        ...style,
        backgroundImage: config.gradualColor,
        color: 'transparent',
        WebkitBackgroundClip: 'text',
      }
    }
    if (editComponentKey === config.key) {
      style.border = selectBorderStyle;
    }

    if (effectType === 'none') return (
      <div
        className={className}
        style={style}>
        {dealParam(config.text, subParam)}
      </div>
    )

    if (effectMode === 'init') return effectvisible ? (
      <OverPack
        targetId="maincontent"
        style={{ overflow: 'hidden' }}
        replay={true}>
        <TweenOne
          className={className}
          style={style}
          animation={getAnimation(effectType, config?.customAnimation)}
        >
          {dealParam(config.text, subParam)}
        </TweenOne>
      </OverPack>
    ) : ''

    if (effectMode === 'mousein') return (
      <TweenOne
        className={className}
        style={style}
        reverse={reverse}
        moment={custommoment}
        animation={getMouseAnimation(effectType, config?.customAnimation)}
        paused={paused}
        onMouseEnter={() => {
          setPaused(false);
          setReverse(true);
          setCustommoment(null);
        }}
        onMouseLeave={() => {
          setPaused(false);
          setReverse(false);
          setCustommoment(0);
        }}
      >
        {dealParam(config.text, subParam)}
      </TweenOne>
    )

    if (effectMode === 'scrolldown') return (
      <TweenOne
        className={className}
        style={style}
        reverse={reverse}
        moment={custommoment}
        animation={getMouseAnimation(effectType, config?.customAnimation)}
        paused={paused}
      >
        {dealParam(config.text, subParam)}
      </TweenOne>
    )

    if (effectMode === 'screencenter') {
      return (
        <Parallax
          className={className}
          style={style}
          targetId="editcontent"
          animation={{
            playScale: [0.5, 0.8],
            ...getAnimation(effectType, config?.customAnimation),
          }}
        >
          {dealParam(config.text, subParam)}
        </Parallax>
      )
    }
  }

  //处理自定义工作区问题
  let linkUrl = dealParam(config.url, subParam);
  let strs = linkUrl.split("&target=");
  linkUrl = strs[0];
  let linkType;
  if (strs.length > 1) {
    linkType = strs[1];
  }

  return config?.linkType === 'none' ? getTxt() : (
    <a
      href={config?.linkType === 'downloadFile' ? getLocalDownLoadUrl(linkUrl) : linkUrl}
      download={config?.linkType === 'downloadFile' ? getDownLoadFileName(linkUrl) : ''}
      target={linkType || config.linkType}
    >
      {getTxt()}
    </a>
  )
};

export default CustomText;
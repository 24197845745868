/*
 * @Author: your name
 * @Date: 2021-04-22 17:44:14
 * @LastEditTime: 2023-04-11 10:56:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \website\src\utils\auth.ts
 */
import Cookies from "js-cookie";
import _ from "lodash";


export const setProfile = (token: string, userinfo: any) => {
  let originProfile: any = {};
  originProfile[token] = {
    token,
    profile: userinfo
  };
  Cookies.set("profile", JSON.stringify(originProfile));
}

export const clearProfile = (options: any = {}) => {
  Cookies.remove("profile");
  Cookies.remove("profile", options);
}

export const getToken = () => {
  const curProfileStr = Cookies.get("profile") || "";
  if (curProfileStr === "") return "";
  const curProfile = JSON.parse(curProfileStr);
  const profile: any = Object.values(curProfile)[0];
  if (_.isNil(profile)) return "";
  return profile?.token;
}

export const getUserInfo = () => {
  const curProfileStr = Cookies.get("profile") || "";
  if (curProfileStr === "") return null;
  const curProfile = JSON.parse(curProfileStr);
  const profile: any = Object.values(curProfile)[0];
  if (_.isNil(profile)) return null;
  return profile?.profile || {};
}

export const isLogin = () => {
  return !_.isNil(Cookies.get("profile"));
}

import React, { useState, useEffect } from "react";
import _ from 'lodash';
import moment from "moment";
import 'moment/locale/zh-cn'; 
import { useSelector } from "react-redux";
import { selectBorderStyle, DatavDataSource } from 'utils/componentdefine';
import { executeFunctionFilter } from 'utils/common';
import { fontFamilys } from 'utils/componentdefine';
import styles from './index.module.scss';


export type DatavTimerConfig = {
  type: String;  //类型 datavtext
  key: String; //主键
  format: String; //格式
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
  fontSize: String; //字体大小
  fontFamily: String; //字体
  color: String;  //颜色
  gradualColor: String; //渐变颜色
  fontWeight: Boolean; //粗体
  intalic: Boolean; //斜体
  underline: Boolean; //下划线
}

interface IProps {
  config: DatavTimerConfig;
}

//时间器
const DatavTimer: React.FC<IProps> = (props: IProps) => {
  const { config } = props;
  const [txt, setTxt] = useState('');

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);

  const className = `${config.intalic ? styles.italic : styles.unitalic} ${config.underline ? styles.underline : styles.ununderline}`;
  let style = {
    width: config.width,
    height: config.height,
    left: config.left,
    top: config.top,
    fontSize: config.fontSize,
    fontFamily: config.fontFamily.replace(/([^,]+)/g, "'$1'"),  //font1,font2=>'font1','font2'
    color: config.color,
    fontWeight: config.fontWeight ? "bold" : "normal",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    position: "absolute",
  };

  useEffect(() => {
    moment.locale('zh-cn');
    getTxt();
    const interval = setInterval(getTxt, config.format.includes('SSS') ? 10 : 1000);
    // 组件卸载时清除定时器
    return () => {
      clearInterval(interval);
    }
  }, [config])

  //渐变颜色处理
  if (!_.isEmpty(config?.gradualColor)) {
    style = {
      ...style,
      backgroundImage: config.gradualColor,
      color: 'transparent',
      WebkitBackgroundClip: 'text',
    }
  }
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  const getTxt = () => {
    setTxt(moment().format(config.format));
  }

  return (
    <div
      className={className}
      style={style}>
      {txt}
    </div>
  )

};

export default DatavTimer;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\customslider\index.jsx
 * @LastEditTime: 2024-04-09 14:16:51
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { dealParam, dealParamObj, executeFunctionFilter, getData } from 'utils/common';
import { getViewFromConfig } from '../pageview';
import Slider from "react-slick";
import _ from 'lodash';
import CustomApi from '../customapi';
import { reqAgencyRequest } from 'api/api';
import styles from './index.module.scss';

export type CustomSliderConfig = {
  type: String;  //类型 customslider 轮播图
  key: String; //主键
  title: String; //标题
  width: String; //宽度
  height: String; //高度
  infinite: Boolean; //循环播放
  slidesToShow: Number, //每屏显示个数
  slidesToScroll: Number, //每次滚动个数
  arrows: Boolean; //是否显示按钮
  dots: Boolean; //是否显示进度条  
  speed: Number; //滚动速度
  smoothScroll: Boolean; //平滑滚动
  autoplay: Boolean; //自动播放
  autoplaySpeed: Boolean; //自动播放速度
  customConfig: Object; //自定义配置
  useDataSource: Boolean; //是否使用数据源
  data: DatavDataSource; //数据源
  children?: any[];  //轮播图中的内容
}

interface IProps {
  config: CustomSliderConfig;
  subParam?: any; //父组件传给子组件的参数
}

//轮播图  https://react-slick.neostack.com/docs/example/simple-slider
const CustomSlider: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const { config, subParam } = props;
  const [curindex, setCurindex] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log('useEffect');
    if (config?.useDataSource) {
      getData(config.data, pageParam, setData);
      if (config.data.dataSource === 'api' && config.data.requestSec > 0) {
        const interval = setInterval(() => {
          getData(config.data, pageParam, setData);
        }, config.data.requestSec * 1000);
        // 组件卸载时清除定时器
        return () => {
          clearInterval(interval);
        }
      }
    }
  }, [config])


  const handleAfterChange = (current) => {
    setCurindex(current);
  }

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = {
    width: config.width,
    height: config.height,
    display: 'block',
    // padding: '30px',
  };
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  let settings = {
    infinite: config.infinite,
    slidesToShow: config.slidesToShow,
    slidesToScroll: config.slidesToScroll,
    arrows: config.arrows,
    dots: config.dots,
    speed: config.speed,
    cssEase: config.smoothScroll ? 'linear' : '',
    autoplay: config.autoplay,
    autoplaySpeed: config.autoplaySpeed,
    smoothScroll: config.smoothScroll,
    lazyLoad: true,
    ...config.customConfig,
  }

  let showindexs = [];
  for (let i = 0; i < config.slidesToShow; i++) {
    showindexs.push((curindex + i) % (config?.children || []).length);
  }

  const getNormalChildren = () => {
    return (config?.children || []).map((item, index) => {
      let tempitem = {
        ...item,
        index
      }
      return getViewFromConfig([tempitem], showindexs.includes(index), subParam);
    });
  }

  const getDataSourceChildren = () => {
    return data.map((item, index) => {
      return (config?.children || []).map((subitem, subindex) => {
        let tempitem = {
          ...subitem,
          index
        }
        return getViewFromConfig([tempitem], showindexs.includes(index), item);
      })
    })
  }

  return (
    <Slider
      className={styles.slider}
      style={style}
      {...settings}
      afterChange={handleAfterChange}
    >
      {config?.useDataSource ? getDataSourceChildren() : getNormalChildren()}
    </Slider>
  )
};

export default CustomSlider;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageheader\index.tsx
 * @LastEditTime: 2024-03-08 15:56:38
 */

import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { clearLoginInfo } from 'store/slices/globalSlice';
import { Redirect } from "react-router-dom";
import { Tooltip, Modal, Form, Input, Carousel, Image, Button } from 'antd';
import _ from 'lodash';
import logo from 'assets/muzhiyun-logo.png';
import { getUserInfo, isLogin } from "utils/auth";
import styles from './index.module.scss';


const { version } = require('../../../package.json');

interface IProps {
  children?: any;
}

const PageHeader = (props: IProps) => {
  const dispatch = useDispatch();

   const getNickName: any = () => {
    // if (Cookies.get("profile")){
    //   const user=JSON.parse(Cookies.get("profile") as string);
    //   return user.nick;
    // }
    if (isLogin()){
      return getUserInfo().nick;
    }
    
    // if (sessionStorage.getItem("profile")){
    //   const user=JSON.parse(sessionStorage.getItem("profile") as string);
    //   return user.nick;
    // }
    return '';
  }

  const handleLogout=()=>{
    dispatch(clearLoginInfo({}));
    window.location.href = "/login";
  }

  const handleHelp=()=>{
    window.open("/doc/ReadMe.html");
  }

  if (!isLogin()) {
    return <Redirect to={{ pathname: "/login" }} />;
  }
  // if (!sessionStorage.getItem("token")) {
  //   return <Redirect to={{ pathname: "/login" }} />;
  // }

  return (
    <div className={styles.container}>
      {/* <img src={logo} className={styles.logo} alt="logo" />  */}
      <a style={{color:"white"}} href="/version" target="_blank">{`v-${version}`}</a>     
      <div className={styles.center}>
        {props.children}
      </div>
      <div className={styles.right} >
        <span className={styles.nick}>{getNickName()}</span >
        |
        <Button type="link" onClick={handleLogout}>退出</Button>
        <Button type="link" onClick={handleHelp}>帮助</Button>
      </div>
    </div>
  )
};

export default PageHeader;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\pageview\index.jsx
 * @LastEditTime: 2024-07-10 22:30:56
 */
import React, { useState, useEffect } from "react";
import Banner from '../banner';
import Container from '../container';
import PageMenu from '../pagemenu';
import ArticleList from '../articlelist';
import CustomArticleList from '../customarticlelist';
import HtmlView from '../htmlview';
import Picture from '../picture';
import CustomText from '../customtext';
import CustomVideo from '../customvideo';
import CustomAudio from '../customaudio';
import CustomButton from '../custombutton';
import CustomSlider from '../customslider';
import WorkSpace from '../workspace';
import CustomWorkSpace from '../customworkspace';
import CustomApi from '../customapi';
import LoginStatus from '../loginstatus';
import PaperList from '../paperlist';
import CustomFrame from '../customframe';

import DatavText from '../datavtext';
import DatavTimer from '../datavtimer';
import DatavPicture from '../datavpicture';
import DatavBorderBox from '../datavborderbox';
import DatavDecoration from '../datavdecoration';
import DatavNumber from '../datavnumber';
import DatavLineChart from '../datavlinechart';
import DatavAreaChart from '../datavareachart';
import DatavColumnChart from '../datavcolumnchart';
import DatavBarChart from '../datavbarchart';
import DatavPieChart from '../datavpiechart';
import DatavScatterChart from '../datavscatterchart';
import DatavRadarChart from '../datavradarchart';
import DatavLiquidChart from '../datavliquidchart';
import DatavWordCloudChart from '../datavwordcloudchart';
import DatavActiveRingChart from '../datavactiveringchart';
import DatavCapsuleChart from '../datavcapsulechart';
import DatavPercentPond from '../datavpercentpond';
import DatavScrollBoard from '../datavscrollboard';
import DatavScrollRankingBoard from '../datavscrollrankingboard';
import DatavVideo from '../datavvideo';
import DatavFrame from '../datavframe';
import DatavGroup from '../datavgroup';
import { reqGetArticles } from 'api/api';
import _ from 'lodash';
import { NewGuid } from "utils/common";

interface IProps {
  config: any;
}

//subParam: 传给子组件的参数
export const getViewFromConfig = (config, effectvisible = true, subParam) => {
  if (_.isNil(config) || config.length === 0) return '';
  const handleGetArticles = async (articlegroupid: String) => {
    const res = await reqGetArticles({ articlegroupid });
    return res.items;
  }

  const handleGetArticleContent = async (articleid: String) => {
    const res = await reqGetArticles({ articleid });
    if (res.items.length === 1) {
      return res.items[0].content;
    } else {
      return '';
    }
    return res.items;
  }

  return config.map((item, index) => {
    const props = {
      config: item,
      // key: NewGuid(),
    }
    switch (item.type) {
      case 'banner':
        return (<Banner {...props} subParam={subParam} />)
      case 'container':
        return (<Container {...props} effectvisible={effectvisible} subParam={subParam} />)
      case 'pagemenu':
        return (<PageMenu {...props} subParam={subParam} />)
      case 'picture':
        return (<Picture {...props} subParam={subParam} />)
      case 'customvideo':
        return (<CustomVideo {...props} subParam={subParam} />)
      case 'customaudio':
        return (<CustomAudio {...props} subParam={subParam} />)
      case 'customtext':
        return (<CustomText {...props} effectvisible={effectvisible} subParam={subParam} />)
      case 'custombutton':
        return (<CustomButton {...props} subParam={subParam} />)
      case 'customslider':
        return (<CustomSlider {...props} />)
      case 'workspace':
        return (<WorkSpace {...props} />)
      case 'customworkspace':
        return (<CustomWorkSpace {...props} />)
      case 'customapi':
        return (<CustomApi {...props} />)
      case 'loginstatus':
        return (<LoginStatus {...props} />)
      case 'paperlist':
        return (<PaperList {...props} />)
      case 'customframe':
        return (<CustomFrame {...props} />)
      case 'articlelist':
        return (<ArticleList
          {...props}
          getItems={handleGetArticles}
        />)
      case 'customarticlelist':
        return (<CustomArticleList
          {...props}
          getItems={handleGetArticles}
        />)
      case 'htmlview':
        return (<HtmlView
          {...props}
          getArticleContent={handleGetArticleContent} />)

      //以下是datav组件
      case 'datavtext':
        return (<DatavText {...props} />)
      case 'datavtimer':
        return (<DatavTimer {...props} />)
      case 'datavpicture':
        return (<DatavPicture {...props} />)
      case 'datavborderbox':
        return (<DatavBorderBox {...props} />)
      case 'datavdecoration':
        return (<DatavDecoration {...props} />)
      case 'datavnumber':
        return (<DatavNumber {...props} />)
      case 'datavlinechart':
        return (<DatavLineChart {...props} />)
      case 'datavareachart':
        return (<DatavAreaChart {...props} />)
      case 'datavcolumnchart':
        return (<DatavColumnChart {...props} />)
      case 'datavbarchart':
        return (<DatavBarChart {...props} />)
      case 'datavpiechart':
        return (<DatavPieChart {...props} />)
      case 'datavscatterchart':
        return (<DatavScatterChart {...props} />)
      case 'datavradarchart':
        return (<DatavRadarChart {...props} />)
      case 'datavliquidchart':
        return (<DatavLiquidChart {...props} />)
      case 'datavwordcloudchart':
        return (<DatavWordCloudChart {...props} />)
      case 'datavactiveringchart':
        return (<DatavActiveRingChart {...props} />)
      case 'datavcapsulechart':
        return (<DatavCapsuleChart {...props} />)
      case 'datavpercentpond':
        return (<DatavPercentPond {...props} />)
      case 'datavscrollboard':
        return (<DatavScrollBoard {...props} />)
      case 'datavscrollrankingboard':
        return (<DatavScrollRankingBoard {...props} />)
      case 'datavvideo':
        return (<DatavVideo {...props} />)
      case 'datavframe':
        return (<DatavFrame {...props} />)
      case 'datavgroup':
        return (<DatavGroup {...props} />)

      default:
        return '';
    }
  })
}


const PageView: React.FC<IProps> = (props: IProps) => {
  const getViewFromConfig = (config) => {
    return (
      <div>
        {config.map(item => {
          switch (item.type) {
            case 'banner':
              return (<Banner config={item} />)
            default:
              return '';
          }
        })}
      </div>
    )
  }

  return (
    <div>
      {getViewFromConfig(props.config)}
    </div>

  )
};

export default PageView;
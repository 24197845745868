/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\banner\index.jsx
 * @LastEditTime: 2024-03-26 16:34:55
 */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { Carousel } from 'antd';
import TweenOne from 'rc-tween-one';
import { getViewFromConfig } from '../pageview';
import _ from 'lodash';
import { EditOutlined, DeleteOutlined, CaretUpOutlined, CaretDownOutlined, CopyOutlined } from '@ant-design/icons';
import styles from './index.module.scss';

//图片缩放
export const ImageScales = [{
  id: 0,
  title: '铺满',
  setting: 'cover',  //css background-size:cover;
}, {
  id: 1,
  title: '适应高度',
  setting: 'auto 100%',
}, {
  id: 2,
  title: '适应宽度',
  setting: '100% auto',
}, {
  id: 3,
  title: '不缩放',
  setting: 'auto',
}]

//图片效果
export const ImageEffects = [{
  id: 0,
  title: '默认',
  setting: 'scroll',  //css background-attachment:scroll;
}, {
  id: 1,
  title: '固定，不跟随页面滚动',
  setting: 'fixed',
}]

//切换效果
export const ScrollEffects = [{
  id: 0,
  title: '滚动',
  setting: 'scrollx',  //<Carousel effect="scrollx" />
}, {
  id: 1,
  title: '渐变',
  setting: 'fade',
}]

//跳转方式
export const LinkTypes = [{
  id: 0,
  title: '不跳转',
}, {
  id: 1,
  title: '页面跳转',
}, {
  id: 2,
  title: '弹窗跳转',
},]

export type BannerConfig = {
  type: string;  //类型
  width?: String; //宽度
  height?: String;  //高度 默认值是800px
  imageScale?: Number;   //图片缩放
  imageEffect?: Number;  //图片效果
  autoPlay?: Boolean; //是否自动切换 
  scrollEffect?: Number; //切换效果
  images: {
    imageUrl?: String;
    linkType?: Number;  //跳转方式 
    linkUrl?: String;  //跳转链接      
  }[],
  children?: any[];  //子配置
}

interface IProps {
  config: BannerConfig;
  subParam?: any; //父组件传给子组件的参数
}

//首页
const Banner: React.FC<IProps> = (props: IProps) => {
  const [curindex, setCurindex] = useState(0);
  const history = useHistory();
  const { config, subParam } = props;

  const handleAfterChange = (current) => {
    setCurindex(current);
  }

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = {};
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  return (
    <Carousel
      arrows={true}
      prevArrow={(<CopyOutlined />)}
      nextArrow={(<CopyOutlined />)}
      autoplay={config?.autoPlay || true}
      effect={_.find(ScrollEffects, o => o.id === config?.scrollEffect)?.setting || "scrollx"}
      afterChange={handleAfterChange}
      style={style}
    >
      {(config?.children || []).map((item, index) => {
        return getViewFromConfig([item], index === curindex,subParam);
      })}
      {/* {getViewFromConfig(config?.children)} */}
      {/* {config?.images.map((item, index) => (
        <div key={`image${index}`}
          style={{
            position: "relative",
            width: "100%",
          }}>
          <div
            style={{
              width: config.width,
              height: config.height,
              cursor: item.linkType === 0 ? "default" : "pointer",
              backgroundPosition: "50% 50%",
              backgroundRepeat: "no-repeat",
              overflow: "hidden",
              display: "block",
              outline: "none",
              margin: "0px auto",
              position: "relative",
              backgroundSize: _.find(ImageScales, o => o.id === config?.imageScale)?.setting || "conver",
              backgroundImage: `url(${item.imageUrl})`,
              backgroundAttachment: _.find(ImageEffects, o => o.id === config?.imageEffect)?.setting || "scroll",
            }}
            onClick={() => {
              switch (item.linkType) {
                case 1:
                  history.push(item.linkUrl);
                  break;
                case 2:
                  window.open(item.linkUrl);
                  break;
                default:
                  break;
              }
            }}
          >
            {item.subImages.map((subImage, imageIndex) => (
              <div
                key={`subimage${imageIndex}`}
                style={{
                  width: subImage.width,
                  height: subImage.height,
                  top: subImage.top,
                  left: subImage.left,
                  backgroundRepeat: "no-repeat",
                  position: "absolute",
                  backgroundSize: "cover",
                  backgroundImage: `url(${subImage.imageUrl})`,
                }}>
              </div>
            ))}
            {item.subTexts.map((subText, textIndex) => {
              return true ? (
            //     <TweenOne
            //   animation={{
            //     type: "from",
            //     y: -80,
            //   }}
            // >
              <div
                key={`subtext${textIndex}`}
                className={`${subText.intalic ? styles.italic : styles.unitalic} ${subText.underline ? styles.underline : styles.ununderline}`}
                style={{
                  width: subText.width,
                  height: subText.height,
                  left: subText.left,
                  top: subText.top,
                  fontSize: subText.fontSize,
                  fontFamily: subText.fontFamily,
                  backgroundColor: "transparent",
                  color: subText.color,
                  fontWeight: subText.bold ? "bold" : "normal",
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                {subText.text}
              </div>
              // </TweenOne>
              ) : ''
              
              })}
          </div>
        </div>
      ))} */}
    </Carousel>
  )
};

export default Banner;
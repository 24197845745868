/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2024-07-10 22:18:35
 * @FilePath: \website\src\components\datavframe\index.jsx
 * @LastEditTime: 2024-07-10 22:51:31
 */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import { dealParam } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';


export type DatavFrameConfig = {
  type: String;  //类型 datavframe
  key: String; //主键
  src: String; //地址
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
}

interface IProps {
  config: DatavFrameConfig;
}

//嵌套页面
const DatavFrame: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  const { config } = props;
  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);

  const getStyle = () => {
    let style = {
      width: config.width,
      height: config.height,
      left: config.left,
      top: config.top,
      position: "absolute",
    };
    if (editComponentKey === config.key) {
      style.border = selectBorderStyle;
    }
    return style;
  }


  return (
    <iframe
      style={getStyle()}
      frameBorder={0}
      src={config.src}
    />
  )
};

export default DatavFrame;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\articlelist\index.jsx
 * @LastEditTime: 2023-03-06 16:52:52
 */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import _ from 'lodash';
import { getViewFromConfig } from '../pageview';
import styles from './index.module.scss';

export type CustomArticleListConfig = {
  type: String;  //类型 customarticlelist
  key: String; //主键
  title: String; //标题
  articlegroupid: String; //文章分类
  subitemcount: Number; //子项个数
  children?: any[];  //子配置
}

interface IProps {
  config: CustomArticleListConfig;
  getItems: (articlegroupid: String) => any;
}

//自定义文章列表 返回items中有： index,id,coverImage,url,title
const CustomArticleList: React.FC<IProps> = (props: IProps) => {
  const { config, getItems } = props;
  const [items, setItems] = useState([]);

  const urlSearch = new URLSearchParams(useLocation().search);
  const articlegroupid = urlSearch.get('articlegroupid') || 'empty';

  useEffect(async () => {
    if (config.articlegroupid === 'outid') {
      const tempItems = await getItems(articlegroupid);
      setItems(tempItems);
    } else {
      const tempItems = await getItems(config.articlegroupid || 'empty');
      setItems(tempItems);
    }
  }, [config.articlegroupid])

  //index,id,coverImage,url,title
  return items && _.take(items, config.subitemcount).map((item: any, index: Number) => {
    const subParam = {
      index: index + 1,
      id: item.id,
      coverImage: item.coverImage,
      url: item.originalType === 101 ? item.url : `/article?articleid=${item.id}`,
      title: item.title,
    }
    return getViewFromConfig(config.children, true, subParam);
  })
};

export default CustomArticleList;
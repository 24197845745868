
import React, { useState, useEffect } from "react";
import _ from 'lodash';
import { useSelector } from "react-redux";
import { selectBorderStyle } from 'utils/componentdefine';
import * as datav from '@jiaminghi/data-view-react';
import styles from './index.module.scss';


export type DatavBorderBoxConfig = {
  type: String;  //类型 datavborderbox
  key: String; //主键
  borderBoxType: String; //类型 
  left: Number; //左 
  top: Number;  //上
  width: Number; //宽度
  height: Number;  //高度 
}

interface IProps {
  config: DatavBorderBoxConfig;
}

//边框
const DatavBorderBox: React.FC<IProps> = (props: IProps) => {
  const { config } = props;

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);

  let style = {
    width: config.width,
    height: config.height,
    left: config.left,
    top: config.top,
    position: "absolute",
  };

  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  switch (config.borderBoxType) {
    case 'BorderBox1':
      return (<datav.BorderBox1 style={style}></datav.BorderBox1>)
    case 'BorderBox2':
      return (<datav.BorderBox2 style={style}></datav.BorderBox2>)
    case 'BorderBox3':
      return (<datav.BorderBox3 style={style}></datav.BorderBox3>)
    case 'BorderBox4':
      return (<datav.BorderBox4 style={style}></datav.BorderBox4>)
    case 'BorderBox5':
      return (<datav.BorderBox5 style={style}></datav.BorderBox5>)
    case 'BorderBox6':
      return (<datav.BorderBox6 style={style}></datav.BorderBox6>)
    case 'BorderBox7':
      return (<datav.BorderBox7 style={style}></datav.BorderBox7>)
    case 'BorderBox8':
      return (<datav.BorderBox8 style={style}></datav.BorderBox8>)
    case 'BorderBox9':
      return (<datav.BorderBox9 style={style}></datav.BorderBox9>)
    case 'BorderBox10':
      return (<datav.BorderBox10 style={style}></datav.BorderBox10>)
    case 'BorderBox11':
      return (<datav.BorderBox11 style={style}></datav.BorderBox11>)
    case 'BorderBox12':
      return (<datav.BorderBox12 style={style}></datav.BorderBox12>)
    case 'BorderBox13':
      return (<datav.BorderBox13 style={style}></datav.BorderBox13>)
    default:
      return (<datav.BorderBox1 style={style}></datav.BorderBox1>)
  }
};

export default DatavBorderBox;
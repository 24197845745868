import { message } from 'antd';
/*
 * @Author: your name
 * @Date: 2021-04-22 17:44:14
 * @LastEditTime: 2024-08-28 11:30:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \website\src\utils\common.ts
 */
import Cookies from "js-cookie";
import _ from "lodash";
import html2canvas from 'html2canvas';
import moment from "moment";
import { DatavDataSource } from './componentdefine';
import { reqAgencyRequest } from 'api/api';

export const isNull = (val: any) => {
  if (
    val === null ||
    val === undefined ||
    val === "" ||
    val === "null" ||
    val === "undefined"
  ) {
    return true;
  } else if (val == 0 || val == "0") {
    return false;
  } else {
    return false;
  }
};

export const swap = (arr: any[], index1: number, index2: number) => {
  var temp = arr[index1];
  arr[index1] = arr[index2];
  arr[index2] = temp;
  return arr;
};

export const momentTime2millisecond = (time: any) => {
  return time
    ? ((time.hour() * 60 + time.minute()) * 60 + time.second()) * 1000
    : 0;
};

export const NewGuid = () => {
  var guid = "";
  for (var i = 1; i <= 32; i++) {
    var n = Math.floor(Math.random() * 16.0).toString(16);
    guid += n;
    if (i === 8 || i === 12 || i === 16 || i === 20) guid += "-";
  }
  return guid;
};

// 时间字符串转time
export const string2time = (stime: string) => {
  return new Date(`1976/01/01 ${stime}`);
};

export const OVERTIME = new Date(`1976/01/02 00:00:00`).getTime();
export const BASETIME = new Date(`1976/01/01 00:00:00`).getTime();

/**
 * @description: 时间戳时间转汉字显示
 * @param {number} time
 * @return {*} 汉字时间显示
 */
export const time2ZH = (time: number) => {
  time = Math.round(time / 1000);
  // 小时
  const hour = Math.floor(time / 3600);
  let sRes = hour === 0 ? "" : hour + "小时";
  time = time - hour * 3600;
  // 分钟
  const minute = Math.floor(time / 60);
  sRes = minute === 0 ? sRes : sRes + minute + "分";
  time = time - minute * 60;
  // 秒
  const second = time;
  sRes = second === 0 ? sRes : sRes + second + "秒";

  sRes = sRes === "" ? "0秒" : sRes;
  return sRes;
};

/**
 * @description: 对象深度复制
 * @param {type}
 * @return:
 */
export const deepClone = (source: any) => {
  const targetObj: any = source.constructor === Array ? [] : {}; // 判断复制的目标是数组还是对象
  for (let keys in source) {
    // 遍历目标
    if (source.hasOwnProperty(keys)) {
      if (source[keys] && typeof source[keys] === "object") {
        // 如果值是对象，就递归一下
        targetObj[keys] = source[keys].constructor === Array ? [] : {};
        targetObj[keys] = deepClone(source[keys]);
      } else {
        // 如果不是，就直接赋值
        targetObj[keys] = source[keys];
      }
    }
  }
  return targetObj;
};

export const getCookie = (sFind: string) => {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(sFind + "=");
    if (c_start != -1) {
      c_start = c_start + sFind.length + 1;
      let c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) c_end = document.cookie.length;
      // console.log(unescape(document.cookie.substring(c_start, c_end)))
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
};

// field: token, profile
const strProfile = "profile";
const strToken = "token";

// tokenObj
// {
//   token: "n9nwdSV38AnSUemquRUq79+QtssPgrhKXsGQk",
//   profile: {
//     accountid: "9208750090",
//     isAdmin: true,
//     nick: "王斌",
//     test: true,
//     userId: "96D4CF1E-4F1D-466C-BDB4-D497C11FA5E0",
//     userMobilePhone: "18612934289",
//     userTrueName: "王斌",
//   },
//   userinfo: {}
// }
// export const getCookiesToken = (field: string) => {
//   if (Cookies.get("profile")) {
//     let cookiesObj = JSON.parse(Cookies.get("profile") as string);
//     // let cookiesObj = JSON.parse(getCookie('profile') as string)
//     let cookiesKesys = Object.keys(cookiesObj);
//     for (let i = 0; i < cookiesKesys.length; i++) {
//       const tokenObj = cookiesObj[cookiesKesys[i]];
//       let tokenKeys = Object.keys(tokenObj);

//       if (tokenKeys.indexOf(strToken) >= 0) {
//         return tokenObj[field];
//       }
//     }
//   }
//   return null;
// };

/**
 * @description: 获取音频文件长度
 * @param {any} file
 * @return {*}
 */
export const getDuration = (file: any) => {
  return new Promise((resolve, reject) => {
    window.URL = window.URL || window.webkitURL;
    const url = window.URL.createObjectURL(file);

    const audio = new Audio(url);
    audio.preload = "metadata";

    audio.onloadedmetadata = function () {
      window.URL.revokeObjectURL(audio.src);
      let duration = audio.duration;
      resolve(duration * 1000);
    };

    audio.onerror = function () {
      reject(0);
    };
  });
};

//   profile: {
//     accountid: "9208750090",
//     isAdmin: true,
//     nick: "王斌",
//     test: true,
//     userId: "96D4CF1E-4F1D-466C-BDB4-D497C11FA5E0",
//     userMobilePhone: "18612934289",
//     userTrueName: "王斌",
//   },
// export const getUser: any = () => {
//   let userProfile = getCookiesToken(strProfile);
//   if (userProfile) return userProfile;
//   if (sessionStorage.getItem("profile"))
//     return JSON.parse(sessionStorage.getItem("profile") as string);
//   return null;
// };

// export const getToken = () => {
//   let token = getCookiesToken(strToken);
//   if (token) return token;
//   return sessionStorage.getItem("token");
// };

/**
 * param 将要转为URL参数字符串的对象
 * key URL参数字符串的前缀
 * encode true/false 是否进行URL编码,默认为true
 * return URL参数字符串
 */
export function urlEncode(param: any, key: string | null, encode: boolean) {
  if (param == null) return "";
  let paramStr = "";
  let t = typeof param;
  if (t === "string" || t === "number" || t === "boolean") {
    paramStr +=
      "&" +
      key +
      "=" +
      (encode == null || encode ? encodeURIComponent(param) : param);
  } else {
    for (let i in param) {
      let k =
        key == null
          ? i
          : key + (param instanceof Array ? "[" + i + "]" : "." + i);
      paramStr += urlEncode(param[i], k, encode);
    }
  }
  return paramStr;
}


/**
 * 序列化参数对象
 * @param param 参数对象
 * @param key 
 * @param encode 是否编码
 */
export function parseParam(
  param: any,
  key: string | null = null,
  encode: boolean = true
) {
  return urlEncode(param, key, encode).replace(/&/, "?");
}

export const isColor = (strColor: string) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
}

//const template = '按钮是{1{title}}'; obj={title: 'test'}
//字符串模板实现
export const templateRender = (template: string, obj: any) => {
  if (_.isNil(obj)) return template;
  let reg = /{{(.*?)}}/g
  let res = template.replace(reg, (item, key) => {
    return obj[key]
  });
  return res;
}

// 获取 .后缀名
export const getExtension = (name: string) => {
  return name.substring(name.lastIndexOf("."))
}
//获取文件名 https://images.muzhiyun.cn/infomediaWeb/home/paw5000.png  -> paw5000.png
export const getFileName = (name: string) => {
  const pos = name.lastIndexOf("/");
  return name.substring(pos + 1);
}
//获取文件名不带后缀 https://images.muzhiyun.cn/infomediaWeb/home/paw5000.png -> paw5000
export const getFileNameWithOutExt = (name: string) => {
  const pos = name.lastIndexOf("/");
  const tempname = name.substring(pos + 1);
  return tempname.substring(0, tempname.lastIndexOf("."));
}

const dealDate = (templateStr: any) => {
  const BaseDate = moment('1970-01-01');
  let tempStr = templateStr;
  if (tempStr.includes('today')) {
    tempStr = tempStr.replaceAll('today', moment().startOf('day').diff(BaseDate, 'days'));
  }
  if (tempStr.includes('yearstart')) {
    tempStr = tempStr.replaceAll('yearstart', moment().startOf('year').diff(BaseDate, 'days'));
  }
  if (tempStr.includes('yearend')) {
    tempStr = tempStr.replaceAll('yearend', moment().endOf('year').diff(BaseDate, 'days'));
  }
  if (tempStr.includes('quarterstart')) {
    tempStr = tempStr.replaceAll('quarterstart', moment().startOf('quarter').diff(BaseDate, 'days'));
  }
  if (tempStr.includes('quarterend')) {
    tempStr = tempStr.replaceAll('quarterend', moment().endOf('quarter').diff(BaseDate, 'days'));
  }
  if (tempStr.includes('monthstart')) {
    tempStr = tempStr.replaceAll('monthstart', moment().startOf('month').diff(BaseDate, 'days'));
  }
  if (tempStr.includes('monthend')) {
    tempStr = tempStr.replaceAll('monthend', moment().endOf('month').diff(BaseDate, 'days'));
  }
  if (tempStr.includes('weekstart')) {
    tempStr = tempStr.replaceAll('weekstart', moment().startOf('week').diff(BaseDate, 'days'));
  }
  if (tempStr.includes('weekend')) {
    tempStr = tempStr.replaceAll('weekend', moment().endOf('week').diff(BaseDate, 'days'));
  }
  let tempNum = moment().startOf('day').diff(BaseDate, 'days');
  try {
    tempNum = eval(tempStr);
  } catch (error) {

  }
  return moment(tempNum * (1000 * 60 * 60 * 24)).format('YYYY-MM-DD');
}

//处理组件属性中的跟日期相关的数据 @{today} @{monthstart+1}
export const dealDateParam: any = (templateStr: any) => {
  if (_.isNil(templateStr)) return templateStr;
  const reg = /\@\{(.*?)\}/
  const temRes = templateStr.match(reg);
  if (_.isNil(temRes)) return templateStr;
  const tempStr = templateStr.replace(reg, dealDate(temRes[1]));
  return dealDateParam(tempStr);
}

//处理组件属性中的参数模板 如：'标题: ${title} 地址:${url} id:${id}' ${}中的内容替换为参数中的内容
export const dealParam: any = (templateStr: String, param: any) => {
  if (_.isNil(templateStr) || _.isNil(param)) return dealDateParam(templateStr);
  const reg = /\$\{(.*?)\}/
  const temRes = templateStr.match(reg);
  if (_.isNil(temRes)) return dealDateParam(templateStr);
  // return _.at(param,temRes[1]);
  const tempStr = templateStr.replace(reg, _.at(param, temRes[1])[0]);
  return dealParam(tempStr, param);
}

//处理组件属性中的参数模板,参数为对象，只处理第一层，且value为字符串的
export const dealParamObj: any = (templateObj: any, param: any) => {
  let tempObj = _.cloneDeep(templateObj);
  for (let key in tempObj) {
    if (typeof tempObj[key] === 'string') {
      tempObj[key] = dealParam(tempObj[key], param);
    }
  }
  return tempObj;
}

export const loadFont = (font: any) => {
  const fontStyles = `
  @font-face {
    font-family: '${font.title}';
    src: url('${font.url}') format('truetype');
  }
`;
  const styleElement = document.createElement('style');
  styleElement.textContent = fontStyles;
  document.head.appendChild(styleElement);
}

//检查字符串是否是一个合法的JSON结构
export const checkJSON = (str: string, valueType: 'object' | 'array' = 'object') => {
  try {
    const value = JSON.parse(str);
    if (valueType === 'array') {
      return Array.isArray(value);
    } else {
      return !Array.isArray(value);
    }
  } catch (error) {
    return false;
  }
}

const escapeSpecialCharacters = (str: string) => {
  return str.replace(/\\/g, '\\\\')  // 转义斜杠
    .replace(/"/g, '\\"')     // 转义双引号
    .replace(/\n/g, '\\n')    // 转义换行符
    .replace(/\r/g, '\\r')    // 转义回车符
    .replace(/\t/g, '\\t');   // 转义制表符
}

/**
 * 传入代码及参数，实现执行代码并返回结果
 * var code = 'function(x) { return x * x; }';
    var args = 5;
    var result = executeFunction(code, args);
    console.log(result); // 输出 25
 * @param code 
 * @param args 
 * @returns 
 */
export const executeFunction = (code: string, args: any) => {
  // 构建函数体
  // var functionBody = `(${code})(JSON.parse('${JSON.stringify(args)}'))`;
  var functionBody = `(${code})(JSON.parse('${escapeSpecialCharacters(JSON.stringify(args))}'))`;

  // 使用 eval 执行函数体
  var result = eval(functionBody);
  // 返回执行结果
  return result;
}

/**
 * 过滤器使用,code结构为 function(res) { **** }  ****部分
 * @param code 
 * @param args 
 */
export const executeFunctionFilter = (code: string, args: any) => {
  const mycode = `function(res) { ${code} }`;
  return executeFunction(mycode, args);
}

/**
 * 字符串转数字，如果不符合要求则为0
 * @param str 
 * @returns 
 */
export const convertToNumber = (str: any) => {
  var number = parseFloat(str);
  if (isNaN(number)) {
    return 0;
  }
  return number;
}


/**
 * 用于对成组的datav组件，设置组的left top width height，改变children及children的children的位置
 * @param item 
 * @param left 
 * @param top 
 * @param width 
 * @param height 
 */
export const setItemPosition = (item: any, left: number, top: number, width: number, height: number) => {
  if (item.type === 'datavgroup') {
    item.children.forEach((child: any) => {
      //left top:new  item:old
      const templeft = (child.left - item.left) * width / item.width + left;
      const temptop = (child.top - item.top) * height / item.height + top;
      const tempwidth = child.width * width / item.width;
      const tempheight = child.height * height / item.height;
      setItemPosition(child, templeft, temptop, tempwidth, tempheight);
    });
  }
  item.left = left;
  item.top = top;
  item.width = width;
  item.height = height;
}

//下载文件
export const downLoadFile = (url: string) => {
  if (!url) return;
  let fileUrl = url.replace(process.env.REACT_APP_IMAGE_OSS_URL || '', '/imageoss');
  // 文件名
  let fileName = getFileName(fileUrl);
  let index = fileName.indexOf("_");
  fileName = fileName.substring(index + 1);

  // 创建一个隐藏的<a>标签
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.target = 'downloadFile';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

//获取本地化链接地址
export const getLocalDownLoadUrl = (url: string) => {
  return url.replace(process.env.REACT_APP_IMAGE_OSS_URL || '', '/imageoss');
}

//url规则为 xxx/guid_filename.xxx  返回filename.xxx
export const getDownLoadFileName = (url: string) => {
  let fileUrl = url.replace(process.env.REACT_APP_IMAGE_OSS_URL || '', '/imageoss');
  // 文件名
  let fileName = getFileName(fileUrl);
  let index = fileName.indexOf("_");
  fileName = fileName.substring(index + 1);
  return fileName;
}

//截屏并保存到阿里云平台
export const screenShotAndSaveAliOss = async (docid: string, dataOSS: any) => {
  const element: any = document.getElementById(docid);
  const canvas = await html2canvas(element, {
    // dpi: 300,
    useCORS: true,
    allowTaint: false,
    logging: false,
    scale: 2
  });

  const blob: any = await new Promise((resolve, reject) => {
    canvas.toBlob((b) => {
      resolve(b);
    });
  });

  const url = dataOSS.key + NewGuid() + ".png";
  const formData = new FormData();
  formData.append('key', url);
  formData.append('OSSAccessKeyId', dataOSS.accessid);
  formData.append('policy', dataOSS.policy);
  formData.append('signature', dataOSS.signature);
  formData.append('file', blob);

  // 发起 POST 请求上传文件
  const response = await fetch(dataOSS.host, {
    method: 'POST',
    body: formData
  });

  if (response.ok) {
    return {
      success: true,
      url: response.url + url,
    }
  } else {
    return {
      success: false,
      message: "File upload failed"
    }
  }
}

//datav组件获取数据
export const getData = (data: DatavDataSource, pageParam: any, setData: any) => {
  if (data.dataSource === 'static') {
    setData(data.staticData);
  } else if (data.dataSource === 'api') {
    const params = {
      "requestUrl": dealParam(data.requestUrl, pageParam),
      "requestType": data.requestType,
      "headers": data.headers,
      "body": dealParamObj(data.body, pageParam),
    }
    reqAgencyRequest(params).then(res => {
      try {
        const result = executeFunctionFilter(data.filters, res);
        setData(result);
      } catch (error: any) {
        console.log('apierror', error.message);
      }
    })
  } else {
    try {
      const result = executeFunctionFilter(data.param, pageParam);
      setData(result);
    } catch (error: any) {
      console.log('paramerror', error.message);
    }

  }
}
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\main\index.tsx
 * @LastEditTime: 2024-04-10 15:33:03
 */

import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isLogin } from "utils/auth";
import { Tooltip, Modal, Form, Input, Carousel, Image, Button } from 'antd';
import _ from 'lodash';
import { reqAgencyRequest, reqGetArticles, reqGetPages, reqGetWorkSpaces, reqGetWorkSpacesByUser } from 'api/api';
import { setApiParam, setBaseParam, setOrganization, setPageApis, setPageParam, setScrollTop, setUrlParam, setWorkSpaces } from "store/slices/globalSlice";
import { getViewFromConfig } from 'components/pageview';
import styles from './index.module.scss';
import { dealParam, dealParamObj, executeFunctionFilter, loadFont, NewGuid } from "utils/common";
import { DatavDataSource, defaultDatavBgUrl } from "utils/componentdefine";


//首页
const Main: React.FC = () => {
  const dispatch = useDispatch();

  const [pages, setPages] = useState<any>([]);
  const [page, setPage] = useState<any>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); //当前窗口的宽度
  const [screenHeight, setScreenHeight] = useState(window.innerHeight); //当前窗口的高度

  // const UE=window.UE;
  const pathname = useLocation().pathname;
  const host = window.location.host;
  const urlParams = new URLSearchParams(window.location.search);

  const pageParam = useSelector((state: any) => state.global.pageParam);
  const baseParam = useSelector((state: any) => state.global.baseParam);
  const apiParam = useSelector((state: any) => state.global.apiParam);
  const pageApis = useSelector((state: any) => state.global.pageApis);

  useEffect(() => {
    document.title = '';
    reqGetPages({ templateurl: host }).then(res => {
      console.log('reqGetPages', res);
      setPages(res.items);
      if (res?.items) {
        const temppage = _.find(res.items, o => o.pageurl === pathname);
        console.log(temppage);
        setPageFromConfig(temppage);
        document.title = temppage?.title;
        //注册Scroll事件 +++++++++++++++++++
        // const element= document.getElementById('root'); 
        // console.log('element',element);
        // window.addEventListener('scroll', handleScroll,true);
      }
    })

    // 监听窗口大小变化
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      // 清除监听
      window.removeEventListener('resize', handleResize);
    };

  }, [])


  useEffect(() => {
    console.log('pathname', pathname);
    const temppage = _.find(pages, o => o.pageurl === pathname);
    setPageFromConfig(temppage);
  }, [pathname])

  const setPageFromConfig = (myPage: any) => {
    if (myPage) {
      const isMobile = /iPhone|iPad|Android/i.test(navigator.userAgent);
      if (myPage.pageStyle === 'pc' && !_.isEmpty(myPage.redirectUrl) && isMobile) {
        //跳转手机端页面
        window.location.href = myPage.redirectUrl;
      } else {
        setPage(myPage);
        //页面配置中的参数
        let pageparams = myPage?.pageparams || {};

        //将url的参数转成对象
        let urlParam = Array.from(urlParams.entries()).reduce((acc: any, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});

        //将参数放到公共变量中
        dispatch(setUrlParam(urlParam || {}));
        dispatch(setBaseParam(pageparams?.baseParam || {}));
        dispatch(setPageApis(pageparams?.pageApis || []));

        //获取工作区
        if (isLogin()) {
          reqGetWorkSpacesByUser(host).then(res => {
            console.log(res);
            dispatch(setWorkSpaces(res.Data));
          })
        } else {
          reqGetWorkSpaces(myPage?.organizationId || '', host).then(res => {
            dispatch(setWorkSpaces(res.Data));
          })
        }

        //加载自定义字体
        reqGetArticles({
          organizationId: myPage?.organizationId,
          originalType: 10,
          page: 1,
          count: 999
        }).then(res => {
          console.log('refreshFonts', res.items);
          res.items.forEach((item: any) => {
            loadFont(item);
          });
        })

        //这里没有返回Organization对象，因此只有创建一个只有id的机构对象
        dispatch(setOrganization({ organizationGuid: myPage?.organizationId }));
      }

    } else {
      //处理/article?articleid=123 和 /articlepagelist?articlegroupid=1          
      switch (pathname) {
        case '/article':
          setPage({
            id: NewGuid(),
            name: 'article',
            pagecontent: [{
              type: 'htmlview',
              key: NewGuid(),
              title: 'HTML编辑',
              width: '100%',
              height: 'auto',
              htmlsource: 'outid',
              content: '',
            }],
            pageurl: '/article',
          });
          break;

        case '/articlepagelist':
          setPage({
            id: NewGuid(),
            name: 'articlepagelist',
            pagecontent: [{
              type: 'articlelist',
              key: NewGuid(),
              title: '文章列表',
              articlegroupid: 'outid',
              width: '100%',
              height: 'auto',
              direction: 'column',   //方向
              moretitle: '',
              displaytype: 'text', //展示方式 图 文 图加文
              imagewidth: 'auto',
              imageheight: 'auto',
              textwidth: '90%',
              textheight: 'auto',
              subitemcount: 999,
              subitemdirection: 'row-reverse',
            }],
            pageurl: '/articlepagelist',
          });
          break;

        default:
          break;
      }
    }
  }

  //处理pageApis变化后调用接口
  useEffect(() => {
    const getData = (api: DatavDataSource) => {
      const params = {
        "requestUrl": dealParam(api.requestUrl, pageParam),
        "requestType": api.requestType,
        "headers": api.headers,
        "body": dealParamObj(api.body, pageParam),
      }
      reqAgencyRequest(params).then(res => {
        const result = executeFunctionFilter(api.filters, res);
        let tempApiParam = _.cloneDeep(apiParam);
        tempApiParam = {
          ...tempApiParam,
          ...result,
        }
        dispatch(setApiParam(tempApiParam));
      })
    }

    pageApis.forEach((api: any) => {
      getData(api);
    });

    let tempApiTimers: any[] = [];
    pageApis.filter((api: any) => api.requestSec > 0).forEach((api: any) => {
      const interval = setInterval(() => {
        getData(api);
      }, api.requestSec * 1000);

      tempApiTimers.push(interval);
    });
    return () => {
      tempApiTimers.forEach((timer: any) => {
        clearInterval(timer); // 清除定时器
      })
    }
  }, [pageApis]);


  const handleScroll = (event: any) => {
    // console.log(window.pageYOffset);  

    // const scrollTop= window.pageYOffset;
    // dispatch(setScrollTop(scrollTop));

    const scrollTop = event.target.scrollTop;
    dispatch(setScrollTop(scrollTop));
  }

  if (page?.pageStyle === 'datav') {
    return (
      <div style={{
        width: '100%',
        height: '100vh',
        overflow: 'hidden'
      }}>
        <div id='datavcontent' className={styles.datavcontent}
          style={{
            width: `${page.pageWidth}px`,
            height: `${page.pageHeight}px`,
            backgroundImage: `url(${_.isEmpty(page.bgurl) ? defaultDatavBgUrl : page.bgurl})`,
            transform: `scale(${screenWidth / page?.pageWidth},${screenHeight / page?.pageHeight})`
          }}>
          {pageParam?.showpageparam && (
            <div style={{ position: "absolute", color: "white" }}>
              {JSON.stringify(pageParam, null, 2)}
            </div>
          )}
          {page && getViewFromConfig(page.pagecontent)}
        </div>
      </div>
    )
  }
  return (
    <div id='maincontent' className={styles.container}
      onScroll={handleScroll}
    >
      {page && getViewFromConfig(page.pagecontent)}
    </div>
  )
};

export default Main;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\common\jsoninput\index.jsx
 * @LastEditTime: 2024-06-06 15:03:34
 */
import React, { useState, useEffect } from "react";
import { Button, Input, Tooltip, message, Modal } from 'antd';
import { AlignRightOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { showColors } from 'utils/componentdefine';
import { checkJSON } from 'utils/common';
import _ from 'lodash';
import styles from './index.module.scss';

const { TextArea } = Input;

interface IProps {
  valueType: 'object' | 'array'; //对象或数组  
  value?: any;
  onChange?: (value: any) => void;
}

//JSON格式录入
const JsonInput: React.FC<IProps> = (props: IProps) => {
  const { valueType, value, onChange } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [txt, setTxt] = useState('');


  const handleTxtChange = (e: any) => {
    setTxt(e.target.value);
  }

  const handleClick = () => {
    setTxt(JSON.stringify(value, null, 2));
    setModalVisible(true);
  }

  const handleOK = () => {
    // console.log(txt,valueType);
    if (!checkJSON(txt, valueType)) {
      message.warn('格式错误');
      return;
    }
    onChange && onChange(JSON.parse(txt));
    setModalVisible(false);
  }

  const handleCancel = () => {
    setModalVisible(false);
  }

  const handleJsonEdit=()=>{
    window.open("/jsonedit", '_blank');
  }

  return (
    <Input.Group compact>
      <Input
        style={{
          width: "calc(100% - 32px)"
        }}
        value={JSON.stringify(value)} />
      <Button icon={<AlignRightOutlined />} onClick={handleClick} />
      <Modal
        title='编辑JSON'
        okText="确认"
        cancelText="取消"
        visible={modalVisible}
        onOk={handleOK}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleJsonEdit}>Json生成器</Button>,
          <Button onClick={handleCancel}>取消</Button>,
          <Button type="primary" onClick={handleOK}>确认</Button>,
        ]}
      >
        <div className={styles.container}>
          <TextArea
            style={{ width: '100%' }}
            rows={10}
            value={txt}
            onChange={handleTxtChange}
            onDoubleClick={() => {
              if (checkJSON(txt, valueType)) {
                setTxt(JSON.stringify(JSON.parse(txt), null, 2))
              }
            }} />
          {
            (!checkJSON(txt, valueType)) && (
              <Tooltip title="错误的对象结构">
                <InfoCircleOutlined className={styles.warn} />
              </Tooltip>
            )
          }
          {/* <Button onClick={()=>{
            console.log(JSON.parse(txt));
          }}>test</Button> */}
        </div>
      </Modal>
    </Input.Group>
  );
};

export default JsonInput;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\empty\cube\pllcube\index.tsx
 * @LastEditTime: 2024-08-29 17:36:22
 */
// import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Tooltip, Modal, Form, Input, Select, Avatar, Menu, Carousel, Image, Button,
  ConfigProvider, Popover, Statistic, InputNumber, Checkbox, Dropdown, message, Table, Tag
} from 'antd';
import { RightCircleOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import BaseCube from '../basecube/index';
import _ from 'lodash';
import { BaseCubeData, PllDatas } from '../config/cubeconfig';
import PlantCube from './plantcube/index';
import styles from './index.module.scss';
import Countdown from "antd/lib/statistic/Countdown";
import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import { log } from "console";


const columns: ColumnsType<any> = [
  {
    title: 'PLL',
    dataIndex: 'index',
    key: 'index',
    width: 110,
    render: index => <span>{`${index}.${PllDatas[index - 1].title}-Perm`}</span>,
  },
  {
    title: '状态',
    dataIndex: 'state',
    key: 'state',
    width: 70,
    render: state => <span>{state === 1 ? '完成' : '未完成'}</span>,
  },
  {
    title: '提示',
    dataIndex: 'tip',
    key: 'tip',
    width: 70,
    render: tip => <span>{tip === 1 ? '无' : '有'}</span>,
  },
  {
    title: '时长',
    dataIndex: 'duration',
    key: 'duration',
    width: 80,
    sorter: (a, b) => a.duration - b.duration,
    sortDirections: ['descend', 'ascend'],
  },

  {
    title: '错误',
    key: 'err',
    dataIndex: 'err',
    render: (_, { err }) => (
      <>
        {err.map((index: number) => {
          return (
            <Tag style={{ margin: "0 8px 5px 0" }} color="#f50">
              {PllDatas[index - 1].title}
            </Tag>
          );
        })}
      </>
    ),
    sorter: (a, b) => a.err.length - b.err.length,
    sortDirections: ['descend', 'ascend'],
  },
]


//数组6*9 top bottom front right back left  top bottom 从上向下看依次排列 其他从外边看依次排列
const PllCube = (props: any) => {
  const elementRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(200);
  const [parentHeight, setParentHeight] = useState(200);

  const [tx, settx] = useState(0);
  const [ty, setty] = useState(0);
  const [tz, settz] = useState(0);
  const [rx, setrx] = useState(0);
  const [ry, setry] = useState(0);
  const [rz, setrz] = useState(0);
  const [cubedata, setcubedata] = useState(BaseCubeData);
  const [plldata, setplldata] = useState(PllDatas[0].data);
  const [pllIndex, setpllIndex] = useState(0);
  const [showtip, setShowtip] = useState(false); //显示提示
  const [errorSel, setErrorSel] = useState<number[]>([]);  //错误的index
  const [startTime, setStartTime] = useState(moment()); //开始时间
  const [countData, setCountData] = useState<any>([]); //统计数据 {index,duration,state: 1.完成 0.未完成,errcount: ,err:,tip}
  const [countVisible, setCountVisible] = useState(false); //显示统计数据 
  const [duration, setDuration] = useState("");

  useEffect(() => {
    // 监听窗口大小变化
    const handleResize = () => {
      if (elementRef.current) {
        setParentWidth((elementRef.current as any).offsetWidth);
        setParentHeight((elementRef.current as any).offsetHeight);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();
    return () => {
      // 清除监听
      window.removeEventListener('resize', handleResize);
    };
  }, [elementRef.current]);

  useEffect(() => {
    settz(100);
    setrx(-15);
    setry(45);
    GetPLLData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = moment.duration(moment().diff(startTime));
      setDuration(moment.utc(diff.asMilliseconds()).format('HH:mm:ss'));
    }, 500);

    return () => clearInterval(interval); // 清除定时器
  }, [startTime]);

  const GetPLLData = () => {
    let data = _.cloneDeep(BaseCubeData);
    const randomIndex = Math.floor(Math.random() * PllDatas.length);

    let pllData = _.cloneDeep(PllDatas[randomIndex].data);
    const radamnum = Math.floor(Math.random() * 4);
    for (let i = 0; i < pllData.length; i++) {
      for (let j = 0; j < pllData[i].length; j++) {
        let tempnum = pllData[i][j];
        let radomtempnum = (tempnum + radamnum) % 4; //随机排列方向
        pllData[i][j] = radomtempnum;
        data[2 + i][j] = radomtempnum + 3;
      }
    }
    setcubedata(data);
    setplldata(pllData);
    setpllIndex(randomIndex + 1);
  }

  //新增统计数据 state: 0: 未完成 1：完成
  const addCountData = (state: number) => {
    let tempCountData = _.cloneDeep(countData);
    console.log('addCountData', state);

    tempCountData.push({
      index: pllIndex,
      duration: moment().diff(startTime, 'seconds'),
      state: state,
      errcount: errorSel.length,
      err: errorSel,
      tip: showtip,
    })
    setCountData(tempCountData);
  }

  const handleRefresh = (state: number) => {
    GetPLLData();
    addCountData(state);
    setErrorSel([]); //清空错误数据
    setShowtip(false); //关闭提示   
    setStartTime(moment()); //开始计时    
  }

  const handleCheck = (index: number) => {
    if (index === pllIndex) {
      message.success('弟弟答对啦！');
      handleRefresh(1);
    } else {
      if (!errorSel.includes(index)) {
        let temperrorSel = _.cloneDeep(errorSel);
        temperrorSel.push(index);
        setErrorSel(temperrorSel);
      }
    }
  }

  const handleTip = () => {
    console.log(parentWidth, parentHeight);
    setShowtip(true);
  }

  return (
    <div className={styles.container}>
      <div className={styles.titleline}>
        PLL六格观察法
      </div>
      <div className={styles.bottom}>
        <div ref={elementRef} className={styles.cubebar}>
          <div
            className={styles.left}
            style={{
              transform: `scale(${Math.min(parentWidth / 400, parentHeight / 400)}) translateX(${(Math.min(parentWidth, parentHeight) - 400) / 2}px) translateY(${(Math.min(parentWidth, parentHeight) - 400) / 2}px)`,
            }}
          >
            <BaseCube
              tx={tx}
              ty={ty}
              tz={tz}
              rx={rx}
              ry={ry}
              rz={rz}
              cubedata={cubedata}
            />
          </div>
          <div className={styles.right}>
            <h1 className={styles.info}>{`第${countData.length + 1}盘计时：${duration}`}</h1>
            {showtip && (
              <div className={styles.plantcube}>
                <span className={styles.title}>{`${pllIndex}.${PllDatas.find(o => o.index === pllIndex)?.title}-Perm`}</span>
                <div className={styles.plantcubecontiner}>
                  <PlantCube data={plldata} />
                </div>
              </div>
            )}
            <div className={styles.btntool}>
              <Button
                className={styles.btn}
                type="primary"
                onClick={() => { handleRefresh(0) }}>
                切换
              </Button>
              <Button
                className={styles.btn}
                type="primary"
                onClick={handleTip}>
                提示
              </Button>
              <Button
                className={styles.btn}
                type="primary"
                disabled={countData.length === 0}
                onClick={() => { setCountVisible(true) }}>
                统计
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.plantcubebar}>
          {PllDatas.map(item => (
            <div
              key={`plantcube${item.index}`}
              className={styles.pllitem}
              onClick={() => { handleCheck(item.index) }}>
              <span className={styles.title}
                style={{ color: errorSel.includes(item.index) ? "red" : "black" }}>
                {`${item.index}.${item.title}`}
              </span>
              <div className={styles.plantcube}>
                <PlantCube data={item.data} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal title='统计数据'
        width={650}
        okText="确认"
        cancelText="取消"
        visible={countVisible}
        onOk={() => { setCountVisible(false) }}
        onCancel={() => { setCountVisible(false) }}>
        <div className={styles.modal}>
          <h3 style={{ color: "#fa541c", margin: "10px" }} >
            {`弟弟挑战${countData.length}盘，完成${countData.filter((item: any) => item.state === 1).length
              }盘，提示${countData.filter((item: any) => item.tip).length
              }次，错误${countData.reduce((pre: number, cur: any) => { return pre + cur.errcount }, 0)
              }次，平均耗时${(countData.reduce((pre: number, cur: any) => { return pre + cur.duration }, 0) / countData.length).toFixed(3)
              }秒，明细如下：`}
          </h3>
          <div className={styles.table}>
            <Table columns={columns} 
            dataSource={countData} 
            pagination={{ defaultPageSize: 5, pageSizeOptions: [5, 10, 20, 50, 100] }}            
            scroll={{ y: 295 }} />
          </div>
        </div>
      </Modal>
    </div>
  )
};


{/* <div className={styles.line}>
          <span className={styles.label}>tx:</span>
          <Input value={tx} onChange={(e: any) => settx(parseFloat(e.target.value))} />
          <PlusOutlined onClick={() => { settx((tx) + 1) }} />
          <MinusOutlined onClick={() => { settx((tx) - 1) }} />
        </div>
        <div className={styles.line}>
          <span className={styles.label}>ty:</span>
          <Input value={ty} onChange={(e: any) => setty(parseFloat(e.target.value))} />
          <PlusOutlined onClick={() => { setty((ty) + 1) }} />
          <MinusOutlined onClick={() => { setty((ty) - 1) }} />
        </div>
        <div className={styles.line}>
          <span className={styles.label}>tz:</span>
          <Input value={tz} onChange={(e: any) => settz(parseFloat(e.target.value))} />
          <PlusOutlined onClick={() => { settz((tz) + 1) }} />
          <MinusOutlined onClick={() => { settz((tz) - 1) }} />
        </div>
        <div className={styles.line}>
          <span className={styles.label}>rx:</span>
          <Input value={rx} onChange={(e: any) => setrx(parseFloat(e.target.value))} />
          <PlusOutlined onClick={() => { setrx((rx) + 1) }} />
          <MinusOutlined onClick={() => { setrx((rx) - 1) }} />
        </div>
        <div className={styles.line}>
          <span className={styles.label}>ry:</span>
          <Input value={ry} onChange={(e: any) => setry(parseFloat(e.target.value))} />
          <PlusOutlined onClick={() => { setry((ry) + 1) }} />
          <MinusOutlined onClick={() => { setry((ry) - 1) }} />
        </div>
        <div className={styles.line}>
          <span className={styles.label}>rz:</span>
          <Input value={rz} onChange={(e: any) => setrz(parseFloat(e.target.value))} />
          <PlusOutlined onClick={() => { setrz((rz) + 1) }} />
          <MinusOutlined onClick={() => { setrz((rz) - 1) }} />
        </div>
        <div className={styles.line}>
          <Button onClick={handleGetCubeData}>生成</Button>
        </div>
        <div style={{
          width: 200,
          height: 200
        }}>
          <PlantCube data={selectplldata.data} />
        </div>

        <div className={styles.line}>
          <span className={styles.label}>PLL公式:</span>
          <Select style={{ width: 120 }}
          value={selectplldata.title}
          onChange={value => {
            setselectplldata(PllDatas.find(o => o.title === value) || PllDatas[0]);
          }}
          options={PllDatas.map(item => {
            return {
              value: item.title,
              label: item.title,
            }
          })} />
        </div> */}

export default PllCube;
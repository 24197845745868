/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\pageedit\pagemenuedit\menuitemlist\index.tsx
 * @LastEditTime: 2022-12-20 19:57:00
 */

import React from "react";
import {
  PlusOutlined
} from '@ant-design/icons';
import MenuItem,{defaultValue} from './menuitem';
import _ from 'lodash';
import styles from './index.module.scss';

interface IProps {
  value?: any;
  onChange?: (value: any) => void;
}

const MenuItemList: React.FC<IProps> = (props: IProps) => {
  const { value, onChange } = props;

  const handleItemChange = (item: any, index: number) => {
    const tempvalue = _.cloneDeep(value);
    tempvalue[index] = {...item};
    onChange && onChange(tempvalue);
  }

  const handleMoveUp=(index:number)=>{
    if (index===0) return;
    const tempvalue = _.cloneDeep(value);
    [tempvalue[index],tempvalue[index-1]]=[tempvalue[index-1],tempvalue[index]];
    onChange && onChange(tempvalue);
  }

  const handleMoveDown=(index:number)=>{
    if (index===value.length-1) return;
    const tempvalue = _.cloneDeep(value);
    [tempvalue[index],tempvalue[index+1]]=[tempvalue[index+1],tempvalue[index]];
    onChange && onChange(tempvalue);
  }

  const handleDel=(index:number)=>{
    const tempvalue = _.cloneDeep(value);
    _.pullAt(tempvalue, index);
    onChange && onChange(tempvalue);
  }

  const handleAdd=()=>{
    const tempvalue = _.cloneDeep(value);
    tempvalue.push({...defaultValue()});
    onChange && onChange(tempvalue);
  }

  return (
    <div className={styles.container}>
      {value && value.map((item: any, index: number) => (
        <MenuItem
          value={item}
          onChange={e => { handleItemChange(e, index) }}
          onMoveUp={() => { handleMoveUp(index) }}
          onMoveDown={() => { handleMoveDown(index) }}
          onDel={() => { handleDel(index) }} />
      ))}
      <div className={styles.add} onClick={handleAdd}><PlusOutlined />新增</div>
    </div>
  )
};

export default MenuItemList;
/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-11-28 10:40:06
 * @FilePath: \website\src\components\picture\index.jsx
 * @LastEditTime: 2024-04-25 12:29:57
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from 'lodash';
import { Image } from 'antd';
import TweenOne from 'rc-tween-one';
import { OverPack, Parallax } from 'rc-scroll-anim';
import { getAnimation, getMouseAnimation, selectBorderStyle } from 'utils/componentdefine';
import { dealParam } from 'utils/common';
import styles from './index.module.scss';

//跳转方式
export const LinkTypes = [{
  id: 0,
  title: '不跳转',
  setting: 'none',
}, {
  id: 1,
  title: '页面跳转',
  setting: '_self',
}, {
  id: 2,
  title: '弹窗跳转',
  setting: '_blank',
}, {
  id: 3,
  title: '放大图片',
  setting: 'preview',
},]

//定位方式
export const Positions = [{
  id: 0,
  title: '默认',
  setting: 'static',
}, {
  id: 1,
  title: '绝对位置',
  setting: 'absolute',
},]

export type PictureConfig = {
  type: String;  //类型 container
  key: String; //主键
  title: String; //标题
  width?: String; //宽度
  height?: String;  //高度 
  aspectRatio?: String; //宽高比
  margin?: String;  //外边距
  borderradius?: String; //圆角
  position?: String; //定位方式
  left?: String; //左 
  top?: String;  //上
  right?: String;  //右
  bottom?: String;  //下
  src: String; //图片地址
  url?: String;  //外链地址
  linkType: String;  //跳转方式
  effectType?: String; //动画效果
  customAnimation?: Object; //自定义动效
  effectMode?: String; //动效模式 进场动画/鼠标移入动画
}

interface IProps {
  config: PictureConfig;
  effectvisible?: true;
  subParam?: any; //父组件传给子组件的参数
}

//图片
const Picture: React.FC<IProps> = (props: IProps) => {
  const pageParam = useSelector((state: any) => state.global.pageParam);
  let { config, subParam } = props;
  subParam = {
    ...pageParam,
    ...subParam,
  }
  const effectvisible = props.effectvisible;
  const effectType = config.effectType || 'none';
  const effectMode = config.effectMode || 'init';

  const [visible, setVisible] = useState(false);
  const [paused, setPaused] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [custommoment, setCustommoment] = useState(0);

  const editComponentKey = useSelector((state: any) => state.global.editComponentKey);
  let style = {
    width: config.width,
    height: config.height,
    aspectRatio: config?.aspectRatio || "auto",
    margin: config.margin,
    borderRadius: config.borderradius,
    position: config.position,
    left: config?.left || "auto",
    top: config?.top || "auto",
    right: config?.right || "auto",
    bottom: config?.bottom || "auto",
  };
  if (editComponentKey === config.key) {
    style.border = selectBorderStyle;
  }

  //滚动条方向
  const scrollDirection = useSelector((state: any) => state.global.scrollDirection);

  //向下滚动效果触发
  useEffect(() => {
    console.log(getMouseAnimation(effectType, config?.customAnimation));
    if (effectMode === 'scrolldown') {  //scrolldown
      if (scrollDirection === 1) {
        setPaused(false);
        setReverse(true);
        setCustommoment(null);
      } else {
        setPaused(false);
        setReverse(false);
        setCustommoment(0);
      }
    }
  }, [scrollDirection]);

  const getPreview = () => {
    if (config.linkType === 'preview') {
      return (
        <Image
          style={{ display: 'none' }}
          //如果有url以url为准，否则以src为准
          src={dealParam(config.url || config.src, subParam)}
          preview={{
            visible,
            onVisibleChange: (value) => {
              setVisible(value);
            },
          }} />
      )
    } else {
      return ''
    }
  }

  const key = `${config.key}${config?.index || ''}`;

  const getPic = () => {
    if (effectType === 'none') return (
      <>
        <img src={dealParam(config.src, subParam)}
          style={style}
          onClick={() => { setVisible(true) }} />
        {getPreview()}
      </>
    )

    if (effectMode === 'init') return effectvisible ? (
      <OverPack
        targetId="maincontent"
        replay={true}>
        <TweenOne
          animation={getAnimation(effectType, config?.customAnimation)}
        >
          <img src={dealParam(config.src, subParam)}
            style={style}
            onClick={() => { setVisible(true) }} />
          {getPreview()}
        </TweenOne>
      </OverPack>
    ) : ''

    if (effectMode === 'mousein') return (
      <TweenOne
        key={key}
        id={key}
        reverse={reverse}
        moment={custommoment}
        animation={getMouseAnimation(effectType, config?.customAnimation)}
        paused={paused} //??????????到底起作用不
      >
        <img src={dealParam(config.src, subParam)}
          onMouseEnter={() => {
            console.log('onMouseEnter');
            setPaused(false);
            setReverse(true);
            setCustommoment(null);
          }}
          onMouseLeave={() => {
            console.log('onMouseLeave');
            setPaused(false);
            setReverse(false);
            setCustommoment(0);
          }}
          // onClick={() => { setVisible(true) }}
          style={style} />
        {getPreview()}
      </TweenOne>
    )

    if (effectMode === 'scrolldown') return (
      <TweenOne
        reverse={reverse}
        moment={custommoment}
        animation={getMouseAnimation(effectType, config?.customAnimation)}
        paused={paused} //??????????到底起作用不
      >
        <img src={dealParam(config.src, subParam)}
          onClick={() => { setVisible(true) }}
          style={style} />
        {getPreview()}
      </TweenOne>
    )
  }

  if (effectMode === 'screencenter') return (
    <Parallax
      targetId="editcontent"
      animation={{
        playScale: [0.5, 0.8],
        ...getAnimation(effectType, config?.customAnimation),
      }}>
      <img src={dealParam(config.src, subParam)}
        style={style} />
    </Parallax>
  )

  //处理自定义工作区问题
  let linkUrl = dealParam(config.url, subParam);
  let strs = linkUrl.split("&target=");
  linkUrl = strs[0];
  let linkType;
  if (strs.length > 1) {
    linkType = strs[1];
  }

  return (config.linkType === 'none' || config.linkType === 'preview') ? getPic() : (
    <a
      href={linkUrl}
      target={linkType || config.linkType}>
      {getPic()}
    </a>
  )
};

export default Picture;
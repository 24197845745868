/*
 * @Description: 
 * @Version: 1.0
 * @Autor: gpf
 * @Date: 2022-08-01 14:28:34
 * @FilePath: \website\src\pages\articlemanager\index.tsx
 * @LastEditTime: 2023-05-29 10:41:55
 */

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { message, Tabs, Tooltip, Modal, Form, Input, Select, Avatar, Radio, Carousel, Image, Button, Space, Popconfirm, Table } from 'antd';
import _ from 'lodash';
import { reqGetArticles, reqAddArticle, reqUpdateArticle, reqRemoveArticle, reqGetArticleGroups, reqGetSignature } from 'api/api';
import UEditor, { getContent, setContent } from "components/ueditor/Ueditor";
import styles from './index.module.scss';
import moment from "moment";
import ImageInput from "components/common/imageinput";

const ArticleManager = () => {
  const [articles, setArticles] = useState<any>([]);
  const [articleGroups, setArticleGroups] = useState<any>([]);
  const [articleGroup, setArticleGroup] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [ueditorVisible, setUeditorVisible] = useState(false);
  const [article, setArticle] = useState<any>({});
  const [openMode, setOpenMode] = useState(0); //0：浏览 1：新增 2：修改
  const [html, setHtml] = useState('');
  const [imageOSS, setImageOSS] = React.useState<any>({}) //图片oss地址

  const [form] = Form.useForm();
  const originalType = Form.useWatch('originalType', form);
  const coverImage = Form.useWatch('coverImage', form);

  const organization = useSelector((state: any) => state.global.organization);

  const columns = [
    {
      title: '文章标题',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: '简介',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: '分类名称',
      dataIndex: 'articlegrouptitle',
      key: 'articlegrouptitle'
    },
    {
      title: '缩略图',
      key: 'coverImage',
      render: (_: any, record: any) => (
        <Avatar src={<Image src={record.coverImage} style={{ width: 32 }} />} />
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (_: any, record: any) => (
        <Space size="middle">
          <a onClick={() => { handleEdit(record) }}>修改</a>
          <Popconfirm
            title={`确认删除?`}
            onConfirm={() => { handleDel(record) }}>
            <a >删除</a>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  useEffect(() => {
    handleRefresh();
    getArticleGroups();
  }, [organization]);

  useEffect(() => {
    handleRefresh();
    getArticleGroups();
    //获取ImageOss地址
    reqGetSignature({ bucketType: 2, system: 'muzhiyun' }).then(res => {
      setImageOSS(res.Data[0]);
    })
  }, []);


  const handleRefresh = () => {
    if (organization?.organizationGuid) {
      const params = {
        organizationId: organization?.organizationGuid,
        articletype: '0',
        page: 1,
        count: 999
      }
      reqGetArticles(params).then(res => {
        setArticles(res.items.map((item: any) => {
          return {
            ...item,
            articlegrouptitle: item.articlegroup.title
          }
        }));
      })
    }
  }

  const getArticleGroups = () => {
    if (organization?.organizationGuid) {
      const param={
        organizationId: organization?.organizationGuid,
        articletype: 0,
      }
      reqGetArticleGroups(param).then(res => {
        setArticleGroups(res.items
          .sort((a: any, b: any) => { return moment(a.createdAt).diff(moment(b.createdAt), 'second') }));
      })
    }
  }

  const handleNew = () => {
    form.setFieldsValue({
      title: '',
      description: '',
      coverImage: '',
      originalType: 102,
    });
    setHtml('');
    setOpenMode(1);
    setModalVisible(true);
  }

  const handleEdit = (record: any) => {
    setArticle(record);
    form.setFieldsValue({
      ...record
    });
    setHtml(record.content);
    setOpenMode(2);
    setModalVisible(true);
  }

  const handleDel = (record: any) => {
    reqRemoveArticle(record.id).then(res => {
      message.success(res.msg);
      handleRefresh();
    })
  }

  const handleOK = () => {
    form.validateFields().then(async (formdata) => {
      let res: any = {};
      if (openMode === 1) {
        res = await reqAddArticle({
          organizationId: organization.organizationGuid,
          url: '',
          content: html,
          ...formdata,
        });
        message.success(res.msg);
      }
      else if (openMode === 2) {
        res = await reqUpdateArticle({
          ...article,
          content: html,
          ...formdata,
        });
        message.success(res.msg);
      }
      // console.log(formdata);

      setModalVisible(false);
      setOpenMode(0);
      handleRefresh();
    });
  }

  const handleCancel = () => {
    setModalVisible(false);
    setOpenMode(0);
  }

  const handleDesignArticle = () => {
    setUeditorVisible(true);
    setContent(html);
  }

  const handleUEditorOk = () => {
    setHtml(getContent());
    setUeditorVisible(false);
  }

  const handleUEditorCancel = () => {
    setUeditorVisible(false);
  }

  const getArticleGroupOptions = () => {
    let tempitems = articleGroups.map((item: any) => ({
      value: item.id,
      label: item.title
    }));
    tempitems = [{
      value: '',
      label: '全部',
    }].concat(tempitems);
    return tempitems;
  }

  const handleTest = () => {
    console.log(articleGroups);
    console.log(organization);
    console.log(getArticleGroupOptions());

  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        文章管理
      </div>
      <div className={styles.tools}>
        <Select
          value={articleGroup}
          onChange={setArticleGroup}
          style={{ width: 120, marginRight: "5px" }}
          options={getArticleGroupOptions()} />

        <Button type="primary" className={styles.btn} onClick={handleNew}>新增</Button>
        {/* <Button type="primary" className={styles.btn} onClick={handleTest}>测试</Button> */}
      </div>
      <div className={styles.content}>
        <Table columns={columns}
          scroll={{ y: 380 }}
          dataSource={articles.filter((item: any) => { return articleGroup === '' || item.articlegroupid === articleGroup })} />
      </div>
      {/* <div style={{width:"80%",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <UEditor 
        // value={html} onChange={setHtml}
        />
      </div> */}
      <Modal title='编辑'
        visible={modalVisible}
        onOk={handleOK}
        onCancel={handleCancel}>
        <Form
          form={form}
          name="control-ref"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
          preserve={false}
        >
          {/* 显示标题 */}
          <Form.Item
            name="title"
            label="文章标题"
            rules={[{ required: true, max: 200 }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="简介"
            rules={[{ required: true, max: 200 }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="articlegroupid"
            label="分类名称"
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: 120 }}
              options={articleGroups.map((item: any) => ({ value: item.id, label: item.title }))} />
          </Form.Item>
          <Form.Item
            name="coverImage"
            label="缩略图"
          >
            <ImageInput dataOSS={imageOSS} />
            {/* <Input addonAfter={(<Avatar src={<Image src={coverImage} style={{ width: 32 }} />} />)} /> */}
          </Form.Item>
          <Form.Item
            name="originalType"
            label="模式"
            rules={[{ required: true }]}
          >
            <Radio.Group >
              <Radio value={101}>外链</Radio>
              <Radio value={102}>内容</Radio>
            </Radio.Group>
            {/* <Button type="primary" onClick={() => { setUeditorVisible(true) }}>编辑内容</Button> */}
          </Form.Item>
          {originalType === 101 ? (
            <Form.Item
              name="url"
              label="外链地址"
            >
              <Input />
            </Form.Item>) : ''}
          {originalType === 102 ? (
            <Form.Item label="内容">
              <Button type="primary" onClick={handleDesignArticle}>设计</Button>
            </Form.Item>) : ''}
        </Form>
      </Modal>

      <Modal title='内容编辑'
        visible={ueditorVisible}
        width={1000}
        onOk={handleUEditorOk}
        onCancel={handleUEditorCancel}>
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <UEditor />
        </div>
      </Modal>
    </div>
  )
};

export default ArticleManager;